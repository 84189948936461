export async function ReadFileFirstLine(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    const limit = 2500

    reader.onload = (e) => {
      const text = e.target.result
      const lines = text.split("\r")
      if (lines.length > 0) {
        const primeiraLinha = lines[0]
        // console.log(primeiraLinha);
        resolve(primeiraLinha)
      }
    }
    reader.readAsText(file.slice(0, limit))
  })
}

export async function ValidaFileECD(file, fileType) {
  const result = await ReadFileFirstLine(file)
  if (fileType === 8) {
    if (result.includes("LECD")) {
      const dados = result
      const datas = dados.substring(0, 100).split("|")
      const dados3 = datas[3].substring(2)
      const data = `${dados3.substring(2, 6)}-${dados3.substring(0, 2)}`

      return {
        sucesso: true,
        arquivo: file.name,
        mensagem: "INCLUI LECD",
        data
      }
    }
    throw new Error("Invalid")
  }

  return [false, file.name, " NAO EH TIPO 8"]
}

export async function ValidaFileECDFISCAL(file, fileType) {
  const result = await ReadFileFirstLine(file)
  if (fileType === 9) {
    const dados = result
    const protocolo = dados.substring(0, 100).split("|")
    if (
      protocolo[2].includes("010") ||
      protocolo[2].includes("011") ||
      protocolo[2].includes("012") ||
      protocolo[2].includes("013") ||
      protocolo[2].includes("014") ||
      protocolo[2].includes("015") ||
      protocolo[2].includes("016") ||
      protocolo[2].includes("017")
    ) {
      const coluna = dados.substring(0, 100).split("|")
      const datas = coluna[4].substring(2)
      const data = `${datas.substring(2, 6)}-${datas.substring(0, 2)}`
      const cnpj = coluna[7]
      return {
        sucesso: true,
        arquivo: file.name,
        mensagem: "Deu certo",
        data,
        cnpj
      }
    }
    throw new Error("Invalid")
  }

  return [false, file.name, " NAO EH TIPO 9"]
}

export function removeDuplicates(arr) {
  const unique = []
  arr.forEach((element) => {
    if (!unique.includes(element)) {
      unique.push(element)
    }
  })
  return unique
}

export async function ValidaFileECF(file, fileType) {
  const result = await ReadFileFirstLine(file)
  if (fileType === 14) {
    if (result.includes("LECF")) {
      const dados = result
      const datas = dados.substring(0, 100).split("|")
      const data = datas[10].substring(4, 8)

      return {
        sucesso: true,
        arquivo: file.name,
        mensagem: "INCLUI LECF",
        data
      }
    }
    throw new Error("Invalid")
  }
}

export async function readFilesInChunks(files, cb) {
  const CHUNK_SIZE = 1024 * 1024 // 1 MB chunk size
  const decoder = new TextDecoder("utf-8") // create a new TextDecoder object with UTF-8 encoding

  const resultados = []
  files.forEach((file, idx) => {
    const versoesValidas = {
      "003": true,
      "004": true,
      "005": true,
      "006": true
    }

    const validacao = {
      versaoValida: false,
      cnpjFiliais: [],
      data: null,
      fileName: file.name
    }

    let offset = 0 // current offset into the file
    const filesize = file.size
    // create a new file reader
    const reader = new FileReader()
    // set up the onload function to read the next chunk of data
    reader.onload = () => {
      const buffer = reader.result // get the file buffer as an array buffer
      const data = new Uint8Array(buffer) // convert the array buffer to a Uint8Array

      const chunkString = decoder.decode(data)
      const lines = chunkString.split("\n")
      for (const line of lines) {
        const first6 = line.substring(0, 6)
        const ultimoCaractere = line.at(-1)
        if (ultimoCaractere === "\r") {
          if (first6 === "|0000|") {
            const dadosIdentificacao = line.split("|")
            const codigoDaVersaoDoLayout = dadosIdentificacao[2]
            const dataMMYYYY = dadosIdentificacao[6].substring(2)
            if (versoesValidas[codigoDaVersaoDoLayout] !== true) {
              validacao.versaoValida = false
              // Força parada da leitura do arquivo.
              offset = filesize
              break
            } else {
              validacao.versaoValida = true
              validacao.data = `${dataMMYYYY.substring(
                2,
                6
              )}-${dataMMYYYY.substring(0, 2)}`
            }
          }

          if (first6 === "|0140|") {
            const cnpj = line.split("|")[4]
            validacao.cnpjFiliais.push(cnpj)
          }

          if (first6 === "|9999|") {
            // Força parada da leitura do arquivo.
            offset = filesize
          } else {
            offset += line.length // Atualiza o offset caso a linha terminar com sucesso.
          }
        }
      }

      // Ira ler o proximo chunk caso o offset for menor que o tamanho do arquivo -1
      // o -1 representa o ato de o ultimo caractere do arquivo nao ser um \r
      if (offset < filesize - 1) {
        readChunk() // read the next chunk of data
      } else {
        resultados.push(validacao)
        if (idx === files.length - 1) {
          cb(resultados)
          console.log(resultados)
        }
      }
    }

    // read the next chunk of data
    const readChunk = () => {
      const blob = file.slice(offset, offset + CHUNK_SIZE) // get the next chunk of data
      reader.readAsArrayBuffer(blob) // read the chunk as an array buffer
    }

    readChunk() // start reading the file
  })
}
