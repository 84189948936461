import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { toast, ToastContainer } from "react-toastify"

import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

import IconButton from "@mui/material/IconButton"
import SaveIcon from "@mui/icons-material/Save"

import { Alert } from "reactstrap"

import Modal from "@mui/material/Modal"
import api from "../../../../Config/api"

const styleModalErro = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 1,
  align: "center",
  fontSize: 10
}

const Painel = () => {
  const [openModalVazio, setOpenModalVazio] = useState(false)
  const handleOpenVazio = () => setOpenModalVazio(true)
  const handleCloseVazio = () => setOpenModalVazio(false)

  const params = useParams()

  const cnpj = params._id

  const [statusEdit, setStatusEdit] = useState("")

  // CIAP
  const [status27_id, setStatus27_id] = useState("")
  const [status27_0, setStatus27_0] = useState("")
  const [status27_1, setStatus27_1] = useState("")
  const [status27_2, setStatus27_2] = useState("")
  const [status27_3, setStatus27_3] = useState("")
  const [status27_4, setStatus27_4] = useState("")
  const [status27_5, setStatus27_5] = useState("")
  const [status27_6, setStatus27_6] = useState("")
  const [status27_7, setStatus27_7] = useState("")
  const [status27_8, setStatus27_8] = useState("")
  const [status27_9, setStatus27_9] = useState("")
  const [status27_10, setStatus27_10] = useState("")
  const [status27_11, setStatus27_11] = useState("")
  const [status27_12, setStatus27_12] = useState("")
  const [status27_13, setStatus27_13] = useState("")
  const [status27_14, setStatus27_14] = useState("")
  const [status27_15, setStatus27_15] = useState("")
  const [status27_16, setStatus27_16] = useState("")
  const [status27_17, setStatus27_17] = useState("")
  const [status27_18, setStatus27_18] = useState("")
  const [status27_19, setStatus27_19] = useState("")
  const [status27_20, setStatus27_20] = useState("")
  const [status27_21, setStatus27_21] = useState("")
  const [status27_22, setStatus27_22] = useState("")
  const [status27_23, setStatus27_23] = useState("")
  const [status27_24, setStatus27_24] = useState("")
  const [status27_25, setStatus27_25] = useState("")
  const [status27_26, setStatus27_26] = useState("")
  const [status27_27, setStatus27_27] = useState("")
  const [status27_28, setStatus27_28] = useState("")
  const [status27_29, setStatus27_29] = useState("")
  const [status27_30, setStatus27_30] = useState("")
  const [status27_31, setStatus27_31] = useState("")
  const [status27_32, setStatus27_32] = useState("")
  const [status27_33, setStatus27_33] = useState("")
  const [status27_34, setStatus27_34] = useState("")
  const [status27_35, setStatus27_35] = useState("")
  const [status27_36, setStatus27_36] = useState("")
  const [status27_37, setStatus27_37] = useState("")
  const [status27_38, setStatus27_38] = useState("")
  const [status27_39, setStatus27_39] = useState("")
  const [status27_40, setStatus27_40] = useState("")
  const [status27_41, setStatus27_41] = useState("")
  const [status27_42, setStatus27_42] = useState("")
  const [status27_43, setStatus27_43] = useState("")
  const [status27_44, setStatus27_44] = useState("")
  const [status27_45, setStatus27_45] = useState("")
  const [status27_46, setStatus27_46] = useState("")
  const [status27_47, setStatus27_47] = useState("")
  const [status27_48, setStatus27_48] = useState("")
  const [status27_49, setStatus27_49] = useState("")
  const [status27_50, setStatus27_50] = useState("")
  const [status27_51, setStatus27_51] = useState("")
  const [status27_52, setStatus27_52] = useState("")
  const [status27_53, setStatus27_53] = useState("")
  const [status27_54, setStatus27_54] = useState("")
  const [status27_55, setStatus27_55] = useState("")
  const [status27_56, setStatus27_56] = useState("")
  const [status27_57, setStatus27_57] = useState("")
  const [status27_58, setStatus27_58] = useState("")
  const [status27_59, setStatus27_59] = useState("")

  const [datas, setDatas] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/up27", { cnpj })
      const datasUp = await api.post("/checklist", { cnpj })
      let datas = []
      datas = datasUp.data.dados.cincoAnos
      let x = 0
      const meses = []
      while (x < datas.length) {
        meses.push({ id: x, data: datas[x] })
        x++
      }
      setDatas(meses)

      if (!cliente.data.up4) {
        setStatus27_id(cliente.data.tipo._id)
        setStatus27_0(cliente.data.tipo.statusCincoAnos[0])
        setStatus27_1(cliente.data.tipo.statusCincoAnos[1])
        setStatus27_2(cliente.data.tipo.statusCincoAnos[2])
        setStatus27_3(cliente.data.tipo.statusCincoAnos[3])
        setStatus27_4(cliente.data.tipo.statusCincoAnos[4])
        setStatus27_5(cliente.data.tipo.statusCincoAnos[5])
        setStatus27_6(cliente.data.tipo.statusCincoAnos[6])
        setStatus27_7(cliente.data.tipo.statusCincoAnos[7])
        setStatus27_8(cliente.data.tipo.statusCincoAnos[8])
        setStatus27_9(cliente.data.tipo.statusCincoAnos[9])
        setStatus27_10(cliente.data.tipo.statusCincoAnos[10])
        setStatus27_11(cliente.data.tipo.statusCincoAnos[11])
        setStatus27_12(cliente.data.tipo.statusCincoAnos[12])
        setStatus27_13(cliente.data.tipo.statusCincoAnos[13])
        setStatus27_14(cliente.data.tipo.statusCincoAnos[14])
        setStatus27_15(cliente.data.tipo.statusCincoAnos[15])
        setStatus27_16(cliente.data.tipo.statusCincoAnos[16])
        setStatus27_17(cliente.data.tipo.statusCincoAnos[17])
        setStatus27_18(cliente.data.tipo.statusCincoAnos[18])
        setStatus27_19(cliente.data.tipo.statusCincoAnos[19])
        setStatus27_20(cliente.data.tipo.statusCincoAnos[20])
        setStatus27_21(cliente.data.tipo.statusCincoAnos[21])
        setStatus27_22(cliente.data.tipo.statusCincoAnos[22])
        setStatus27_23(cliente.data.tipo.statusCincoAnos[23])
        setStatus27_24(cliente.data.tipo.statusCincoAnos[24])
        setStatus27_25(cliente.data.tipo.statusCincoAnos[25])
        setStatus27_26(cliente.data.tipo.statusCincoAnos[26])
        setStatus27_27(cliente.data.tipo.statusCincoAnos[27])
        setStatus27_28(cliente.data.tipo.statusCincoAnos[28])
        setStatus27_29(cliente.data.tipo.statusCincoAnos[29])
        setStatus27_30(cliente.data.tipo.statusCincoAnos[30])
        setStatus27_31(cliente.data.tipo.statusCincoAnos[31])
        setStatus27_32(cliente.data.tipo.statusCincoAnos[32])
        setStatus27_33(cliente.data.tipo.statusCincoAnos[33])
        setStatus27_34(cliente.data.tipo.statusCincoAnos[34])
        setStatus27_35(cliente.data.tipo.statusCincoAnos[35])
        setStatus27_36(cliente.data.tipo.statusCincoAnos[36])
        setStatus27_37(cliente.data.tipo.statusCincoAnos[37])
        setStatus27_38(cliente.data.tipo.statusCincoAnos[38])
        setStatus27_39(cliente.data.tipo.statusCincoAnos[39])
        setStatus27_40(cliente.data.tipo.statusCincoAnos[40])
        setStatus27_41(cliente.data.tipo.statusCincoAnos[41])
        setStatus27_42(cliente.data.tipo.statusCincoAnos[42])
        setStatus27_43(cliente.data.tipo.statusCincoAnos[43])
        setStatus27_44(cliente.data.tipo.statusCincoAnos[44])
        setStatus27_45(cliente.data.tipo.statusCincoAnos[45])
        setStatus27_46(cliente.data.tipo.statusCincoAnos[46])
        setStatus27_47(cliente.data.tipo.statusCincoAnos[47])
        setStatus27_48(cliente.data.tipo.statusCincoAnos[48])
        setStatus27_49(cliente.data.tipo.statusCincoAnos[49])
        setStatus27_50(cliente.data.tipo.statusCincoAnos[50])
        setStatus27_51(cliente.data.tipo.statusCincoAnos[51])
        setStatus27_52(cliente.data.tipo.statusCincoAnos[52])
        setStatus27_53(cliente.data.tipo.statusCincoAnos[53])
        setStatus27_54(cliente.data.tipo.statusCincoAnos[54])
        setStatus27_55(cliente.data.tipo.statusCincoAnos[55])
        setStatus27_56(cliente.data.tipo.statusCincoAnos[56])
        setStatus27_57(cliente.data.tipo.statusCincoAnos[57])
        setStatus27_58(cliente.data.tipo.statusCincoAnos[58])
        setStatus27_59(cliente.data.tipo.statusCincoAnos[59])
      }
    }

    getCliente()
  }, [cnpj])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  async function salvar(id) {
    const statusCincoAnos = [
      status27_0,
      status27_1,
      status27_2,
      status27_3,
      status27_4,
      status27_5,
      status27_6,
      status27_7,
      status27_8,
      status27_9,
      status27_10,
      status27_11,
      status27_12,
      status27_13,
      status27_14,
      status27_15,
      status27_16,
      status27_17,
      status27_18,
      status27_19,
      status27_20,
      status27_21,
      status27_22,
      status27_23,
      status27_24,
      status27_25,
      status27_26,
      status27_27,
      status27_28,
      status27_29,
      status27_30,
      status27_31,
      status27_32,
      status27_33,
      status27_34,
      status27_35,
      status27_36,
      status27_37,
      status27_38,
      status27_39,
      status27_40,
      status27_41,
      status27_42,
      status27_43,
      status27_44,
      status27_45,
      status27_46,
      status27_47,
      status27_48,
      status27_49,
      status27_50,
      status27_51,
      status27_52,
      status27_53,
      status27_54,
      status27_55,
      status27_56,
      status27_57,
      status27_58,
      status27_59
    ]

    await api.put("/editar-tipo-upload-60meses", { id, statusCincoAnos })
    toast.success("Registro salvo com sucesso!", {
      position: toast.POSITION.TOP_CENTER
    })
  }

  return (
    <div>
      {status27_0.length === 0 ? (
        <></>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <ToastContainer />
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Arquivo</TableCell>
                {datas.map((column) => (
                  <TableCell key={column.id} align="center">
                    {formataData(column.data)}
                  </TableCell>
                ))}
                <TableCell>Salvar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>DCTFWEB</TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_0 ?? ""}
                    onChange={(e) => setStatus27_0(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_1 ?? ""}
                    onChange={(e) => setStatus27_1(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_2 ?? ""}
                    onChange={(e) => setStatus27_2(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_3 ?? ""}
                    onChange={(e) => setStatus27_3(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_4 ?? ""}
                    onChange={(e) => setStatus27_4(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_5 ?? ""}
                    onChange={(e) => setStatus27_5(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_6 ?? ""}
                    onChange={(e) => setStatus27_6(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_7 ?? ""}
                    onChange={(e) => setStatus27_7(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_8 ?? ""}
                    onChange={(e) => setStatus27_8(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_9 ?? ""}
                    onChange={(e) => setStatus27_9(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_10 ?? ""}
                    onChange={(e) => setStatus27_10(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_11 ?? ""}
                    onChange={(e) => setStatus27_11(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_12 ?? ""}
                    onChange={(e) => setStatus27_12(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_13 ?? ""}
                    onChange={(e) => setStatus27_13(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_14 ?? ""}
                    onChange={(e) => setStatus27_14(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_15 ?? ""}
                    onChange={(e) => setStatus27_15(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_16 ?? ""}
                    onChange={(e) => setStatus27_16(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_17 ?? ""}
                    onChange={(e) => setStatus27_17(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_18 ?? ""}
                    onChange={(e) => setStatus27_18(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_19 ?? ""}
                    onChange={(e) => setStatus27_19(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_20 ?? ""}
                    onChange={(e) => setStatus27_20(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_21 ?? ""}
                    onChange={(e) => setStatus27_21(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_22 ?? ""}
                    onChange={(e) => setStatus27_22(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_23 ?? ""}
                    onChange={(e) => setStatus27_23(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_24 ?? ""}
                    onChange={(e) => setStatus27_24(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_25 ?? ""}
                    onChange={(e) => setStatus27_25(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_26 ?? ""}
                    onChange={(e) => setStatus27_26(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_27 ?? ""}
                    onChange={(e) => setStatus27_27(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_28 ?? ""}
                    onChange={(e) => setStatus27_28(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_29 ?? ""}
                    onChange={(e) => setStatus27_29(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_30 ?? ""}
                    onChange={(e) => setStatus27_30(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_31 ?? ""}
                    onChange={(e) => setStatus27_31(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_32 ?? ""}
                    onChange={(e) => setStatus27_32(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_33 ?? ""}
                    onChange={(e) => setStatus27_33(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_34 ?? ""}
                    onChange={(e) => setStatus27_34(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_35 ?? ""}
                    onChange={(e) => setStatus27_35(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_36 ?? ""}
                    onChange={(e) => setStatus27_36(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_37 ?? ""}
                    onChange={(e) => setStatus27_37(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_38 ?? ""}
                    onChange={(e) => setStatus27_38(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_39 ?? ""}
                    onChange={(e) => setStatus27_39(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_40 ?? ""}
                    onChange={(e) => setStatus27_40(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_41 ?? ""}
                    onChange={(e) => setStatus27_41(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_42 ?? ""}
                    onChange={(e) => setStatus27_42(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_43 ?? ""}
                    onChange={(e) => setStatus27_43(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_44 ?? ""}
                    onChange={(e) => setStatus27_44(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_45 ?? ""}
                    onChange={(e) => setStatus27_45(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_46 ?? ""}
                    onChange={(e) => setStatus27_46(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_47 ?? ""}
                    onChange={(e) => setStatus27_47(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_48 ?? ""}
                    onChange={(e) => setStatus27_48(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_49 ?? ""}
                    onChange={(e) => setStatus27_49(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_50 ?? ""}
                    onChange={(e) => setStatus27_50(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_51 ?? ""}
                    onChange={(e) => setStatus27_51(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_52 ?? ""}
                    onChange={(e) => setStatus27_52(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_53 ?? ""}
                    onChange={(e) => setStatus27_53(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_54 ?? ""}
                    onChange={(e) => setStatus27_54(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_55 ?? ""}
                    onChange={(e) => setStatus27_55(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_56 ?? ""}
                    onChange={(e) => setStatus27_56(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_57 ?? ""}
                    onChange={(e) => setStatus27_57(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_58 ?? ""}
                    onChange={(e) => setStatus27_58(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status27_59 ?? ""}
                    onChange={(e) => setStatus27_59(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    q{" "}
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    onClick={() => salvar(status27_id)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Modal
        open={openModalVazio}
        onClose={handleCloseVazio}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalErro}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Registro salvo com sucesso.</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  )
}

export default Painel
