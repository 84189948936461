import React, { useEffect, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useParams } from "react-router-dom"
import ResponsiveDrawerAdmin from "../../../../components/menu"
import ResponsiveDrawer from "../../../../components/menuCliente"
import api from "../../../../Config/api"

const mdTheme = createTheme()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "White",
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const CheckListSped = () => {
  const params = useParams()
  const cnpjCliente = params._id

  const cnpj = localStorage.getItem("cnpj")

  const [loading, setLoading] = useState(false)
  const [perfil, setPerfil] = useState("")
  const [rows, setRows] = useState([])
  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState("")
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")
  const [data5, setData5] = useState("")
  const [data6, setData6] = useState("")
  const [data7, setData7] = useState("")
  const [data8, setData8] = useState("")
  const [data9, setData9] = useState("")
  const [data10, setData10] = useState("")
  const [data11, setData11] = useState("")
  const [data12, setData12] = useState("")

  useEffect(() => {
    const jsonRecuperado = localStorage.getItem("user")
    const perfilUser = JSON.parse(jsonRecuperado).perfil
    setPerfil(perfilUser)
  }, [])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/checklist", { cnpj: cnpjCliente })
      setRazaoSocial(cliente.data.dados.razaoSocial)
      setSimples(cliente.data.dados.simples)
      setData1(cliente.data.dados.data1)
      setData2(cliente.data.dados.data2)
      setData3(cliente.data.dados.data3)
      setData4(cliente.data.dados.data4)
      if (cliente.data.dados.simples === true) {
        setData4(cliente.data.dados.data4)
        setData5(cliente.data.dados.data5)
        setData6(cliente.data.dados.data6)
        setData7(cliente.data.dados.data7)
        setData8(cliente.data.dados.data8)
        setData9(cliente.data.dados.data9)
        setData10(cliente.data.dados.data10)
        setData11(cliente.data.dados.data11)
        setData12(cliente.data.dados.data12)
      }
    }

    getCliente()
  }, [cnpj])

  useEffect(() => {
    async function getTipoSped() {
      const sped = await api.post("/checklist-tipo-sped-fiscal", {
        cnpj: cnpjCliente
      })
      const spedFiscalMatriz = sped.data.statusSpedFiscalMatriz.map((d) => ({
        cnpj: d.cnpj,
        tipoUpload: d.tipoUploadMatriz
      }))

      const spedFiscalFilial = sped.data.statusSpedFiscalFilial.map((d) => ({
        cnpj: d.cnpjFilial,
        tipoUpload: d.tipoUploadFilial
      }))
      setRows([spedFiscalMatriz, spedFiscalFilial])
    }
    getTipoSped()
    setLoading(false)
  }, [cnpj])

  const dadosSpedFiscal = rows
    .flatMap((d) => d)
    .map((d) => ({
      cnpj: d.cnpj,
      ...d.tipoUpload
        .map((d) => ({
          status1: d.status1,
          status2: d.status2,
          status3: d.status3,
          status4: d.status4
        }))
        .pop()
    }))

  function formatarData(data) {
    if (data === null) {
      return ""
    }
    const mes = data.substr(5, 2)
    const ano = data.substr(0, 4)
    const dataAtual = `${mes}/${ano}`
    return dataAtual
  }

  function formatarDataSimples(data) {
    if (data === null) {
      return "-"
    }
    const mes = data.substr(5, 2)
    const ano = data.substr(2, 2)
    const dataAtual = `${mes}/${ano}`
    return dataAtual
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {perfil === 2 ? (
          <ResponsiveDrawer title={`Cliente - ${razaoSocial}`} />
        ) : (
          <ResponsiveDrawerAdmin title={`Cliente - ${razaoSocial}`} />
        )}

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4 }}>
            <Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Container sx={{ mt: 4, mb: 4 }}>
                  <center>
                    <span className="submenu">Checklist de Documentos</span>
                  </center>
                  <hr />
                  <center>
                    <span className="sublabel">SPED FISCAL</span>
                  </center>
                  <hr />
                  <>
                    <TableContainer component={Paper}>
                      <Table aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <div className="title-arquivo">CNPJ</div>
                            </TableCell>
                            <StyledTableCell align="center">
                              {formatarData(data1)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarData(data2)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarData(data3)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarData(data4)}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dadosSpedFiscal.map((row, idx) => (
                            <StyledTableRow key={row.idx}>
                              <StyledTableCell component="th" scope="row">
                                {row.cnpj ? row.cnpj : "-"}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {row.status1 === 0 ? (
                                  <CancelIcon sx={{ color: "red" }} />
                                ) : (
                                  ""
                                )}
                                {row.status1 === 1 ? (
                                  <ErrorIcon sx={{ color: "gold" }} />
                                ) : (
                                  ""
                                )}
                                {row.status1 === 2 ? (
                                  <CheckCircleIcon sx={{ color: "green" }} />
                                ) : (
                                  ""
                                )}
                                {row.status1 === 3 ? (
                                  <RectangleIcon sx={{ color: "black" }} />
                                ) : (
                                  ""
                                )}
                                {row.status1 === 4 ? (
                                  <FormatItalicIcon sx={{ color: "black" }} />
                                ) : (
                                  ""
                                )}
                              </StyledTableCell>
                              {row.status2 === 0 && data2 === null ? (
                                ""
                              ) : (
                                <StyledTableCell align="center">
                                  {row.status2 === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status2 === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status2 === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status2 === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status2 === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                              )}
                              {row.status3 === 0 && data3 === null ? (
                                ""
                              ) : (
                                <StyledTableCell align="center">
                                  {row.status3 === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status3 === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status3 === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status3 === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status3 === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                              )}
                              {row.status4 === 0 && data4 === null ? (
                                ""
                              ) : (
                                <StyledTableCell align="center">
                                  {row.status4 === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status4 === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status4 === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status4 === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.status4 === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                              )}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                </Container>
              )}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <CheckListSped />
}
