import { produce } from "immer"
import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import Button from "@mui/material/Button"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import { ITabelaAnaliticaValoresUtilizados } from "./types"

type IPageData = {
  data: ITabelaAnaliticaValoresUtilizados[]
  setRowFaturas: (newScreenData: any) => void
  isReadOnly: boolean
}

const ValoresUtilizados = (props: IPageData) => {
  const { data, setRowFaturas, isReadOnly } = props
  const handleAddRow = () => {
    const newData = produce(data, (draft) => {
      draft.push({ descricao: "", valor: 0 })
    })
    setRowFaturas(newData)
  }

  const handleRemoveRow = (index: number) => {
    const newData = produce(data, (draft) => {
      draft.splice(index, 1)
    })
    setRowFaturas(newData)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    field: string
  ) => {
    const newData = produce(data, (draft) => {
      const oportunidade = draft[index]

      const newOportunidade = Object.assign(oportunidade, {
        [field]: event.target.value
      })

      draft[index] = newOportunidade
    })
    setRowFaturas(newData)
  }

  const handleInputChangeNumeric = (
    values: NumberFormatValues,
    index: number,
    field: string
  ) => {
    const newData = produce(data, (draft) => {
      const oportunidade = draft[index]

      const newOportunidade = Object.assign(oportunidade, {
        [field]: values.floatValue || 0
      })

      draft[index] = newOportunidade
    })
    setRowFaturas(newData)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: "80px" }}>
                Valores Utilizados
              </TableCell>
              <TableCell align="left">Valor Crédito</TableCell>
              <TableCell align="left">Vcto. Fatura</TableCell>
              <TableCell align="left">Fatura Emitida</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell>
                  {isReadOnly ? (
                    <span>{row.descricao}</span>
                  ) : (
                    <TextField
                      value={row.descricao}
                      onChange={(event) =>
                        handleInputChange(event, index, "descricao")
                      }
                    />
                  )}
                </TableCell>
                <TableCell sx={{ height: "14px", top: "3px" }} align="left">
                  <NumericFormat
                    value={row.valor}
                    allowLeadingZeros
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale
                    decimalScale={2}
                    allowedDecimalSeparators={[","]}
                    prefix="R$ "
                    displayType={isReadOnly ? "text" : "input"}
                    style={{
                      top: "3",
                      height: "25px",
                      width: "150px",
                      border: "none",
                      borderBottom: "1px solid #cccccc"
                    }}
                    onValueChange={(values) =>
                      handleInputChangeNumeric(values, index, "valor")
                    }
                  />
                </TableCell>

                <TableCell sx={{ height: "14px", top: "3px" }} align="left">
                  {isReadOnly ? (
                    <span>{row.vencimento}</span>
                  ) : (
                    <TextField
                      value={row.vencimento}
                      onChange={(event) =>
                        handleInputChange(event, index, "vencimento")
                      }
                    />
                  )}
                </TableCell>

                <TableCell sx={{ height: "14px", top: "3px" }} align="left">
                  <NumericFormat
                    value={row.faturaEmitida}
                    allowLeadingZeros
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale
                    decimalScale={2}
                    displayType={isReadOnly ? "text" : "input"}
                    allowedDecimalSeparators={[","]}
                    prefix="R$ "
                    style={{
                      top: "3",
                      height: "25px",
                      width: "150px",
                      border: "none",
                      borderBottom: "1px solid #cccccc"
                    }}
                    onValueChange={(values) =>
                      handleInputChangeNumeric(values, index, "faturaEmitida")
                    }
                  />
                </TableCell>
                {!isReadOnly && (
                  <TableCell align="left">
                    <Button
                      sx={{
                        "&:hover": {
                          background: "red",
                          color: "white"
                        },
                        color: "red",
                        cursor: "pointer"
                      }}
                      onClick={() => handleRemoveRow(index)}
                    >
                      REMOVER
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />

              <TableCell
                align="left"
                sx={{
                  fontSize: 20
                }}
              >
                Total:
                <NumericFormat
                  value={data.reduce((acc, x) => acc + (x.valor || 0), 0)}
                  allowLeadingZeros
                  thousandSeparator="."
                  readOnly
                  decimalSeparator=","
                  fixedDecimalScale
                  decimalScale={2}
                  allowedDecimalSeparators={[","]}
                  prefix="R$ "
                  style={{
                    top: "3",
                    height: "25px",
                    width: "150px",
                    border: "none"
                  }}
                />
              </TableCell>
              {!isReadOnly && (
                <TableCell align="left">
                  <Button
                    sx={{
                      "&:hover": {
                        background: "green",
                        color: "white"
                      },
                      color: "green",
                      cursor: "pointer"
                    }}
                    onClick={handleAddRow}
                  >
                    ADICIONAR
                  </Button>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ValoresUtilizados
