import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  Toolbar,
  Tooltip
} from "@mui/material"

import { toast, ToastContainer } from "react-toastify"

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

import { Label } from "reactstrap"

import api from "../../Config/api"
import ResponsiveDrawer from "../../components/menu"

const mdTheme = createTheme()

export default function AddAssociadoAoCliente() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [listaAssociados, setListaAssociados] = useState([])
  const [associadoSelecionado, setAssociadoSelecionado] = useState([])

  const params = useParams()
  const cliente = params._id
  const { _id } = params

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const allAssociado = associadoSelecionado.map(
      async (d) =>
        await api
          .post("/associado_x_cliente", { cliente, associado: d.id })
          .then((res) => {
            toast.success("Associado(s) cadastrado(s) com sucesso!", {
              position: toast.POSITION.TOP_CENTER
            })
            setLoading(false)
            navigate("/novoClienteProximo")
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(
                `O Relacionamento entre Associado e Cliente já Existe`,
                {
                  position: toast.POSITION.TOP_CENTER
                }
              )
              setLoading(false)
            }
            if (error.response.status === 500) {
              toast.error(
                `Erro ao vincular o associado, tente novamente mais tarde!`,
                {
                  position: toast.POSITION.TOP_CENTER
                }
              )
              setLoading(false)
            }
          })
    )
  }

  useEffect(() => {
    async function getDadosCliente() {
      const findClienteId = await api.post("/find-cliente-id", { _id })
      localStorage.setItem("cnpj", findClienteId.data.dadosCliente[0].cnpj)
      localStorage.setItem(
        "razaoSocial",
        findClienteId.data.dadosCliente[0].razaoSocial
      )
      localStorage.setItem(
        "simples",
        findClienteId.data.dadosCliente[0].simples
      )
    }

    getDadosCliente()
  }, [])

  useEffect(() => {
    setLoading(true)
    async function getListaAssociados() {
      const associados = await api.get("/associados")
      const { data } = associados
      setListaAssociados(() => [
        ...data.map((item) => {
          return { ...item, id: item.id, nome: item.nome }
        })
      ])
    }
    getListaAssociados()
    setLoading(false)
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Adicionar associado ao cliente" />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <Grid>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <center>
                        <span className="submenu">
                          Adicionar Associado ao Cliente
                        </span>
                      </center>
                      <hr />
                      <Box
                        sx={{
                          "& > :not(style)": { m: 1, width: "50ch" }
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Label>
                          Selecione os parceiros associados a este cliente:
                        </Label>
                        <Tooltip
                          title="Ao selecionar um ou mais associados confirme todos os nomes antes de prosseguir"
                          arrow
                        >
                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={listaAssociados}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              setAssociadoSelecionado(newValue)
                            }}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 5 }}
                                  checked={selected}
                                />

                                {option.nome}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Associados"
                                placeholder="Selecione um ou mais associados"
                              />
                            )}
                          />
                        </Tooltip>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse"
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,
                              backgroundColor: "#28A6DE",
                              color: "#f2f2f2"
                            }}
                          >
                            Salvar
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse"
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,
                              backgroundColor: "#28A6DE",
                              color: "#f2f2f2"
                            }}
                            onClick={() => navigate("/novoClienteProximo")}
                          >
                            Adicionar depois
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </div>
                </Grid>
              </form>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
