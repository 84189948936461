import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { alpha } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import { Link, useParams } from "react-router-dom"
import api from "../../../Config/api"

const headCells = [
  {
    id: "cnpj",
    numeric: false,
    disablePadding: true,
    label: <strong>Documentos</strong>
  }
]

const EnhancedTableHead = (props) => {
  const { order, orderBy } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "normal" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          <center>
            <span className="submenu">Checklist de Documentos</span>
          </center>
          <hr />
        </Typography>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
}

export default function EnhancedTable() {
  const params = useParams()
  const cnpj = params._id
  const [_id, set_Id] = useState("")
  const [dadosCliente, setDadosCliente] = useState({})
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const buscaCNPJ = { cnpj }
      const cliente = await api.post("/dashboard", buscaCNPJ)
      setDadosCliente(cliente)
      set_Id(cliente.data.cliente._id)
      setRows(cliente.data.tipos)
      setLoading(false)
    }

    getCliente()
    setLoading(true)
  }, [cnpj])

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle">
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  {row.tipo === 1 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoArquivosCompactados/${cnpj}`}
                        className="linkList"
                      >
                        Solicitações Complementares
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 2 ? (
                    <TableCell align="left" component="td" padding="normal">
                      <Link
                        to={`/DiagnosticoDocPinsCofinsSintetica/${cnpj}`}
                        className="linkList"
                      >
                        Planilhas memórias de cálculo Pis/Cofins - Sintética
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 3 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoDocPinsCofinsAnalitica/${cnpj}`}
                        className="linkList"
                      >
                        Planilhas memórias de cálculo Pis/Cofins - Analítica
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 4 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoICMSAntecipacao/${cnpj}`}
                        className="linkList"
                      >
                        Memória de cálculo/Rascunho do ICMS, ICMS ST e ICMS
                        Antecipação{" "}
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 5 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoDCTFs/${cnpj}`}
                        className="linkList"
                      >
                        DCTFs
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 6 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoBalancetesContabeis/${cnpj}`}
                        className="linkList"
                      >
                        Balancetes Contábeis
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 7 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoRazoesContabeis/${cnpj}`}
                        className="linkList"
                      >
                        Livros Razões Contábeis
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 8 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoDocSpedContabil/${cnpj}`}
                        className="linkList"
                      >
                        SPED Contábil
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 9 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoDocSped/${cnpj}`}
                        className="linkList"
                      >
                        SPED Fiscal
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 10 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoEFDContribuicoes/${cnpj}`}
                        className="linkList"
                      >
                        EFD Contribuições
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {row.tipo === 12 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoDocCiap/${cnpj}`}
                        className="linkList"
                      >
                        CIAP - Controle de Crédito do ICMS do Ativo Permanente
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 13 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoComprovanteArrecadacaoTributos/${cnpj}`}
                        className="linkList"
                      >
                        E-CAC - Comprovante de Arrecadação dos Tributos IRPJ e
                        CSLL
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 14 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link to={`/DiagnosticoECF/${cnpj}`} className="linkList">
                        ECF
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 15 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoFontesPag/${cnpj}`}
                        className="linkList"
                      >
                        E-CAC - Fontes Pagadoras
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {row.tipo === 16 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoAtivoImobilizado/${cnpj}`}
                        className="linkList"
                      >
                        Bens do ativo imobilizado
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 17 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoMemoriaIRPJeCSLL/${cnpj}`}
                        className="linkList"
                      >
                        Memórias de Cálculo do IRPJ e CSLL
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 18 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoCreditosExtemporaneos/${cnpj}`}
                        className="linkList"
                      >
                        Créditos Extemporâneos
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 19 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoResumoFolha/${cnpj}`}
                        className="linkList"
                      >
                        Resumo da folha de pagamento
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 20 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoINSSRubrica/${cnpj}`}
                        className="linkList"
                      >
                        Comprovante de declaração a recolher do INSS (Rubrica)
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 21 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoPrintFAP/${cnpj}`}
                        className="linkList"
                      >
                        Print da FAP
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 22 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link to={`/DiagnosticoGPS/${cnpj}`} className="linkList">
                        GPS
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 23 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoTabelaIncidenciaINSS/${cnpj}`}
                        className="linkList"
                      >
                        Tabela de Incidência do INSS sobre os eventos da folha
                        de pagamentos (Contribuições Previdenciárias)
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 24 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoMemoriaCPRBDesoneracao/${cnpj}`}
                        className="linkList"
                      >
                        Memórias de Cálculo de CPRB/Desoneração
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 25 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoQuestionarioPrevio/${cnpj}`}
                        className="linkList"
                      >
                        Questionário Prévio
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 26 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoDARF/${cnpj}`}
                        className="linkList"
                      >
                        DARF
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 27 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoDCTFWEB/${cnpj}`}
                        className="linkList"
                      >
                        DCTFWEB
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 29 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoXMLsEntradas/${cnpj}`}
                        className="linkList"
                      >
                        XMLs NF-es e CT-es de entradas
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 30 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoExtratoSimplesNacional/${cnpj}`}
                        className="linkList"
                      >
                        Extrato Simples Nacional
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 31 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link to={`/DiagnosticoDAS/${cnpj}`} className="linkList">
                        DAS
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 32 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoXMLsSaidas/${cnpj}`}
                        className="linkList"
                      >
                        XMLs NF-es e CT-es de saídas
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {row.tipo === 33 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoGuiaICMS/${cnpj}`}
                        className="linkList"
                      >
                        Guia ou Declaração de Apuração do ICMS (GIA; DMA; DAM;
                        DAPI; GIME; GIAM)
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {row.tipo === 34 ? (
                    <TableCell align="left" component="th" padding="normal">
                      <Link
                        to={`/DiagnosticoBalanceteContabilUltimoNaoEncerrado/${cnpj}`}
                        className="linkList"
                      >
                        Balancete Contábil Último Não Encerrado
                      </Link>
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
