import React, { useState, useEffect } from "react"
import api from "../../../Config/api"
import ResponsiveDrawer from "../../../components/menuCliente"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { CircularProgress, Grid } from "@mui/material"
import "react-toastify/dist/ReactToastify.min.css"
import { useParams } from "react-router-dom"
import DiagnosticoPorAno from "./DiagnosticoPorAno"
import ResponsiveDrawerAssociado from "../../../components/menu"

const mdTheme = createTheme()

const Diagnostico = () => {
  const params = useParams()
  const cnpjCliente = params._id

  const [perfil, setPerfil] = useState("")
  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState(false)
  const [_id, set_Id] = useState("")

  const [colunas, setColunas] = useState([])
  const [colunasSN, setColunasSN] = useState([])

  const [resumoStatusSpedFiscal, setResumoStatusSpedFiscal] = useState("")
  const [tipo1, setTipo1] = useState([])

  const [tipo2, setTipo2] = useState([])
  const [tipo3, setTipo3] = useState([])
  const [tipo4, setTipo4] = useState([])

  const [tipo5, setTipo5] = useState([])
  const [tipo6, setTipo6] = useState([])
  const [tipo7, setTipo7] = useState([])
  const [tipo9, setTipo9] = useState([])
  const [tipo10, setTipo10] = useState([])
  const [tipo12, setTipo12] = useState([])
  const [tipo23, setTipo23] = useState([])
  const [tipo29, setTipo29] = useState([])
  const [tipo32, setTipo32] = useState([])

  const [tipo33, setTipo33] = useState([])
  const [tipo34, setTipo34] = useState([])

  const [tipo16, setTipo16] = useState([])
  const [tipo18, setTipo18] = useState([])
  const [tipo25, setTipo25] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/checklist", { cnpj: cnpjCliente })
      set_Id(cliente.data.dados._id)

      setSimples(cliente.data.dados.simples)

      const col = []
      if (cliente.data.dados.data1 !== null) {
        col.push(
          `${cliente.data.dados.data1.substr(
            0,
            4
          )}/${cliente.data.dados.data1.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data2 !== null) {
        col.push(
          `${cliente.data.dados.data2.substr(
            0,
            4
          )}/${cliente.data.dados.data2.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data3 !== null) {
        col.push(
          `${cliente.data.dados.data3.substr(
            0,
            4
          )}/${cliente.data.dados.data3.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data4 !== null) {
        col.push(
          `${cliente.data.dados.data4.substr(
            0,
            4
          )}/${cliente.data.dados.data4.substr(5, 2)}`
        )
      }
      const colSN = []
      if (cliente.data.dados.data1 !== null) {
        colSN.push(cliente.data.dados.data1)
      }
      if (cliente.data.dados.data2 !== null) {
        colSN.push(cliente.data.dados.data2)
      }
      if (cliente.data.dados.data3 !== null) {
        colSN.push(cliente.data.dados.data3)
      }
      if (cliente.data.dados.data4 !== null) {
        colSN.push(cliente.data.dados.data4)
      }
      if (cliente.data.dados.data5 !== null) {
        colSN.push(cliente.data.dados.data5)
      }
      if (cliente.data.dados.data6 !== null) {
        colSN.push(cliente.data.dados.data6)
      }
      if (cliente.data.dados.data7 !== null) {
        colSN.push(cliente.data.dados.data7)
      }
      if (cliente.data.dados.data8 !== null) {
        colSN.push(cliente.data.dados.data8)
      }
      if (cliente.data.dados.data9 !== null) {
        colSN.push(cliente.data.dados.data9)
      }
      if (cliente.data.dados.data10 !== null) {
        colSN.push(cliente.data.dados.data10)
      }
      if (cliente.data.dados.data11 !== null) {
        colSN.push(cliente.data.dados.data11)
      }
      if (cliente.data.dados.data12 !== undefined) {
        colSN.push(cliente.data.dados.data12)
      }
      setColunas(col)
      setColunasSN(colSN)
      setRazaoSocial(cliente.data.dados.razaoSocial)
      setResumoStatusSpedFiscal(cliente.data.resumoStatusSpedFiscal)

      let cont = 0
      while (cont < cliente.data.tipos.length) {
        if (cliente.data.tipos[cont].tipo === 1) {
          setTipo1(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 2) {
          setTipo2(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 3) {
          setTipo3(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 4) {
          setTipo4(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 5) {
          setTipo5(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 6) {
          setTipo6(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 7) {
          setTipo7(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 9) {
          setTipo9(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 10) {
          setTipo10(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 12) {
          setTipo12(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 23) {
          setTipo23(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 29) {
          setTipo29(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 32) {
          setTipo32(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 33) {
          setTipo33(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 34) {
          setTipo34(cliente.data.tipos[cont])
        }

        if (cliente.data.tipos[cont].tipo === 16) {
          setTipo16(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 18) {
          setTipo18(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 25) {
          setTipo25(cliente.data.tipos[cont])
        }

        cont++
      }
      setLoading(false)
      if (
        (cliente.data.resumoStatusSpedFiscal.status1 === 0 && 1) ||
        (cliente.data.resumoStatusSpedFiscal.status2 === 0 && 1) ||
        (cliente.data.resumoStatusSpedFiscal.status3 === 0 && 1)
      );
    }

    getCliente()
    setLoading(true)
  }, [])

  useEffect(() => {
    const jsonRecuperado = localStorage.getItem("user")
    const perfilUser = JSON.parse(jsonRecuperado).perfil
    setPerfil(perfilUser)
  }, [])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {perfil !== 2 ? (
          <ResponsiveDrawerAssociado title={`Cliente - ${razaoSocial}`} />
        ) : (
          <ResponsiveDrawer />
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4
            }}
          >
            <Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Container sx={{ mt: 4, mb: 4 }}>
                  <center>
                    <span className="submenu">Checklist de Documentos</span>
                  </center>
                  <hr />

                  <>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                          {simples === true ? (
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <span className="title">Arquivos</span>
                                </TableCell>
                                {colunasSN.map((colSN, index) => (
                                  <TableCell key={index} align="center">
                                    {formataData(colSN)}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                          ) : (
                            <TableHead>
                              <TableRow>
                                <TableCell className="title">
                                  <div className="title">Arquivos</div>
                                </TableCell>
                                <TableCell align="center">
                                  <div className="title">
                                    {colunas.length === 0 ? "-" : colunas[0]}{" "}
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  <div className="title">
                                    {colunas.length === 0 ? "-" : colunas[1]}
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  <div className="title">
                                    {colunas.length === 0 ? "-" : colunas[2]}
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  <div className="title">
                                    {colunas.length === 0 ? "-" : colunas[3]}
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  <div className="title">
                                    {colunas.length === 0 ? "-" : colunas[4]}
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          )}
                          {simples === true ? (
                            <TableBody>
                              {/* TIPO29 */}
                              {tipo29.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    XMLs das NF-es e CT-es de entradas
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status2 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status2 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status2 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status2 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status2 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {tipo29.status3 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status3 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status3 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status3 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status3 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {tipo29.status4 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status4 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status4 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status4 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status4 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status5 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status5 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status5 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status5 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status5 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status6 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status6 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status6 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status6 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status6 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status7 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status7 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status7 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status7 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status7 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status8 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status8 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status8 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status8 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status8 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status9 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status9 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status9 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status9 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status9 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status10 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status10 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status10 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status10 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status10 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status11 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status11 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status11 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status11 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status11 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status12 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status12 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status12 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status12 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status12 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}

                              {/* TIPO32 */}
                              {tipo32.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    XMLs das NF-es e CT-es de saídas
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {tipo32.status2 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status2 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status2 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status2 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status2 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {tipo32.status3 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status3 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status3 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status3 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status3 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {tipo32.status4 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status4 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status4 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status4 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status4 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status5 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status5 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status5 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status5 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status5 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status6 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status6 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status6 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status6 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status6 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status7 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status7 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status7 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status7 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status7 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status8 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status8 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status8 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status8 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status8 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status9 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status9 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status9 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status9 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status9 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status10 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status10 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status10 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status10 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status10 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status11 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status11 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status11 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status11 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status11 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status12 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status12 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status12 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status12 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status12 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          ) : (
                            <TableBody>
                              {/* tipo1 */}
                              {tipo1.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Solicitações Complementares
                                  </TableCell>

                                  <TableCell align="center">
                                    {tipo1.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo1.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo1.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo1.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo1.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo1.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo1.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo1.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo1.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}
                              {/* tipo2 */}
                              {tipo2.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Planilhas memórias de cálculo Pis/Cofins -
                                    Sintética
                                  </TableCell>

                                  <TableCell align="center">
                                    {tipo2.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo2.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo2.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo2.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo2.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo2.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo2.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo2.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo2.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* tipo3 */}
                              {tipo3.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Planilhas memórias de cálculo Pis/Cofins -
                                    Analítica
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo3.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo3.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo3.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo3.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo3.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo3.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo3.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo3.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo3.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}
                              {/* tipo4 */}
                              {tipo4.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Memória de cálculo/Rascunho do ICMS, ICMS ST
                                    e ICMS Antecipação
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo4.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo4.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo4.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo4.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo4.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo4.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo4.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo4.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo4.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}
                              {/* tipo5 */}
                              {tipo5.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">DCTFs</TableCell>

                                  <TableCell align="center">
                                    {tipo5.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo5.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo5.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo5.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo5.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo5.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo5.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo5.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo5.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* tipo6 */}
                              {tipo6.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Balancetes Contábeis
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo6.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo6.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo6.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo6.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo6.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo6.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo6.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo6.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo6.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* tipo7 */}
                              {tipo7.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Livros Razões Contábeis
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo7.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo7.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo7.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo7.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo7.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo7.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo7.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo7.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo7.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* tipo9 */}
                              {tipo9.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">SPED Fiscal</TableCell>

                                  <TableCell align="center">
                                    {resumoStatusSpedFiscal.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {resumoStatusSpedFiscal.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {resumoStatusSpedFiscal.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {resumoStatusSpedFiscal.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {resumoStatusSpedFiscal.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {resumoStatusSpedFiscal.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {resumoStatusSpedFiscal.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {resumoStatusSpedFiscal.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {resumoStatusSpedFiscal.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* tipo10 */}
                              {tipo10.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    EFD Contribuições
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo10.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo10.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo10.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo10.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo10.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo10.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo10.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo10.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo10.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}
                              {/* TIPO12 */}
                              {tipo12.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    CIAP - Controle de Crédito do ICMS do Ativo
                                    Permanente
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo12.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo12.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo12.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo12.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo12.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo12.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo12.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo12.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo12.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* TIPO23 */}
                              {tipo23.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Tabela de Incidência do INSS sobre os
                                    eventos da folha de pagamentos
                                    (Contribuições Previdenciárias)
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo23.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo23.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo23.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo23.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo23.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo23.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo23.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo23.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo23.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* TIPO29 */}
                              {tipo29.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    XMLs das NF-es e CT-es de entradas
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo29.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo29.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo29.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo29.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo29.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo29.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* TIPO32 */}
                              {tipo32.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    XMLs das NF-es e CT-es de saídas
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo32.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo32.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo32.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo32.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo32.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo32.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}

                              {/* TIPO33 */}
                              {tipo33.length === 0 ? (
                                <></>
                              ) : (
                                <TableRow sx={{ "": { border: 0 } }}>
                                  <TableCell scope="row">
                                    Guia ou Declaração de Apuração do ICMS (GIA;
                                    DMA; DAM; DAPI; GIME; GIAM){" "}
                                  </TableCell>
                                  <TableCell align="center">
                                    {tipo33.status1 === 0 ? (
                                      <CancelIcon sx={{ color: "red" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo33.status1 === 1 ? (
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo33.status1 === 2 ? (
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {tipo33.status1 === 3 ? (
                                      <RectangleIcon sx={{ color: "black" }} />
                                    ) : (
                                      ""
                                    )}
                                    {tipo33.status1 === 4 ? (
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  {colunas.length > 1 ? (
                                    <TableCell align="center">
                                      {tipo33.status2 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status2 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status2 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status2 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status2 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                  {colunas.length > 2 ? (
                                    <TableCell align="center">
                                      {tipo33.status3 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status3 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status3 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status3 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status3 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {colunas.length === 4 ? (
                                    <TableCell align="center">
                                      {tipo33.status4 === 0 ? (
                                        <CancelIcon sx={{ color: "red" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status4 === 1 ? (
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status4 === 2 ? (
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status4 === 3 ? (
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {tipo33.status4 === 4 ? (
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    " "
                                  )}
                                </TableRow>
                              )}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </Paper>
                    <hr />
                    <DiagnosticoPorAno />

                    <hr />
                    {simples === true ? (
                      ""
                    ) : (
                      <TableContainer component={Paper}>
                        <Table aria-label="a dense table">
                          <TableHead>
                            <TableCell>
                              <div className="title">Arquivos</div>
                            </TableCell>
                            <TableCell>
                              <div className="title">Observações</div>
                            </TableCell>
                            <TableCell>
                              <div className="title">Status</div>
                            </TableCell>
                            {tipo34.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow sx={{ "": { border: 0 } }}>
                                <TableCell scope="row">
                                  Balancete Contábil
                                </TableCell>

                                <TableCell scope="row">
                                  Último balancete encerrado (Período mais
                                  recente possível)
                                </TableCell>
                                <TableCell align="center">
                                  {tipo34.status1 === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo34.status1 === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo34.status1 === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo34.status1 === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo34.status1 === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>

                          <TableHead>
                            {tipo16.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow sx={{ "": { border: 0 } }}>
                                <TableCell scope="row">
                                  Bens do ativo imobilizado
                                </TableCell>

                                <TableCell scope="row">
                                  Aquisições incorridas a partir de maio/2004
                                </TableCell>
                                <TableCell align="center">
                                  {tipo16.status1 === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo16.status1 === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo16.status1 === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo16.status1 === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo16.status1 === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>
                          <TableHead>
                            {tipo18.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow sx={{ "": { border: 0 } }}>
                                <TableCell scope="row">
                                  Créditos Extemporâneos
                                </TableCell>

                                <TableCell scope="row">
                                  Todo e qualquer levantamento de crédito
                                  efetuado pela empresa ou por terceiros e que
                                  tenham sido apurados após o mês de origem do
                                  crédito
                                </TableCell>
                                <TableCell align="center">
                                  {tipo18.status1 === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo18.status1 === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo18.status1 === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo18.status1 === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo18.status1 === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>
                          <TableHead>
                            {tipo25.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow sx={{ "": { border: 0 } }}>
                                <TableCell scope="row">
                                  Questionário Prévio
                                </TableCell>

                                <TableCell scope="row">
                                  Este questionário foi enviado por e-mail.
                                  {/* Caso prefira, <strong><a style={{textDecoration: 'none'}}>clique aqui</a></strong> para baixá-lo. */}
                                </TableCell>
                                <TableCell align="center">
                                  {tipo25.status1 === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo25.status1 === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo25.status1 === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo25.status1 === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {tipo25.status1 === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>
                        </Table>
                      </TableContainer>
                    )}
                  </>
                </Container>
              )}
            </Grid>
          </Container>
          <Container
            sx={{
              mt: 4,
              mb: 4
            }}
          >
            <>
              <TableContainer>
                <Table sx={{ minWidth: 500 }} aria-label="a dense table">
                  <TableHead>
                    <TableRow scope="row" align="center">
                      <TableCell className="title">Status:</TableCell>

                      <TableCell scope="row" align="center">
                        <CheckCircleIcon sx={{ color: "green" }} /> <p />{" "}
                        <p> Arquivos recebidos </p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <ErrorIcon sx={{ color: "gold" }} /> <p />{" "}
                        <p>Arquivos em análise</p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <CancelIcon sx={{ color: "red" }} /> <p />{" "}
                        <p>Arquivos pendentes</p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <RectangleIcon sx={{ color: "black" }} /> <p />{" "}
                        <p>Arquivos não existentes</p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <FormatItalicIcon sx={{ color: "black" }} /> <p />{" "}
                        <p>Arquivos parciais</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <Diagnostico />
}
