import React, { useState, useEffect, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { visuallyHidden } from "@mui/utils"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import InputAdornment from "@mui/material/InputAdornment"
import TableCell from "@mui/material/TableCell"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import {
  Paper,
  Tooltip,
  FormGroup,
  alpha,
  InputBase,
  Button
} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import CancelIcon from "@mui/icons-material/Cancel"
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded"
import SaveIcon from "@mui/icons-material/Save"
import IconButton from "@mui/material/IconButton"
import { Col, Row } from "reactstrap"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import TextField from "@mui/material/TextField"
import { toast, ToastContainer } from "react-toastify"
import { NumericFormat } from "react-number-format"
import ExportCSV from "../../components/ExportCSVCronogramaExec"
import ResponsiveDrawer from "../../components/menu"
import api from "../../Config/api"

const mdTheme = createTheme()

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "center",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 120,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: "dataRecebimentoContrato",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Prazo entre as partes</span>
      </strong>
    )
  },
  {
    id: "prazoClienteObtencaoDados",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Prazo - Cliente</span>
      </strong>
    )
  },
  {
    id: "prazoBKPSValidacao",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Prazo - BKPS</span>
      </strong>
    )
  },
  {
    id: "prazoBKPSDiagnostico",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Prazo - BKPS</span>
      </strong>
    )
  },
  {
    id: "prazoClienteTriagem",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Prazo - Cliente</span>
      </strong>
    )
  },
  {
    id: "prazoEntreAsPartes",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Prazo entre as partes</span>
      </strong>
    )
  },
  {
    id: "prazoClienteAprovacao",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Prazo - Cliente</span>
      </strong>
    )
  },
  {
    id: "previsaoDiagnostico",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Previsão - Diagnóstico</span>
      </strong>
    )
  },
  {
    id: "honorarioPotencialPrevisaoDiagnostico",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Honorário potencial</span>
      </strong>
    )
  }
]

const headCels2 = [
  {
    id: "statusLevantamentoDeCampo",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Status do Levantamento de Campo</span>
      </strong>
    )
  },
  {
    id: "projetoConcluidoEm",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Projeto Concluido Em</span>
      </strong>
    )
  }
]

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const EnhacedTableHead2 = (props) => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCels2.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhacedTableHead2.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const headCellsDiagAprovado = [
  {
    id: "Referência",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Referência</span>
      </strong>
    )
  },
  {
    id: "Potencial do diagnóstico ",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">Potencial TOTAL do diagnóstico </span>
      </strong>
    )
  },
  {
    id: "Potencial do diagnóstico aprovado",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">
          Potencial do diagnóstico{" "}
          <span style={{ color: "green", textDecoration: "underline" }}>
            aprovado
          </span>
        </span>
      </strong>
    )
  },
  {
    id: "Potencial do diagnóstico pendente de aprovação",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">
          Potencial do diagnóstico{" "}
          <span style={{ color: "red", textDecoration: "underline" }}>
            pendente
          </span>{" "}
          de aprovação
        </span>
      </strong>
    )
  },
  {
    id: "Potencial do diagnóstico não aprovado",
    numeric: false,
    disablePadding: true,
    label: (
      <strong>
        <span className="title">
          Potencial do diagnóstico{" "}
          <span style={{ color: "red", textDecoration: "underline" }}>não</span>{" "}
          aprovado
        </span>
      </strong>
    )
  }
]

const EnhancedTableHeadDiagAprovado = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCellsDiagAprovado.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHeadDiagAprovado.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const CronogramaExec = () => {
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [selected, setSelected] = React.useState([])
  const isSelected = (name) => selected.indexOf(name) !== -1

  const params = useParams()
  const cnpj = params._id
  const [razaoSocial, setRazaoSocial] = useState("")
  const [statusEdit, setStatusEdit] = useState("")
  const [cnpjCliente, setCnpjCliente] = useState("")
  const [rows, setRows] = useState([])
  const [idCliente, setIdCliente] = useState("")
  const [diagnosticoData, setDiagnosticoData] = useState(null)
  const [calcDiagnostico, setCalcDiagnostico] = useState(null)

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/find-cliente-cnpj", { cnpj })
      setCnpjCliente(cliente.data.cliente.cnpj)
      setRazaoSocial(cliente.data.cliente.razaoSocial)
      setIdCliente(cliente.data.cliente._id)
    }

    getCliente()
  }, [cnpj])

  useEffect(() => {
    if (!idCliente) {
      return
    }
    async function getData() {
      await api
        .get(`/diagnostico-analitico/${idCliente}`)
        .then((res) => setDiagnosticoData(res.data))
    }
    getData()
  }, [idCliente])

  useEffect(() => {
    if (!diagnosticoData) {
      return
    }

    const calcularDiagnostico = () => {
      const res = {}

      const todasOportunidades = diagnosticoData.oportunidades
      const oportunidadesAprovadas = diagnosticoData.oportunidades?.filter(
        (x) => x.statusAprovacao === "Aprovado"
      )

      const oportunidadesReprovadas = diagnosticoData.oportunidades?.filter(
        (x) => x.statusAprovacao === "Não Aprovado"
      )
      const oportunidadesPendentes = diagnosticoData.oportunidades?.filter(
        (x) =>
          x.statusAprovacao !== "Não Aprovado" &&
          x.statusAprovacao !== "Aprovado"
      )

      // sum column oportunidade from todasOportunidades
      const sumTodasOportunidades = todasOportunidades.reduce(
        (acc, cur) => acc + cur.oportunidade || 0,
        0
      )
      res.sumTodasOportunidades = sumTodasOportunidades

      // sum column oportunidade from oportunidadesAprovadas
      const sumOportunidadesAprovadas = oportunidadesAprovadas.reduce(
        (acc, cur) => acc + cur.oportunidade || 0,
        0
      )
      res.sumOportunidadesAprovadas = sumOportunidadesAprovadas

      // sum column oportunidade from oportunidadesReprovadas
      const sumOportunidadesReprovadas = oportunidadesReprovadas.reduce(
        (acc, cur) => acc + cur.oportunidade || 0,
        0
      )
      res.sumOportunidadesReprovadas = sumOportunidadesReprovadas

      // sum column oportunidade from oportunidadesPendentes
      const sumOportunidadesPendentes = oportunidadesPendentes.reduce(
        (acc, cur) => acc + cur.oportunidade || 0,
        0
      )
      res.sumOportunidadesPendentes = sumOportunidadesPendentes

      // sum column previsaoDeHonorarios from todasOportunidades
      const sumPrevisaoDeHonorarios = todasOportunidades.reduce(
        (acc, cur) => acc + cur.previsaoDeHonorarios || 0,
        0
      )
      res.sumPrevisaoDeHonorarios = sumPrevisaoDeHonorarios

      // sum column previsaoDeHonorarios from oportunidadesAprovadas
      const sumPrevisaoDeHonorariosAprovadas = oportunidadesAprovadas.reduce(
        (acc, cur) => acc + cur.previsaoDeHonorarios || 0,
        0
      )
      res.sumPrevisaoDeHonorariosAprovadas = sumPrevisaoDeHonorariosAprovadas

      // sum column previsaoDeHonorarios from oportunidadesReprovadas
      const sumPrevisaoDeHonorariosReprovadas = oportunidadesReprovadas.reduce(
        (acc, cur) => acc + cur.previsaoDeHonorarios || 0,
        0
      )
      res.sumPrevisaoDeHonorariosReprovadas = sumPrevisaoDeHonorariosReprovadas

      // sum column previsaoDeHonorarios from oportunidadesPendentes
      const sumPrevisaoDeHonorariosPendentes = oportunidadesPendentes.reduce(
        (acc, cur) => acc + cur.previsaoDeHonorarios || 0,
        0
      )
      res.sumPrevisaoDeHonorariosPendentes = sumPrevisaoDeHonorariosPendentes

      // sum totalValoresEntreguesOportunidade from todasOportunidades
      const sumTotalValoresEntreguesOportunidade = todasOportunidades.reduce(
        (acc, cur) => acc + cur.totalValoresEntreguesOportunidade || 0,
        0
      )
      res.sumTotalValoresEntreguesOportunidade =
        sumTotalValoresEntreguesOportunidade

      const { valoresUtilizados } = diagnosticoData
      // sum valor from valoresUtilizados
      const sumValoresUtilizados = valoresUtilizados.reduce(
        (acc, cur) => acc + cur.valor || 0,
        0
      )
      res.sumValoresUtilizados = sumValoresUtilizados

      setCalcDiagnostico(res)
    }

    calcularDiagnostico()
  }, [diagnosticoData])

  const refObservacao1 = useRef(null)

  const [id0, setStatusId0] = useState(null)
  const setarStatusId0 = (e) =>
    setStatusId0({
      ...id0,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.0"
    })

  const [id1, setStatusId1] = useState(null)
  const setarStatusId1 = (e) =>
    setStatusId1({
      ...id1,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.1"
    })

  const [id2, setStatusId2] = useState(null)
  const setarStatusId2 = (e) =>
    setStatusId2({
      ...id2,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.2"
    })

  const [id3, setStatusId3] = useState(null)
  const setarStatusId3 = (e) =>
    setStatusId3({
      ...id3,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.3"
    })

  const [id4, setStatusId4] = useState(null)
  const setarStatusId4 = (e) =>
    setStatusId4({
      ...id4,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.4"
    })

  const [id5, setStatusId5] = useState(null)
  const setarStatusId5 = (e) =>
    setStatusId5({
      ...id5,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.5"
    })

  const [id6, setStatusId6] = useState(null)
  const setarStatusId6 = (e) =>
    setStatusId6({
      ...id6,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.6"
    })

  const [id7, setStatusId7] = useState(null)
  const setarStatusId7 = (e) =>
    setStatusId7({
      ...id7,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.7"
    })

  const [id8, setStatusId8] = useState(null)
  const setarStatusId8 = (e) =>
    setStatusId8({
      ...id8,
      [e.target.name]: e.target.value,
      diagnosticoTributario: "1.8"
    })

  // const [id9, setStatusId9] = useState(null);
  // const setarStatusId9 = e => setStatusId9({ ...id9, [e.target.name]: e.target.value, diagnosticoTributario:"1.9"  });

  const [id9, setStatusId9] = useState(null)
  const setarStatusId9 = (e) =>
    setStatusId9({
      ...id9,
      observacao: e.target.value,
      diagnosticoTributario: "1.9"
    })

  // const [id10, setStatusId10] = useState(null);
  // const setarStatusId10 = e => setStatusId10({ ...id10, [e.target.name]: e.target.value , diagnosticoTributario:"1.10" });

  const [id10, setStatusId10] = useState(null)
  const setarStatusId10 = (values) =>
    setStatusId10({
      ...id10,
      observacao: values.floatValue,
      diagnosticoTributario: "1.10"
    })

  // const [id11, setStatusId11] = useState(null);
  // const setarStatusId11 = e => setStatusId11({ ...id11, [e.target.name]: e.target.value, diagnosticoTributario:"1.11"  });

  const [id11, setStatusId11] = useState(null)
  const setarStatusId11 = (values) =>
    setStatusId11({
      ...id11,
      observacao: values.floatValue,
      diagnosticoTributario: "1.11"
    })

  // const [id12, setStatusId12] = useState(null);
  // const setarStatusId12 = e => setStatusId12({ ...id12, [e.target.name]: e.target.value, diagnosticoTributario:"1.12"  });

  const [id12, setStatusId12] = useState(null)
  const setarStatusId12 = (values) =>
    setStatusId12({
      ...id12,
      observacao: values.floatValue,
      diagnosticoTributario: "1.12"
    })

  // const [id13, setStatusId13] = useState(null);
  // const setarStatusId13 = e => setStatusId13({ ...id13, [e.target.name]: e.target.value , diagnosticoTributario:"1.13" });

  const [id13, setStatusId13] = useState(null)
  const setarStatusId13 = (values) =>
    setStatusId13({
      ...id13,
      observacao: values.floatValue,
      diagnosticoTributario: "1.13"
    })

  // const [id14, setStatusId14] = useState(null);
  // const setarStatusId14 = e => setStatusId14({ ...id14, [e.target.name]: e.target.value , diagnosticoTributario:"1.14" });

  const [id14, setStatusId14] = useState(null)
  const setarStatusId14 = (values) =>
    setStatusId14({
      ...id14,
      observacao: values.floatValue,
      diagnosticoTributario: "1.14"
    })

  const [id21, setStatusId21] = useState(null)
  const setarStatusId21 = (values) =>
    setStatusId21({
      ...id21,
      observacao: values.floatValue,
      diagnosticoTributario: "1.21"
    })

  const [id22, setStatusId22] = useState(null)
  const setarStatusId22 = (values) =>
    setStatusId22({
      ...id22,
      observacao: values.floatValue,
      diagnosticoTributario: "1.22"
    })

  useEffect(() => {
    const getCronograma = async () => {
      const cronograma = await api.post("/find-cronograma", { cnpj })
      const listaCronograma = cronograma.data.cronograma[0].cronogramaExecucao

      for (let i = 0; i <= 14; i++) {
        const v = listaCronograma.find(
          (x) => x.diagnosticoTributario === `1.${i}`
        )
        const statusId = `setStatusId${i}`
        const diagnosticoTributario = `1.${i}`
        const observacao = v?.observacao ?? ""
        const statusObj = {
          status: v?.status ?? 0,
          diagnosticoTributario,
          observacao
        }
        const t = `${statusId}(${JSON.stringify(statusObj)})`
        console.log(t)
        eval(t)
      }
    }

    getCronograma()
  }, [cnpj])

  useEffect(() => {
    async function listaAtividades() {
      const listaDescricaoAtividades = await api.get(
        `/description-cronograma-de-execucao`
      )

      const listaAtividades = listaDescricaoAtividades.data
      let cont = 0
      const rows = []
      while (cont < listaAtividades.length) {
        rows.push({
          id: listaAtividades[cont].id,
          description: listaAtividades[cont].description
        })
        cont++
      }
      setRows(rows)
    }
    listaAtividades()
  }, [])

  async function salvarCronograma(
    id,
    status,
    observacao,
    cnpj,
    diagnosticoTributario
  ) {
    console.log(status)

    setStatusEdit(0)
    await api
      .put("/editar-cronograma", {
        id,
        status,
        observacao,
        cnpj,
        diagnosticoTributario
      })
      .then((response) => {
        toast.success("Registro salvo com sucesso!", {
          position: toast.POSITION.TOP_CENTER
        })
        setStatusEdit(1)
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(
            `O item anterior não foi concluído, faça a atualização antes de prosseguir!`,
            {
              position: toast.POSITION.TOP_CENTER
            }
          )
        }
        setStatusEdit(0)
      })
  }

  if (
    !id0 ||
    !id1 ||
    !id2 ||
    !id3 ||
    !id4 ||
    !id5 ||
    !id6 ||
    !id7 ||
    !id8 ||
    !id9 ||
    !id10 ||
    !id11 ||
    !id12 ||
    !id13 ||
    !id14
  ) {
    return <div> loading </div>
  }

  const data = [
    {
      id: "1.0",
      description: "Recebimento contrato",
      status: id0.status,
      observacao: id0.observacao
    },
    {
      id: "1.1",
      description: "Obtenção dos dados",
      status: id1.status,
      observacao: id1.observacao
    },
    {
      id: "1.2",
      description: "ValidaçãoValidação",
      status: id2.status,
      observacao: id2.observacao
    },
    {
      id: "1.3",
      description: "Diagnóstico",
      status: id3.status,
      observacao: id3.observacao
    },
    {
      id: "1.4",
      description: "Triagens",

      status: id4.status,
      observacao: id4.observacao
    },
    {
      id: "1.5",
      description: "Apresentação",
      status: id5.status,
      observacao: id5.observacao
    },
    {
      id: "1.6",
      description: "Aprovação",
      status: id6.status,
      observacao: id6.observacao
    },
    // {
    //   id: "1.7",
    //   description: "Valor",
    //   status: id7.status,
    //   observacao: id7.observacao
    // },
    // {
    //   id: "1.8",
    //   description: "Honorários",
    //   status: id8.status,
    //   observacao: id8.observacao
    // },
    {
      id: "1.9",
      description: "Crédito Envolvido - Potencial Aprovado",
      status: id9.status,
      observacao: id9.observacao
    },
    {
      id: "1.10",
      description: "Crédito Envolvido - Potencial Pendente",
      status: id10.status,
      observacao: id10.observacao
    },
    {
      id: "1.11",
      description: "Crédito Envolvido - Potencial Não Aprovado",
      status: id11.status,
      observacao: id11.observacao
    },
    {
      id: "1.12",
      description: "Honorários - Potencial Aprovado",
      status: id12.status,
      observacao: id12.observacao
    },
    {
      id: "1.13",
      description: "Honorários - Potencial Pendente",
      status: id13.status,
      observacao: id13.observacao
    },
    {
      id: "1.14",
      description: "Honorários - Potencial Não Aprovado",
      status: id14.status,
      observacao: id14.observacao
    },
    {
      description: "-----------",
      status: "-----------",
      observacao: "-----------"
    },

    {
      description: "Leitura dos Status ---",
      status0: "0 --- Não Recebido",
      status1: "1 --- Recebido",
      status2: "2 --- Em aguardo"
    }
  ]

  const wscols = [
    { wch: Math.max(...data.map((data) => data.id)) },
    { wch: Math.max(...data.map((data) => data.description)) },
    { wch: Math.max(...data.map((data) => data.status)) },
    { wch: Math.max(...data.map((data) => data.observacao)) }
  ]

  //   console.log(rows);

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <ResponsiveDrawer title={razaoSocial} />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflowX: "hidden"
        }}
      >
        <Toolbar />

        <Container
          sx={{
            mt: 4,
            mb: 4,
            maxWidth: "100vw"
          }}
        >
          <center>
            <span className="submenu">Acompanhamento do Projeto</span>
          </center>
          <hr />
          <center>
            <span className="submenu">Status Sintético</span>
          </center>
          <br />
          <Row>
            <Col xs="12" xxl={9}>
              <Paper elevation={10}>
                <center
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    backgroundColor: "#121212",
                    color: "white"
                  }}
                >
                  Diagnóstico - 1ª Fase
                </center>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`
                        return row.id === "1.0" ? (
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            align="center"
                            style={{ lineHeight: 4.0, fontSize: "11px" }}
                          >
                            <Tooltip title={row.description} arrow>
                              <center
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#121212",
                                  color: "white"
                                }}
                              >
                                {/* {row.id} */}
                                1.1
                              </center>
                            </Tooltip>
                            <center>Recebimento do contrato</center>
                            <TextField
                              id="observacao0"
                              sx={{
                                height: "24px",
                                top: "3px",
                                marginLeft: "3px",
                                marginRight: "3px"
                              }}
                              value={id0.observacao ?? ""}
                              name="observacao"
                              onChange={setarStatusId0}
                            />
                            <br />
                            <br />
                            <Select
                              variant="standard"
                              name="status"
                              value={id0.status ?? ""}
                              onChange={setarStatusId0}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <UpdateRoundedIcon sx={{ color: "gold" }} />
                              </MenuItem>
                            </Select>
                            <br />
                            <IconButton
                              onClick={() =>
                                salvarCronograma(
                                  id0._id,
                                  id0.status,
                                  id0.observacao,
                                  cnpj,
                                  id0.diagnosticoTributario
                                )
                              }
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SaveIcon />
                            </IconButton>
                            <br />
                          </TableCell>
                        ) : row.id === "1.1" ? (
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            align="center"
                            style={{ lineHeight: 4.0, fontSize: "11px" }}
                          >
                            <Tooltip title={row.description} arrow>
                              <center
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#121212",
                                  color: "white"
                                }}
                              >
                                {/* {row.id} */}
                                1.2
                              </center>
                            </Tooltip>
                            <center>Obtenção dos dados</center>
                            <TextField
                              id="observacao1"
                              sx={{
                                height: "24px",
                                top: "3px",
                                marginLeft: "3px",
                                marginRight: "3px"
                              }}
                              value={id1.observacao ?? ""}
                              name="observacao"
                              onChange={setarStatusId1}
                            />
                            <br />
                            <br />
                            <Select
                              variant="standard"
                              name="status"
                              value={id1.status ?? ""}
                              onChange={setarStatusId1}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <UpdateRoundedIcon sx={{ color: "gold" }} />
                              </MenuItem>
                            </Select>
                            <br />
                            <IconButton
                              onClick={() =>
                                salvarCronograma(
                                  id1._id,
                                  id1.status,
                                  id1.observacao,
                                  cnpj,
                                  id1.diagnosticoTributario
                                )
                              }
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SaveIcon />
                            </IconButton>
                            <br />
                          </TableCell>
                        ) : row.id === "1.2" ? (
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            align="center"
                            style={{ lineHeight: 4.0, fontSize: "11px" }}
                          >
                            <Tooltip title={row.description} arrow>
                              <center
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#121212",
                                  color: "white"
                                }}
                              >
                                {/* {row.id} */}
                                1.3
                              </center>
                            </Tooltip>
                            <center>Validação dos arquivos</center>
                            <TextField
                              id="observacao2"
                              sx={{
                                height: "24px",
                                top: "3px",
                                marginLeft: "3px",
                                marginRight: "3px"
                              }}
                              value={id2.observacao ?? ""}
                              name="observacao"
                              onChange={setarStatusId2}
                            />
                            <br />
                            <br />
                            <Select
                              variant="standard"
                              name="status"
                              value={id2.status ?? ""}
                              onChange={setarStatusId2}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <UpdateRoundedIcon sx={{ color: "gold" }} />
                              </MenuItem>
                            </Select>
                            <br />
                            <IconButton
                              onClick={() =>
                                salvarCronograma(
                                  id2._id,
                                  id2.status,
                                  id2.observacao,
                                  cnpj,
                                  id2.diagnosticoTributario
                                )
                              }
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SaveIcon />
                            </IconButton>
                            <br />
                          </TableCell>
                        ) : row.id === "1.3" ? (
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            align="center"
                            style={{ lineHeight: 4.0, fontSize: "11px" }}
                          >
                            <Tooltip title={row.description} arrow>
                              <center
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#121212",
                                  color: "white"
                                }}
                              >
                                {/* {row.id} */}
                                1.4
                              </center>
                            </Tooltip>
                            <div style={{ lineHeight: 2, height: "44px" }}>
                              Diagnóstico (5 dias úteis)
                            </div>

                            <TextField
                              id="observacao3"
                              sx={{
                                height: "24px",
                                top: "3px",
                                marginLeft: "3px",
                                marginRight: "3px"
                              }}
                              value={id3.observacao ?? ""}
                              name="observacao"
                              onChange={setarStatusId3}
                            />
                            <br />
                            <br />
                            <Select
                              variant="standard"
                              name="status"
                              value={id3.status ?? ""}
                              onChange={setarStatusId3}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <UpdateRoundedIcon sx={{ color: "gold" }} />
                              </MenuItem>
                            </Select>
                            <br />
                            <IconButton
                              onClick={() =>
                                salvarCronograma(
                                  id3._id,
                                  id3.status,
                                  id3.observacao,
                                  cnpj,
                                  id3.diagnosticoTributario
                                )
                              }
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SaveIcon />
                            </IconButton>
                            <br />
                          </TableCell>
                        ) : row.id === "1.4" ? (
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            align="center"
                            style={{ lineHeight: 4.0, fontSize: "11px" }}
                          >
                            <Tooltip title={row.description} arrow>
                              <center
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#121212",
                                  color: "white"
                                }}
                              >
                                {/* {row.id} */}
                                1.5
                              </center>
                            </Tooltip>
                            <center>Triagens</center>
                            <TextField
                              id="observacao4"
                              sx={{
                                height: "24px",
                                top: "3px",
                                marginLeft: "3px",
                                marginRight: "3px"
                              }}
                              name="observacao"
                              value={id4.observacao ?? ""}
                              onChange={setarStatusId4}
                            />
                            <br />
                            <br />
                            <Select
                              variant="standard"
                              name="status"
                              value={id4.status ?? ""}
                              onChange={setarStatusId4}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <UpdateRoundedIcon sx={{ color: "gold" }} />
                              </MenuItem>
                            </Select>
                            <br />
                            <IconButton
                              onClick={() =>
                                salvarCronograma(
                                  id4._id,
                                  id4.status,
                                  id4.observacao,
                                  cnpj,
                                  id4.diagnosticoTributario
                                )
                              }
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SaveIcon />
                            </IconButton>
                            <br />
                          </TableCell>
                        ) : row.id === "1.5" ? (
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            align="center"
                            style={{ lineHeight: 4.0, fontSize: "11px" }}
                          >
                            <Tooltip title={row.description} arrow>
                              <center
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#121212",
                                  color: "white"
                                }}
                              >
                                {/* {row.id} */}
                                1.6
                              </center>
                            </Tooltip>

                            <div style={{ lineHeight: 2, height: "44px" }}>
                              Apresentação dos resultados
                            </div>

                            <TextField
                              id="observacao5"
                              sx={{
                                height: "24px",
                                top: "3px",
                                marginLeft: "3px",
                                marginRight: "3px"
                              }}
                              value={id5.observacao ?? ""}
                              name="observacao"
                              onChange={setarStatusId5}
                            />
                            <br />
                            <br />
                            <Select
                              variant="standard"
                              name="status"
                              value={id5.status ?? ""}
                              onChange={setarStatusId5}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <UpdateRoundedIcon sx={{ color: "gold" }} />
                              </MenuItem>
                            </Select>
                            <br />
                            <IconButton
                              onClick={() =>
                                salvarCronograma(
                                  id5._id,
                                  id5.status,
                                  id5.observacao,
                                  cnpj,
                                  id5.diagnosticoTributario
                                )
                              }
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SaveIcon />
                            </IconButton>
                            <br />
                          </TableCell>
                        ) : row.id === "1.6" ? (
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            align="center"
                            style={{ lineHeight: 4.0, fontSize: "11px" }}
                          >
                            <Tooltip title={row.description} arrow>
                              <center
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#121212",
                                  color: "white"
                                }}
                              >
                                {row.id}
                              </center>
                            </Tooltip>
                            <center>Aprovação</center>
                            <TextField
                              id="observacao6"
                              sx={{
                                height: "24px",
                                top: "3px",
                                marginLeft: "3px",
                                marginRight: "3px"
                              }}
                              value={id6.observacao ?? ""}
                              name="observacao"
                              onChange={setarStatusId6}
                            />
                            <br />
                            <br />
                            <Select
                              variant="standard"
                              name="status"
                              value={id6.status ?? ""}
                              onChange={setarStatusId6}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <UpdateRoundedIcon sx={{ color: "gold" }} />
                              </MenuItem>
                            </Select>
                            <br />
                            <IconButton
                              onClick={() =>
                                salvarCronograma(
                                  id6._id,
                                  id6.status,
                                  id6.observacao,
                                  cnpj,
                                  id6.diagnosticoTributario
                                )
                              }
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SaveIcon />
                            </IconButton>
                            <br />
                          </TableCell>
                        ) : null
                      }
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </Col>
            <Col xs="12" xxl={3}>
              <Paper elevation={10}>
                <center
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    backgroundColor: "#121212",
                    color: "white"
                  }}
                >
                  2ª Fase dos Trabalhos
                </center>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableCell
                      component="th"
                      padding="none"
                      align="center"
                      style={{ lineHeight: 4.0, fontSize: "11px" }}
                    >
                      <Tooltip title="Status do Levantamento de Campo" arrow>
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          2.1
                        </center>
                      </Tooltip>
                      <div style={{ lineHeight: 2, height: "44px" }}>
                        Status do levantamento de campo
                      </div>

                      <TextField
                        id="observacao7"
                        sx={{
                          height: "24px",
                          top: "3px",
                          marginLeft: "3px",
                          marginRight: "3px"
                        }}
                        value={id7.observacao ?? ""}
                        name="observacao"
                        onChange={setarStatusId7}
                      />
                      <br />
                      <br />
                      <Select
                        variant="standard"
                        name="status"
                        value={id7.status ?? ""}
                        onChange={setarStatusId7}
                      >
                        <MenuItem value={0}>
                          <CancelIcon sx={{ color: "red" }} />
                        </MenuItem>
                        <MenuItem value={1}>
                          <CheckCircleIcon sx={{ color: "green" }} />
                        </MenuItem>
                        <MenuItem value={2}>
                          <UpdateRoundedIcon sx={{ color: "gold" }} />
                        </MenuItem>
                      </Select>
                      <br />
                      <IconButton
                        onClick={() =>
                          salvarCronograma(
                            id7._id,
                            id7.status,
                            id7.observacao,
                            cnpj,
                            id7.diagnosticoTributario
                          )
                        }
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <SaveIcon />
                      </IconButton>
                      <br />
                    </TableCell>
                    <TableCell
                      component="th"
                      padding="none"
                      align="center"
                      style={{ lineHeight: 4.0, fontSize: "11px" }}
                    >
                      <Tooltip title="Projeto Concluído em:" arrow>
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          2.2
                        </center>
                      </Tooltip>
                      <center>Projeto Concluído em:</center>
                      <TextField
                        id="observacao0"
                        sx={{
                          height: "24px",
                          top: "3px",
                          marginLeft: "3px",
                          marginRight: "3px"
                        }}
                        value={id8.observacao ?? ""}
                        name="observacao"
                        onChange={setarStatusId8}
                      />
                      <br />
                      <br />
                      <Select
                        variant="standard"
                        name="status"
                        value={id8.status ?? ""}
                        onChange={setarStatusId8}
                      >
                        <MenuItem value={0}>
                          <CancelIcon sx={{ color: "red" }} />
                        </MenuItem>
                        <MenuItem value={1}>
                          <CheckCircleIcon sx={{ color: "green" }} />
                        </MenuItem>
                        <MenuItem value={2}>
                          <UpdateRoundedIcon sx={{ color: "gold" }} />
                        </MenuItem>
                      </Select>
                      <br />
                      <IconButton
                        onClick={() =>
                          salvarCronograma(
                            id8._id,
                            id8.status,
                            id8.observacao,
                            cnpj,
                            id8.diagnosticoTributario
                          )
                        }
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <SaveIcon />
                      </IconButton>
                      <br />
                    </TableCell>
                  </TableBody>
                </Table>
              </Paper>
            </Col>
          </Row>

          <Row>
            <Col>
              <Paper elevation={10}>
                <center
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    backgroundColor: "#121212",
                    color: "white"
                  }}
                >
                  Observação
                </center>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableCell
                      component="th"
                      id="1.9"
                      padding="none"
                      align="center"
                      style={{ lineHeight: 4.0, fontSize: "11px" }}
                    >
                      <TextField
                        id="observacao9"
                        multiline
                        sx={{
                          width: "100%",
                          height: "24px",
                          top: "3px",
                          marginLeft: "3px",
                          marginRight: "3px"
                        }}
                        value={id9.observacao ?? ""}
                        name="observacao"
                        onChange={setarStatusId9}
                      />

                      <br />
                      <br />
                      <IconButton
                        onClick={() =>
                          salvarCronograma(
                            id9._id,
                            id9.status,
                            id9.observacao,
                            cnpj,
                            id9.diagnosticoTributario
                          )
                        }
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <SaveIcon />
                      </IconButton>
                      <br />
                    </TableCell>
                  </TableBody>
                </Table>
              </Paper>
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs="12" xxl={9}>
              <Paper elevation={10}>
                <center
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    backgroundColor: "#121212",
                    color: "white"
                  }}
                >
                  Diagnóstico
                </center>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        padding="none"
                        align="center"
                        style={{ lineHeight: 4.0, fontSize: "11px" }}
                      >
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          Referência
                        </center>
                      </TableCell>
                      <TableCell
                        component="th"
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          Potencial TOTAL do diagnóstico
                        </center>
                      </TableCell>
                      <TableCell
                        component="th"
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          Potencial do diagnóstico aprovado
                        </center>
                      </TableCell>{" "}
                      <TableCell
                        component="th"
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          Potencial do diagnóstico pendente de aprovação
                        </center>
                      </TableCell>{" "}
                      <TableCell
                        component="th"
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          Potencial do diagnóstico não aprovado
                        </center>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        padding="none"
                        align="center"
                        style={{ lineHeight: 4.0, fontSize: "11px" }}
                      >
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          Crédito envolvido
                        </center>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={calcDiagnostico?.sumTodasOportunidades || 0}
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={calcDiagnostico?.sumOportunidadesAprovadas}
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>

                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={calcDiagnostico?.sumOportunidadesReprovadas}
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={calcDiagnostico?.sumOportunidadesPendentes}
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <center
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#121212",
                            color: "white"
                          }}
                        >
                          Honorário potencial
                        </center>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={calcDiagnostico?.sumPrevisaoDeHonorarios}
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>

                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={
                            calcDiagnostico?.sumPrevisaoDeHonorariosAprovadas
                          }
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={
                            calcDiagnostico?.sumPrevisaoDeHonorariosReprovadas
                          }
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 2.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={
                            calcDiagnostico?.sumPrevisaoDeHonorariosPendentes
                          }
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Col>
            <Col xs="12" xxl={3}>
              <Paper elevation={10}>
                <center
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    backgroundColor: "#121212",
                    color: "white"
                  }}
                >
                  2ª Fase dos Trabalhos
                </center>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableCell
                      component="th"
                      padding="none"
                      align="center"
                      style={{ lineHeight: 2.0, fontSize: "11px" }}
                    >
                      <center
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#121212",
                          color: "white"
                        }}
                      >
                        Total dos créditos já entregues
                      </center>
                    </TableCell>

                    <TableCell
                      component="th"
                      padding="none"
                      align="center"
                      style={{ lineHeight: 2.0, fontSize: "11px" }}
                    >
                      <center
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#121212",
                          color: "white"
                        }}
                      >
                        Total dos créditos já utilizados
                      </center>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 4.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={
                            calcDiagnostico?.sumTotalValoresEntreguesOportunidade
                          }
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ lineHeight: 4.0, fontSize: "11px" }}
                      >
                        <NumericFormat
                          value={calcDiagnostico?.sumValoresUtilizados}
                          allowLeadingZeros
                          thousandSeparator="."
                          readOnly
                          decimalSeparator=","
                          fixedDecimalScale
                          decimalScale={2}
                          displayType="text"
                          allowedDecimalSeparators={[","]}
                          prefix="R$ "
                          style={{
                            top: "3",
                            height: "25px",
                            width: "150px",
                            border: "none"
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Col>
          </Row>

          <Row
            sx={{
              display: "flex"
            }}
          >
            <Link
              to={`/tabela-analitica-cliente/${cnpj}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                variant="contained"
              >
                Visualizar Analítico 1ª FASE DOS TRABALHOS - DIAGNÓSTICO
              </Button>
            </Link>

            <ExportCSV
              csvData={data}
              fileName={`Cronograma-Execução${cnpj}`}
              wscols={wscols}
            />
          </Row>
        </Container>
      </Box>
    </Box>
  )
}
export default function TabelaCronogramaExec() {
  return <CronogramaExec />
}
