import React, { useContext, useEffect, useState } from "react"
import api from "../../Config/api"

import { AuthContext } from "../../Context/auth"
import { toast, ToastContainer } from "react-toastify"

import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import SendIcon from "@mui/icons-material/Send"
import Modal from "@mui/material/Modal"
import CircularProgress from "@mui/material/CircularProgress"
import FolderOpenIcon from "@mui/icons-material/FolderOpen"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { FormGroup, Tooltip } from "@mui/material"
import { Col, Row } from "reactstrap"
import "react-toastify/dist/ReactToastify.min.css"
import { useParams } from "react-router-dom"
import ResponsiveDrawer from "../../components/menu"
import {
  removeDuplicates,
  ValidaFileECD,
  ValidaFileECDFISCAL,
  readFilesInChunks,
  ValidaFileECF
} from "../../utils/ValidaFileReader"

const mdTheme = createTheme()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray" /* Altera a cor de fundo do menu */,
    color: "white" /* Altera a cor da Letra */
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const Input = styled("input")({
  display: "none"
})

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "100",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 0.9,
  align: "center",
  fontSize: 14
}

const styleModalErro = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 0.9,
  align: "center",
  fontSize: 16
}

const styleModalErroFormato = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: "100",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 0.9,
  align: "center",
  fontSize: 12
}

const DashboardAdmin = () => {
  const [openModal, setOpenModal] = useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  const [openModalErro, setOpenModalErro] = useState(false)
  const handleOpenErro = () => setOpenModalErro(true)
  const handleCloseErro = () => setOpenModalErro(false)

  const [openModalErroFormato, setOpenModalErroFormato] = useState(false)
  const handleOpenErroFormato = () => setOpenModalErroFormato(true)
  const handleCloseErroFormato = () => setOpenModalErroFormato(false)

  const [openModalVazio, setOpenModalVazio] = useState(false)
  const handleOpenVazio = () => setOpenModalVazio(true)
  const handleCloseVazio = () => setOpenModalVazio(false)

  const headers = {
    "Content-Type": "multipart/form-data"
  }

  const params = useParams()
  const cnpj = params._id

  const [rows, setRows] = useState([])
  const [razaoSocial, setRazaoSocial] = useState("")
  const [_id, set_Id] = useState("")
  const [dadosCliente, setDadosCliente] = useState({})
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")
  const [obs10, setObs10] = useState("")

  const [status, setStatus] = useState("")
  const [simples, setSimples] = useState(false)

  const [dataLimite10, setDataLimite10] = useState("")
  const [tipo10, setTipo10] = useState("")

  const [loading, setLoading] = useState(false)
  const [colunas, setColunas] = useState([])
  const [dados, setDados] = useState([])
  const [tipo1, setTipo1] = useState([])
  const [tipo2, setTipo2] = useState([])
  const [tipo3, setTipo3] = useState([])
  const [tipo5, setTipo5] = useState([])
  const [tipo6, setTipo6] = useState([])
  const [tipo7, setTipo7] = useState([])
  const [tipo8, setTipo8] = useState([])
  const [tipo9, setTipo9] = useState([])
  const [lista, setLista] = useState([])

  const [observacao, setObservacao] = useState("")
  const [observacaoCliente, setObservacaoCliente] = useState("")

  useEffect(() => {
    const getCliente = async () => {
      const buscaCNPJ = { cnpj }

      const cliente = await api.post("/dashboard", buscaCNPJ)
      setDadosCliente(cliente)
      setRazaoSocial(cliente.data.cliente.razaoSocial)
      setData1(cliente.data.cliente.data1)
      setData2(cliente.data.cliente.data2)
      setData3(cliente.data.cliente.data3)
      setData4(cliente.data.cliente.data4)
      setSimples(cliente.data.cliente.simples)
      set_Id(cliente.data.cliente._id)
      setStatus(
        cliente.data.cliente.tiposUploads.filter(
          (dado) => dado.tipo === 10 && dado.statusCincoAnos === 0
        )
      )
      setObservacaoCliente(
        cliente.data.tipos
          .filter((dado) => dado.tipo === 10)
          .map((d) => d.setObservacaoCliente)
      )
      setObservacao(
        cliente.data.tipos
          .filter((dado) => dado.tipo === 10)
          .map((d) => d.observacao)
      )
      setDataLimite10(
        cliente.data.tipos.filter((d) => d.tipo === 10).map((d) => d.dataLimite)
      )
      setTipo10(
        cliente.data.tipos.filter((d) => d.tipo === 10).map((d) => d.tipo)[0]
      )
      setRows(cliente.data.tipos)
      setLoading(false)
    }
    getCliente()
    setLoading(true)
  }, [cnpj])

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/checklist", { cnpj })
      const col = []
      if (cliente.data.dados.data1 !== null) {
        col.push(
          `${cliente.data.dados.data1.substr(
            0,
            4
          )}/${cliente.data.dados.data1.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data2 !== null) {
        col.push(
          `${cliente.data.dados.data2.substr(
            0,
            4
          )}/${cliente.data.dados.data2.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data3 !== null) {
        col.push(
          `${cliente.data.dados.data3.substr(
            0,
            4
          )}/${cliente.data.dados.data3.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data4 !== null) {
        col.push(
          `${cliente.data.dados.data4.substr(
            0,
            4
          )}/${cliente.data.dados.data4.substr(5, 2)}`
        )
      }

      setColunas(col)
      setDados(cliente.data.dados)
      let cont = 0
      while (cont < cliente.data.tipos.length) {
        if (cliente.data.tipos[cont].tipo === 1) {
          setTipo1(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 2) {
          setTipo2(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 3) {
          setTipo3(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 5) {
          setTipo5(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 6) {
          setTipo6(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 7) {
          setTipo7(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 8) {
          setTipo8(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 9) {
          setTipo9(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 10) {
          setTipo10(cliente.data.tipos[cont])
        }

        cont++
      }
      setLoading(false)
    }

    getCliente()
    setLoading(true)
  }, [cnpj])

  const [file, setFile] = useState("")

  const [filesFail, setFilesFail] = useState([])

  const [uploadTipo, setUploadTipo] = useState({
    idTipo: "",
    tipo: ""
  })

  const pastas = [
    { tipo: 0, pasta: "0" },
    { tipo: 1, pasta: "Solicitacoes_Complementares" },
    { tipo: 2, pasta: "Planilhas_memorias_de_calculo_Pis_Cofins_Sintetica" },
    { tipo: 3, pasta: "Planilhas_memorias_de_calculo_Pis_Cofins_Analitica" },
    {
      tipo: 4,
      pasta: "Memoria_de_cálculo_Rascunho_do_ICMS_ICMS_ST_e_ICMS_Antecipacao"
    },
    { tipo: 5, pasta: "DCTFs" },
    { tipo: 6, pasta: "Balancetes_Contabeis" },
    { tipo: 7, pasta: "Livros_Razoes_Contabeis" },
    { tipo: 8, pasta: "SPEDContabil" },
    { tipo: 9, pasta: "SPEDFiscal" },
    { tipo: 10, pasta: "EFD_Contribuicoes" },
    { tipo: 12, pasta: "CIAP" },
    {
      tipo: 13,
      pasta: "ECAC_Comprovante_de_Arrecadação_dos_Tributos_IRPJ_e_CSLL"
    },
    { tipo: 14, pasta: "ECF" },
    { tipo: 15, pasta: "ECAC_Fontes_Pagadoras" },
    { tipo: 16, pasta: "Bens_do_ativo_imobilizado" },
    { tipo: 17, pasta: "Memorias_de_Calculo_do_IRPJ_e_CSLL" },
    { tipo: 18, pasta: "Creditos_Extemporaneos" },
    { tipo: 19, pasta: "Resumo_da_folha_pagamento" },
    { tipo: 20, pasta: "Comprovante_de_declaração_a_recolher_do_INSS_Rubrica" },
    { tipo: 21, pasta: "Print_da_FAP" },
    { tipo: 22, pasta: "GPS" },
    {
      tipo: 23,
      pasta:
        "Tabela_de_Incidência_INSS_sobre_eventos_da_folha_de_pagamentos_Contribuicoes_Previdenciarias"
    },
    { tipo: 24, pasta: "Memoria_de_Calculo_de_CPRB_Desoneracao" },
    { tipo: 25, pasta: "Questionario_Previo" },
    { tipo: 26, pasta: "DARF" },
    { tipo: 27, pasta: "DCTFWEB" },
    { tipo: 29, pasta: "XMLs_NF-es_e_CT-es_de_entradas" },
    { tipo: 30, pasta: "Extrato_Simples_Nacional" },
    { tipo: 31, pasta: "DAS" },
    { tipo: 32, pasta: "XMLs_NF-es_e_CT-es_de_saída" },
    { tipo: 33, pasta: "Guia_ou_Declaração_de_Apuracao_do_ICMS" },
    { tipo: 34, pasta: "Balancete_Contabil_ultimo_nao_encerrado)" }
  ]

  function formatarData(data) {
    if (data === null) {
      return "-"
    }
    const dia = data.substr(8, 2)
    const mes = data.substr(5, 2)
    const ano = data.substr(0, 4)
    const dataAtual = `${dia}/${mes}/${ano}`
    return dataAtual
  }

  const [pasta, setPasta] = useState("")
  const [formato, setFormato] = useState("")

  const upload = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    let x = 0
    while (x < file.length) {
      formData.append("file", file[x])
      x++
    }

    formData.append("data1", data1)
    formData.append("data2", data2)
    formData.append("data3", data3)
    formData.append("data4", data4)

    formData.append("cnpjLogado", cnpj)

    if (
      uploadTipo.tipo !== 5 &&
      uploadTipo.tipo !== 8 &&
      uploadTipo.tipo !== 9 &&
      uploadTipo.tipo !== 8 &&
      uploadTipo.tipo !== 10 &&
      uploadTipo.tipo !== 12 &&
      uploadTipo.tipo !== 14 &&
      uploadTipo.tipo !== 15 &&
      uploadTipo.tipo !== 19 &&
      uploadTipo.tipo !== 20 &&
      uploadTipo.tipo !== 21 &&
      uploadTipo.tipo !== 22 &&
      uploadTipo.tipo !== 29 &&
      uploadTipo.tipo !== 30 &&
      uploadTipo.tipo !== 31 &&
      uploadTipo.tipo !== 32
    ) {
      // Qualquer upload sem necessidade de validação no arquivo ou não pertecente a filial
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          await api.post("/uploads", formData, { headers })
          await api.post("/statusTipo", uploadTipo)
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".txt, .xml, .pdf, .jpge, .xls, arquivos do excel")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 5) {
      // DCTF
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          await api.post("/uploads", formData, { headers })
          await api.post("/statusTipo", uploadTipo)
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 8) {
      // Tipo SPED CONTABIL
      if (file.length > 0) {
        handleOpen()

        try {
          const validacaoArquivos = Array.from(file).map((fileAtual) => {
            return ValidaFileECD(fileAtual, 8)
          })
          const validacoes = await Promise.all(validacaoArquivos)
          api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
            pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
          }/`

          const upload = await api.post("/uploadsSPED", formData, { headers })
          if (upload.data.erro === false) {
            const datas = validacoes
              .filter((d) => d.data !== undefined)
              .map((d) => d.data)
            await api.post("/registra-upload-sped-contabil", {
              cnpj,
              uploadTipo,
              datas
            })
            await api.post("/status-tipo-sped-contabil", {
              dadosCliente,
              uploadTipo,
              datas
            })
            await api.post("/statusTipoCincoAnos", uploadTipo)
          } else {
            handleClose()
            toast.warn("Não foi possível atualizar o status")
          }
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch (e) {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          toast.error("Documento Inválido")
          handleClose()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 9) {
      // Tipo SPED FISCAL
      if (file.length > 0) {
        handleOpen()
        try {
          const validacaoArquivos = Array.from(file).map((fileAtual) => {
            return ValidaFileECDFISCAL(fileAtual, 9)
          })
          const validacoes = await Promise.all(validacaoArquivos)
          api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
            pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
          }/`
          const resposta = await api.post("/uploadsSPED", formData, { headers })
          if (resposta.data.erro === false) {
            const datas = validacoes
              .filter((d) => d.data !== undefined)
              .map((d) => d.data)
            const cnpjs = validacoes
              .filter((d) => d.cnpj !== undefined)
              .map((d) => d.cnpj)
            console.log("cnpjs fiscal", cnpjs)

            await api.post("/registra-upload-sped-fiscal", {
              cnpj,
              cnpjs,
              uploadTipo,
              datas
            })
            const datasCincoAnos = dadosCliente.data.cliente.cincoAnos
            const statusCincoAnos = dadosCliente.data.cliente.tiposUploads
              .filter((d) => d.tipo === 9)
              .map((d) => d.statusCincoAnos)

            await api.post("/status-sped-fiscal", {
              datasCincoAnos,
              statusCincoAnos,
              cnpjs,
              uploadTipo,
              datas,
              cliente: dadosCliente.data.cliente
            })
          }
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch (e) {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          toast.error("Documento Inválido")
          handleClose()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 10) {
      // Tipo EFD Contribuições
      if (file.length > 0) {
        handleOpen()
        try {
          const resultado = await new Promise((resolve) => {
            readFilesInChunks(Array.from(file), resolve)
          })

          api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
            pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
          }/`
          const resposta = await api.post("/uploads", formData, { headers })
          if (resposta.data.erro === false) {
            const datas = resultado
              .filter((d) => d.data !== null)
              .map((d) => d.data)
            const filiais = resultado
              .map((d) => d.cnpjFiliais)
              .flatMap((d) => d)
            const removeFiliaisDuplicadas = removeDuplicates(filiais)
            const datasCincoAnos = dadosCliente.data.cliente.cincoAnos
            const statusCincoAnos = dadosCliente.data.cliente.tiposUploads
              .filter((d) => d.tipo === 9)
              .map((d) => d.statusCincoAnos)

            await api.post("/editar-filiais-cliente", {
              _id,
              filiais: removeFiliaisDuplicadas
            }) // Cadastra filial
            await api.post("/registra-upload-efd", { cnpj, uploadTipo, datas })
            await api.post("/status-efd", {
              datasCincoAnos,
              statusCincoAnos,
              cnpj,
              uploadTipo,
              datas,
              cnpjs: removeFiliaisDuplicadas,
              cliente: dadosCliente.data.cliente
            })
          }
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch (e) {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          toast.error("Documento Inválido")
          handleClose()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 12) {
      // CIAP
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const tipoCiap = await api.post("uploads", formData, { headers })

          const dadosStatusCiap = Object.keys(tipoCiap.data.lista_idTipo_tipo)
            .map((_cnpj) => tipoCiap.data.lista_idTipo_tipo[_cnpj])
            .flatMap((d) => d)
            .filter((tipoDoc) => tipoDoc.tipo === 12)
            .map((d) => ({ tipo: d.tipo, idTipo: d.id }))

          await api.post("/statusTipoDocFilial", {
            lista_idTipo_tipo: dadosStatusCiap
          })
          await api.post("/statusTipo", uploadTipo)

          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum aquivo selcionado!")
      }
    }

    if (uploadTipo.tipo === 14) {
      // Tipo ECF Contribuições
      if (file.length > 0) {
        handleOpen()
        try {
          const validacaoArquivos = Array.from(file).map((fileAtual) => {
            return ValidaFileECF(fileAtual, 14)
          })
          const validacoes = await Promise.all(validacaoArquivos)
          api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
            pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
          }/`
          const upload = await api.post("/uploads", formData, { headers })
          if (upload.data.erro === false) {
            const datas = validacoes.map((d) => d.data)
            await api.post("/registra-upload-ecf", { cnpj, uploadTipo, datas })
            await api.post("/status-tipo-ecf", {
              dadosCliente,
              uploadTipo,
              datas
            })
            await api.post("/statusTipoPeriodoDiagnosticoCincoAnos", uploadTipo)
          } else {
            handleClose()
            toast.warn("Não foi possível atualizar o status")
          }
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch (e) {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          toast.error("Documento Inválido")
          handleClose()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 15) {
      // Fontes pagadoras

      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const tipoFontesPagadoras = await api.post("uploads", formData, {
            headers
          })

          const dadosStatusFontesPagadoras = Object.keys(
            tipoFontesPagadoras.data.lista_idTipo_tipo
          )
            .map((_cnpj) => tipoFontesPagadoras.data.lista_idTipo_tipo[_cnpj])
            .flatMap((d) => d)
            .filter((tipoDoc) => tipoDoc.tipo === 15)
            .map((d) => ({ tipo: d.tipo, idTipo: d.id }))

          await api.post("/statusTipoDocFilial", {
            lista_idTipo_tipo: dadosStatusFontesPagadoras
          })
          await api.post("/statusTipo", uploadTipo)

          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum aquivo selcionado!")
      }
    }

    if (uploadTipo.tipo === 19) {
      // Resumo da Folha de Pagamento
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const tipoResumoFolhaPagamento = await api.post("uploads", formData, {
            headers
          })

          const dadosStatusResumoFolhaPagamento = Object.keys(
            tipoResumoFolhaPagamento.data.lista_idTipo_tipo
          )
            .map(
              (_cnpj) => tipoResumoFolhaPagamento.data.lista_idTipo_tipo[_cnpj]
            )
            .flatMap((d) => d)
            .filter((tipoDoc) => tipoDoc.tipo === 19)
            .map((d) => ({ tipo: d.tipo, idTipo: d.id }))

          await api.post("/statusTipoDocFilial", {
            lista_idTipo_tipo: dadosStatusResumoFolhaPagamento
          })
          await api.post("/statusTipo", uploadTipo)

          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum aquivo selcionado!")
      }
    }

    if (uploadTipo.tipo === 20) {
      // Comprovante INSS – Rubrica
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const tipoComprovanteINSSRubrica = await api.post(
            "uploads",
            formData,
            { headers }
          )

          const dadosStatusComprovanteINSSRubrica = Object.keys(
            tipoComprovanteINSSRubrica.data.lista_idTipo_tipo
          )
            .map(
              (_cnpj) =>
                tipoComprovanteINSSRubrica.data.lista_idTipo_tipo[_cnpj]
            )
            .flatMap((d) => d)
            .filter((tipoDoc) => tipoDoc.tipo === 20)
            .map((d) => ({ tipo: d.tipo, idTipo: d.id }))

          await api.post("/statusTipoDocFilial", {
            lista_idTipo_tipo: dadosStatusComprovanteINSSRubrica
          })
          await api.post("/statusTipo", uploadTipo)

          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum aquivo selcionado!")
      }
    }

    if (uploadTipo.tipo === 21) {
      // Print FAP
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const TipoPrintFAP = await api.post("uploads", formData, { headers })

          const dadosStatusPrintFAP = Object.keys(
            TipoPrintFAP.data.lista_idTipo_tipo
          )
            .map((_cnpj) => TipoPrintFAP.data.lista_idTipo_tipo[_cnpj])
            .flatMap((d) => d)
            .filter((tipoDoc) => tipoDoc.tipo === 21)
            .map((d) => ({ tipo: d.tipo, idTipo: d.id }))

          await api.post("/statusTipoDocFilial", {
            lista_idTipo_tipo: dadosStatusPrintFAP
          })
          await api.post("/statusTipo", uploadTipo)

          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum aquivo selcionado!")
      }
    }

    if (uploadTipo.tipo === 22) {
      // GPS
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const tipoGPS = await api.post("uploads", formData, { headers })

          const dadosStatusGPS = Object.keys(tipoGPS.data.lista_idTipo_tipo)
            .map((_cnpj) => tipoGPS.data.lista_idTipo_tipo[_cnpj])
            .flatMap((d) => d)
            .filter((tipoDoc) => tipoDoc.tipo === 22)
            .map((d) => ({ tipo: d.tipo, idTipo: d.id }))

          await api.post("/statusTipoDocFilial", {
            lista_idTipo_tipo: dadosStatusGPS
          })
          await api.post("/statusTipo", uploadTipo)
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          toast.error("Arquivo Inválido")
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum aquivo selcionado!")
      }
    }

    if (uploadTipo.tipo === 29) {
      // XML
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const xml = await api.post("/validaXML", formData, { headers })

          if (xml.data.erro === true) {
            toast.error("Documento Inválido")
            handleClose()
          }
          const resposta = await api.post("/uploadsXML", formData, { headers })
          // const uploadsArquivos = await api.post('/uploadsArquivos', { xmls, _id, uploadTipo });

          if (resposta.data.erro === false) {
            const { datas } = xml.data
            await api.post("/registra-upload-xml", { cnpj, uploadTipo, datas })
            await api.post("/statusTipo", uploadTipo)
          }
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso!")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          // setFormato('.zip, .rar');
          toast.error("Documento Inválido")
          handleClose()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 30) {
      // Extrato simples nacional
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const esns = await api.post("/validaESN", formData, { headers })
          if (esns.data.filesFail.length > 0) {
            await api.post("/uploadsESN", formData, { headers })
            const res = await api.post("/uploadsArquivosESN", {
              dadosCliente,
              esns,
              _id,
              uploadTipo
            })
            const { datas } = res.data
            await api.post("/registra-upload-esn", { cnpj, uploadTipo, datas })
            await api.post("/statusTipo", uploadTipo)

            setFile("")
            handleClose()
            toast.success("Upload realizado com sucesso!")
          } else {
            handleClose()
            setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
            setFilesFail(esns.data.filesFail)
            handleOpenErro()
          }
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          handleOpenErroFormato()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }
    if (uploadTipo.tipo === 31) {
      // DAS
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const dass = await api.post("/validaDAS", formData, { headers })
          if (dass.data.filesFail.length > 0) {
            await api.post("/uploadsDAS", formData, { headers })
            const res = await api.post("/uploadsArquivosDAS", {
              dadosCliente,
              dass,
              uploadTipo
            })
            const { datas } = res.data
            await api.post("/registra-upload-esn", { cnpj, uploadTipo, datas })
            await api.post("/statusTipo", uploadTipo)
            setFile("")
            handleClose()
            toast.success("Upload realizado com sucesso!")
          } else {
            handleClose()
            setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
            setFilesFail(dass.data.filesFail)
            handleOpenErro()
          }
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          setFormato(".pdf")
          handleClose()
          handleOpenErroFormato()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }

    if (uploadTipo.tipo === 32) {
      // XML
      if (file.length > 0) {
        handleOpen()
        api.defaults.headers.Authorization = `${razaoSocial}/Arquivos_Recebidos_do_Cliente/${
          pastas.find((d) => d.tipo === uploadTipo.tipo).pasta
        }/`
        try {
          const xml = await api.post("/validaXML", formData, { headers })

          if (xml.data.erro === true) {
            toast.error("Documento Inválido")
            handleClose()
          }
          const resposta = await api.post("/uploadsXML", formData, { headers })

          if (resposta.data.erro === false) {
            const { datas } = xml.data
            await api.post("/registra-upload-xml", { cnpj, uploadTipo, datas })
            await api.post("/statusTipo", uploadTipo)
          }
          setFile("")
          handleClose()
          toast.success("Upload realizado com sucesso!")
        } catch {
          setPasta(pastas.find((d) => d.tipo === uploadTipo.tipo).pasta)
          // setFormato('.zip, .rar');
          toast.error("Documento Inválido")
          handleClose()
        }
      } else {
        handleOpenVazio()
        toast.warn("Nenhum arquivo selecionado")
      }
    }
  }
  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title={`Upload - ${razaoSocial}`} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />

          {/* { !tipo10 === 10 && status === 0 ? */}

          <Grid>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Container sx={{ mt: 4, mb: 4 }}>
                <center>
                  <span className="submenu">Uploads</span>
                </center>
                <hr />
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    {simples === true ? (
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="title">
                              Períodos de Diagnósticos:
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[0]}{" "}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    ) : (
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="title">
                              Períodos de Diagnósticos:
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[0]}{" "}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[1]}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[2]}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[3]}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[4]}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}
                  </Table>
                </TableContainer>
              </Container>
            )}
          </Grid>

          <Container sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 550 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <span className="title">Arquivos a Enviar</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">Prazo de Envio</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">Observações Cliente</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">Observações</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">Selecionar arquivos </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">Enviar</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.tipo}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={row.tipo}
                        >
                          {row.tipo === 1 ? "Solicitações Complementares" : ""}
                          {row.tipo === 2
                            ? "Planilhas memórias de cálculo Pis/Cofins - Sintética"
                            : ""}
                          {row.tipo === 3 ? (
                            <div>
                              Planilhas memórias de cálculo Pis/Cofins -
                              Analítica :
                              <p className="credExtemp">
                                Planilha que contenha os valores em aberto (item
                                a item) de créditos e débitos.
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 4
                            ? "Memória de cálculo/Rascunho do ICMS, ICMS ST e ICMS Antecipação "
                            : ""}
                          {row.tipo === 5 ? (
                            <div>
                              DCTFs
                              <p className="credExtemp">Declaração</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 6 ? "Balancetes Contábeis" : ""}
                          {row.tipo === 7 ? "Livros Razões Contábeis" : ""}
                          {row.tipo === 8 ? "SPED Contábil" : ""}
                          {row.tipo === 9 ? (
                            <div>
                              SPED Fiscal
                              <p className="credExtemp">
                                Enviar Matriz e filiais
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 10 ? (
                            <div>
                              EFD Contribuições
                              <p className="credExtemp">
                                formato .txt - (Recomendado upload de até 3GB
                                por vez){" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 12 ? (
                            <div>
                              CIAP - Controle de Crédito do ICMS do Ativo
                              Permanente
                              <p className="credExtemp">
                                Enviar Matriz e filiais
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 13 ? (
                            <div>
                              E-CAC - Comprovante de Arrecadação dos Tributos
                              IRPJ e CSLL
                              <p className="credExtemp">
                                Relatorio detalhado por tributo{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 14 ? "ECF" : ""}
                          {row.tipo === 15 ? (
                            <div>
                              E-CAC - Fontes Pagadoras
                              <p className="credExtemp">
                                Aberto por código de retenção - em TXT{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 16 ? (
                            <div>
                              Bens do ativo imobilizado
                              <p className="credExtemp">
                                Aquisições a partir de 2004{" "}
                              </p>{" "}
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 17
                            ? "Memórias de Cálculo do IRPJ e CSLL"
                            : ""}

                          {row.tipo === 18 ? (
                            <div>
                              Créditos Extemporâneos
                              <p className="credExtemp">
                                Composição analítica de todo e qualquer crédito
                                que tenha sido apropriado fora do mês corrente,
                                sendo ele de forma administrativa ou judicial,
                                referente ao período dos últimos 5 anos.{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 19
                            ? "Resumo da folha de pagamento"
                            : ""}
                          {row.tipo === 20 ? (
                            <div>
                              Comprovante de declaração a recolher do INSS
                              (Rubrica)
                              <p className="credExtemp">
                                Enviar Matriz e filiais{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 21 ? "Print da FAP" : ""}
                          {row.tipo === 22 ? "GPS" : ""}
                          {row.tipo === 23
                            ? "Tabela de Incidência do INSS sobre os eventos da folha de pagamentos (Contribuições Previdenciárias)"
                            : ""}
                          {row.tipo === 24
                            ? "Memórias de Cálculo de CPRB/Desoneração"
                            : ""}
                          {row.tipo === 25 ? "Questionário Prévio" : ""}
                          {row.tipo === 26 ? (
                            <div>
                              DARF
                              <p className="credExtemp">Código 1410</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 27 ? "DCTFWEB" : ""}
                          {row.tipo === 29
                            ? "XMLs das NF-es e CT-es de entradas"
                            : ""}
                          {row.tipo === 30 ? "Extrato Simples Nacional" : ""}
                          {row.tipo === 31
                            ? "DAS (Enviar apenas para os períodos sem informação de pagamento no extrato)"
                            : ""}
                          {row.tipo === 32
                            ? "XMLs das NF-es e CT-es de saídas"
                            : ""}
                          {row.tipo === 33
                            ? "Guia ou Declaração de Apuração do ICMS (GIA; DMA; DAM; DAPI; GIME; GIAM)"
                            : ""}
                          {row.tipo === 34 ? (
                            <div>
                              Balancete Contábil (Ultimo não encerrado)
                              <p className="credExtemp">
                                período mais recente, após conciliação da
                                contabilidade.{" "}
                              </p>{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {formatarData(row.dataLimite)}
                        </StyledTableCell>

                        <StyledTableCell alignitems="center">
                          {row.observacaoCliente === ""
                            ? "-"
                            : row.observacaoCliente}
                        </StyledTableCell>

                        <StyledTableCell alignitems="center">
                          {row.obs === "" ? "-" : row.obs}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          onSubmit={upload}
                          encType="multipart/form-data"
                        >
                          <form encType="multipart/form-data">
                            <FormGroup>
                              <Row>
                                <Col xl="12">
                                  <label
                                    htmlFor={`icon-button-file-${row.tipo}`}
                                  >
                                    <Input
                                      id={`icon-button-file-${row.tipo}`}
                                      type="file"
                                      multiple
                                      name="file[]"
                                      accept={
                                        [8, 14, 9].includes(row.tipo)
                                          ? "text/plain"
                                          : undefined
                                      }
                                      onChange={(e) => setFile(e.target.files)}
                                      hidden
                                    />
                                    <IconButton
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                    >
                                      <FolderOpenIcon />
                                    </IconButton>
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                          </form>
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          onSubmit={upload}
                          encType="multipart/form-data"
                        >
                          <form encType="multipart/form-data">
                            <FormGroup>
                              <Row>
                                <IconButton
                                  color="success"
                                  variant="contained"
                                  type="submit"
                                  onClick={(e) =>
                                    setUploadTipo({
                                      idTipo: row.id,
                                      tipo: row.tipo
                                    })
                                  }
                                >
                                  <SendIcon />
                                </IconButton>
                              </Row>
                            </FormGroup>
                          </form>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Container>

          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <Row>
                <Col xl="4">
                  <CircularProgress />
                </Col>
                <Col xl="7">
                  <TableCell align="center" sx={{ color: "blue" }}>
                    Carregando arquivos
                  </TableCell>
                </Col>
              </Row>
            </Box>
          </Modal>

          <Modal
            open={openModalErroFormato}
            onClose={handleCloseErroFormato}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalErroFormato}>
              <Row>
                <Col xl="12">
                  <TableCell align="center" sx={{ color: "blue" }}>
                    Formato errado para o arquivo: {pasta}.{" "}
                    <p> Formato válido: {formato} </p>
                  </TableCell>
                </Col>
              </Row>
            </Box>
          </Modal>

          <Modal
            open={openModalErro}
            onClose={handleCloseErro}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalErro}>
              <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ color: "red" }}>
                        Arquivos incompatíveis com o tipo: {pasta}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesFail.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Modal>
          <Modal
            open={openModalErro}
            onClose={handleCloseErro}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalErro}>
              <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ color: "red" }}>
                        Arquivos incompatíveis com o tipo: {pasta}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesFail.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Modal>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <DashboardAdmin />
}
