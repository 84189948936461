import React, { useState, useEffect } from "react"
import api from "../../../Config/api"
import ResponsiveDrawer from "../../../components/menuCliente"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { CircularProgress, Grid } from "@mui/material"
import "react-toastify/dist/ReactToastify.min.css"
import { useParams } from "react-router-dom"
import ResponsiveDrawerAssociado from "../../../components/menu"

const mdTheme = createTheme()

const DiagnosticoPorAno = () => {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const params = useParams()
  const cnpjCliente = params._id

  const [perfil, setPerfil] = useState("")
  const [razaoSocial, setRazaoSocial] = useState("")

  const cnpj = localStorage.getItem("cnpj")
  const [colunas, setColunas] = useState([])
  const [simples, setSimples] = useState(false)
  const [tipo8, setTipo8] = useState([])
  const [tipo13, setTipo13] = useState([])
  const [tipo14, setTipo14] = useState([])
  const [tipo15, setTipo15] = useState([])
  const [tipo17, setTipo17] = useState([])
  const [tipo21, setTipo21] = useState([])
  const [tipo24, setTipo24] = useState([])
  const [tipo26, setTipo26] = useState([])
  const [tipo27, setTipo27] = useState([])
  const [tipo31, setTipo31] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/checklist", {
        cnpj: cnpj === null ? cnpjCliente : cnpj
      })
      setSimples(cliente.data.dados.simples)
      const col = []
      if (cliente.data.dados.ano1 !== null) {
        col.push(cliente.data.dados.ano1)
      }
      if (cliente.data.dados.ano2 !== null) {
        col.push(cliente.data.dados.ano2)
      }
      if (cliente.data.dados.ano3 !== null) {
        col.push(cliente.data.dados.ano3)
      }
      if (cliente.data.dados.ano4 !== null) {
        col.push(cliente.data.dados.ano4)
      }
      if (cliente.data.dados.ano5 !== null) {
        col.push(cliente.data.dados.ano5)
      }
      setColunas(col)
      setRazaoSocial(cliente.data.dados.razaoSocial)

      let cont = 0
      while (cont < cliente.data.tipos.length) {
        if (cliente.data.tipos[cont].tipo === 8) {
          setTipo8(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 13) {
          setTipo13(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 14) {
          setTipo14(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 15) {
          setTipo15(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 17) {
          setTipo17(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 21) {
          setTipo21(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 24) {
          setTipo24(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 26) {
          setTipo26(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 27) {
          setTipo27(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 31) {
          setTipo31(cliente.data.tipos[cont])
        }

        cont++
      }
      setLoading(false)
    }

    getCliente()
    setLoading(true)
  }, [cnpj])

  useEffect(() => {
    const jsonRecuperado = localStorage.getItem("user")
    const perfilUser = JSON.parse(jsonRecuperado).perfil
    setPerfil(perfilUser)
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      {perfil !== 2 ? (
        <ResponsiveDrawerAssociado title={`Cliente - ${razaoSocial}`} />
      ) : (
        <ResponsiveDrawer />
      )}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1
        }}
      >
        <Grid>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="title-arquivo">Arquivos</div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="title">
                          {colunas.length === 0 ? "-" : colunas[0]}{" "}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="title">
                          {colunas.length === 0 ? "-" : colunas[1]}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="title">
                          {colunas.length === 0 ? "-" : colunas[2]}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="title">
                          {colunas.length === 0 ? "-" : colunas[3]}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="title">
                          {colunas.length === 0 ? "-" : colunas[4]}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {simples === true ? (
                    <TableBody>
                      {/* tipo31 */}
                      {tipo31.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">DAS</TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {/* tipo15 */}
                      {tipo15.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">
                            E-CAC - Fontes Pagadoras
                          </TableCell>
                          <TableCell align="center">
                            {tipo15.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo15.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo15.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo15.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo15.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo15.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo13 */}
                      {tipo13.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">
                            E-CAC - Comprovante de Arrecadação dos Tributos IRPJ
                            e CSLL{" "}
                          </TableCell>
                          <TableCell align="center">
                            {tipo13.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo13.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo13.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo13.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo13.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo13.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo8 */}
                      {tipo8.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">SPED Contábil</TableCell>
                          <TableCell align="center">
                            {tipo8.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo8.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo8.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo8.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo8.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo8.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}

                      {/* tipo14 */}
                      {tipo14.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">ECF</TableCell>
                          <TableCell align="center">
                            {tipo14.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo14.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo14.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo14.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo14.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo14.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo17 */}
                      {tipo17.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">
                            Memórias de Cálculo do IRPJ e CSLL
                          </TableCell>
                          <TableCell align="center">
                            {tipo17.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo17.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo17.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo17.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo17.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo17.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo21 */}
                      {tipo21.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">Print da FAP</TableCell>
                          <TableCell align="center">
                            {tipo21.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo21.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo21.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo21.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo21.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo21.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo21 */}
                      {tipo24.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">
                            Memórias de Cálculo de CPRB/Desoneração
                          </TableCell>
                          <TableCell align="center">
                            {tipo24.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo24.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo24.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo24.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo24.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo24.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo27 */}
                      {tipo27.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">DCTFWEB </TableCell>
                          <TableCell align="center">
                            {tipo27.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo27.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo27.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo27.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo27.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo27.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo26 */}
                      {tipo26.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">DARF </TableCell>
                          <TableCell align="center">
                            {tipo26.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo26.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo26.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo26.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo26.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo26.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* tipo31 */}
                      {tipo31.length === 0 ? (
                        <></>
                      ) : (
                        <TableRow sx={{ "": { border: 0 } }}>
                          <TableCell scope="row">DAS</TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno2 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno2 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno3 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno3 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno4 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno4 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tipo31.statusAno5 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo31.statusAno5 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <DiagnosticoPorAno />
}
