import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import CurrencyInput from "react-currency-input-field"
import { IMaskInput } from "react-imask"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import {
  alpha,
  InputBase,
  Box,
  Toolbar,
  CssBaseline,
  Container,
  Grid,
  Paper,
  Button,
  styled,
  createTheme,
  ThemeProvider,
  Tooltip
} from "@mui/material"

import { Col, Input, Label, Row } from "reactstrap"

import { toast, ToastContainer } from "react-toastify"

import api from "../../Config/api"
import "./style.css"
import ResponsiveDrawer from "../../components/menu"

const mdTheme = createTheme()

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 420,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))

const NovoCliente = () => {
  const navigate = useNavigate()

  const handleChange = (event) => {
    setSimples(event.target.checked)
  }

  const [cnpj, setCnpj] = useState("")
  const [razaoSocial, setRazaoSocial] = useState("")
  const [email, setEmail] = useState("")
  const [data, setData] = useState("")
  const [listaEmailSecundario, setListaEmailSecundario] = useState([""])
  const [simples, setSimples] = useState(false)
  const [dataSimples, setDataSimples] = useState("")
  const [dataContrato, setDataContrato] = useState("")
  const [faturamento, setFaturamento] = useState("")
  const [observacao, setObservacao] = useState("")
  const [plataforma, setPlataforma] = useState("")
  const [grupo, setGrupo] = useState("")
  const [subgrupo, setSubgrupo] = useState("")
  const [recebimentoContrato, setRecebimentoContrato] = useState("")
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")
  const [data5, setData5] = useState("")
  const [data6, setData6] = useState("")
  const [data7, setData7] = useState("")
  const [data8, setData8] = useState("")
  const [data9, setData9] = useState("")
  const [data10, setData10] = useState("")
  const [data11, setData11] = useState("")
  const [data12, setData12] = useState("")
  const [cincoAnos, setCincoAnos] = useState([])
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e, index) => {
    const list = [...listaEmailSecundario]
    list[index] = e.target.value
    setListaEmailSecundario(list)
  }

  const handleRemoveClick = (index) => {
    const list = [...listaEmailSecundario]
    list.splice(index, 1)
    setListaEmailSecundario(list)
  }

  const handleAddClick = () => {
    setListaEmailSecundario([...listaEmailSecundario, ""])
  }

  useEffect(() => {
    const today = new Date()
    let mes = today.getMonth() + 1
    let ano = today.getFullYear()
    if (mes < 10) mes = `0${mes}`
    let data = `${ano}-${mes}`

    let cont = 0
    const dados = []
    while (cont < 60) {
      dados.push(data)
      if (mes === "01") {
        mes = 12
        ano -= 1
      } else {
        mes -= 1
      }
      if (mes < 10) mes = `0${mes}`
      data = `${ano}-${mes}`
      cont++
    }
    setCincoAnos(dados)
  }, [])

  function mesAnterior(data) {
    let ano = data.substring(0, 4)
    let mes = data.substring(5, 7)
    const dia = data.substring(8, 10)
    setData1(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData2(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData3(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData4(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData5(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData6(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData7(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData8(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData9(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData10(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }

    setData11(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData12(`${ano}-${mes}-${dia}`)
    return (
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12
    )
  }

  const novoCliente = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (email.split("@")[1] == null) {
      toast.warn("Email com formato inválido!", {
        position: toast.POSITION.TOP_CENTER
      })
      setLoading(false)
    }
    if (data1 === null || data2 === null || data3 === null) {
      toast.warn("Escolha pelo menos uma data!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 15000
      })
      setLoading(false)
    }
    if (data1 && data2 === null && data3 === null) {
      toast.warn("Aplicando apenas um periodo de diagnóstico!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 15000
      })
      setLoading(false)
    }

    if (data1 && data2 && data3 === null) {
      toast.warn("Aplicando 2 periodos de diagnóstico!", {
        position: toast.POSITION.TOP_CENTER
      })
      setLoading(false)
    }

    if (simples && !dataSimples) {
      toast.warn("Selecione a data!", {
        position: toast.POSITION.TOP_CENTER
      })
      setLoading(false)
    }
    if (!cnpj || !razaoSocial || !email) {
      toast.warn("Preencha todos os campos!", {
        position: toast.POSITION.TOP_CENTER
      })
      setLoading(false)
    } else {
      const buscaCnpj = await api.post("/find-cliente-pelo-cnpj", { cnpj })
      if (!buscaCnpj.data.err) {
        const perfil = 2
        if (simples === true) {
          mesAnterior(dataSimples)
          localStorage.setItem("cnpj", cnpj)
          localStorage.setItem("razaoSocial", razaoSocial)
          localStorage.setItem("simples", simples)
          const dados = {
            cnpj,
            razaoSocial,
            email,
            emailSecundario: listaEmailSecundario,
            simples,
            dataContrato,
            faturamento,
            observacao,
            plataforma,
            grupo,
            subgrupo,
            recebimentoContrato,
            perfil,
            data1,
            data2,
            data3,
            data4,
            data5,
            data6,
            data7,
            data8,
            data9,
            data10,
            data11,
            data12,
            cincoAnos
          }
          api.post("/novo-cliente", dados).then((response) => {
            const idCliente = response.data.cliente._id
            toast.success("Cliente cadastrado com sucesso!", {
              position: toast.POSITION.TOP_CENTER
            })
            setLoading(false)
            navigate(`/associado-cliente/${idCliente}`)
          })
        } else {
          localStorage.setItem("cnpj", cnpj)
          localStorage.setItem("razaoSocial", razaoSocial)
          localStorage.setItem("simples", simples)
          const dados = {
            cnpj,
            razaoSocial,
            email,
            emailSecundario: listaEmailSecundario,
            dataContrato,
            faturamento,
            observacao,
            plataforma,
            grupo,
            subgrupo,
            recebimentoContrato,
            simples,
            perfil,
            data1,
            data2,
            data3,
            data4,
            cincoAnos
          }
          api
            .post("/novo-cliente", dados)
            .then((response) => {
              const idCliente = response.data.cliente._id
              toast.success("Cliente cadastrado com sucesso!", {
                position: toast.POSITION.TOP_CENTER
              })
              setLoading(false)
              navigate(`/associado-cliente/${idCliente}`)
            })
            .catch((error) => {
              if (error.response.status === 400) {
                toast.error(`CNPJ inválido!`, {
                  position: toast.POSITION.TOP_CENTER
                })
              }
            })
        }
      }
      if (buscaCnpj.data.err) {
        toast.warn("CNPJ já cadastrado!", {
          position: toast.POSITION.TOP_CENTER
        })
        setLoading(false)
      }
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Cadastrar Cliente" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <form className="form" autoComplete="off" onSubmit={novoCliente}>
              <Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <center>
                      <span className="submenu">Novo Cliente</span>
                    </center>
                    <hr />
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <BootstrapInput
                        type="text"
                        required
                        inputProps={{
                          maxLength: "14"
                        }}
                        label="CNPJ"
                        id="cnpj"
                        name="cnpj"
                        placeholder="CNPJ"
                        onChange={(e) => setCnpj(e.target.value)}
                      />
                      <BootstrapInput
                        type="text"
                        required
                        id="razaoSocial"
                        name="razaoSocial"
                        placeholder="Razão social"
                        label="Razão social"
                        onChange={(e) => setRazaoSocial(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <BootstrapInput
                        type="text"
                        id="plataforma"
                        name="plataforma"
                        placeholder="Plataforma"
                        label="Plataforma"
                        onChange={(e) => setPlataforma(e.target.value)}
                      />
                      <BootstrapInput
                        type="text"
                        id="observacao"
                        name="observacao"
                        placeholder="Observações"
                        label="Observações"
                        onChange={(e) => setObservacao(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <BootstrapInput
                        type="text"
                        id="grupo"
                        name="grupo"
                        placeholder="Grupo"
                        label="Grupo"
                        onChange={(e) => setGrupo(e.target.value)}
                      />
                      <BootstrapInput
                        type="text"
                        id="subgrupo"
                        name="subgrupo"
                        placeholder="Subgrupo"
                        label="Subgrupo"
                        onChange={(e) => setSubgrupo(e.target.value)}
                      />
                    </Box>
                    <hr />
                    <Box
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "50ch",
                          display: "flex",
                          flexDirection: "column"
                        }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Label>Data do Contrato:</Label>
                      <BootstrapInput
                        type="date"
                        id="dataContrato"
                        name="dataContrato"
                        onChange={(e) => setDataContrato(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "50ch",
                          display: "flex",
                          flexDirection: "column"
                        }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Label>Data do Receb. Contrato:</Label>
                      <BootstrapInput
                        type="date"
                        id="recebimentoContrato"
                        name="recebimentoContrato"
                        onChange={(e) => setRecebimentoContrato(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <BootstrapInput
                        style={{ width: 445 }}
                        placeholder="Faturamento"
                        type="text"
                        onChange={(e) => setFaturamento(e.target.value)}
                      />
                    </Box>

                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      autoComplete="off"
                    >
                      <Tooltip
                        title="O email cadastrado neste campo irá receber usuário e senha para entrar no sistema, 
                          assim como notíficações sobre atraso no envio de documentos"
                        arrow
                      >
                        <BootstrapInput
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email principal"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          label="Email Principal"
                        />
                      </Tooltip>
                    </Box>
                    {listaEmailSecundario.map((email, idx) => {
                      return (
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "50ch" }
                          }}
                          noValidate
                          autoComplete="off"
                          key={idx}
                        >
                          <BootstrapInput
                            type="email"
                            id="emailSecundario"
                            name="emailSecundario"
                            placeholder="Email secundário"
                            value={email}
                            onChange={(e) => handleInputChange(e, idx)}
                          />
                          <Box
                            sx={{
                              "& > :not(style)": { mr: 1, width: "25ch" }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            {listaEmailSecundario.length !== 1 && (
                              <Button
                                variant="contained"
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  backgroundColor: "#f07167"
                                }}
                                onClick={() => handleRemoveClick(idx)}
                                startIcon={<DeleteIcon fontSize="small" />}
                              >
                                Remover
                              </Button>
                            )}
                            {listaEmailSecundario.length - 1 === idx && (
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  backgroundColor: "#28A6DE"
                                }}
                                onClick={handleAddClick}
                                startIcon={<AddCircleOutlineOutlinedIcon />}
                              >
                                Adicionar
                              </Button>
                            )}
                          </Box>
                        </Box>
                      )
                    })}

                    <Row className="linhas">
                      <Col>
                        <Input
                          id="simples"
                          name="simples"
                          type="checkbox"
                          onChange={handleChange}
                        />{" "}
                        <Label check>Projeto Simples Nacional</Label>
                      </Col>
                    </Row>

                    {simples === false ? (
                      <div>
                        <hr />
                        <p />
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "50ch" }
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Label>
                            Selecione as datas para os periodos de diagnósticos
                          </Label>
                        </Box>
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "25ch" }
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Tooltip
                            title="Selecione pelo menos uma data para diagnóstico. Digite as datas começando por este campo"
                            arrow
                          >
                            <BootstrapInput
                              type="date"
                              id="data1"
                              name="data1"
                              required
                              onChange={(e) => setData1(e.target.value)}
                            />
                          </Tooltip>
                          <BootstrapInput
                            type="date"
                            id="data2"
                            name="data2"
                            onChange={(e) => setData2(e.target.value)}
                          />

                          <BootstrapInput
                            type="date"
                            id="data3"
                            name="data3"
                            onChange={(e) => setData3(e.target.value)}
                          />
                        </Box>
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "25ch" }
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <BootstrapInput
                            type="date"
                            id="data4"
                            name="data4"
                            onChange={(e) => setData4(e.target.value)}
                          />
                        </Box>
                      </div>
                    ) : (
                      <div>
                        <hr />
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "60ch" }
                          }}
                        >
                          <Label>
                            Selecione a data para o periodo de diagnóstico sobre
                            o simples
                          </Label>
                        </Box>

                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "25ch" }
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <BootstrapInput
                            type="date"
                            id="dataSimples"
                            name="dataSimples"
                            onChange={(e) => setDataSimples(e.target.value)}
                          />
                        </Box>
                      </div>
                    )}

                    {simples === false ? (
                      <div>
                        <Row className="linhas">
                          <Col xl={4}>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{ mt: 3, mb: 2, backgroundColor: "#28A6DE" }}
                            >
                              Próximo
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row className="linhas">
                          <Col xl={4}>
                            <Button
                              type="submit"
                              onClick={() => mesAnterior(dataSimples)}
                              variant="contained"
                              sx={{ mt: 3, mb: 2, backgroundColor: "#28A6DE" }}
                            >
                              Próximo
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Paper>
                </div>
              </Grid>
            </form>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <NovoCliente />
}
