import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"
import ArticleIcon from "@mui/icons-material/Article"
import AddBusinessIcon from "@mui/icons-material/AddBusiness"
import UploadIcon from "@mui/icons-material/Upload"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import FileOpenIcon from "@mui/icons-material/FileOpen"
import { visuallyHidden } from "@mui/utils"
import { ptBR } from "@mui/x-data-grid"
import SearchIcon from "@mui/icons-material/Search"
import EventNoteIcon from "@mui/icons-material/EventNote"
import api from "../../../Config/api"
import "../../../styles/styles.css"
import BKPSPaginationActions from "../../../components/PaginationActions"
import { minTime } from "date-fns"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: "grupo",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Grupo</span>
  },
  {
    id: "subgrupo",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Subgrupo</span>
  },
  {
    id: "cnpj",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">CNPJ</span>
  },
  {
    id: "razaoSocial",
    numeric: false,
    disablePadding: true,
    label: (
      <span className="tableFont" align="center">
        Razão Social
      </span>
    )
  },
  {
    id: "recebimentoContrato",
    numeric: true,
    disablePadding: true,
    label: (
      <span className="tableFont" align="center">
        Data de Recebimento
      </span>
    )
  },
  {
    id: "cronograma",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Acompanhamento do Projeto</span>
  },
  {
    id: "diag",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Diagnóstico</span>
  },
  {
    id: "filial",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Filiais</span>
  },
  {
    id: "upload",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Uploads</span>
  },
  {
    id: "checklist",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Checklist Documentos</span>
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Editar</span>
  }
]

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ padding: "9px", fontSize: 13 }}
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const _id = localStorage.getItem("_id")

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([_id])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const [rows, setRows] = useState([])
  const [originalData, setOriginalData] = useState([])

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  function handleInput(e) {
    const input = e.currentTarget.value
    setRows(searchTable(input, rows))
  }

  function searchTable(value) {
    const lowerCaseInputValue = value.toLowerCase()
    return originalData.filter((row) => {
      if (value.length === 0) {
        return true
      }

      const groupFilter = row.grupo?.toLowerCase().includes(lowerCaseInputValue)
      const subGroupFilter = row.subgrupo
        ?.toLowerCase()
        .includes(lowerCaseInputValue)
      const cnpjFilter = row.cnpj?.toLowerCase().includes(lowerCaseInputValue)

      const recContratoVal = new Date(
        row.recebimentoContrato
      ).toLocaleDateString()
      const recContrato = recContratoVal
        .toLowerCase()
        .includes(lowerCaseInputValue)
      const razaoSocialFilter = row.razaoSocial
        ?.toLowerCase()
        .includes(lowerCaseInputValue)

      return (
        groupFilter ||
        subGroupFilter ||
        cnpjFilter ||
        razaoSocialFilter ||
        recContrato
      )
    })
  }

  useEffect(() => {
    async function pegaClientes() {
      const res = await api.post("/find-cliente", { _id })

      const clientes = res.data.cliente
      let cont = 0
      const rows = []
      while (cont < clientes.length) {
        rows.push({
          id: clientes[cont].cnpj,
          grupo: clientes[cont].grupo,
          subgrupo: clientes[cont].subgrupo,
          cnpj: clientes[cont].razaoSocial,
          simples: clientes[cont].simples,
          recebimentoContrato: clientes[cont].recebimentoContrato
        })
        cont++
      }
      setRows(rows)
      setOriginalData(rows)
    }

    pegaClientes()
  }, [_id])

  return (
    <Box>
      <TableCell component="th" padding="normal" align="justify" sx={{ p: 2 }}>
        <SearchIcon sx={{ color: "#28a6de", fontSize: 30 }} />
      </TableCell>
      <TableCell
        component="th"
        padding="normal"
        align="justify"
        sx={{ p: 2, width: "400px" }}
      >
        <input
          className="search"
          onChange={handleInput}
          id="input-table"
          placeholder="Pesquisar"
        />
      </TableCell>

      <TableContainer>
        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow hover key={row.id}>
                    <TableCell
                      align="center"
                      sx={{ padding: "16px, 31px", lineHeight: 4.27 }}
                    >
                      <span>{row.grupo}</span>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "16px, 31px", lineHeight: 4.27 }}
                    >
                      <span>
                        <span>{row.subgrupo}</span>
                      </span>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      padding="none"
                      align="center"
                      sx={{ pr: 4, pl: 1 }}
                    >
                      {row.id}
                    </TableCell>

                    <TableCell align="center">
                      <Link
                        to={`/cronogramaExec/${row.id}`}
                        className="table_row__link"
                      >
                        {row.cnpj}
                      </Link>
                    </TableCell>

                    <TableCell align="center">
                      {!row.recebimentoContrato ||
                      row.recebimentoContrato === minTime
                        ? "-"
                        : new Date(
                            row.recebimentoContrato
                          ).toLocaleDateString()}
                    </TableCell>

                    {/* Link para tela de Acompanhamento do Projeto */}
                    <TableCell align="center">
                      <Link to={`/cronogramaExec/${row.id}`}>
                        <EventNoteIcon />
                      </Link>
                    </TableCell>

                    <TableCell align="center">
                      <Link to={`/diagnostico-cliente/${row.id}`}>
                        <ArticleIcon />
                      </Link>
                    </TableCell>

                    <TableCell align="center">
                      <Link to={`/checklistCampo/${row.id}`}>
                        <AddBusinessIcon />
                      </Link>
                    </TableCell>

                    <TableCell align="center">
                      <Link to={`/dashboardAdmin/${row.id}`}>
                        <UploadIcon />
                      </Link>
                    </TableCell>

                    <TableCell align="center">
                      <Link to={`/checklistCampoDocumentos/${row.id}`}>
                        <FileOpenIcon />
                      </Link>
                    </TableCell>

                    <TableCell align="center">
                      <Link to={`/editar-cliente/${row.id}`}>
                        <EditIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        component="div"
        count={rows.length}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}-${to} de ${count}`
        }}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        pageSize={10}
        rowsPerPageOptions={[10]}
        experimentalFeatures={{ newEditingApi: false }}
        getRowHeight={() => "auto"}
        ActionsComponent={BKPSPaginationActions}
      />
    </Box>
  )
}
