import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  Toolbar,
  Tooltip
} from "@mui/material"
import { DataGrid, GridActionsCellItem, ptBR } from "@mui/x-data-grid"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import { toast, ToastContainer } from "react-toastify"

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

import { Label } from "reactstrap"

import api from "../../Config/api"
import ResponsiveDrawer from "../../components/menu"

const mdTheme = createTheme()

export default function EditarAssociadoAoCliente(props) {
  const [loading, setLoading] = useState(false)
  const [associadosToDisplay, setAssociadosToDisplay] = useState([])
  const [associadosSelecionados, setAssociadosSelecionados] = useState([])
  const [associadosAoCliente, setAssociadosAoCliente] = useState([])

  const params = useParams()
  const cliente = params._id

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const allAssociado = associadosSelecionados.map(
      async (d) =>
        await api
          .post("/associado_x_cliente", { cliente, associado: d.id })
          .then((res) => {
            toast.success("Associado(s) cadastrado(s) com sucesso!", {
              position: toast.POSITION.TOP_CENTER
            })
            setLoading(false)
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(
                `O Relacionamento entre Associado e Cliente já Existe`,
                {
                  position: toast.POSITION.TOP_CENTER
                }
              )
              setLoading(false)
            }
            if (error.response.status === 500) {
              toast.error(
                `Erro ao vincular o associado, tente novamente mais tarde!`,
                {
                  position: toast.POSITION.TOP_CENTER
                }
              )
              setLoading(false)
            }
          })
    )
    getAssociadoAoCliente()
  }

  const getDadosCliente = useCallback(async () => {
    const findClienteId = await api.post("/find-cliente-id", { cliente })
    localStorage.setItem("cnpj", findClienteId.data.dadosCliente[0].cnpj)
    localStorage.setItem(
      "razaoSocial",
      findClienteId.data.dadosCliente[0].razaoSocial
    )
    localStorage.setItem("simples", findClienteId.data.dadosCliente[0].simples)
  }, [cliente])

  const getAssociadoAoCliente = useCallback(async () => {
    const listaAssociadoAoCliente = await api.get(
      `/lista-associado-por-cliente/${cliente}`
    )
    const listaAssociados = listaAssociadoAoCliente.data.dadosAssociado
    const rowsAssociado = listaAssociados.map((d) => ({
      id: d.idAssociadoCliente,
      associado: d.nameAssociado
    }))
    setAssociadosAoCliente(rowsAssociado)
  }, [cliente])

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        await Promise.all([getAssociadoAoCliente(), getDadosCliente()])
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setLoading(true)
    async function getListaAssociados() {
      const associados = await api.get("/associados")
      const { data } = associados
      setAssociadosToDisplay(() => [
        ...data.map((item) => {
          return { ...item, id: item.id, nome: item.nome }
        })
      ])
    }
    getListaAssociados()
    setLoading(false)
  }, [])

  const columns = [
    {
      field: "associado",
      headerName: "Associado",
      width: 350,
      editable: false
    },
    {
      field: "delete",
      headerName: "Deletar",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteForeverIcon sx={{ color: "red", fontSize: 30 }} />}
          label="Deletar"
          onClick={async (e) => {
            e.preventDefault()
            setLoading(true)
            const { id } = params.row
            await api
              .delete(`/deleta-associado-cliente/${id}`)
              .then((response) => {
                if (response.status === 200) {
                  toast.success(
                    "Associado deletado com sucesso do cadastro do cliente",
                    {
                      position: toast.POSITION.TOP_CENTER
                    }
                  )
                  setLoading(false)
                } else {
                  toast.error(
                    "Não foi possível excluir associado do cadastrdo do cliente",
                    {
                      position: toast.POSITION.TOP_CENTER
                    }
                  )
                  setLoading(false)
                }
                getAssociadoAoCliente()
              })
          }}
        />
      ]
    }
  ]

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Editar Associado" />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          {associadosAoCliente.length > 0 ? (
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <center>
                  <span className="submenu">
                    Excluir Associado Viculado ao Cliente
                  </span>
                </center>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    alignContent: "center",
                    marginRight: "15px"
                  }}
                >
                  <hr />
                </div>
                <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                  <Box sx={{ height: 326, width: 500 }}>
                    <DataGrid
                      localeText={{
                        MuiTablePagination: {
                          labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} de mais de ${count} páginas`
                        }
                      }}
                      rows={Object.keys(associadosAoCliente).map(
                        (key) => associadosAoCliente[key]
                      )}
                      onCellEditStart={(params) => {
                        setAssociadosAoCliente({
                          ...associadosAoCliente,
                          [params.row.id]: { ...params.row, editing: true }
                        })
                      }}
                      onCellEditStop={(params) => {
                        setAssociadosAoCliente({
                          ...associadosAoCliente,
                          [params.row.id]: { ...params.row, editing: false }
                        })
                      }}
                      processRowUpdate={(newRow) =>
                        setAssociadosAoCliente({
                          ...associadosAoCliente,
                          [newRow.id]: {
                            ...newRow,
                            dataLimite: newRow.dataLimite
                          }
                        }) ?? newRow
                      }
                      onProcessRowUpdateError={() => {}}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowHeight={() => "auto"}
                    />
                  </Box>
                </Container>
              </Paper>
            </Container>
          ) : (
            <></>
          )}
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <Grid>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <center>
                        <span className="submenu">
                          Adicionar Associado ao Cliente
                        </span>
                      </center>
                      <hr />
                      <Box
                        sx={{
                          "& > :not(style)": { width: 517 }
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Label>
                          Selecione os parceiros associados a este cliente:
                        </Label>
                        <Tooltip
                          title="Ao selecionar um ou mais associados confirme todos os nomes antes de prosseguir"
                          arrow
                        >
                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={associadosToDisplay}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              setAssociadosSelecionados(newValue)
                            }}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 5 }}
                                  checked={selected}
                                />
                                {option.nome}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Associados"
                                placeholder="Selecione um ou mais associados"
                              />
                            )}
                          />
                        </Tooltip>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse"
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,
                              backgroundColor: "#28A6DE",
                              color: "#f2f2f2"
                            }}
                          >
                            Adicionar
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse"
                          }}
                        />
                      </Box>
                    </Paper>
                  </div>
                </Grid>
              </form>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
