import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AuthContext } from "../../Context/auth"

import api from "../../Config/api"

import "./style.css"

import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import GlobalStyles from "@mui/material/GlobalStyles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircularProgress from "@mui/material/CircularProgress"

import Badge from "@mui/material/Badge"
import LogoutIcon from "@mui/icons-material/Logout"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import Modal from "@mui/material/Modal"
import { Col, Row } from "reactstrap"

import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

import { DataGrid, ptBR } from "@mui/x-data-grid"
import { mainListItems, secondaryListItems } from "./listItems"

const drawerWidth = 240

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
]

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 400,
  height: 100,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 1,
  align: "center"
}

const mdTheme = createTheme()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: "white"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const Cliente = () => {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const [openModal, setOpenModal] = useState(true)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  const [status, setStatus] = useState(0)

  const { logout } = useContext(AuthContext)

  const params = useParams()

  const cnpj = params._id

  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState("")
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")
  const [data5, setData5] = useState("")
  const [data6, setData6] = useState("")
  const [data7, setData7] = useState("")
  const [data8, setData8] = useState("")
  const [data9, setData9] = useState("")
  const [data10, setData10] = useState("")
  const [data11, setData11] = useState("")
  const [data12, setData12] = useState("")
  const [ano1, setAno1] = useState("")
  const [ano2, setAno2] = useState("")
  const [ano3, setAno3] = useState("")
  const [ano4, setAno4] = useState("")
  const [ano5, setAno5] = useState("")

  /// ////////////////////////////////////////////////////////////
  // SET STATUS SELECTS 29
  const [tipo29, setTipo29] = useState("")
  const [tipo291, setTipo291] = useState("")
  const handleChange291 = (event) => {
    setTipo291(event.target.value)
  }

  const [tipo292, setTipo292] = useState("")
  const handleChange292 = (event) => {
    setTipo292(event.target.value)
  }

  const [tipo293, setTipo293] = useState("")
  const handleChange293 = (event) => {
    setTipo293(event.target.value)
  }

  const [tipo294, setTipo294] = useState("")
  const handleChange294 = (event) => {
    setTipo294(event.target.value)
  }

  const [tipo295, setTipo295] = useState("")
  const handleChange295 = (event) => {
    setTipo295(event.target.value)
  }

  const [tipo296, setTipo296] = useState("")
  const handleChange296 = (event) => {
    setTipo296(event.target.value)
  }

  const [tipo297, setTipo297] = useState("")
  const handleChange297 = (event) => {
    setTipo297(event.target.value)
  }

  const [tipo298, setTipo298] = useState("")
  const handleChange298 = (event) => {
    setTipo298(event.target.value)
  }

  const [tipo299, setTipo299] = useState("")
  const handleChange299 = (event) => {
    setTipo299(event.target.value)
  }

  const [tipo2910, setTipo2910] = useState("")
  const handleChange2910 = (event) => {
    setTipo2910(event.target.value)
  }

  const [tipo2911, setTipo2911] = useState("")
  const handleChange2911 = (event) => {
    setTipo2911(event.target.value)
  }

  const [tipo2912, setTipo2912] = useState("")
  const handleChange2912 = (event) => {
    setTipo2912(event.target.value)
  }

  const [tipo29ano1, setTipo29ano1] = useState("")
  const handleChange29ano1 = (event) => {
    setTipo29ano1(event.target.value)
  }

  const [tipo29ano2, setTipo29ano2] = useState("")
  const handleChange29ano2 = (event) => {
    setTipo29ano2(event.target.value)
  }

  const [tipo29ano3, setTipo29ano3] = useState("")
  const handleChange29ano3 = (event) => {
    setTipo29ano3(event.target.value)
  }

  const [tipo29ano4, setTipo29ano4] = useState("")
  const handleChange29ano4 = (event) => {
    setTipo29ano4(event.target.value)
  }

  const [tipo29ano5, setTipo29ano5] = useState("")
  const handleChange29ano5 = (event) => {
    setTipo29ano5(event.target.value)
  }

  /// ///////////// FIM DO 29

  /// /////////////////////////////////////////////////////////////////////////
  // SET STATUS SELECTS 30
  const [tipo30, setTipo30] = useState("")

  const [tipo301, setTipo301] = useState("")
  const handleChange301 = (event) => {
    setTipo301(event.target.value)
  }

  const [tipo302, setTipo302] = useState("")
  const handleChange302 = (event) => {
    setTipo302(event.target.value)
  }

  const [tipo303, setTipo303] = useState("")
  const handleChange303 = (event) => {
    setTipo303(event.target.value)
  }

  const [tipo304, setTipo304] = useState("")
  const handleChange304 = (event) => {
    setTipo304(event.target.value)
  }

  const [tipo305, setTipo305] = useState("")
  const handleChange305 = (event) => {
    setTipo305(event.target.value)
  }

  const [tipo306, setTipo306] = useState("")
  const handleChange306 = (event) => {
    setTipo306(event.target.value)
  }

  const [tipo307, setTipo307] = useState("")
  const handleChange307 = (event) => {
    setTipo307(event.target.value)
  }

  const [tipo308, setTipo308] = useState("")
  const handleChange308 = (event) => {
    setTipo308(event.target.value)
  }

  const [tipo309, setTipo309] = useState("")
  const handleChange309 = (event) => {
    setTipo309(event.target.value)
  }

  const [tipo3010, setTipo3010] = useState("")
  const handleChange3010 = (event) => {
    setTipo3010(event.target.value)
  }

  const [tipo3011, setTipo3011] = useState("")
  const handleChange3011 = (event) => {
    setTipo3011(event.target.value)
  }

  const [tipo3012, setTipo3012] = useState("")
  const handleChange3012 = (event) => {
    setTipo3012(event.target.value)
  }

  const [tipo30ano1, setTipo30ano1] = useState("")
  const handleChange30ano1 = (event) => {
    setTipo30ano1(event.target.value)
  }

  const [tipo30ano2, setTipo30ano2] = useState("")
  const handleChange30ano2 = (event) => {
    setTipo30ano2(event.target.value)
  }

  const [tipo30ano3, setTipo30ano3] = useState("")
  const handleChange30ano3 = (event) => {
    setTipo30ano3(event.target.value)
  }

  const [tipo30ano4, setTipo30ano4] = useState("")
  const handleChange30ano4 = (event) => {
    setTipo30ano4(event.target.value)
  }

  const [tipo30ano5, setTipo30ano5] = useState("")
  const handleChange30ano5 = (event) => {
    setTipo30ano5(event.target.value)
  }

  /// ///////////// FIM DO 30 //////////////////////////////

  /// /////////////////////////////////////////////////////////////////////////
  // SET STATUS SELECTS 31
  const [tipo31, setTipo31] = useState("")

  const [tipo311, setTipo311] = useState("")
  const handleChange311 = (event) => {
    setTipo311(event.target.value)
  }

  const [tipo312, setTipo312] = useState("")
  const handleChange312 = (event) => {
    setTipo312(event.target.value)
  }

  const [tipo313, setTipo313] = useState("")
  const handleChange313 = (event) => {
    setTipo313(event.target.value)
  }

  const [tipo314, setTipo314] = useState("")
  const handleChange314 = (event) => {
    setTipo314(event.target.value)
  }

  const [tipo315, setTipo315] = useState("")
  const handleChange315 = (event) => {
    setTipo315(event.target.value)
  }

  const [tipo316, setTipo316] = useState("")
  const handleChange316 = (event) => {
    setTipo316(event.target.value)
  }

  const [tipo317, setTipo317] = useState("")
  const handleChange317 = (event) => {
    setTipo317(event.target.value)
  }

  const [tipo318, setTipo318] = useState("")
  const handleChange318 = (event) => {
    setTipo318(event.target.value)
  }

  const [tipo319, setTipo319] = useState("")
  const handleChange319 = (event) => {
    setTipo319(event.target.value)
  }

  const [tipo3110, setTipo3110] = useState("")
  const handleChange3110 = (event) => {
    setTipo3110(event.target.value)
  }

  const [tipo3111, setTipo3111] = useState("")
  const handleChange3111 = (event) => {
    setTipo3111(event.target.value)
  }

  const [tipo3112, setTipo3112] = useState("")
  const handleChange3112 = (event) => {
    setTipo3112(event.target.value)
  }

  const [tipo31ano1, setTipo31ano1] = useState("")
  const handleChange31ano1 = (event) => {
    setTipo31ano1(event.target.value)
  }

  const [tipo31ano2, setTipo31ano2] = useState("")
  const handleChange31ano2 = (event) => {
    setTipo31ano2(event.target.value)
  }

  const [tipo31ano3, setTipo31ano3] = useState("")
  const handleChange31ano3 = (event) => {
    setTipo31ano3(event.target.value)
  }

  const [tipo31ano4, setTipo31ano4] = useState("")
  const handleChange31ano4 = (event) => {
    setTipo31ano4(event.target.value)
  }

  const [tipo31ano5, setTipo31ano5] = useState("")
  const handleChange31ano5 = (event) => {
    setTipo31ano5(event.target.value)
  }

  /// //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/editar-cliente", { cnpj })
      setRazaoSocial(cliente.data.cliente.razaoSocial)
      setSimples(cliente.data.cliente.simples)
      setData1(cliente.data.cliente.data1)
      setData2(cliente.data.cliente.data2)
      setData3(cliente.data.cliente.data3)
      if (cliente.data.cliente.simples === true) {
        setData4(cliente.data.cliente.data4)
        setData5(cliente.data.cliente.data5)
        setData6(cliente.data.cliente.data6)
        setData7(cliente.data.cliente.data7)
        setData8(cliente.data.cliente.data8)
        setData9(cliente.data.cliente.data9)
        setData10(cliente.data.cliente.data10)
        setData11(cliente.data.cliente.data11)
        setData12(cliente.data.cliente.data12)
        setAno1(cliente.data.cliente.ano1)
        setAno2(cliente.data.cliente.ano2)
        setAno3(cliente.data.cliente.ano3)
        setAno4(cliente.data.cliente.ano4)
        setAno5(cliente.data.cliente.ano5)
      }

      const qtde = cliente.data.tipos.length
      let cont = 0

      while (cont < qtde) {
        if (cliente.data.tipos[cont].tipo === 29) {
          setTipo291(cliente.data.tipos[cont].status1)
          setTipo292(cliente.data.tipos[cont].status2)
          setTipo293(cliente.data.tipos[cont].status3)
          setTipo294(cliente.data.tipos[cont].status4)
          setTipo295(cliente.data.tipos[cont].status5)
          setTipo296(cliente.data.tipos[cont].status6)
          setTipo297(cliente.data.tipos[cont].status7)
          setTipo298(cliente.data.tipos[cont].status8)
          setTipo299(cliente.data.tipos[cont].status9)
          setTipo2910(cliente.data.tipos[cont].status10)
          setTipo2911(cliente.data.tipos[cont].status11)
          setTipo2912(cliente.data.tipos[cont].status12)
          setTipo29ano1(cliente.data.tipos[cont].statusAno1)
          setTipo29ano2(cliente.data.tipos[cont].statusAno2)
          setTipo29ano3(cliente.data.tipos[cont].statusAno3)
          setTipo29ano4(cliente.data.tipos[cont].statusAno4)
          setTipo29ano5(cliente.data.tipos[cont].statusAno5)
          setTipo29(cliente.data.tipos[cont].tipo)
        }
        if (cliente.data.tipos[cont].tipo === 30) {
          setTipo301(cliente.data.tipos[cont].status1)
          setTipo302(cliente.data.tipos[cont].status2)
          setTipo303(cliente.data.tipos[cont].status3)
          setTipo304(cliente.data.tipos[cont].status4)
          setTipo305(cliente.data.tipos[cont].status5)
          setTipo306(cliente.data.tipos[cont].status6)
          setTipo307(cliente.data.tipos[cont].status7)
          setTipo308(cliente.data.tipos[cont].status8)
          setTipo309(cliente.data.tipos[cont].status9)
          setTipo3010(cliente.data.tipos[cont].status10)
          setTipo3011(cliente.data.tipos[cont].status11)
          setTipo3012(cliente.data.tipos[cont].status12)
          setTipo30ano1(cliente.data.tipos[cont].statusAno1)
          setTipo30ano2(cliente.data.tipos[cont].statusAno2)
          setTipo30ano3(cliente.data.tipos[cont].statusAno3)
          setTipo30ano4(cliente.data.tipos[cont].statusAno4)
          setTipo30ano5(cliente.data.tipos[cont].statusAno5)
          setTipo30(cliente.data.tipos[cont].tipo)
        }
        if (cliente.data.tipos[cont].tipo === 31) {
          setTipo311(cliente.data.tipos[cont].status1)
          setTipo312(cliente.data.tipos[cont].status2)
          setTipo313(cliente.data.tipos[cont].status3)
          setTipo314(cliente.data.tipos[cont].status4)
          setTipo315(cliente.data.tipos[cont].status5)
          setTipo316(cliente.data.tipos[cont].status6)
          setTipo317(cliente.data.tipos[cont].status7)
          setTipo318(cliente.data.tipos[cont].status8)
          setTipo319(cliente.data.tipos[cont].status9)
          setTipo3110(cliente.data.tipos[cont].status10)
          setTipo3111(cliente.data.tipos[cont].status11)
          setTipo3112(cliente.data.tipos[cont].status12)
          setTipo31ano1(cliente.data.tipos[cont].statusAno1)
          setTipo31ano2(cliente.data.tipos[cont].statusAno2)
          setTipo31ano3(cliente.data.tipos[cont].statusAno3)
          setTipo31ano4(cliente.data.tipos[cont].statusAno4)
          setTipo31ano5(cliente.data.tipos[cont].statusAno5)
          setTipo31(cliente.data.tipos[cont].tipo)
        }

        cont++
      }
      handleClose()
    }

    getCliente()
  }, [cnpj])

  const [rows, setRows] = useState([])
  const [datas, setDatas] = useState([])

  const [status4, setStatus4] = useState([])
  const [status12, setStatus12] = useState([])
  const [status13, setStatus13] = useState([])
  const [status15, setStatus15] = useState([])
  const [status16, setStatus16] = useState([])
  const [status17, setStatus17] = useState([])
  const [status18, setStatus18] = useState([])
  const [status19, setStatus19] = useState([])
  const [status20, setStatus20] = useState([])
  const [status21, setStatus21] = useState([])
  const [status22, setStatus22] = useState([])
  const [status23, setStatus23] = useState([])
  const [status24, setStatus24] = useState([])
  const [status25, setStatus25] = useState([])
  const [status26, setStatus26] = useState([])
  const [status27, setStatus27] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const buscaCNPJ = { cnpj }
      const cliente = await api.post("/checklist", buscaCNPJ)
      let datas = []
      datas = cliente.data.dados.cincoAnos
      let x = 0
      const meses = []
      while (x < datas.length) {
        meses.push({ id: x, data: datas[x] })
        x++
      }
      setDatas(meses)

      const uploads = cliente.data.tipos

      let cont = 0
      const dados = []
      let status = []
      while (cont < uploads.length) {
        x = 0
        status = []
        if (uploads[cont].tipo === 4) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus4(status)
        }
        if (uploads[cont].tipo === 12) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus12(status)
        }
        if (uploads[cont].tipo === 13) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus13(status)
        }
        if (uploads[cont].tipo === 15) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus15(status)
        }
        if (uploads[cont].tipo === 16) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus16(status)
        }
        if (uploads[cont].tipo === 17) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus17(status)
        }
        if (uploads[cont].tipo === 18) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus18(status)
        }
        if (uploads[cont].tipo === 19) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus19(status)
        }
        if (uploads[cont].tipo === 20) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus20(status)
        }
        if (uploads[cont].tipo === 21) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus21(status)
        }
        if (uploads[cont].tipo === 22) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus22(status)
        }
        if (uploads[cont].tipo === 23) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus23(status)
        }
        if (uploads[cont].tipo === 24) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus24(status)
        }
        if (uploads[cont].tipo === 25) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus25(status)
        }
        if (uploads[cont].tipo === 26) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus26(status)
        }
        if (uploads[cont].tipo === 27) {
          while (x < 66) {
            status.push({ id: x, status: uploads[cont].statusCincoAnos[x] })
            x++
          }
          setStatus27(status)
        }

        cont++
      }
      setRows(dados)
    }

    getCliente()
  }, [cnpj])

  function formatarDataSimples(data) {
    if (data === null) {
      return "-"
    }
    const mes = data.substr(5, 2)
    const ano = data.substr(2, 2)
    const dataAtual = `${mes}/${ano}`
    return dataAtual
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <GlobalStyles styles={{ h5: { color: "grey" } }} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: "#28A6DE"
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Cliente - {razaoSocial}
            </Typography>
            <IconButton color="inherit" onClick={logout}>
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1]
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container maxWidth="xxl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {simples === true ? (
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <h5>Diagnóstico</h5>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              Pasta
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data1)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data2)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data3)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data4)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data5)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data6)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data7)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data8)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data9)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data10)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data11)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatarDataSimples(data12)}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tipo29 ? (
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                XMLs
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo291}
                                  onChange={handleChange291}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo292}
                                  onChange={handleChange292}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo293}
                                  onChange={handleChange293}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo294}
                                  onChange={handleChange294}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo295}
                                  onChange={handleChange295}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo296}
                                  onChange={handleChange296}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo297}
                                  onChange={handleChange297}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo298}
                                  onChange={handleChange298}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo299}
                                  onChange={handleChange299}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo2910}
                                  onChange={handleChange2910}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo2911}
                                  onChange={handleChange2911}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo2912}
                                  onChange={handleChange2912}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}

                          {tipo30 ? (
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                ESN
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo301}
                                  onChange={handleChange301}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo302}
                                  onChange={handleChange302}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo303}
                                  onChange={handleChange303}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo304}
                                  onChange={handleChange304}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo305}
                                  onChange={handleChange305}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo306}
                                  onChange={handleChange306}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo307}
                                  onChange={handleChange307}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo308}
                                  onChange={handleChange308}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo309}
                                  onChange={handleChange309}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo3010}
                                  onChange={handleChange3010}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo3011}
                                  onChange={handleChange3011}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo3012}
                                  onChange={handleChange3012}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}

                          {tipo31 ? (
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                DAS
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo311}
                                  onChange={handleChange311}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo312}
                                  onChange={handleChange312}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo313}
                                  onChange={handleChange313}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo314}
                                  onChange={handleChange314}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo315}
                                  onChange={handleChange315}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo316}
                                  onChange={handleChange316}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo317}
                                  onChange={handleChange317}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo318}
                                  onChange={handleChange318}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo319}
                                  onChange={handleChange319}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo3110}
                                  onChange={handleChange3110}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo3111}
                                  onChange={handleChange3111}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo3112}
                                  onChange={handleChange3112}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : (
                  ""
                )}

                {simples === true ? (
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5
                    }}
                  >
                    <h5>Trabalho de campo</h5>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              Pasta
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ano1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ano2}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ano3}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ano4}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ano5}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tipo29 ? (
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                XMLs
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo29ano1}
                                  onChange={handleChange29ano1}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo29ano2}
                                  onChange={handleChange29ano2}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo29ano3}
                                  onChange={handleChange29ano3}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo29ano4}
                                  onChange={handleChange29ano4}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo29ano5}
                                  onChange={handleChange29ano5}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                          {tipo30 ? (
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                ESN
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo30ano1}
                                  onChange={handleChange30ano1}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo30ano2}
                                  onChange={handleChange30ano2}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo30ano3}
                                  onChange={handleChange30ano3}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo30ano4}
                                  onChange={handleChange30ano4}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo30ano5}
                                  onChange={handleChange30ano5}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                          {tipo31 ? (
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                DAS
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo31ano1}
                                  onChange={handleChange31ano1}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo31ano2}
                                  onChange={handleChange31ano2}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo31ano3}
                                  onChange={handleChange31ano3}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="selects"
                              >
                                <Select
                                  className="selects"
                                  variant="standard"
                                  value={tipo31ano4}
                                  onChange={handleChange31ano4}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Select
                                  variant="standard"
                                  value={tipo31ano5}
                                  onChange={handleChange31ano5}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                </Select>
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : (
                  ""
                )}

                {simples === false ? (
                  <div style={{ height: 500, width: "100%" }}>
                    <DataGrid
                      localeText={
                        ptBR.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={rows}
                      columns={columns}
                      pageSize={7}
                      rowsPerPageOptions={[5]}
                    />
                    roberto
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <div>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Row>
              <Col xl="3">
                <CircularProgress />
              </Col>
              <Col xl="9">
                <h4>Carregando dados</h4>
              </Col>
            </Row>
          </Box>
        </Modal>
      </div>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <Cliente />
}
