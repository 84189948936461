import React, { useState, useContext } from "react"
import { AuthContext } from "../../Context/auth"
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { IMaskInput } from "react-imask"
import MailIcon from "@mui/icons-material/Mail"
import "../../styles/styles.css"
import "../../styles/styles.scss"
import LockIcon from "@mui/icons-material/Lock"
import logo_bkps from "../../assets/imgs/logo_bkps.png"
import "bootstrap/dist/css/bootstrap.min.css"
import { Box, Tooltip } from "@mui/material"
import background from "../../assets/imgs/background.png"

export default function NovaSenhaAssociado() {
  const { trocaSenhaAssociado } = useContext(AuthContext)
  const [email, setEmail] = useState("")
  const [senhaAntiga, setSenhaAntiga] = useState("")
  const [senhaNova, setSenhaNova] = useState("")
  const [state, setstate] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const toggleBtn = (event) => {
    setstate((prevState) => !prevState)
  }

  const logar = async (e) => {
    e.preventDefault()
    trocaSenhaAssociado(email, senhaAntiga, senhaNova)
  }

  return (
    <div>
      <ToastContainer />
      <div className="col-md-6 background">
        <img src={background} alt="Logo" />
      </div>

      <img src={logo_bkps} alt="logo" className="brand" />
      <div className="row" />
      <div className="container col-md-6">
        <div className="container-nova-senha">
          <form onSubmit={handleSubmit} className="col-md-12">
            <div className="p-3 offset-3 col-md-12">
              <span className="span">Troca de Senha</span>
            </div>

            <span className="text">
              Utilize a senha enviada no email cadastrado, e realize a troca.
            </span>

            <br />
            <div className="py-2 p-1">
              <div className="icon">
                <MailIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">E-mail</label>
            </div>

            {/* <br></br> */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <div className="p-3 pt-1">
                <IMaskInput
                  className="input-login"
                  placeholder="Digite o E-mail"
                  autoComplete="false"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Box>
            <div className="mt-1 py-1 p-1">
              <div className="icon">
                <LockIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Senha Antiga</label>
            </div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <div className="p-3 pt-1">
                <input
                  className="input-login"
                  placeholder="Senha Antiga"
                  autoComplete="false"
                  id="senhaAntiga"
                  name="senhaAntiga"
                  type={state ? "text" : "password"}
                  value={senhaAntiga}
                  onChange={(e) => setSenhaAntiga(e.target.value)}
                />
              </div>
            </Box>

            <div className="py-1 p-1">
              <div className="icon">
                <LockIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Nova Senha</label>
            </div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <Tooltip
                title="A senha deve conter 8 caracteres no mínimo, 1 Letra Maiúscula no mínimo, 1 Número no mínimo e 1 Símbolo no mínimo: $*&@#"
                arrow
                placement="top-end"
              >
                <div className="p-3 pt-1">
                  <input
                    className="input-login padding-right"
                    placeholder="Nova Senha"
                    autoComplete="false"
                    id="senhaNova"
                    name="senhaNova"
                    type={state ? "text" : "password"}
                    value={senhaNova}
                    onChange={(e) => setSenhaNova(e.target.value)}
                  />
                </div>
              </Tooltip>
            </Box>
            <button className="btnEye" onClick={toggleBtn}>
              {state ? (
                <AiOutlineEye size={25} />
              ) : (
                <AiOutlineEyeInvisible size={25} />
              )}
            </button>

            <button className="button" alt="Login" onClick={logar}>
              Salvar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
