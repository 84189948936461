import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Button } from "@mui/material"
import { Col, Row } from "reactstrap"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Tabela from "./Tabelas/Filiais"
import api from "../../Config/api"
import ResponsiveDrawer from "../../components/menu"

const mdTheme = createTheme()

const CheckListCampo = () => {
  const params = useParams()
  const cnpj = params._id

  const [rows, setRows] = useState([])
  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState("")
  const [filiais, setFiliais] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const buscaCNPJ = { cnpj }
      const cliente = await api.post("/checklist", buscaCNPJ)
      setFiliais(cliente.data.dados.filiais)
      setRazaoSocial(cliente.data.dados.razaoSocial)
      setSimples(cliente.data.dados.simples)
      setRows(cliente.data.tipos)
    }

    getCliente()
  }, [cnpj])

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Filiais" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            {simples === true ? (
              <div>
                <strong className="title">
                  Cliente não possui filiais cadastradas!
                </strong>
                <Row className="linhas">
                  <Col xl={4}>
                    <Button
                      type="link"
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: "#28A6DE",
                        color: "#f2f2f2"
                      }}
                    >
                      <Link id="rollback" to="/clientes/">
                        Voltar
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              // Não pertence ao Simples Nacional
              <div>
                {filiais.length > 0 ? (
                  <div>
                    {/* tabela com as filiais */}
                    <Tabela />
                  </div>
                ) : (
                  <div>
                    <strong className="title">
                      Cliente não possui filiais cadastradas no momento!
                    </strong>
                    <Row className="linhas">
                      <Col xl={4}>
                        <Button
                          type="link"
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "#28A6DE",
                            color: "#f2f2f2"
                          }}
                        >
                          <Link id="rollback" to="/clientes/">
                            Voltar
                          </Link>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <CheckListCampo />
}
