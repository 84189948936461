import React, { useEffect, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useParams } from "react-router-dom"
import ResponsiveDrawerAdmin from "../../../../components/menu"
import ResponsiveDrawer from "../../../../components/menuCliente"
import api from "../../../../Config/api"

const mdTheme = createTheme()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "White",
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const CheckListAtivoImobilizado = () => {
  const [loading, setLoading] = useState(false)
  const [perfil, setPerfil] = useState("")
  const params = useParams()
  const cnpjCliente = params._id
  const cnpj = localStorage.getItem("cnpj")
  const [rows, setRows] = useState([])
  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState("")
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")

  useEffect(() => {
    const jsonRecuperado = localStorage.getItem("user")
    const perfilUser = JSON.parse(jsonRecuperado).perfil
    setPerfil(perfilUser)
  }, [])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/checklist", { cnpj: cnpjCliente })
      setRazaoSocial(cliente.data.dados.razaoSocial)
      setSimples(cliente.data.dados.simples)
      setData1(cliente.data.dados.data1)
      setData2(cliente.data.dados.data2)
      setData3(cliente.data.dados.data3)
    }
    getCliente()
  }, [cnpj])

  useEffect(() => {
    async function getTipoAtivoImobilizado() {
      const ativoImobilizado = await api.post(
        "/checklist-tipo-ativo-imobilizado",
        { cnpj: cnpjCliente }
      )
      const ativoImobilizadoMatriz =
        ativoImobilizado.data.statusAtivoImobilizado.map((d) => ({
          cnpj: d.cnpj,
          tipoUpload: d.tipoUploadMatriz
        }))

      setRows([ativoImobilizadoMatriz])
    }
    getTipoAtivoImobilizado()
    setLoading(false)
  }, [cnpj])

  const dadosAtivoImobilizado = rows
    .flatMap((d) => d)
    .map((d) => ({
      cnpj: d.cnpj,
      ...d.tipoUpload.map((d) => ({ statusUnico: d.statusUnico })).pop()
    }))

  function formatarData(data) {
    if (data === null) {
      return "-"
    }
    const mes = data.substr(5, 2)
    const ano = data.substr(0, 4)
    const dataAtual = `${mes}/${ano}`
    return dataAtual
  }

  function formatarDataSimples(data) {
    if (data === null) {
      return "-"
    }
    const mes = data.substr(5, 2)
    const ano = data.substr(2, 2)
    const dataAtual = `${mes}/${ano}`
    return dataAtual
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {perfil === 2 ? (
          <ResponsiveDrawer title={`Cliente - ${razaoSocial}`} />
        ) : (
          <ResponsiveDrawerAdmin title={`Cliente - ${razaoSocial}`} />
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4 }}>
            <div>
              <Grid>
                {loading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Container sx={{ mt: 4, mb: 4 }}>
                    <center>
                      <span className="submenu">Checklist de Documentos</span>
                    </center>
                    <hr />
                    <center>
                      <span className="sublabel">
                        Bens do ativo imobilizado
                      </span>
                    </center>
                    <hr />
                    <>
                      <TableContainer component={Paper}>
                        <Table aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <div className="title-arquivo">CNPJ</div>
                              </TableCell>

                              <StyledTableCell align="center">
                                Status
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dadosAtivoImobilizado.map((row, idx) => (
                              <StyledTableRow key={row.idx}>
                                <StyledTableCell component="th" scope="row">
                                  {row.cnpj ? row.cnpj : "-"}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {row.statusUnico === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.statusUnico === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.statusUnico === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.statusUnico === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row.statusUnico === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  </Container>
                )}
              </Grid>
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <CheckListAtivoImobilizado />
}
