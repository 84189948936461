import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"

import { Link } from "react-router-dom"

import "./style.css"

export const mainListItems = (
  <>
    <Link to="/novoCliente" className="linkList">
      <ListItemButton>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText
          style={{ color: "#FFFFFF" }}
          primary="Novo Cliente"
          className="linkList"
        />
      </ListItemButton>
    </Link>

    <Link to="/clientes" className="linkList">
      <ListItemButton>
        <ListItemIcon>
          <PeopleAltIcon />
        </ListItemIcon>
        <ListItemText style={{ color: "#FFFFFF" }} primary="Clientes" />
      </ListItemButton>
    </Link>
  </>
)

export const secondaryListItems = <></>
