import React from "react"

import Rotas from "./Rotas"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
function App() {
  return <Rotas />
  ;<ToastContainer />
}
export default App
