import React, { useState, useEffect } from "react"

import { useParams } from "react-router-dom"

import api from "../../../Config/api"

import Up10 from "./Tabelas/up10"
import Up27 from "./Tabelas/up27"
import Up29 from "./Tabelas/up29"
import Up30 from "./Tabelas/up30"
import Up31 from "./Tabelas/up31"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import { Button } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import SaveIcon from "@mui/icons-material/Save"
import InputLabel from "@mui/material/InputLabel"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import ExportCSV from "../../../components/ExportCSV"
import ResponsiveDrawer from "../../../components/menu"
import "react-toastify/dist/ReactToastify.min.css"
import { toast, ToastContainer } from "react-toastify"
import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"
import Up26 from "./Tabelas/up26"
import Up25 from "./Tabelas/up25"
import Up16 from "./Tabelas/up16"
import Up24 from "./Tabelas/up24"
import Up23 from "./Tabelas/up23"
import Up22 from "./Tabelas/up22"
import Up21 from "./Tabelas/up21"
import Up20 from "./Tabelas/up20"
import Up19 from "./Tabelas/up19"
import Up18 from "./Tabelas/up18"
import "../style.css"

const mdTheme = createTheme()

const Diagnostico = () => {
  const [statusEdit, setStatusEdit] = useState("")

  const params = useParams()

  const cnpj = params._id
  localStorage.setItem("cnpj", cnpj)
  const [razaoSocial, setRazaoSocial] = useState("")

  const [dados, setDados] = useState([])
  const [colunas, setColunas] = useState([])
  const [colunasSN, setColunasSN] = useState([])

  const [anos, setAnos] = useState([])

  const [tipo, setTipo] = useState("")
  const setarTipo = (e) => setTipo({ ...tipo, [e.target.name]: e.target.value })

  const [tipo1, setTipo1] = useState("")
  const setarTipo1 = (e) =>
    setTipo1({ ...tipo1, [e.target.name]: e.target.value })

  const [tipo2, setTipo2] = useState("")
  const setarTipo2 = (e) =>
    setTipo2({ ...tipo2, [e.target.name]: e.target.value })

  const [tipo3, setTipo3] = useState("")
  const setarTipo3 = (e) =>
    setTipo3({ ...tipo3, [e.target.name]: e.target.value })

  const [tipo4, setTipo4] = useState("")
  const setarTipo4 = (e) =>
    setTipo4({ ...tipo4, [e.target.name]: e.target.value })

  const [tipo5, setTipo5] = useState("")
  const setarTipo5 = (e) =>
    setTipo5({ ...tipo5, [e.target.name]: e.target.value })

  const [tipo6, setTipo6] = useState("")
  const setarTipo6 = (e) =>
    setTipo6({ ...tipo6, [e.target.name]: e.target.value })

  const [tipo7, setTipo7] = useState("")
  const setarTipo7 = (e) =>
    setTipo7({ ...tipo7, [e.target.name]: e.target.value })

  const [tipo8, setTipo8] = useState("")
  const setarTipo8 = (e) =>
    setTipo8({ ...tipo8, [e.target.name]: e.target.value })

  const [tipo9, setTipo9] = useState("")
  const setarTipo9 = (e) =>
    setTipo9({ ...tipo9, [e.target.name]: e.target.value })

  const [tipo10, setTipo10] = useState("")
  const setarTipo10 = (e) =>
    setTipo10({ ...tipo10, [e.target.name]: e.target.value })

  const [tipo12, setTipo12] = useState("")
  const setarTipo12 = (e) =>
    setTipo12({ ...tipo12, [e.target.name]: e.target.value })

  const [tipo13, setTipo13] = useState("")
  const setarTipo13 = (e) =>
    setTipo13({ ...tipo13, [e.target.name]: e.target.value })

  const [tipo14, setTipo14] = useState("")
  const setarTipo14 = (e) =>
    setTipo14({ ...tipo14, [e.target.name]: e.target.value })

  const [tipo15, setTipo15] = useState("")
  const setarTipo15 = (e) =>
    setTipo15({ ...tipo15, [e.target.name]: e.target.value })

  const [tipo17, setTipo17] = useState("")
  const setarTipo17 = (e) =>
    setTipo17({ ...tipo17, [e.target.name]: e.target.value })

  const [tipo21, setTipo21] = useState("")
  const setarTipo21 = (e) =>
    setTipo21({ ...tipo21, [e.target.name]: e.target.value })

  const [tipo24, setTipo24] = useState("")
  const setarTipo24 = (e) =>
    setTipo24({ ...tipo24, [e.target.name]: e.target.value })

  const [tipo27, setTipo27] = useState("")
  const setarTipo27 = (e) =>
    setTipo27({ ...tipo27, [e.target.name]: e.target.value })

  const [tipo26, setTipo26] = useState("")
  const setarTipo26 = (e) =>
    setTipo26({ ...tipo26, [e.target.name]: e.target.value })

  const [tipo31, setTipo31] = useState("")
  const setarTipo31 = (e) =>
    setTipo31({ ...tipo31, [e.target.name]: e.target.value })

  const [tipo23, setTipo23] = useState("")
  const setarTipo23 = (e) =>
    setTipo23({ ...tipo23, [e.target.name]: e.target.value })

  const [tipo29, setTipo29] = useState("")
  const setarTipo29 = (e) =>
    setTipo29({ ...tipo29, [e.target.name]: e.target.value })

  const [tipo32, setTipo32] = useState("")
  const setarTipo32 = (e) =>
    setTipo32({ ...tipo32, [e.target.name]: e.target.value })

  const [tipo33, setTipo33] = useState("")
  const setarTipo33 = (e) =>
    setTipo33({ ...tipo33, [e.target.name]: e.target.value })

  const [tipo34, setTipo34] = useState("")
  const setarTipo34 = (e) =>
    setTipo34({ ...tipo34, [e.target.name]: e.target.value })

  const [tipo16, setTipo16] = useState("")
  const setarTipo16 = (e) =>
    setTipo16({ ...tipo16, [e.target.name]: e.target.value })

  const [tipo18, setTipo18] = useState("")
  const setarTipo18 = (e) =>
    setTipo18({ ...tipo18, [e.target.name]: e.target.value })

  const [tipo25, setTipo25] = useState("")
  const setarTipo25 = (e) =>
    setTipo25({ ...tipo25, [e.target.name]: e.target.value })

  const [resumoStatusSpedFiscal, setResumoStatusSpedFiscal] = useState("")

  const [tipo19, setTipo19] = useState("")
  const [tipo20, setTipo20] = useState("")
  const [tipo22, setTipo22] = useState("")
  const [tipo30, setTipo30] = useState("")
  const [simples, setSimples] = useState(false)

  const [tiposUpload, setTiposUpload] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/checklist", { cnpj })
      setResumoStatusSpedFiscal(cliente.data.dados.resumoStatusSpedFiscal)
      setRazaoSocial(cliente.data.dados.razaoSocial)
      setSimples(cliente.data.dados.simples)
      const col = []
      if (cliente.data.dados.data1 !== null) {
        col.push(
          `${cliente.data.dados.data1.substr(
            0,
            4
          )}/${cliente.data.dados.data1.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data2 !== null) {
        col.push(
          `${cliente.data.dados.data2.substr(
            0,
            4
          )}/${cliente.data.dados.data2.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data3 !== null) {
        col.push(
          `${cliente.data.dados.data3.substr(
            0,
            4
          )}/${cliente.data.dados.data3.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data4 !== null) {
        col.push(
          `${cliente.data.dados.data4.substr(
            0,
            4
          )}/${cliente.data.dados.data4.substr(5, 2)}`
        )
      }
      const colSN = []
      if (cliente.data.dados.data1 !== null) {
        colSN.push(cliente.data.dados.data1)
      }
      if (cliente.data.dados.data2 !== null) {
        colSN.push(cliente.data.dados.data2)
      }
      if (cliente.data.dados.data3 !== null) {
        colSN.push(cliente.data.dados.data3)
      }
      if (cliente.data.dados.data4 !== null) {
        colSN.push(cliente.data.dados.data4)
      }
      if (cliente.data.dados.data5 !== null) {
        colSN.push(cliente.data.dados.data5)
      }
      if (cliente.data.dados.data6 !== null) {
        colSN.push(cliente.data.dados.data6)
      }
      if (cliente.data.dados.data7 !== null) {
        colSN.push(cliente.data.dados.data7)
      }
      if (cliente.data.dados.data8 !== null) {
        colSN.push(cliente.data.dados.data8)
      }
      if (cliente.data.dados.data9 !== null) {
        colSN.push(cliente.data.dados.data9)
      }
      if (cliente.data.dados.data10 !== null) {
        colSN.push(cliente.data.dados.data10)
      }
      if (cliente.data.dados.data11 !== null) {
        colSN.push(cliente.data.dados.data11)
      }
      if (cliente.data.dados.data12 !== null) {
        colSN.push(cliente.data.dados.data12)
      }
      const anos = []
      if (cliente.data.dados.ano1 !== null) {
        anos.push(cliente.data.dados.ano1)
      }
      if (cliente.data.dados.ano2 !== null) {
        anos.push(cliente.data.dados.ano2)
      }
      if (cliente.data.dados.ano3 !== null) {
        anos.push(cliente.data.dados.ano3)
      }
      if (cliente.data.dados.ano4 !== null) {
        anos.push(cliente.data.dados.ano4)
      }
      if (cliente.data.dados.ano5 !== null) {
        anos.push(cliente.data.dados.ano5)
      }
      setColunas(col)
      setColunasSN(colSN)
      setAnos(anos)
      setDados(cliente.data.dados)
      setLoading(false)
      let cont = 0
      while (cont < cliente.data.tipos.length) {
        if (cliente.data.tipos[cont].tipo === 1) {
          setTipo1(cliente.data.tipos[cont])
        }

        if (cliente.data.tipos[cont].tipo === 2) {
          setTipo2(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 3) {
          setTipo3(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 4) {
          setTipo4(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 5) {
          setTipo5(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 6) {
          setTipo6(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 7) {
          setTipo7(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 8) {
          setTipo8(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 9) {
          setTipo9(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 10) {
          setTipo10(cliente.data.tipos[cont])
        }

        if (cliente.data.tipos[cont].tipo === 12) {
          setTipo12(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 13) {
          setTipo13(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 14) {
          setTipo14(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 15) {
          setTipo15(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 16) {
          setTipo16(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 17) {
          setTipo17(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 18) {
          setTipo18(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 19) {
          setTipo19(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 20) {
          setTipo20(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 21) {
          setTipo21(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 22) {
          setTipo22(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 23) {
          setTipo23(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 24) {
          setTipo24(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 25) {
          setTipo25(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 26) {
          setTipo26(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 27) {
          setTipo27(cliente.data.tipos[cont])
        }

        if (cliente.data.tipos[cont].tipo === 29) {
          setTipo29(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 30) {
          setTipo30(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 31) {
          setTipo31(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 32) {
          setTipo32(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 33) {
          setTipo33(cliente.data.tipos[cont])
        }
        if (cliente.data.tipos[cont].tipo === 34) {
          setTipo34(cliente.data.tipos[cont])
        }
        cont++
      }
    }

    getCliente()
    setLoading(true)
  }, [cnpj])

  async function salvarSN(
    id,
    status1,
    status2,
    status3,
    status4,
    status5,
    status6,
    status7,
    status8,
    status9,
    status10,
    status11,
    status12
  ) {
    setStatusEdit(0)
    await api.put("/editar-tipo-upload-esn", {
      id,
      status1,
      status2,
      status3,
      status4,
      status5,
      status6,
      status7,
      status8,
      status9,
      status10,
      status11,
      status12
    })
    setStatusEdit(1)
    if (statusEdit === 1) {
      toast.success("Registro salvo com sucesso!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  async function salvar(id, s1, s2, s3, s4) {
    setStatusEdit(0)
    await api.put("/editar-tipo-upload", { id, s1, s2, s3, s4 })
    setStatusEdit(1)
    if (statusEdit === 1) {
      toast.success("Registro salvo com sucesso!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  async function salvarAno(
    id,
    statusAno1,
    statusAno2,
    statusAno3,
    statusAno4,
    statusAno5
  ) {
    setStatusEdit(0)
    await api.put("/editar-tipo-upload", {
      id,
      statusAno1,
      statusAno2,
      statusAno3,
      statusAno4,
      statusAno5
    })
    setStatusEdit(1)
    if (statusEdit === 1) {
      toast.success("Registro salvo com sucesso!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  async function salvarTudo() {
    const _tiposUpload = [
      {
        id: tipo1.id,
        s1: tipo1.status1,
        s2: tipo1.status2,
        s3: tipo1.status3,
        s4: tipo1.status4,
        statusAno1: tipo1.statusAno1,
        statusAno2: tipo1.statusAno2,
        statusAno3: tipo1.statusAno3,
        statusAno4: tipo1.statusAno4,
        statusAno5: tipo1.statusAno5
      },
      {
        id: tipo2.id,
        s1: tipo2.status1,
        s2: tipo2.status2,
        s3: tipo2.status3,
        s4: tipo2.status4,
        statusAno1: tipo2.statusAno1,
        statusAno2: tipo2.statusAno2,
        statusAno3: tipo2.statusAno3,
        statusAno4: tipo2.statusAno4,
        statusAno5: tipo2.statusAno5
      },
      {
        id: tipo3.id,
        s1: tipo3.status1,
        s2: tipo3.status2,
        s3: tipo3.status3,
        s4: tipo3.status4,
        statusAno1: tipo3.statusAno1,
        statusAno2: tipo3.statusAno2,
        statusAno3: tipo3.statusAno3,
        statusAno4: tipo3.statusAno4,
        statusAno5: tipo3.statusAno5
      },
      {
        id: tipo4.id,
        s1: tipo4.status1,
        s2: tipo4.status2,
        s3: tipo4.status3,
        s4: tipo4.status4,
        statusAno1: tipo4.statusAno1,
        statusAno2: tipo4.statusAno2,
        statusAno3: tipo4.statusAno3,
        statusAno4: tipo4.statusAno4,
        statusAno5: tipo4.statusAno5
      },
      {
        id: tipo5.id,
        s1: tipo5.status1,
        s2: tipo5.status2,
        s3: tipo5.status3,
        s4: tipo5.status4,
        statusAno1: tipo5.statusAno1,
        statusAno2: tipo5.statusAno2,
        statusAno3: tipo5.statusAno3,
        statusAno4: tipo5.statusAno4,
        statusAno5: tipo5.statusAno5
      },
      {
        id: tipo6.id,
        s1: tipo6.status1,
        s2: tipo6.status2,
        s3: tipo6.status3,
        s4: tipo6.status4,
        statusAno1: tipo6.statusAno1,
        statusAno2: tipo6.statusAno2,
        statusAno3: tipo6.statusAno3,
        statusAno4: tipo6.statusAno4,
        statusAno5: tipo6.statusAno5
      },
      {
        id: tipo7.id,
        s1: tipo7.status1,
        s2: tipo7.status2,
        s3: tipo7.status3,
        s4: tipo7.status4,
        statusAno1: tipo7.statusAno1,
        statusAno2: tipo7.statusAno2,
        statusAno3: tipo7.statusAno3,
        statusAno4: tipo7.statusAno4,
        statusAno5: tipo7.statusAno5
      },
      {
        id: tipo8.id,
        s1: tipo8.status1,
        s2: tipo8.status2,
        s3: tipo8.status3,
        s4: tipo8.status4,
        statusAno1: tipo8.statusAno1,
        statusAno2: tipo8.statusAno2,
        statusAno3: tipo8.statusAno3,
        statusAno4: tipo8.statusAno4,
        statusAno5: tipo8.statusAno5
      },
      {
        id: tipo9.id,
        s1: tipo9.status1,
        s2: tipo9.status2,
        s3: tipo9.status3,
        s4: tipo9.status4,
        statusAno1: tipo9.statusAno1,
        statusAno2: tipo9.statusAno2,
        statusAno3: tipo9.statusAno3,
        statusAno4: tipo9.statusAno4,
        statusAno5: tipo9.statusAno5
      },
      {
        id: tipo10.id,
        s1: tipo10.status1,
        s2: tipo10.status2,
        s3: tipo10.status3,
        s4: tipo10.status4,
        statusAno1: tipo10.statusAno1,
        statusAno2: tipo10.statusAno2,
        statusAno3: tipo10.statusAno3,
        statusAno4: tipo10.statusAno4,
        statusAno5: tipo10.statusAno5
      },
      {
        id: tipo12.id,
        s1: tipo12.status1,
        s2: tipo12.status2,
        s3: tipo12.status3,
        s4: tipo12.status4,
        statusAno1: tipo12.statusAno1,
        statusAno2: tipo12.statusAno2,
        statusAno3: tipo12.statusAno3,
        statusAno4: tipo12.statusAno4,
        statusAno5: tipo12.statusAno5
      },
      {
        id: tipo13.id,
        s1: tipo13.status1,
        s2: tipo13.status2,
        s3: tipo13.status3,
        s4: tipo13.status4,
        statusAno1: tipo13.statusAno1,
        statusAno2: tipo13.statusAno2,
        statusAno3: tipo13.statusAno3,
        statusAno4: tipo13.statusAno4,
        statusAno5: tipo13.statusAno5
      },
      {
        id: tipo14.id,
        s1: tipo14.status1,
        s2: tipo14.status2,
        s3: tipo14.status3,
        s4: tipo14.status4,
        statusAno1: tipo14.statusAno1,
        statusAno2: tipo14.statusAno2,
        statusAno3: tipo14.statusAno3,
        statusAno4: tipo14.statusAno4,
        statusAno5: tipo14.statusAno5
      },
      {
        id: tipo15.id,
        s1: tipo15.status1,
        s2: tipo15.status2,
        s3: tipo15.status3,
        s4: tipo15.status4,
        statusAno1: tipo15.statusAno1,
        statusAno2: tipo15.statusAno2,
        statusAno3: tipo15.statusAno3,
        statusAno4: tipo15.statusAno4,
        statusAno5: tipo15.statusAno5
      },
      {
        id: tipo16.id,
        s1: tipo16.status1,
        s2: tipo16.status2,
        s3: tipo16.status3,
        s4: tipo16.status4,
        statusAno1: tipo16.statusAno1,
        statusAno2: tipo16.statusAno2,
        statusAno3: tipo16.statusAno3,
        statusAno4: tipo16.statusAno4,
        statusAno5: tipo16.statusAno5
      },
      {
        id: tipo17.id,
        s1: tipo17.status1,
        s2: tipo17.status2,
        s3: tipo17.status3,
        s4: tipo17.status4,
        statusAno1: tipo17.statusAno1,
        statusAno2: tipo17.statusAno2,
        statusAno3: tipo17.statusAno3,
        statusAno4: tipo17.statusAno4,
        statusAno5: tipo17.statusAno5
      },
      {
        id: tipo18.id,
        s1: tipo18.status1,
        s2: tipo18.status2,
        s3: tipo18.status3,
        s4: tipo18.status4,
        statusAno1: tipo18.statusAno1,
        statusAno2: tipo18.statusAno2,
        statusAno3: tipo18.statusAno3,
        statusAno4: tipo18.statusAno4,
        statusAno5: tipo18.statusAno5
      },
      {
        id: tipo21.id,
        s1: tipo21.status1,
        s2: tipo21.status2,
        s3: tipo21.status3,
        s4: tipo21.status4,
        statusAno1: tipo21.statusAno1,
        statusAno2: tipo21.statusAno2,
        statusAno3: tipo21.statusAno3,
        statusAno4: tipo21.statusAno4,
        statusAno5: tipo21.statusAno5
      },
      {
        id: tipo23.id,
        s1: tipo23.status1,
        s2: tipo23.status2,
        s3: tipo23.status3,
        s4: tipo23.status4,
        statusAno1: tipo23.statusAno1,
        statusAno2: tipo23.statusAno2,
        statusAno3: tipo23.statusAno3,
        statusAno4: tipo23.statusAno4,
        statusAno5: tipo23.statusAno5
      },
      {
        id: tipo24.id,
        s1: tipo24.status1,
        s2: tipo24.status2,
        s3: tipo24.status3,
        s4: tipo24.status4,
        statusAno1: tipo24.statusAno1,
        statusAno2: tipo24.statusAno2,
        statusAno3: tipo24.statusAno3,
        statusAno4: tipo24.statusAno4,
        statusAno5: tipo24.statusAno5
      },
      {
        id: tipo25.id,
        s1: tipo25.status1,
        s2: tipo25.status2,
        s3: tipo25.status3,
        s4: tipo25.status4,
        statusAno1: tipo25.statusAno1,
        statusAno2: tipo25.statusAno2,
        statusAno3: tipo25.statusAno3,
        statusAno4: tipo25.statusAno4,
        statusAno5: tipo25.statusAno5
      },
      {
        id: tipo26.id,
        s1: tipo26.status1,
        s2: tipo26.status2,
        s3: tipo26.status3,
        s4: tipo26.status4,
        statusAno1: tipo26.statusAno1,
        statusAno2: tipo26.statusAno2,
        statusAno3: tipo26.statusAno3,
        statusAno4: tipo26.statusAno4,
        statusAno5: tipo26.statusAno5
      },
      {
        id: tipo27.id,
        s1: tipo27.status1,
        s2: tipo27.status2,
        s3: tipo27.status3,
        s4: tipo27.status4,
        statusAno1: tipo27.statusAno1,
        statusAno2: tipo27.statusAno2,
        statusAno3: tipo27.statusAno3,
        statusAno4: tipo27.statusAno4,
        statusAno5: tipo27.statusAno5
      },
      {
        id: tipo29.id,
        s1: tipo29.status1,
        s2: tipo29.status2,
        s3: tipo29.status3,
        s4: tipo29.status4,
        statusAno1: tipo29.statusAno1,
        statusAno2: tipo29.statusAno2,
        statusAno3: tipo29.statusAno3,
        statusAno4: tipo29.statusAno4,
        statusAno5: tipo29.statusAno5
      },
      {
        id: tipo31.id,
        s1: tipo31.status1,
        s2: tipo31.status2,
        s3: tipo31.status3,
        s4: tipo31.status4,
        statusAno1: tipo31.statusAno1,
        statusAno2: tipo31.statusAno2,
        statusAno3: tipo31.statusAno3,
        statusAno4: tipo31.statusAno4,
        statusAno5: tipo31.statusAno5
      },
      {
        id: tipo32.id,
        s1: tipo32.status1,
        s2: tipo32.status2,
        s3: tipo32.status3,
        s4: tipo32.status4,
        statusAno1: tipo32.statusAno1,
        statusAno2: tipo32.statusAno2,
        statusAno3: tipo32.statusAno3,
        statusAno4: tipo32.statusAno4,
        statusAno5: tipo32.statusAno5
      },
      {
        id: tipo33.id,
        s1: tipo33.status1,
        s2: tipo33.status2,
        s3: tipo33.status3,
        s4: tipo33.status4,
        statusAno1: tipo33.statusAno1,
        statusAno2: tipo33.statusAno2,
        statusAno3: tipo33.statusAno3,
        statusAno4: tipo33.statusAno4,
        statusAno5: tipo33.statusAno5
      },
      {
        id: tipo34.id,
        s1: tipo34.status1,
        s2: tipo34.status2,
        s3: tipo34.status3,
        s4: tipo34.status4,
        statusAno1: tipo34.statusAno1,
        statusAno2: tipo34.statusAno2,
        statusAno3: tipo34.statusAno3,
        statusAno4: tipo34.statusAno4,
        statusAno5: tipo34.statusAno5
      }
    ]

    await api.put("/editar-multiplos-status-tipos-upload", {
      tiposUpload: _tiposUpload
    })

    toast.success("Todos os status alterados com sucesso!", {
      position: toast.POSITION.TOP_CENTER
    })
  }

  async function salvarTudoSN() {
    const _tiposUpload = [
      {
        id: tipo29.id,
        status1: tipo29.status1,
        status2: tipo29.status2,
        status3: tipo29.status3,
        status4: tipo29.status4,
        status5: tipo29.status5,
        status6: tipo29.status6,
        status7: tipo29.status7,
        status8: tipo29.status8,
        status9: tipo29.status9,
        status10: tipo29.status10,
        status11: tipo29.status11,
        status12: tipo29.status12
      },
      {
        id: tipo31.id,
        statusAno1: tipo31.statusAno1,
        statusAno2: tipo31.statusAno2,
        statusAno3: tipo31.statusAno3,
        statusAno4: tipo31.statusAno4,
        statusAno5: tipo31.statusAno5
      },
      {
        id: tipo32.id,
        status1: tipo32.status1,
        status2: tipo32.status2,
        status3: tipo32.status3,
        status4: tipo32.status4,
        status5: tipo32.status5,
        status6: tipo32.status6,
        status7: tipo32.status7,
        status8: tipo32.status8,
        status9: tipo32.status9,
        status10: tipo32.status10,
        status11: tipo32.status11,
        status12: tipo32.status12
      }
    ]

    await api.put("/editar-multiplos-status-esn-tipos-upload", {
      tiposUpload: _tiposUpload
    })

    toast.success("Todos os status alterados com sucesso!", {
      position: toast.POSITION.TOP_CENTER
    })
  }
  const data = [
    {
      arquivos: "---",
      status1: colunas[0],
      status2: colunas[1],
      status3: colunas[2]
    },
    {
      arquivos: " Solicitações Complementares",
      status1: tipo1.status1,
      status2: tipo1.status2,
      status3: tipo1.status3
    },
    {
      arquivos: " Planilhas memórias de cálculo Pis/Cofins - Sintética",
      status1: tipo2.status1,
      status2: tipo2.status2,
      status3: tipo2.status3
    },
    {
      arquivos: " Planilhas memórias de cálculo Pis/Cofins - Analítica",
      status1: tipo3.status1,
      status2: tipo3.status2,
      status3: tipo3.status3
    },
    {
      arquivos:
        " Memória de cálculo/Rascunho do ICMS, ICMS ST e ICMS Antecipação",
      status1: tipo4.status1,
      status2: tipo4.status2,
      status3: tipo4.status3
    },
    {
      arquivos: " DCTFs",
      status1: tipo5.status1,
      status2: tipo5.status2,
      status3: tipo5.status3
    },
    {
      arquivos: " Balancetes Contábeis",
      status1: tipo6.status1,
      status2: tipo6.status2,
      status3: tipo6.status3
    },
    {
      arquivos: " Livros Razões Contábeis",
      status1: tipo7.status1,
      status2: tipo7.status2,
      status3: tipo7.status3
    },
    {
      arquivos: " SPED Fiscal",
      status1: tipo9.status1,
      status2: tipo9.status2,
      status3: tipo9.status3
    },
    {
      arquivos: " EFD Contribuições",
      status1: tipo10.status1,
      status2: tipo10.status2,
      status3: tipo10.status3
    },
    {
      arquivos: " CIAP - Controle de Crédito do ICMS do Ativo Permanente",
      status1: tipo12.status1,
      status2: tipo12.status2,
      status3: tipo12.status3
    },
    {
      arquivos:
        " Tabela de Incidência do INSS sobre os eventos da folha de pagamentos (Contribuições Previdenciárias)",
      status1: tipo23.status1,
      status2: tipo23.status2,
      status3: tipo23.status3
    },
    {
      arquivos: " XMLs das NF-es e CT-es de entradas",
      status1: tipo29.status1,
      status2: tipo29.status2,
      status3: tipo29.status3
    },
    {
      arquivos: " XMLs das NF-es e CT-es de saídas",
      status1: tipo32.status1,
      status2: tipo32.status2,
      status3: tipo32.status3
    },
    {
      arquivos:
        " Guia ou Declaração de Apuração do ICMS (GIA; DMA; DAM; DAPI; GIME; GIAM)",
      status1: tipo33.status1,
      status2: tipo33.status2,
      status3: tipo33.status3
    },
    {
      arquivos: "-----------",
      status1: "-----------",
      status2: "-----------",
      status3: "-----------"
    },

    {
      arquivos: "Leitura dos Status ---",
      status1: "0 --- Não Recebido",
      status2: "1 --- Recebido em Análise",
      status3: "2 --- Recebido"
    },
    {
      arquivos: "Outros ---",
      status1: "3 --- Arquivos não existentes",
      status2: "4 --- Recebido parcialmente",
      status3: ""
    }
  ]

  const wscols = [
    { wch: Math.max(...data.map((data) => data.arquivos)) },
    { wch: Math.max(...data.map((data) => data.status1)) },
    { wch: Math.max(...data.map((data) => data.status2)) },
    { wch: Math.max(...data.map((data) => data.status3)) }
  ]

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title={`Cliente - ${razaoSocial}`} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />

          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Container sx={{ mt: 4, mb: 4 }}>
                  <center>
                    <span className="submenu">Diagnóstico</span>
                  </center>
                  <hr />
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">
                            <ExportCSV
                              csvData={data}
                              fileName={`Checklist_${cnpj}`}
                              wscols={wscols}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </Paper>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        {simples === true ? (
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <span className="title">Arquivos</span>
                              </TableCell>
                              {colunasSN.map((colSN, index) => (
                                <TableCell key={index} align="center">
                                  {formataData(colSN)}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                        ) : (
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <span className="title">Arquivos</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="title">
                                  {colunas.length === 0 ? "-" : colunas[0]}{" "}
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="title">
                                  {colunas.length === 0 ? "-" : colunas[1]}
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="title">
                                  {colunas.length === 0 ? "-" : colunas[2]}
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="title">
                                  {colunas.length === 0 ? "-" : colunas[3]}
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="title">
                                  {colunas.length === 0 ? "-" : colunas[4]}
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        )}
                        {simples === true ? (
                          <TableBody>
                            {/* TIPO29 */}
                            {tipo29.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  XMLs das NF-es e CT-es de entradas
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo29.status1 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>

                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status2"
                                    value={tipo29.status2 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>

                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status3"
                                    value={tipo29.status3 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>

                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status4"
                                    value={tipo29.status4 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status5"
                                    value={tipo29.status5 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status6"
                                    value={tipo29.status6 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status7"
                                    value={tipo29.status7 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status8"
                                    value={tipo29.status8 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status9"
                                    value={tipo29.status9 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status10"
                                    value={tipo29.status10 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status11"
                                    value={tipo29.status11 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status12"
                                    value={tipo29.status12 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvarSN(
                                        tipo29.id,
                                        tipo29.status1,
                                        tipo29.status2,
                                        tipo29.status3,
                                        tipo29.status4,
                                        tipo29.status4,
                                        tipo29.status5,
                                        tipo29.status6,
                                        tipo29.status7,
                                        tipo29.status8,
                                        tipo29.status9,
                                        tipo29.status10,
                                        tipo29.status11,
                                        tipo29.status12
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO32 */}
                            {tipo32.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  XMLs das NF-es e CT-es de saídas
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo32.status1 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>

                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status2"
                                    value={tipo32.status2 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>

                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status3"
                                    value={tipo32.status3 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>

                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status4"
                                    value={tipo32.status4 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status5"
                                    value={tipo32.status5 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status6"
                                    value={tipo32.status6 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status7"
                                    value={tipo32.status7 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status8"
                                    value={tipo32.status8 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status9"
                                    value={tipo32.status9 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status10"
                                    value={tipo32.status10 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status11"
                                    value={tipo32.status11 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status12"
                                    value={tipo32.status12 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvarSN(
                                        tipo32.id,
                                        tipo32.status1,
                                        tipo32.status2,
                                        tipo32.status3,
                                        tipo32.status4,
                                        tipo32.status5,
                                        tipo32.status6,
                                        tipo32.status7,
                                        tipo32.status8,
                                        tipo32.status9,
                                        tipo32.status10,
                                        tipo32.status11,
                                        tipo32.status12
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        ) : (
                          <TableBody>
                            {/* TIPO1 */}
                            {tipo1.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Solicitações Complementares
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo1.status1 ?? ""}
                                    onChange={setarTipo1}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo1.status2 ?? ""}
                                      onChange={setarTipo1}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  " "
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo1.status3 ?? ""}
                                      onChange={setarTipo1}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo1.status4 ?? ""}
                                      onChange={setarTipo1}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo1.id,
                                        tipo1.status1,
                                        tipo1.status2,
                                        tipo1.status3
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO2 */}
                            {tipo2.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Planilhas memórias de cálculo Pis/Cofins -
                                  Sintética
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo2.status1 ?? ""}
                                    onChange={setarTipo2}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo2.status2 ?? ""}
                                      onChange={setarTipo2}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo2.status3 ?? ""}
                                      onChange={setarTipo2}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo2.status4 ?? ""}
                                      onChange={setarTipo2}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo2.id,
                                        tipo2.status1,
                                        tipo2.status2,
                                        tipo2.status3,
                                        tipo2.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO3 */}
                            {tipo3.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Planilhas memórias de cálculo Pis/Cofins -
                                  Analítica
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo3.status1 ?? ""}
                                    onChange={setarTipo3}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo3.status2 ?? ""}
                                      onChange={setarTipo3}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo3.status3 ?? ""}
                                      onChange={setarTipo3}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo3.status4 ?? ""}
                                      onChange={setarTipo3}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo3.id,
                                        tipo3.status1,
                                        tipo3.status2,
                                        tipo3.status3,
                                        tipo3.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                            {/* TIPO4 */}
                            {tipo4.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Memória de cálculo/Rascunho do ICMS, ICMS ST e
                                  ICMS Antecipação
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo4.status1 ?? ""}
                                    onChange={setarTipo4}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo4.status2 ?? ""}
                                      onChange={setarTipo4}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo4.status3 ?? ""}
                                      onChange={setarTipo4}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo4.status4 ?? ""}
                                      onChange={setarTipo4}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo4.id,
                                        tipo4.status1,
                                        tipo4.status2,
                                        tipo4.status3,
                                        tipo4.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO5 */}
                            {tipo5.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  DCTFs
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo5.status1 ?? ""}
                                    onChange={setarTipo5}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo5.status2 ?? ""}
                                      onChange={setarTipo5}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo5.status3 ?? ""}
                                      onChange={setarTipo5}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo5.status4 ?? ""}
                                      onChange={setarTipo5}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo5.id,
                                        tipo5.status1,
                                        tipo5.status2,
                                        tipo5.status3,
                                        tipo5.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                            {/* TIPO6 */}
                            {tipo6.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Balancetes Contábeis
                                </TableCell>

                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo6.status1 ?? ""}
                                    onChange={setarTipo6}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>

                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo6.status2 ?? ""}
                                      onChange={setarTipo6}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo6.status3 ?? ""}
                                      onChange={setarTipo6}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo6.status4 ?? ""}
                                      onChange={setarTipo6}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo6.id,
                                        tipo6.status1,
                                        tipo6.status2,
                                        tipo6.status3,
                                        tipo6.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO7 */}
                            {tipo7.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Livros Razões Contábeis
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo7.status1 ?? ""}
                                    onChange={setarTipo7}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo7.status2 ?? ""}
                                      onChange={setarTipo7}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo7.status3 ?? ""}
                                      onChange={setarTipo7}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo7.status4 ?? ""}
                                      onChange={setarTipo7}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo7.id,
                                        tipo7.status1,
                                        tipo7.status2,
                                        tipo7.status3,
                                        tipo7.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO9 */}
                            {tipo9.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  SPED Fiscal
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo9.status1 ?? ""}
                                    onChange={setarTipo9}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo9.status2 ?? ""}
                                      onChange={setarTipo9}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo9.status3 ?? ""}
                                      onChange={setarTipo9}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo9.status4 ?? ""}
                                      onChange={setarTipo9}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo9.id,
                                        tipo9.status1,
                                        tipo9.status2,
                                        tipo9.status3,
                                        tipo9.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO10 */}
                            {tipo10.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  EFD Contribuições
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo10.status1 ?? ""}
                                    onChange={setarTipo10}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo10.status2 ?? ""}
                                      onChange={setarTipo10}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo10.status3 ?? ""}
                                      onChange={setarTipo10}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo10.status4 ?? ""}
                                      onChange={setarTipo10}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo10.id,
                                        tipo10.status1,
                                        tipo10.status2,
                                        tipo10.status3,
                                        tipo10.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO2 */}
                            {tipo12.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  CIAP - Controle de Crédito do ICMS do Ativo
                                  Permanente
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo12.status1 ?? ""}
                                    onChange={setarTipo12}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo12.status2 ?? ""}
                                      onChange={setarTipo12}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo12.status3 ?? ""}
                                      onChange={setarTipo12}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo12.status4 ?? ""}
                                      onChange={setarTipo12}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo12.id,
                                        tipo12.status1,
                                        tipo12.status2,
                                        tipo12.status3,
                                        tipo12.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO3 */}
                            {tipo23.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Tabela de Incidência do INSS sobre os eventos
                                  da folha de pagamentos (Contribuições
                                  Previdenciárias)
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo23.status1 ?? ""}
                                    onChange={setarTipo23}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo23.status2 ?? ""}
                                      onChange={setarTipo23}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo23.status3 ?? ""}
                                      onChange={setarTipo23}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo23.status4 ?? ""}
                                      onChange={setarTipo23}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo23.id,
                                        tipo23.status1,
                                        tipo23.status2,
                                        tipo23.status3,
                                        tipo23.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* tipo29 */}
                            {tipo29.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  XMLs das NF-es e CT-es de entradas
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo29.status1 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo29.status2 ?? ""}
                                      onChange={setarTipo29}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo29.status3 ?? ""}
                                      onChange={setarTipo29}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo29.status4 ?? ""}
                                      onChange={setarTipo29}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo29.id,
                                        tipo29.status1,
                                        tipo29.status2,
                                        tipo29.status3,
                                        tipo29.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {simples === false ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  XMLs das NF-es e CT-es de entradas
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo29.status1 ?? ""}
                                    onChange={setarTipo29}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo29.status2 ?? ""}
                                      onChange={setarTipo29}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo29.status3 ?? ""}
                                      onChange={setarTipo29}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo29.status4 ?? ""}
                                      onChange={setarTipo29}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo29.id,
                                        tipo29.status1,
                                        tipo29.status2,
                                        tipo29.status3,
                                        tipo29.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO32 */}
                            {tipo32.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  XMLs das NF-es e CT-es de saídas
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo32.status1 ?? ""}
                                    onChange={setarTipo32}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo32.status2 ?? ""}
                                      onChange={setarTipo32}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo32.status3 ?? ""}
                                      onChange={setarTipo32}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo32.status4 ?? ""}
                                      onChange={setarTipo32}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo32.id,
                                        tipo32.status1,
                                        tipo32.status2,
                                        tipo32.status3,
                                        tipo32.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* TIPO33 */}
                            {tipo33.length === 0 ? (
                              <></>
                            ) : (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Guia ou Declaração de Apuração do ICMS (GIA;
                                  DMA; DAM; DAPI; GIME; GIAM)
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    variant="standard"
                                    name="status1"
                                    value={tipo33.status1 ?? ""}
                                    onChange={setarTipo33}
                                  >
                                    <MenuItem value={0}>
                                      <CancelIcon sx={{ color: "red" }} />
                                    </MenuItem>
                                    <MenuItem value={1}>
                                      <ErrorIcon sx={{ color: "gold" }} />
                                    </MenuItem>
                                    <MenuItem value={2}>
                                      <CheckCircleIcon
                                        sx={{ color: "green" }}
                                      />
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <RectangleIcon sx={{ color: "black" }} />
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <FormatItalicIcon
                                        sx={{ color: "black" }}
                                      />
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                                {colunas.length > 1 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status2"
                                      value={tipo33.status2 ?? ""}
                                      onChange={setarTipo33}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length > 2 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status3"
                                      value={tipo33.status3 ?? ""}
                                      onChange={setarTipo33}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                {colunas.length === 4 ? (
                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status4"
                                      value={tipo33.status4 ?? ""}
                                      onChange={setarTipo33}
                                    >
                                      <MenuItem value={0}>
                                        <CancelIcon sx={{ color: "red" }} />
                                      </MenuItem>
                                      <MenuItem value={1}>
                                        <ErrorIcon sx={{ color: "gold" }} />
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        <CheckCircleIcon
                                          sx={{ color: "green" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        <RectangleIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                      <MenuItem value={4}>
                                        <FormatItalicIcon
                                          sx={{ color: "black" }}
                                        />
                                      </MenuItem>
                                    </Select>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      salvar(
                                        tipo33.id,
                                        tipo33.status1,
                                        tipo33.status2,
                                        tipo33.status3,
                                        tipo33.status4
                                      )
                                    }
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* {tipo34.length === 0 ? <></> :
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    Balancete Contábil (último não encerrado)
                                  </TableCell>


                                  <TableCell align="center">
                                    <Select
                                      variant="standard"
                                      name="status1"
                                      value={tipo34.status1 ?? ""}
                                      onChange={setarTipo34}
                                    >
                                      <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                                      <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                                      <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                                      <MenuItem value={3}><RectangleIcon sx={{ color: 'black' }} /></MenuItem>
                                      <MenuItem value={4}><FormatItalicIcon sx={{ color: 'black' }} /></MenuItem>
                                    </Select>
                                  </TableCell>

                                  {colunas.length > 1 ?
                                    <TableCell align="center">
                                      <Select
                                        variant="standard"
                                        name="status2"
                                        value={tipo34.status2 ?? ""}
                                        onChange={setarTipo34}
                                      >
                                        <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                                        <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                                        <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                                        <MenuItem value={3}><RectangleIcon sx={{ color: 'black' }} /></MenuItem>
                                        <MenuItem value={4}><FormatItalicIcon sx={{ color: 'black' }} /></MenuItem>
                                      </Select>
                                    </TableCell>
                                    : ""}
                                  {colunas.length > 2 ?
                                    <TableCell align="center">
                                      <Select
                                        variant="standard"
                                        name="status3"
                                        value={tipo34.status3 ?? ""}
                                        onChange={setarTipo34}
                                      >
                                        <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                                        <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                                        <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                                        <MenuItem value={3}><RectangleIcon sx={{ color: 'black' }} /></MenuItem>
                                        <MenuItem value={4}><FormatItalicIcon sx={{ color: 'black' }} /></MenuItem>
                                      </Select>
                                    </TableCell>
                                    : ""}
                                  {colunas.length === 4 ?
                                    <TableCell align="center">
                                      <Select
                                        variant="standard"
                                        name="status4"
                                        value={tipo34.status4 ?? ""}
                                        onChange={setarTipo34}
                                      >
                                        <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                                        <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                                        <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                                        <MenuItem value={3}><RectangleIcon sx={{ color: 'black' }} /></MenuItem>
                                        <MenuItem value={4}><FormatItalicIcon sx={{ color: 'black' }} /></MenuItem>
                                      </Select>
                                    </TableCell>
                                    : ""}
                                  <TableCell align="center">
                                    <IconButton onClick={() => salvar(tipo34.id, tipo34.status1, tipo34.status2, tipo34.status3, tipo34.status4)} color="primary" aria-label="upload picture" component="span">
                                      <SaveIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              } */}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Paper>

                  <hr />
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <span className="title">Arquivos</span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="title">
                                {anos.length === 0 ? "-" : anos[0]}{" "}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="title">
                                {anos.length === 0 ? "-" : anos[1]}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="title">
                                {anos.length === 0 ? "-" : anos[2]}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="title">
                                {anos.length === 0 ? "-" : anos[3]}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="title">
                                {anos.length === 0 ? "-" : anos[4]}
                              </span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* TIPO9 */}
                          {tipo15.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                E-CAC - Fontes Pagadoras
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo15.statusAno1 ?? ""}
                                  onChange={setarTipo15}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo15.statusAno2 ?? ""}
                                  onChange={setarTipo15}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo15.statusAno3 ?? ""}
                                  onChange={setarTipo15}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo15.statusAno4 ?? ""}
                                  onChange={setarTipo15}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo15.statusAno5 ?? ""}
                                  onChange={setarTipo15}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo15.id,
                                      tipo15.statusAno1,
                                      tipo15.statusAno2,
                                      tipo15.statusAno3,
                                      tipo15.statusAno4,
                                      tipo15.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {tipo13.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                E-CAC - Comprovante de Arrecadação dos Tributos
                                IRPJ e CSLL
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo13.statusAno1 ?? ""}
                                  onChange={setarTipo13}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo13.statusAno2 ?? ""}
                                  onChange={setarTipo13}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo13.statusAno3 ?? ""}
                                  onChange={setarTipo13}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo13.statusAno4 ?? ""}
                                  onChange={setarTipo13}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo13.statusAno5 ?? ""}
                                  onChange={setarTipo13}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo13.id,
                                      tipo13.statusAno1,
                                      tipo13.statusAno2,
                                      tipo13.statusAno3,
                                      tipo13.statusAno4,
                                      tipo13.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {tipo8.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                SPED Contábil
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo8.statusAno1 ?? ""}
                                  onChange={setarTipo8}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo8.statusAno2 ?? ""}
                                  onChange={setarTipo8}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo8.statusAno3 ?? ""}
                                  onChange={setarTipo8}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo8.statusAno4 ?? ""}
                                  onChange={setarTipo8}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo8.statusAno5 ?? ""}
                                  onChange={setarTipo8}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo8.id,
                                      tipo8.statusAno1,
                                      tipo8.statusAno2,
                                      tipo8.statusAno3,
                                      tipo8.statusAno4,
                                      tipo8.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {tipo14.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                ECF
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo14.statusAno1 ?? ""}
                                  onChange={setarTipo14}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo14.statusAno2 ?? ""}
                                  onChange={setarTipo14}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo14.statusAno3 ?? ""}
                                  onChange={setarTipo14}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo14.statusAno4 ?? ""}
                                  onChange={setarTipo14}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo14.statusAno5 ?? ""}
                                  onChange={setarTipo14}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo14.id,
                                      tipo14.statusAno1,
                                      tipo14.statusAno2,
                                      tipo14.statusAno3,
                                      tipo14.statusAno4,
                                      tipo14.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {tipo17.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                Memórias de Cálculo do IRPJ e CSLL
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo17.statusAno1 ?? ""}
                                  onChange={setarTipo17}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo17.statusAno2 ?? ""}
                                  onChange={setarTipo17}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo17.statusAno3 ?? ""}
                                  onChange={setarTipo17}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo17.statusAno4 ?? ""}
                                  onChange={setarTipo17}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo17.statusAno5 ?? ""}
                                  onChange={setarTipo17}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo17.id,
                                      tipo17.statusAno1,
                                      tipo17.statusAno2,
                                      tipo17.statusAno3,
                                      tipo17.statusAno4,
                                      tipo17.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {tipo21.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                Print da FAP
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo21.statusAno1 ?? ""}
                                  onChange={setarTipo21}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo21.statusAno2 ?? ""}
                                  onChange={setarTipo21}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo21.statusAno3 ?? ""}
                                  onChange={setarTipo21}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo21.statusAno4 ?? ""}
                                  onChange={setarTipo21}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo21.statusAno5 ?? ""}
                                  onChange={setarTipo21}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo21.id,
                                      tipo21.statusAno1,
                                      tipo21.statusAno2,
                                      tipo21.statusAno3,
                                      tipo21.statusAno4,
                                      tipo21.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                          {tipo24.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                Memórias de Cálculo de CPRB/Desoneração
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo24.statusAno1 ?? ""}
                                  onChange={setarTipo24}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo24.statusAno2 ?? ""}
                                  onChange={setarTipo24}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo24.statusAno3 ?? ""}
                                  onChange={setarTipo24}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo24.statusAno4 ?? ""}
                                  onChange={setarTipo24}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo24.statusAno5 ?? ""}
                                  onChange={setarTipo24}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo24.id,
                                      tipo24.statusAno1,
                                      tipo24.statusAno2,
                                      tipo24.statusAno3,
                                      tipo24.statusAno4,
                                      tipo24.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {tipo27.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                DCTFWEB
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo27.statusAno1 ?? ""}
                                  onChange={setarTipo27}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo27.statusAno2 ?? ""}
                                  onChange={setarTipo27}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo27.statusAno3 ?? ""}
                                  onChange={setarTipo27}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo27.statusAno4 ?? ""}
                                  onChange={setarTipo27}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo27.statusAno5 ?? ""}
                                  onChange={setarTipo27}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo27.id,
                                      tipo27.statusAno1,
                                      tipo27.statusAno2,
                                      tipo27.statusAno3,
                                      tipo27.statusAno4,
                                      tipo27.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {tipo26.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                DARF
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo26.statusAno1 ?? ""}
                                  onChange={setarTipo26}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo26.statusAno2 ?? ""}
                                  onChange={setarTipo26}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo26.statusAno3 ?? ""}
                                  onChange={setarTipo26}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo26.statusAno4 ?? ""}
                                  onChange={setarTipo26}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo26.statusAno5 ?? ""}
                                  onChange={setarTipo26}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo26.id,
                                      tipo26.statusAno1,
                                      tipo26.statusAno2,
                                      tipo26.statusAno3,
                                      tipo26.statusAno4,
                                      tipo26.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                          {tipo31.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                DAS
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno1"
                                  value={tipo31.statusAno1 ?? ""}
                                  onChange={setarTipo31}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno2"
                                  value={tipo31.statusAno2 ?? ""}
                                  onChange={setarTipo31}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno3"
                                  value={tipo31.statusAno3 ?? ""}
                                  onChange={setarTipo31}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno4"
                                  value={tipo31.statusAno4 ?? ""}
                                  onChange={setarTipo31}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="statusAno5"
                                  value={tipo31.statusAno5 ?? ""}
                                  onChange={setarTipo31}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvarAno(
                                      tipo31.id,
                                      tipo31.statusAno1,
                                      tipo31.statusAno2,
                                      tipo31.statusAno3,
                                      tipo31.statusAno4,
                                      tipo31.statusAno5
                                    )
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                  <hr />
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        {simples === true ? (
                          " "
                        ) : (
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <span className="title-arquivo">Arquivos</span>
                              </TableCell>

                              <TableCell align="center">
                                <span className="title">Status</span>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                        )}

                        <TableBody>
                          {/* TIPO34 */}
                          {tipo34.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                Balancete Contábil (último não encerrado)
                              </TableCell>

                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="status1"
                                  value={tipo34.status1 ?? ""}
                                  onChange={setarTipo34}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell />

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvar(tipo34.id, tipo34.status1)
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {/* TIPO16 */}
                          {tipo16.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                Bens do ativo imobilizado
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="status1"
                                  value={tipo16.status1 ?? ""}
                                  onChange={setarTipo16}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell />

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvar(tipo16.id, tipo16.status1)
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {/* TIPO18 */}
                          {tipo18.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                Créditos Extemporâneos
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="status1"
                                  value={tipo18.status1 ?? ""}
                                  onChange={setarTipo18}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell />

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvar(tipo18.id, tipo18.status1)
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}

                          {/* TIPO25 */}
                          {tipo25.length === 0 ? (
                            <></>
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                Questionário Prévio
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  variant="standard"
                                  name="status1"
                                  value={tipo25.status1 ?? ""}
                                  onChange={setarTipo25}
                                >
                                  <MenuItem value={0}>
                                    <CancelIcon sx={{ color: "red" }} />
                                  </MenuItem>
                                  <MenuItem value={1}>
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    <RectangleIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  </MenuItem>
                                </Select>
                              </TableCell>

                              <TableCell />

                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    salvar(tipo25.id, tipo25.status1)
                                  }
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SaveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  {simples === true ? (
                    <Button
                      sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                      variant="contained"
                      onClick={() => salvarTudoSN(tiposUpload)}
                    >
                      Salvar os Status Alterados
                    </Button>
                  ) : (
                    <Button
                      sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                      variant="contained"
                      onClick={() => salvarTudo(tiposUpload)}
                    >
                      Salvar os Status Alterados
                    </Button>
                  )}
                </Container>
              )}
            </Grid>
          </Container>

          <Grid>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Container sx={{ mt: 4, mb: 4 }}>
                <Container sx={{ mt: 4, mb: 4 }}>
                  <Paper
                    sx={{ width: "100%", overflow: "hidden", padding: "24px" }}
                  >
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <InputLabel id="select-tipo">
                          Selecione um tipo de arquivo
                        </InputLabel>
                        <Select
                          labelId="select-tipo"
                          variant="standard"
                          name="tipo"
                          value={tipo.tipo ?? ""}
                          onChange={setarTipo}
                        >
                          {tipo10.tipo === 10 ? (
                            <MenuItem value={10}>
                              <span className="title">EFD Contribuições</span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo19.tipo === 19 ? (
                            <MenuItem value={19}>
                              <span className="title">
                                Resumo da folha de pagamento
                              </span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo20.tipo === 20 ? (
                            <MenuItem value={20}>
                              <span className="title">
                                Comprovante de declaração a recolher do INSS
                                (Rubrica)
                              </span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo21.tipo === 21 ? (
                            <MenuItem value={21}>
                              <span className="title">Print da FAP</span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo22.tipo === 22 ? (
                            <MenuItem value={22}>
                              <span className="title">GPS</span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo23.tipo === 23 ? (
                            <MenuItem value={23}>
                              <span className="title">
                                Tabela de Incidência do INSS sobre os eventos da
                                folha de pagamentos (Contribuições
                                Previdenciárias){" "}
                              </span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo24.tipo === 24 ? (
                            <MenuItem value={24}>
                              <span className="title">
                                Memórias de Cálculo de CPRB/Desoneração
                              </span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo26.tipo === 26 ? (
                            <MenuItem value={26}>
                              <span className="title">DARF</span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo27.tipo === 27 ? (
                            <MenuItem value={27}>
                              <span className="title">DCTFWEB</span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo30.tipo === 30 ? (
                            <MenuItem value={30}>
                              <span className="title">
                                Extrato Simples Nacional
                              </span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {tipo31.tipo === 31 ? (
                            <MenuItem value={31}>
                              <span className="title">DAS</span>
                            </MenuItem>
                          ) : (
                            ""
                          )}
                        </Select>
                      </Table>
                      {tipo.tipo === 10 ? <Up10 /> : <></>}

                      {tipo.tipo === 16 ? <Up16 /> : <></>}

                      {tipo.tipo === 18 ? <Up18 /> : <></>}

                      {tipo.tipo === 19 ? <Up19 /> : <></>}

                      {tipo.tipo === 20 ? <Up20 /> : <></>}

                      {tipo.tipo === 21 ? <Up21 /> : <></>}

                      {tipo.tipo === 22 ? <Up22 /> : <></>}

                      {tipo.tipo === 23 ? <Up23 /> : <></>}

                      {tipo.tipo === 24 ? <Up24 /> : <></>}

                      {tipo.tipo === 26 ? <Up26 /> : <></>}

                      {tipo.tipo === 27 ? <Up27 /> : <></>}

                      {tipo.tipo === 29 ? <Up29 /> : <></>}

                      {tipo.tipo === 30 ? <Up30 /> : <></>}

                      {tipo.tipo === 31 ? <Up31 /> : <></>}
                    </TableContainer>
                  </Paper>
                </Container>
              </Container>
            )}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <Diagnostico />
}
