import { useState, useEffect, createContext } from "react"

import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import api from "../Config/api"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()

  const [user, setUser] = useState(null)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const recuperaUser = localStorage.getItem("user")

    if (recuperaUser) {
      setUser(JSON.parse(recuperaUser))
    }
    setLoading(false)
  }, [])

  const login = async (cnpjLogin, senha) => {
    await api
      .post("/login", { cnpjLogin, senha })
      .then((res) => {
        const token = res.data.token
        const loggedUser = res.data.cliente
        const cnpj = res.data.cliente.cnpj

        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))
        localStorage.setItem("cnpj", cnpj)

        api.defaults.headers.Authorization = `Bearer ${token}`

        navigate("/dashboard")
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.data.necessarioMudarSenha === true
        ) {
          navigate("/novasenha")
        }

        if (error.response.status === 401) {
          toast.error("Login ou Senha incorretos, Tente Novamente!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
        if (error.response.status === 400) {
          toast.error("Login ou Senha incorretos, Tente Novamente!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
        if (error.response.status === 500) {
          toast.warn("Campos obrigários", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const loginAdmin = async (email, senha) => {
    await api
      .post("/login-admin", { email, senha })
      .then((res) => {
        const token = res.data.token
        const loggedUser = res.data.debug

        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))

        api.defaults.headers.Authorization = `Bearer ${token}`

        navigate("/novoCliente")
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.data.necessarioMudarSenha === true
        ) {
          navigate("/novasenhaadmin")
        }
        if (error.response.status === 401) {
          loginAssociado(email, senha)
        }
        if (error.response.status === 400) {
          loginAssociado(email, senha)
        }
        if (error.response.status === 500) {
          toast.error("Login ou Senha incorretos, Tente Novamente!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const trocaSenhaCliente = async (cnpjLogin, senhaAntiga, senhaAtual) => {
    await api
      .post("/mudar-senha-cliente", { cnpjLogin, senhaAntiga, senhaAtual })
      .then((res) => {
        const token = res.data.token
        const loggedUser = res.data.cliente
        const cnpj = res.data.cnpj

        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))
        localStorage.setItem("cnpj", cnpj)

        api.defaults.headers.Authorization = `Bearer ${token}`

        navigate("/dashboard")
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.warn("Login ou Senha incorretos, Tente Novamente!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }

        if (
          error.response.status === 200 &&
          error.response.data.necessarioMudarSenha === false
        ) {
          toast.info("A senha já foi alterada, faça o login", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000
          })
          navigate("/")
        }
        if (error.response.status === 400) {
          toast.warn(
            "A senha deve conter 8 caracteres no mínimo, 1 Letra Maiúscula no mínimo, 1 Número no mínimo e 1 Símbolo no mínimo: $*&@#",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 8000
            }
          )
        }
        if (error.response.status === 500) {
          toast.error("Usuário não localizado", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const trocaSenhaAdmin = async (email, senhaAntiga, senhaNova) => {
    await api
      .post("/mudar-senha-admin", { email, senhaAntiga, senhaNova })
      .then((res) => {
        const token = res.data.token
        const loggedUser = res.data.adminAtualizarSenha
        console.log(loggedUser)
        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))

        api.defaults.headers.Authorization = `Bearer ${token}`

        navigate("/novoCliente")
      })
      .catch((error) => {
        if (error.response.status === 401) {
          trocaSenhaAssociado(email, senhaAntiga, senhaNova)
        }

        if (
          error.response.status === 200 &&
          error.response.data.necessarioMudarSenha === false
        ) {
          toast.info("A senha já foi alterada, faça o login", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000
          })
          navigate("/loginadmin")
        }
        if (error.response.status === 400) {
          trocaSenhaAssociado(email, senhaAntiga, senhaNova)
        }
        if (error.response.status === 500) {
          toast.error("Usuário não localizado", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const trocaSenhaAssociado = async (
    emailPrincipal,
    senhaAntiga,
    senhaAtual
  ) => {
    await api
      .post("/mudar-senha-associado", {
        emailPrincipal,
        senhaAntiga,
        senhaAtual
      })
      .then((res) => {
        const token = res.data.token
        const loggedUser = res.data.associado
        const id = res.data.associado._id

        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))
        localStorage.setItem("id", JSON.stringify(id))

        api.defaults.headers.Authorization = `Bearer ${token}`
        navigate(`/clientes-associado/` + id)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.warn("Login ou Senha incorretos, Tente Novamente!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }

        if (
          error.response.status === 200 &&
          error.response.data.necessarioMudarSenha === false
        ) {
          toast.info("A senha já foi alterada, faça o login", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000
          })
          navigate("/loginadmin")
        }
        if (error.response.status === 400) {
          toast.warn("Todos os campos são obrigatórios", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
        if (error.response.status === 500) {
          toast.error("Usuário não localizado", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const esqueceuSenhaCliente = async (cnpjLogin) => {
    await api
      .post("/recuperar-senha", { cnpjLogin })
      .then((res) => {
        const token = res.data.token
        const loggedUser = res.data.admin

        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))

        api.defaults.headers.Authorization = `Bearer ${token}`
        toast.success("Link enviado com sucesso!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000
        })
        navigate("/novasenha")
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.warn("Campo Obrigatório, confirme seu CNPJ", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const esqueceuSenhaAdmin = async (email) => {
    await api
      .post("/recuperar-senha-admin", { email })
      .then((res) => {
        const token = res.data.token
        const loggedUser = res.data.admin

        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))

        api.defaults.headers.Authorization = `Bearer ${token}`
        toast.success("Link enviado com sucesso!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000
        })
        navigate("/novasenhaadmin")
      })
      .catch((error) => {
        if (error.response.status === 400) {
          esqueceuSenhaAssociado(email)
        }
      })
  }

  const loginAssociado = async (emailPrincipal, senha) => {
    await api
      .post("/login-associado", { emailPrincipal, senha })
      .then((res) => {
        console.log(res.data)

        const id = res.data.associado._id
        const token = res.data.token
        const loggedUser = res.data.associado

        setUser(loggedUser)

        localStorage.setItem("user", JSON.stringify(loggedUser))
        localStorage.setItem("token", JSON.stringify(token))
        localStorage.setItem("id", id)

        api.defaults.headers.Authorization = `Bearer ${token}`
        navigate(`/clientes-associado/` + id)
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.data.necessarioMudarSenha === true
        ) {
          navigate("/novaSenhaAssociado")
        }
        if (error.response.status === 401) {
          toast.error("Login ou Senha incorretos, Tente Novamente!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
        if (error.response.status === 400) {
          toast.warn("Todos os campos são obrigatórios", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
        if (error.response.status === 500) {
          toast.error("Login ou Senha incorretos, Tente Novamente!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const esqueceuSenhaAssociado = async (emailPrincipal) => {
    await api
      .post("/recuperar-senha-associado", { emailPrincipal })
      .then((res) => {
        toast.success("Link enviado com sucesso!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000
        })
        navigate("/novasenhaadmin")
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.warn("Campo Obrigatório, confirme seu E-mail", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000
          })
        }
      })
  }

  const logout = () => {
    setUser(null)
    localStorage.clear()
    api.defaults.headers.Authorization = null
    navigate("/")
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        loading,
        login,
        loginAdmin,
        trocaSenhaCliente,
        trocaSenhaAdmin,
        esqueceuSenhaCliente,
        esqueceuSenhaAdmin,
        loginAssociado,
        trocaSenhaAssociado,
        esqueceuSenhaAssociado,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
