import React, { useState, useEffect, useContext } from "react"

import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

import Badge from "@mui/material/Badge"
import LogoutIcon from "@mui/icons-material/Logout"
import { Button, Input } from "reactstrap"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { mainListItems, secondaryListItems } from "./listItems"
import { AuthContext } from "../../Context/auth"
import api from "../../Config/api"

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

const mdTheme = createTheme()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: "white"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const NovoCliente = () => {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const { logout } = useContext(AuthContext)

  const [status1, setStatus1] = useState(0)
  const [status2, setStatus2] = useState(0)
  const [status3, setStatus3] = useState(0)
  const [status4, setStatus4] = useState(0)
  const [status5, setStatus5] = useState(0)
  const [status6, setStatus6] = useState(0)
  const [status7, setStatus7] = useState(0)
  const [status8, setStatus8] = useState(0)
  const [status9, setStatus9] = useState(0)
  const [status10, setStatus10] = useState(0)
  const [status12, setStatus12] = useState(0)
  const [status13, setStatus13] = useState(0)
  const [status14, setStatus14] = useState(0)
  const [status15, setStatus15] = useState(0)
  const [status16, setStatus16] = useState(0)
  const [status17, setStatus17] = useState(0)
  const [status18, setStatus18] = useState(0)
  const [status19, setStatus19] = useState(0)
  const [status20, setStatus20] = useState(0)
  const [status21, setStatus21] = useState(0)
  const [status22, setStatus22] = useState(0)
  const [status23, setStatus23] = useState(0)
  const [status24, setStatus24] = useState(0)
  const [status25, setStatus25] = useState(0)
  const [status26, setStatus26] = useState(0)
  const [status27, setStatus27] = useState(0)

  const [status29, setStatus29] = useState(0)
  const [status30, setStatus30] = useState(0)
  const [status31, setStatus31] = useState(0)

  const [dataLimite1, setDataLimite1] = useState("")
  const [dataLimite2, setDataLimite2] = useState("")
  const [dataLimite3, setDataLimite3] = useState("")
  const [dataLimite4, setDataLimite4] = useState("")
  const [dataLimite5, setDataLimite5] = useState("")
  const [dataLimite6, setDataLimite6] = useState("")
  const [dataLimite7, setDataLimite7] = useState("")
  const [dataLimite8, setDataLimite8] = useState("")
  const [dataLimite9, setDataLimite9] = useState("")
  const [dataLimite10, setDataLimite10] = useState("")

  const [dataLimite12, setDataLimite12] = useState("")
  const [dataLimite13, setDataLimite13] = useState("")
  const [dataLimite14, setDataLimite14] = useState("")
  const [dataLimite15, setDataLimite15] = useState("")
  const [dataLimite16, setDataLimite16] = useState("")
  const [dataLimite17, setDataLimite17] = useState("")
  const [dataLimite18, setDataLimite18] = useState("")
  const [dataLimite19, setDataLimite19] = useState("")
  const [dataLimite20, setDataLimite20] = useState("")
  const [dataLimite21, setDataLimite21] = useState("")
  const [dataLimite22, setDataLimite22] = useState("")
  const [dataLimite23, setDataLimite23] = useState("")
  const [dataLimite24, setDataLimite24] = useState("")
  const [dataLimite25, setDataLimite25] = useState("")
  const [dataLimite26, setDataLimite26] = useState("")
  const [dataLimite27, setDataLimite27] = useState("")

  const [dataLimite29, setDataLimite29] = useState("")
  const [dataLimite30, setDataLimite30] = useState("")
  const [dataLimite31, setDataLimite31] = useState("")

  const [obs1, setObs1] = useState("")
  const [obs2, setObs2] = useState("")
  const [obs3, setObs3] = useState("")
  const [obs4, setObs4] = useState("")
  const [obs5, setObs5] = useState("")
  const [obs6, setObs6] = useState("")
  const [obs7, setObs7] = useState("")
  const [obs8, setObs8] = useState("")
  const [obs9, setObs9] = useState("")
  const [obs10, setObs10] = useState("")
  const [obs12, setObs12] = useState("")
  const [obs13, setObs13] = useState("")
  const [obs14, setObs14] = useState("")
  const [obs15, setObs15] = useState("")
  const [obs16, setObs16] = useState("")
  const [obs17, setObs17] = useState("")
  const [obs18, setObs18] = useState("")
  const [obs19, setObs19] = useState("")
  const [obs20, setObs20] = useState("")
  const [obs21, setObs21] = useState("")
  const [obs22, setObs22] = useState("")
  const [obs23, setObs23] = useState("")
  const [obs24, setObs24] = useState("")
  const [obs25, setObs25] = useState("")
  const [obs26, setObs26] = useState("")
  const [obs27, setObs27] = useState("")

  const [obs29, setObs29] = useState("")
  const [obs30, setObs30] = useState("")
  const [obs31, setObs31] = useState("")

  const cnpj = localStorage.getItem("cnpj")
  const razaoSocial = localStorage.getItem("razaoSocial")
  const simples = localStorage.getItem("simples")

  const [_id, setIdCliente] = useState("")

  useEffect(() => {
    async function carregaCliente() {
      const dados = await api.post("/find-cliente-cnpj", { cnpj })
      setIdCliente(dados.data.cliente._id)
    }
    carregaCliente()
  }, [cnpj])

  /// //////////////////////////////// Funções UPLOAD tipos ////////////////////////////////////
  const addTipo1 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 1, dataLimite: dataLimite1, obs: obs1 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus1(1)
    }
  }

  const addTipo2 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 2, dataLimite: dataLimite2, obs: obs2 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus2(1)
    }
  }

  const addTipo3 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 3, dataLimite: dataLimite3, obs: obs3 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus3(1)
    }
  }

  const addTipo4 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 4, dataLimite: dataLimite4, obs: obs4 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus4(1)
    }
  }

  const addTipo5 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 5, dataLimite: dataLimite5, obs: obs5 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus5(1)
    }
  }

  const addTipo6 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 6, dataLimite: dataLimite6, obs: obs6 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus6(1)
    }
  }

  const addTipo7 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 7, dataLimite: dataLimite7, obs: obs7 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus7(1)
    }
  }

  const addTipo8 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 8, dataLimite: dataLimite8, obs: obs8 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus8(1)
    }
  }

  const addTipo9 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 9, dataLimite: dataLimite9, obs: obs9 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus9(1)
    }
  }

  const addTipo10 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 10, dataLimite: dataLimite10, obs: obs10 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus10(1)
    }
  }

  const addTipo12 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 12, dataLimite: dataLimite12, obs: obs12 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus12(1)
    }
  }

  const addTipo13 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 13, dataLimite: dataLimite13, obs: obs13 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus13(1)
    }
  }

  const addTipo14 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 14, dataLimite: dataLimite14, obs: obs14 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus14(1)
    }
  }

  const addTipo15 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 15, dataLimite: dataLimite15, obs: obs15 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus15(1)
    }
  }

  const addTipo16 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 16, dataLimite: dataLimite16, obs: obs16 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus16(1)
    }
  }

  const addTipo17 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 17, dataLimite: dataLimite17, obs: obs17 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus17(1)
    }
  }

  const addTipo18 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 18, dataLimite: dataLimite18, obs: obs18 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus18(1)
    }
  }

  const addTipo19 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 19, dataLimite: dataLimite19, obs: obs19 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus19(1)
    }
  }

  const addTipo20 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 20, dataLimite: dataLimite20, obs: obs20 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus20(1)
    }
  }

  const addTipo21 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 21, dataLimite: dataLimite21, obs: obs21 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus21(1)
    }
  }

  const addTipo22 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 22, dataLimite: dataLimite22, obs: obs22 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus22(1)
    }
  }

  const addTipo23 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 23, dataLimite: dataLimite23, obs: obs23 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus23(1)
    }
  }

  const addTipo24 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 24, dataLimite: dataLimite24, obs: obs24 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus24(1)
    }
  }

  const addTipo25 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 25, dataLimite: dataLimite25, obs: obs25 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus25(1)
    }
  }

  const addTipo26 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 26, dataLimite: dataLimite26, obs: obs26 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus26(1)
    }
  }

  const addTipo27 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 27, dataLimite: dataLimite27, obs: obs27 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus27(1)
    }
  }

  const addTipo29 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 29, dataLimite: dataLimite29, obs: obs29 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus29(1)
    }
  }

  const addTipo30 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 30, dataLimite: dataLimite30, obs: obs30 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus30(1)
    }
  }

  const addTipo31 = async (e) => {
    e.preventDefault()
    const tiposUpload = { _id, tipo: 31, dataLimite: dataLimite31, obs: obs31 }
    const add = await api.post("/tipos-uploads", tiposUpload)
    if (add) {
      setStatus31(1)
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px" // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Uploads - {razaoSocial}
            </Typography>
            <IconButton color="inherit" onClick={logout}>
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1]
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container maxWidth="xxl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* Quando for SIMPLES */}
                {simples === "true" ? (
                  <div>
                    <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
                      <Table
                        stickyHeader
                        sx={{ minWidth: 1200 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Tipo de Upload</StyledTableCell>
                            <StyledTableCell align="center">
                              Previsão de envio
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Observações
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Adicionar
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              27 - XMLs
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite29}
                                onChange={(e) =>
                                  setDataLimite29(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs29}
                                onChange={(e) => setObs29(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status29 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo29}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              28 - Extrato Simples Nacional
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite30}
                                onChange={(e) =>
                                  setDataLimite30(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs30}
                                onChange={(e) => setObs30(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status30 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo30}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              <p>29 - DAS</p>
                              <p>
                                Enviar apenas para os períodos sem informação de
                                pagamento no extrato
                              </p>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite31}
                                onChange={(e) =>
                                  setDataLimite31(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs31}
                                onChange={(e) => setObs31(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status31 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo31}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <div>
                    <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
                      <Table
                        stickyHeader
                        sx={{ minWidth: 1200 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Tipo de Upload</StyledTableCell>
                            <StyledTableCell align="center">
                              Data Limite
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Observações
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Adicionar
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              0.0 - Solicitações Complementares
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite1}
                                onChange={(e) => setDataLimite1(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs1}
                                onChange={(e) => setObs1(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status1 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo1}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              1.a - Planilhas memórias de cálculo Pis/Cofins -
                              Sintética
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite2}
                                onChange={(e) => setDataLimite2(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs2}
                                onChange={(e) => setObs2(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status2 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo2}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              1.b - Planilhas memórias de cálculo Pis/Cofins -
                              Analítica
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite3}
                                onChange={(e) => setDataLimite3(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs3}
                                onChange={(e) => setObs3(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status3 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo3}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              2 - Memória de cálculo/Rascunho do ICMS, ICMS ST e
                              ICMS Antecipação
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite4}
                                onChange={(e) => setDataLimite4(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs4}
                                onChange={(e) => setObs4(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status4 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo4}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              3 - DCTFs
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite5}
                                onChange={(e) => setDataLimite5(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs5}
                                onChange={(e) => setObs5(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status5 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo5}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              4 - Balancetes Contábeis
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite6}
                                onChange={(e) => setDataLimite6(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs6}
                                onChange={(e) => setObs6(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status6 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo6}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              5 - Livros Razões Contábeis
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite7}
                                onChange={(e) => setDataLimite7(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs7}
                                onChange={(e) => setObs7(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status7 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo7}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              6 - SPED Contábil
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite8}
                                onChange={(e) => setDataLimite8(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs8}
                                onChange={(e) => setObs8(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status8 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo8}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              7 - SPED Fiscal
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite9}
                                onChange={(e) => setDataLimite9(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs9}
                                onChange={(e) => setObs9(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status9 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo9}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              8 - EFD Contribuições
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite10}
                                onChange={(e) =>
                                  setDataLimite10(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs10}
                                onChange={(e) => setObs10(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status10 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo10}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              10 - CIAP - Controle de Crédito do ICMS do Ativo
                              Permanente
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite12}
                                onChange={(e) =>
                                  setDataLimite12(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs12}
                                onChange={(e) => setObs12(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status12 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo12}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              11 - E-CAC - Comprovante de Arrecadação dos
                              Tributos IRPJ e CSLL
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite13}
                                onChange={(e) =>
                                  setDataLimite13(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs13}
                                onChange={(e) => setObs13(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status13 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo13}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              12 - ECF
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite14}
                                onChange={(e) =>
                                  setDataLimite14(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs14}
                                onChange={(e) => setObs14(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status14 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo14}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              13 - E-CAC - Fontes Pagadoras
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite15}
                                onChange={(e) =>
                                  setDataLimite15(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs15}
                                onChange={(e) => setObs15(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status15 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo15}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              14 - Bens do ativo imobilizado
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite16}
                                onChange={(e) =>
                                  setDataLimite16(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs16}
                                onChange={(e) => setObs16(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status16 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo16}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              15 - Memórias de Cálculo do IRPJ e CSLL
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite17}
                                onChange={(e) =>
                                  setDataLimite17(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs17}
                                onChange={(e) => setObs17(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status17 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo17}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              16 - Créditos Extemporâneos
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite18}
                                onChange={(e) =>
                                  setDataLimite18(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs18}
                                onChange={(e) => setObs18(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status18 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo18}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              17 - Resumo da folha de pagamento
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite19}
                                onChange={(e) =>
                                  setDataLimite19(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs19}
                                onChange={(e) => setObs19(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status19 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo19}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              18 - Comprovante de declaração a recolher do INSS
                              (Rubrica)
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite20}
                                onChange={(e) =>
                                  setDataLimite20(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs20}
                                onChange={(e) => setObs20(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status20 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo20}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              19 - Print da FAP
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite21}
                                onChange={(e) =>
                                  setDataLimite21(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs21}
                                onChange={(e) => setObs21(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status21 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo21}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              20 - GPS
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite22}
                                onChange={(e) =>
                                  setDataLimite22(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs22}
                                onChange={(e) => setObs22(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status22 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo22}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              21 - Tabela de Incidência do INSS sobre os eventos
                              da folha de pagamentos (Contribuições
                              Previdenciárias)
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite23}
                                onChange={(e) =>
                                  setDataLimite23(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs23}
                                onChange={(e) => setObs23(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status23 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo23}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              22 - Memórias de Cálculo de CPRB/Desoneração
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite24}
                                onChange={(e) =>
                                  setDataLimite24(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs24}
                                onChange={(e) => setObs24(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status24 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo24}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              23 - Questionário Prévio
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite25}
                                onChange={(e) =>
                                  setDataLimite25(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs25}
                                onChange={(e) => setObs25(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status25 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo25}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              24 - DARF
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite26}
                                onChange={(e) =>
                                  setDataLimite26(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs26}
                                onChange={(e) => setObs26(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status26 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo26}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              25 - DCTFWEB
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite27}
                                onChange={(e) =>
                                  setDataLimite27(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs27}
                                onChange={(e) => setObs27(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status27 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo27}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              27 - XMLs
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite29}
                                onChange={(e) =>
                                  setDataLimite29(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs29}
                                onChange={(e) => setObs29(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status29 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo29}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              28 - Extrato Simples Nacional
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite30}
                                onChange={(e) =>
                                  setDataLimite30(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs30}
                                onChange={(e) => setObs30(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status30 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo30}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              <p>29 - DAS</p>
                              <p>
                                Enviar apenas para os períodos sem informação de
                                pagamento no extrato
                              </p>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="date"
                                value={dataLimite31}
                                onChange={(e) =>
                                  setDataLimite31(e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="textarea"
                                rows="2"
                                value={obs31}
                                onChange={(e) => setObs31(e.target.value)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {status31 === 0 ? (
                                <Button
                                  className="text-center"
                                  onClick={addTipo31}
                                  color="primary"
                                >
                                  Add
                                </Button>
                              ) : (
                                <CheckCircleOutlineIcon color="success" />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <NovoCliente />
}
