import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { visuallyHidden } from "@mui/utils"
import { ptBR } from "@mui/x-data-grid"
import SearchIcon from "@mui/icons-material/Search"
import api from "../../../Config/api"
import BKPSPaginationActions from "../../../components/PaginationActions"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: "nome",
    numeric: false,
    disablePadding: true,
    label: <strong>Nome</strong>
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: true,
    label: <strong>Editar</strong>
  }
]

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default function TabelaAssociados() {
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const _id = localStorage.getItem("_id")
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([_id])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const [rows, setRows] = useState([])
  const [originalData, setOriginalData] = useState([])

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  function handleInput(e) {
    const input = document.getElementById("input-table").value
    setRows(searchTable(input, rows))
  }

  function searchTable(value) {
    const filteredData = []

    if (value.length === 0) {
      return originalData
    }

    for (let i = 0; i < rows.length; ++i) {
      const newValue = value.toLowerCase()

      const user = rows[i].nome.toLowerCase()

      if (user.includes(newValue)) {
        filteredData.push(rows[i])
      }
    }
    return filteredData
  }

  useEffect(() => {
    async function associadoSelecionado() {
      const listaAssociados = await api.get("/associados")

      const associados = listaAssociados.data
      let cont = 0
      const rows = []
      while (cont < associados.length) {
        rows.push({ id: associados[cont].id, nome: associados[cont].nome })
        cont++
      }
      setRows(rows)
      setOriginalData(rows)
    }

    associadoSelecionado()
  }, [_id])

  return (
    <Box sx={{ width: "100%" }}>
      <TableCell component="th" padding="normal" align="justify" sx={{ p: 2 }}>
        <SearchIcon sx={{ color: "#28a6de", fontSize: 30 }} />
      </TableCell>
      <TableCell
        component="th"
        padding="35px"
        align="justify"
        sx={{ p: 2, width: "400px" }}
      >
        <input
          className="search"
          onChange={handleInput}
          id="input-table"
          placeholder="Pesquisar"
        />
      </TableCell>

      <TableContainer>
        <Table
          sx={{ minWidth: 450 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow hover key={row.id}>
                    <TableCell
                      component="th"
                      id={labelId}
                      padding="none"
                      align="center"
                    >
                      {row.nome}
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/editar-associado/${row.id}`}>
                        <EditIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        localetext={ptBR.components.MuiDataGrid.defaultProps.localetext}
        component="div"
        count={rows.length}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}-${to} de ${count}`
        }}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        pagesize={10}
        rowsPerPageOptions={[5]}
        checkboxselection="true"
        disableselectiononclick="true"
        experimentalfeatures={{ newEditingApi: true }}
        ActionsComponent={BKPSPaginationActions}
      />
    </Box>
  )
}
