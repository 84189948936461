import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { toast, ToastContainer } from "react-toastify"

import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

import IconButton from "@mui/material/IconButton"
import SaveIcon from "@mui/icons-material/Save"

import { Alert } from "reactstrap"

import Modal from "@mui/material/Modal"
import api from "../../../../Config/api"

const styleModalErro = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 1,
  align: "center",
  fontSize: 10
}

const Painel = () => {
  const [openModalVazio, setOpenModalVazio] = useState(false)
  const handleOpenVazio = () => setOpenModalVazio(true)
  const handleCloseVazio = () => setOpenModalVazio(false)

  const params = useParams()

  const cnpj = params._id

  const [statusEdit, setStatusEdit] = useState("")

  // CIAP
  const [status30_id, setStatus30_id] = useState("")
  const [status30_0, setStatus30_0] = useState("")
  const [status30_1, setStatus30_1] = useState("")
  const [status30_2, setStatus30_2] = useState("")
  const [status30_3, setStatus30_3] = useState("")
  const [status30_4, setStatus30_4] = useState("")
  const [status30_5, setStatus30_5] = useState("")
  const [status30_6, setStatus30_6] = useState("")
  const [status30_7, setStatus30_7] = useState("")
  const [status30_8, setStatus30_8] = useState("")
  const [status30_9, setStatus30_9] = useState("")
  const [status30_10, setStatus30_10] = useState("")
  const [status30_11, setStatus30_11] = useState("")
  const [status30_12, setStatus30_12] = useState("")
  const [status30_13, setStatus30_13] = useState("")
  const [status30_14, setStatus30_14] = useState("")
  const [status30_15, setStatus30_15] = useState("")
  const [status30_16, setStatus30_16] = useState("")
  const [status30_17, setStatus30_17] = useState("")
  const [status30_18, setStatus30_18] = useState("")
  const [status30_19, setStatus30_19] = useState("")
  const [status30_20, setStatus30_20] = useState("")
  const [status30_21, setStatus30_21] = useState("")
  const [status30_22, setStatus30_22] = useState("")
  const [status30_23, setStatus30_23] = useState("")
  const [status30_24, setStatus30_24] = useState("")
  const [status30_25, setStatus30_25] = useState("")
  const [status30_26, setStatus30_26] = useState("")
  const [status30_27, setStatus30_27] = useState("")
  const [status30_28, setStatus30_28] = useState("")
  const [status30_29, setStatus30_29] = useState("")
  const [status30_30, setStatus30_30] = useState("")
  const [status30_31, setStatus30_31] = useState("")
  const [status30_32, setStatus30_32] = useState("")
  const [status30_33, setStatus30_33] = useState("")
  const [status30_34, setStatus30_34] = useState("")
  const [status30_35, setStatus30_35] = useState("")
  const [status30_36, setStatus30_36] = useState("")
  const [status30_37, setStatus30_37] = useState("")
  const [status30_38, setStatus30_38] = useState("")
  const [status30_39, setStatus30_39] = useState("")
  const [status30_40, setStatus30_40] = useState("")
  const [status30_41, setStatus30_41] = useState("")
  const [status30_42, setStatus30_42] = useState("")
  const [status30_43, setStatus30_43] = useState("")
  const [status30_44, setStatus30_44] = useState("")
  const [status30_45, setStatus30_45] = useState("")
  const [status30_46, setStatus30_46] = useState("")
  const [status30_47, setStatus30_47] = useState("")
  const [status30_48, setStatus30_48] = useState("")
  const [status30_49, setStatus30_49] = useState("")
  const [status30_50, setStatus30_50] = useState("")
  const [status30_51, setStatus30_51] = useState("")
  const [status30_52, setStatus30_52] = useState("")
  const [status30_53, setStatus30_53] = useState("")
  const [status30_54, setStatus30_54] = useState("")
  const [status30_55, setStatus30_55] = useState("")
  const [status30_56, setStatus30_56] = useState("")
  const [status30_57, setStatus30_57] = useState("")
  const [status30_58, setStatus30_58] = useState("")
  const [status30_59, setStatus30_59] = useState("")

  const [datas, setDatas] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/up30", { cnpj })
      const datasUp = await api.post("/checklist", { cnpj })
      let datas = []
      datas = datasUp.data.dados.cincoAnos
      let x = 0
      const meses = []
      while (x < datas.length) {
        meses.push({ id: x, data: datas[x] })
        x++
      }
      setDatas(meses)

      if (!cliente.data.up4) {
        setStatus30_id(cliente.data.tipo._id)
        setStatus30_0(cliente.data.tipo.statusCincoAnos[0])
        setStatus30_1(cliente.data.tipo.statusCincoAnos[1])
        setStatus30_2(cliente.data.tipo.statusCincoAnos[2])
        setStatus30_3(cliente.data.tipo.statusCincoAnos[3])
        setStatus30_4(cliente.data.tipo.statusCincoAnos[4])
        setStatus30_5(cliente.data.tipo.statusCincoAnos[5])
        setStatus30_6(cliente.data.tipo.statusCincoAnos[6])
        setStatus30_7(cliente.data.tipo.statusCincoAnos[7])
        setStatus30_8(cliente.data.tipo.statusCincoAnos[8])
        setStatus30_9(cliente.data.tipo.statusCincoAnos[9])
        setStatus30_10(cliente.data.tipo.statusCincoAnos[10])
        setStatus30_11(cliente.data.tipo.statusCincoAnos[11])
        setStatus30_12(cliente.data.tipo.statusCincoAnos[12])
        setStatus30_13(cliente.data.tipo.statusCincoAnos[13])
        setStatus30_14(cliente.data.tipo.statusCincoAnos[14])
        setStatus30_15(cliente.data.tipo.statusCincoAnos[15])
        setStatus30_16(cliente.data.tipo.statusCincoAnos[16])
        setStatus30_17(cliente.data.tipo.statusCincoAnos[17])
        setStatus30_18(cliente.data.tipo.statusCincoAnos[18])
        setStatus30_19(cliente.data.tipo.statusCincoAnos[19])
        setStatus30_20(cliente.data.tipo.statusCincoAnos[20])
        setStatus30_21(cliente.data.tipo.statusCincoAnos[21])
        setStatus30_22(cliente.data.tipo.statusCincoAnos[22])
        setStatus30_23(cliente.data.tipo.statusCincoAnos[23])
        setStatus30_24(cliente.data.tipo.statusCincoAnos[24])
        setStatus30_25(cliente.data.tipo.statusCincoAnos[25])
        setStatus30_26(cliente.data.tipo.statusCincoAnos[26])
        setStatus30_27(cliente.data.tipo.statusCincoAnos[27])
        setStatus30_28(cliente.data.tipo.statusCincoAnos[28])
        setStatus30_29(cliente.data.tipo.statusCincoAnos[29])
        setStatus30_30(cliente.data.tipo.statusCincoAnos[30])
        setStatus30_31(cliente.data.tipo.statusCincoAnos[31])
        setStatus30_32(cliente.data.tipo.statusCincoAnos[32])
        setStatus30_33(cliente.data.tipo.statusCincoAnos[33])
        setStatus30_34(cliente.data.tipo.statusCincoAnos[34])
        setStatus30_35(cliente.data.tipo.statusCincoAnos[35])
        setStatus30_36(cliente.data.tipo.statusCincoAnos[36])
        setStatus30_37(cliente.data.tipo.statusCincoAnos[37])
        setStatus30_38(cliente.data.tipo.statusCincoAnos[38])
        setStatus30_39(cliente.data.tipo.statusCincoAnos[39])
        setStatus30_40(cliente.data.tipo.statusCincoAnos[40])
        setStatus30_41(cliente.data.tipo.statusCincoAnos[41])
        setStatus30_42(cliente.data.tipo.statusCincoAnos[42])
        setStatus30_43(cliente.data.tipo.statusCincoAnos[43])
        setStatus30_44(cliente.data.tipo.statusCincoAnos[44])
        setStatus30_45(cliente.data.tipo.statusCincoAnos[45])
        setStatus30_46(cliente.data.tipo.statusCincoAnos[46])
        setStatus30_47(cliente.data.tipo.statusCincoAnos[47])
        setStatus30_48(cliente.data.tipo.statusCincoAnos[48])
        setStatus30_49(cliente.data.tipo.statusCincoAnos[49])
        setStatus30_50(cliente.data.tipo.statusCincoAnos[50])
        setStatus30_51(cliente.data.tipo.statusCincoAnos[51])
        setStatus30_52(cliente.data.tipo.statusCincoAnos[52])
        setStatus30_53(cliente.data.tipo.statusCincoAnos[53])
        setStatus30_54(cliente.data.tipo.statusCincoAnos[54])
        setStatus30_55(cliente.data.tipo.statusCincoAnos[55])
        setStatus30_56(cliente.data.tipo.statusCincoAnos[56])
        setStatus30_57(cliente.data.tipo.statusCincoAnos[57])
        setStatus30_58(cliente.data.tipo.statusCincoAnos[58])
        setStatus30_59(cliente.data.tipo.statusCincoAnos[59])
      }
    }

    getCliente()
  }, [cnpj])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  async function salvar(id) {
    const statusCincoAnos = [
      status30_0,
      status30_1,
      status30_2,
      status30_3,
      status30_4,
      status30_5,
      status30_6,
      status30_7,
      status30_8,
      status30_9,
      status30_10,
      status30_11,
      status30_12,
      status30_13,
      status30_14,
      status30_15,
      status30_16,
      status30_17,
      status30_18,
      status30_19,
      status30_20,
      status30_21,
      status30_22,
      status30_23,
      status30_24,
      status30_25,
      status30_26,
      status30_27,
      status30_28,
      status30_29,
      status30_30,
      status30_31,
      status30_32,
      status30_33,
      status30_34,
      status30_35,
      status30_36,
      status30_37,
      status30_38,
      status30_39,
      status30_40,
      status30_41,
      status30_42,
      status30_43,
      status30_44,
      status30_45,
      status30_46,
      status30_47,
      status30_48,
      status30_49,
      status30_50,
      status30_51,
      status30_52,
      status30_53,
      status30_54,
      status30_55,
      status30_56,
      status30_57,
      status30_58,
      status30_59
    ]

    await api.put("/editar-tipo-upload-60meses", { id, statusCincoAnos })
    toast.success("Registro salvo com sucesso!", {
      position: toast.POSITION.TOP_CENTER
    })
  }

  return (
    <div>
      {status30_0.length === 0 ? (
        <></>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <ToastContainer />
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Arquivo</TableCell>
                {datas.map((column) => (
                  <TableCell key={column.id} align="center">
                    {formataData(column.data)}
                  </TableCell>
                ))}
                <TableCell>Salvar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Extrato Simples Nacional</TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_0 ?? ""}
                    onChange={(e) => setStatus30_0(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_1 ?? ""}
                    onChange={(e) => setStatus30_1(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_2 ?? ""}
                    onChange={(e) => setStatus30_2(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_3 ?? ""}
                    onChange={(e) => setStatus30_3(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_4 ?? ""}
                    onChange={(e) => setStatus30_4(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_5 ?? ""}
                    onChange={(e) => setStatus30_5(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_6 ?? ""}
                    onChange={(e) => setStatus30_6(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_7 ?? ""}
                    onChange={(e) => setStatus30_7(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_8 ?? ""}
                    onChange={(e) => setStatus30_8(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_9 ?? ""}
                    onChange={(e) => setStatus30_9(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_10 ?? ""}
                    onChange={(e) => setStatus30_10(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_11 ?? ""}
                    onChange={(e) => setStatus30_11(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_12 ?? ""}
                    onChange={(e) => setStatus30_12(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_13 ?? ""}
                    onChange={(e) => setStatus30_13(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_14 ?? ""}
                    onChange={(e) => setStatus30_14(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_15 ?? ""}
                    onChange={(e) => setStatus30_15(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_16 ?? ""}
                    onChange={(e) => setStatus30_16(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_17 ?? ""}
                    onChange={(e) => setStatus30_17(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_18 ?? ""}
                    onChange={(e) => setStatus30_18(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_19 ?? ""}
                    onChange={(e) => setStatus30_19(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_20 ?? ""}
                    onChange={(e) => setStatus30_20(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_21 ?? ""}
                    onChange={(e) => setStatus30_21(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_22 ?? ""}
                    onChange={(e) => setStatus30_22(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_23 ?? ""}
                    onChange={(e) => setStatus30_23(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_24 ?? ""}
                    onChange={(e) => setStatus30_24(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_25 ?? ""}
                    onChange={(e) => setStatus30_25(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_26 ?? ""}
                    onChange={(e) => setStatus30_26(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_27 ?? ""}
                    onChange={(e) => setStatus30_27(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_28 ?? ""}
                    onChange={(e) => setStatus30_28(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_29 ?? ""}
                    onChange={(e) => setStatus30_29(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_30 ?? ""}
                    onChange={(e) => setStatus30_30(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_31 ?? ""}
                    onChange={(e) => setStatus30_31(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_32 ?? ""}
                    onChange={(e) => setStatus30_32(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_33 ?? ""}
                    onChange={(e) => setStatus30_33(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_34 ?? ""}
                    onChange={(e) => setStatus30_34(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_35 ?? ""}
                    onChange={(e) => setStatus30_35(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_36 ?? ""}
                    onChange={(e) => setStatus30_36(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_37 ?? ""}
                    onChange={(e) => setStatus30_37(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_38 ?? ""}
                    onChange={(e) => setStatus30_38(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_39 ?? ""}
                    onChange={(e) => setStatus30_39(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_40 ?? ""}
                    onChange={(e) => setStatus30_40(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_41 ?? ""}
                    onChange={(e) => setStatus30_41(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_42 ?? ""}
                    onChange={(e) => setStatus30_42(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_43 ?? ""}
                    onChange={(e) => setStatus30_43(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_44 ?? ""}
                    onChange={(e) => setStatus30_44(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_45 ?? ""}
                    onChange={(e) => setStatus30_45(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_46 ?? ""}
                    onChange={(e) => setStatus30_46(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_47 ?? ""}
                    onChange={(e) => setStatus30_47(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_48 ?? ""}
                    onChange={(e) => setStatus30_48(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_49 ?? ""}
                    onChange={(e) => setStatus30_49(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_50 ?? ""}
                    onChange={(e) => setStatus30_50(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_51 ?? ""}
                    onChange={(e) => setStatus30_51(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_52 ?? ""}
                    onChange={(e) => setStatus30_52(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_53 ?? ""}
                    onChange={(e) => setStatus30_53(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_54 ?? ""}
                    onChange={(e) => setStatus30_54(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_55 ?? ""}
                    onChange={(e) => setStatus30_55(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_56 ?? ""}
                    onChange={(e) => setStatus30_56(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_57 ?? ""}
                    onChange={(e) => setStatus30_57(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_58 ?? ""}
                    onChange={(e) => setStatus30_58(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status30_59 ?? ""}
                    onChange={(e) => setStatus30_59(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    onClick={() => salvar(status30_id)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Modal
        open={openModalVazio}
        onClose={handleCloseVazio}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalErro}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Registro salvo com sucesso.</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  )
}

export default Painel
