import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import "react-toastify/dist/ReactToastify.min.css"
import ResponsiveDrawer from "../../components/menu"

import api from "../../Config/api"

const mdTheme = createTheme()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray" /* Altera a cor de fundo do menu */,
    color: "white" /* Altera a cor da Letra */
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

export default function DashboardAssociado() {
  const params = useParams()
  const cnpj = params._id

  const [rows, setRows] = useState([])
  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState(false)

  const [loading, setLoading] = useState(false)
  const [colunas, setColunas] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const buscaCNPJ = { cnpj }

      const cliente = await api.post("/dashboard", buscaCNPJ)
      setRazaoSocial(cliente.data.cliente.razaoSocial)
      setRows(cliente.data.tipos)
      setSimples(cliente.data.cliente.simples)

      setLoading(false)
    }
    getCliente()
    setLoading(true)
  }, [cnpj])

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/checklist", { cnpj })
      const col = []
      if (cliente.data.dados.data1 !== null) {
        col.push(
          `${cliente.data.dados.data1.substr(
            0,
            4
          )}/${cliente.data.dados.data1.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data2 !== null) {
        col.push(
          `${cliente.data.dados.data2.substr(
            0,
            4
          )}/${cliente.data.dados.data2.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data3 !== null) {
        col.push(
          `${cliente.data.dados.data3.substr(
            0,
            4
          )}/${cliente.data.dados.data3.substr(5, 2)}`
        )
      }
      setColunas(col)
      setLoading(false)
    }

    getCliente()
    setLoading(true)
  }, [cnpj])

  function formatarData(data) {
    if (data === null) {
      return "-"
    }
    const dia = data.substr(8, 2)
    const mes = data.substr(5, 2)
    const ano = data.substr(0, 4)
    const dataAtual = `${dia}/${mes}/${ano}`
    return dataAtual
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title={`Upload - ${razaoSocial}`} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Grid>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Container sx={{ mt: 4, mb: 4 }}>
                <center>
                  <span className="submenu">Uploads</span>
                </center>
                <hr />
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    {simples === true ? (
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="title">
                              Períodos de Diagnósticos:
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[0]}{" "}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    ) : (
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="title">
                              Períodos de Diagnósticos:
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[0]}{" "}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[1]}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[2]}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[3]}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="title">
                              {colunas.length === 0 ? "-" : colunas[4]}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}
                  </Table>
                </TableContainer>
              </Container>
            )}
          </Grid>
          <Container sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 550 }}>
                <Table stickyHeader aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span className="title">Arquivos a Enviar</span>
                      </TableCell>
                      <TableCell>
                        <span className="title">Prazo de Envio</span>
                      </TableCell>
                      <TableCell>
                        <span className="title">Observações</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.tipo}>
                        <StyledTableCell scope="row">
                          {row.tipo === 1 ? "Solicitações Complementares" : ""}
                          {row.tipo === 2
                            ? "Planilhas memórias de cálculo Pis/Cofins - Sintética"
                            : ""}
                          {row.tipo === 3 ? (
                            <div>
                              Planilhas memórias de cálculo Pis/Cofins -
                              Analítica
                              <p className="credExtemp">
                                Planilha que contenha os valores em aberto (item
                                a item) de créditos e débitos
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 4
                            ? "Memória de cálculo/Rascunho do ICMS, ICMS ST e ICMS Antecipação"
                            : ""}
                          {row.tipo === 5 ? (
                            <div>
                              DCTFs
                              <p className="credExtemp">Declaração</p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 6 ? "Balancetes Contábeis" : ""}
                          {row.tipo === 7 ? "Livros Razões Contábeis" : ""}
                          {row.tipo === 8 ? "SPED Contábil" : ""}
                          {row.tipo === 9 ? (
                            <div>
                              SPED Fiscal
                              <p className="credExtemp">
                                Enviar Matriz e filiais
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 10 ? (
                            <div>
                              EFD Contribuições
                              <p className="credExtemp">formato .txt </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {row.tipo === 12 ? (
                            <div>
                              CIAP - Controle de Crédito do ICMS do Ativo
                              Permanente
                              <p className="credExtemp">
                                Enviar Matriz e filiais
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 13 ? (
                            <div>
                              E-CAC - Comprovante de Arrecadação dos Tributos
                              IRPJ e CSLL
                              <p className="credExtemp">
                                Relatorio detalhado por tributo{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 14 ? "ECF" : ""}
                          {row.tipo === 15 ? (
                            <div>
                              E-CAC - Fontes Pagadoras
                              <p className="credExtemp">
                                Aberto por código de retenção - em TXT
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 16 ? (
                            <div>
                              Bens do ativo imobilizado
                              <p className="credExtemp">
                                Aquisições a partir de 2004{" "}
                              </p>{" "}
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 17
                            ? "Memórias de Cálculo do IRPJ e CSLL"
                            : ""}
                          {row.tipo === 18 ? (
                            <div>
                              Créditos Extemporâneos
                              <p className="credExtemp">
                                Composição analítica de todo e qualquer crédito
                                que tenha sido apropriado fora do mês corrente,
                                sendo ele de forma administrativa ou judicial,
                                referente ao período dos últimos 5 anos.{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 19
                            ? "Resumo da folha de pagamento"
                            : ""}
                          {row.tipo === 20 ? (
                            <div>
                              Comprovante de declaração a recolher do INSS
                              (Rubrica)
                              <p className="credExtemp">
                                Enviar Matriz e filiais{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 21 ? "Print da FAP" : ""}
                          {row.tipo === 22 ? "GPS" : ""}
                          {row.tipo === 23
                            ? "Tabela de Incidência INSS sobre os eventos da folha de pagamentos (Contribuições Previdenciárias)"
                            : ""}
                          {row.tipo === 24
                            ? "Memória de Cálculo CPRB/Desoneração"
                            : ""}
                          {row.tipo === 25 ? "Questionário Prévio" : ""}
                          {row.tipo === 26 ? (
                            <div>
                              DARF
                              <p className="credExtemp">Código 1410</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {row.tipo === 27 ? "DCTFWEB" : ""}
                          {row.tipo === 29
                            ? "XMLs NF-es e CT-es de entradas"
                            : ""}
                          {row.tipo === 30 ? "Extrato Simples Nacional" : ""}
                          {row.tipo === 31
                            ? "DAS (Enviar apenas para os períodos sem informação de pagamento no extrato)"
                            : ""}
                          {row.tipo === 32
                            ? "XMLs NF-es e CT-es de saídas"
                            : ""}
                          {row.tipo === 33
                            ? "Guia ou Declaração de Apuração do ICMS - (GIA; DMA; DAM; DAPI; GIME; GIAM)"
                            : ""}
                          {row.tipo === 34 ? (
                            <div>
                              Balancete Contábil (último não encerrado)
                              <p className="credExtemp">
                                período mais recente, após conciliação da
                                contabilidade.
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {formatarData(row.dataLimite)}
                        </StyledTableCell>
                        <StyledTableCell alignitems="center">
                          {row.obs === "" ? "-" : row.obs}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
