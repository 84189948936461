import React, { useState, useContext, useEffect } from "react"
import api from "../../Config/api"
import { useNavigate } from "react-router-dom"

import "./style.css"

import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import Badge from "@mui/material/Badge"
import LogoutIcon from "@mui/icons-material/Logout"
import { Alert, Button, Col, Input, Label, Row } from "reactstrap"
import { mainListItems, secondaryListItems } from "./listItems"
import { AuthContext } from "../../Context/auth"

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

const mdTheme = createTheme()

const NovoCliente = () => {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const { logout } = useContext(AuthContext)

  const navigate = useNavigate()

  const handleChange = (event) => {
    setSimples(event.target.checked)
  }

  const idCreator = localStorage.getItem("id")

  const [cnpj, setCnpj] = useState("")
  const [razaoSocial, setRazaoSocial] = useState("")
  const [email, setEmail] = useState("")
  const [emailSecundario, setEmailSecundario] = useState("")
  const [simples, setSimples] = useState(false)
  const [dataSimples, setDataSimples] = useState("")
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")
  const [data5, setData5] = useState("")
  const [data6, setData6] = useState("")
  const [data7, setData7] = useState("")
  const [data8, setData8] = useState("")
  const [data9, setData9] = useState("")
  const [data10, setData10] = useState("")
  const [data11, setData11] = useState("")
  const [data12, setData12] = useState("")
  const [senha, setSenha] = useState("")
  const [status, setStatus] = useState("")
  const [statusCnpj, setStatusCnpj] = useState("")
  const [statusEmail, setStatusEmail] = useState("")

  function mesAnterior(data) {
    let ano = data.substring(0, 4)
    let mes = data.substring(5, 7)
    const dia = data.substring(8, 10)
    setData1(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData2(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData3(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData4(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData5(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData6(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData7(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData8(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData9(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData10(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData11(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData12(`${ano}-${mes}-${dia}`)
    return (
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12
    )
  }

  const novoCliente = async (e) => {
    e.preventDefault()
    if (!cnpj || !razaoSocial || !email || !senha) {
      setStatus(0)
    } else {
      setStatus(1)
      const buscaCnpj = await api.post("/find-cliente-pelo-cnpj", { cnpj })
      const buscaEmail = await api.post("/find-cliente-pelo-email", { email })

      if (!buscaCnpj.data.err && !buscaEmail.data.err) {
        const perfil = 2
        if (simples === true) {
          mesAnterior(dataSimples)
          localStorage.setItem("cnpj", cnpj)
          localStorage.setItem("razaoSocial", razaoSocial)
          localStorage.setItem("simples", simples)
          const dados = {
            idCreator,
            cnpj,
            razaoSocial,
            email,
            emailSecundario,
            simples,
            perfil,
            senha,
            data1,
            data2,
            data3,
            data4,
            data5,
            data6,
            data7,
            data8,
            data9,
            data10,
            data11,
            data12
          }
          await api.post("/novo-cliente", dados)
          navigate("/novoClienteProximoContadoresSA")
        } else {
          localStorage.setItem("cnpj", cnpj)
          localStorage.setItem("razaoSocial", razaoSocial)
          localStorage.setItem("simples", simples)
          const dados = {
            idCreator,
            cnpj,
            razaoSocial,
            email,
            emailSecundario,
            simples,
            perfil,
            senha,
            data1,
            data2,
            data3
          }
          await api.post("/novo-cliente", dados)
          navigate("/novoClienteProximoContadoresSA")
        }
      }
      if (buscaCnpj.data.err) {
        setStatusCnpj(0)
      }
      if (buscaEmail.data.err) {
        setStatusEmail(0)
      }
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px" // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard - Contadores SA
            </Typography>
            <IconButton color="inherit" onClick={logout}>
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1]
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={2}>
              <form className="form" autoComplete="off" onSubmit={novoCliente}>
                {status === 0 ? (
                  <Alert color="warning">Preencha todos os campos</Alert>
                ) : (
                  ""
                )}
                {status === 2 ? (
                  <Alert color="danger">
                    Erro no sistema, procure o administrador
                  </Alert>
                ) : (
                  ""
                )}
                {statusCnpj === 0 ? (
                  <Alert color="warning">CNPJ já cadastrado</Alert>
                ) : (
                  ""
                )}
                {statusEmail === 0 ? (
                  <Alert color="warning">EMAIL já cadastrado</Alert>
                ) : (
                  ""
                )}
                <Grid>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <h3>Novo cliente</h3>
                    <Row className="linhas">
                      <Col xl="6" md="12">
                        <Input
                          required
                          maxLength="8"
                          id="cnpj"
                          name="cnpj"
                          placeholder="CNPJ"
                          onChange={(e) => setCnpj(e.target.value)}
                        />
                      </Col>
                      <Col xl="6" md="12">
                        <Input
                          required
                          type="text"
                          id="razaoSocial"
                          name="razaoSocial"
                          placeholder="Razão social"
                          onChange={(e) => setRazaoSocial(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="linhas">
                      <Col xl="6" md="12">
                        <Input
                          autoComplete="off"
                          required
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email principal"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                      <Col xl="6" md="12">
                        <Input
                          type="email"
                          id="emailSecundario"
                          name="emailSecundario"
                          placeholder="Email secundário"
                          onChange={(e) => setEmailSecundario(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="linhas">
                      <Col>
                        <Input
                          id="simples"
                          name="simples"
                          type="checkbox"
                          onChange={handleChange}
                        />{" "}
                        <Label check>Projeto Simples Nacional</Label>
                      </Col>
                    </Row>

                    {simples === false ? (
                      <div>
                        <hr />
                        <Row className="linhas">
                          <h6>Previsão de envio</h6>
                          <Col>
                            <Input
                              type="date"
                              id="data1"
                              name="data1"
                              onChange={(e) => setData1(e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Input
                              type="date"
                              id="data2"
                              name="data2"
                              onChange={(e) => setData2(e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Input
                              type="date"
                              id="data3"
                              name="data3"
                              onChange={(e) => setData3(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <hr />
                        <Row className="linhas">
                          <h6>Previsão de envio</h6>
                          <Col xl={4}>
                            <Input
                              required
                              type="date"
                              name="dataSimples"
                              onChange={(e) => setDataSimples(e.target.value)}
                            />
                          </Col>
                          <Col>Orientações sobre a data do simples</Col>
                        </Row>
                      </div>
                    )}
                    <hr />
                    <Row className="linhas">
                      <Col xl={4}>
                        <Label>Senha</Label>
                        <Input
                          required
                          type="password"
                          id="senha"
                          name="senha"
                          placeholder="Senha"
                          onChange={(e) => setSenha(e.target.value)}
                        />
                      </Col>
                    </Row>

                    {simples === false ? (
                      <div>
                        <Row className="linhas">
                          <Col xl={4}>
                            <Button type="submit" color="primary">
                              Próximo
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row className="linhas">
                          <Col xl={4}>
                            <Button
                              type="submit"
                              onClick={() => mesAnterior(dataSimples)}
                              color="primary"
                            >
                              Próximo
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Paper>
                </Grid>
              </form>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <NovoCliente />
}
