import React, { useState, useContext } from "react"
import { AuthContext } from "../../Context/auth"
import { ToastContainer } from "react-toastify"
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import "react-toastify/dist/ReactToastify.css"
import { IMaskInput } from "react-imask"
import "../../styles/styles.css"
import LockIcon from "@mui/icons-material/Lock"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import "bootstrap/dist/css/bootstrap.min.css"
import { Box, Tooltip } from "@mui/material"
import background from "../../assets/imgs/background.png"
import logo_bkps from "../../assets/imgs/logo_bkps.png"

export default function NovaSenha() {
  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
  }

  const [state, setstate] = useState(false)

  const toggleBtn = (event) => {
    setstate((prevState) => !prevState)
  }

  // const [status, setStatus] = useState(0);

  const { trocaSenhaCliente } = useContext(AuthContext)
  const [cnpj, setCnpj] = useState("")
  const [senhaAntiga, setSenhaAntiga] = useState("")
  const [senhaAtual, setSenhaAtual] = useState("")
  // const [confSenhaNova, setConfSenhaNova] = useState('');

  const logar = async (e) => {
    e.preventDefault()
    trocaSenhaCliente(cnpj, senhaAntiga, senhaAtual)
  }

  return (
    <div>
      <ToastContainer />
      <div className="col-md-6 background">
        <img src={background} alt="Logo" />
      </div>

      <img src={logo_bkps} alt="logo" className="brand" />

      <div className="container col-md-6 ">
        <div className="container-nova-senha">
          <form onSubmit={handleSubmit} className="col-md-12">
            <div className="p-3 py-1 offset-3 col-md-12">
              <span className="span">Troca de Senha</span>
            </div>

            <span className="text">
              Utilize a senha enviada no email cadastrado, e realize a troca.
            </span>

            <br />
            <div className="py-2 p-1">
              <div className="icon">
                <AccountCircleIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Login</label>
            </div>

            {/* <br></br> */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <Tooltip
                title="Informe os 8 primeiros dígitos do CNPJ"
                arrow
                placement="top-end"
              >
                <div className="p-3 pt-1">
                  <IMaskInput
                    className="input-login"
                    placeholder="Digite o CNPJ"
                    autoComplete="false"
                    id="cnpj"
                    mask="00000000"
                    name="cnpj"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                  />
                </div>
              </Tooltip>
            </Box>
            <div className="mt-1 py-1 p-1">
              <div className="icon">
                <LockIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Senha Antiga</label>
            </div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <div className="p-3 pt-1">
                <input
                  className="input-login"
                  placeholder="Senha Antiga"
                  autoComplete="false"
                  id="senhaAntiga"
                  name="senhaAntiga"
                  type={state ? "text" : "password"}
                  value={senhaAntiga}
                  onChange={(e) => setSenhaAntiga(e.target.value)}
                />
              </div>
            </Box>
            <div className="py-1 p-1">
              <div className="icon">
                <LockIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Nova Senha</label>
            </div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <Tooltip
                title="A senha deve conter 8 caracteres no mínimo, 1 Letra Maiúscula no mínimo, 1 Número no mínimo e 1 Símbolo no mínimo: $*&@#"
                arrow
                placement="top-end"
              >
                <div className="p-3 pt-1">
                  <input
                    className="input-login padding-right"
                    placeholder="Nova Senha"
                    autoComplete="false"
                    id="senhaAtual"
                    name="senhaAtual"
                    type={state ? "text" : "password"}
                    value={senhaAtual}
                    onChange={(e) => setSenhaAtual(e.target.value)}
                  />
                </div>
              </Tooltip>
            </Box>
            <button className="btnEye" onClick={toggleBtn}>
              {state ? (
                <AiOutlineEye size={25} />
              ) : (
                <AiOutlineEyeInvisible size={25} />
              )}
            </button>

            <button
              className="button"
              alt="Login"
              type="submit"
              onClick={logar}
            >
              Salvar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
