import React, { useState, useContext } from "react"

import { AuthContext } from "../../Context/auth"

import "./style.css"

import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Alert } from "@mui/material"
import logo from "./logo.png"

const theme = createTheme()

export default function LoginContadoresSA() {
  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
  }

  const [status, setStatus] = useState(0)

  const { authenticated, loginContadoresSA, user } = useContext(AuthContext)

  const [email, setEmail] = useState("")
  const [senha, setSenha] = useState("")

  const logar = async (e) => {
    e.preventDefault()
    loginContadoresSA(email, senha)
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Container maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img src={logo} className="App-logo" alt="logo" />

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                type="email"
                margin="normal"
                required
                fullWidth
                label="Email"
                autoFocus
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                id="senha"
                name="senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={logar}
              >
                Entrar
              </Button>
            </Box>
          </Box>
          {user === 1 ? (
            <Alert severity="error">Email ou senha incorretos</Alert>
          ) : (
            ""
          )}
        </Container>
      </ThemeProvider>
    </div>
  )
}
