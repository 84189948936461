import React, { useContext } from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { createTheme, ThemeProvider } from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import Badge from "@mui/material/Badge"
import { Link, useLocation } from "react-router-dom"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import DashboardIcon from "@mui/icons-material/Dashboard"
import EventNoteIcon from "@mui/icons-material/EventNote"
import ViewListIcon from "@mui/icons-material/ViewList"
import ListAltIcon from "@mui/icons-material/ListAlt"
import { AuthContext } from "../Context/auth"
import logo_bkps from "../assets/imgs/logo_bkps.png"
import Documentos from "../Pages/DashboardCliente/Diagnostico/Documentos"
import Filiais from "../Pages/DashboardCliente/Diagnostico/Filiais"
import {
  mainListItems,
  secondaryListItems
} from "../Pages/DashboardCliente/listItems"

const drawerWidth = 240

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
})

const MenuCliente = (props) => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const location = useLocation()
  const cnpj = localStorage.getItem("cnpj")

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined
  const { logout } = useContext(AuthContext)

  const convertToJson = () => {
    const jsonRecuperado = localStorage.getItem("user")
    const cliente = JSON.parse(jsonRecuperado).razaoSocial

    return cliente
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` }
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {convertToJson()}
            </Typography>
            <img src={logo_bkps} alt="logo" className="top" />

            <IconButton color="inherit" onClick={logout}>
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            <List component="nav">
              {mainListItems}
              <Divider sx={{ my: 1 }} />
              {secondaryListItems}
              <Divider sx={{ my: 1 }} />
            </List>

            <Filiais />

            <hr />
            <Documentos />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "inherit",
                width: drawerWidth
              }
            }}
            open
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                background: "rgb(25 118 210 / 30%)"
              }}
            >
              <ListItemButton
                sx={{ bgcolor: "background.paper" }}
                component={Link}
                selected={location.pathname === "/dashboard"}
                to="/dashboard"
              >
                <ListItemIcon sx={{ minWidth: 60 }}>
                  <DashboardIcon sx={{ color: "#28a6de", fontSize: 30 }} />
                </ListItemIcon>
                <ListItemText
                  primary="Uploads"
                  sx={{ color: "#28a6de", fontSize: 30 }}
                  secondary="Matriz/Filial"
                />
              </ListItemButton>

              <ListItemButton
                sx={{ bgcolor: "background.paper" }}
                component={Link}
                selected={location.pathname === `${`/diagnostico/${cnpj}`}`}
                to={`/diagnostico/${cnpj}`}
              >
                <ListItemIcon>
                  <ViewListIcon sx={{ color: "#28a6de", fontSize: 30 }} />
                </ListItemIcon>
                <ListItemText
                  primary="CheckList"
                  sx={{ color: "#28a6de", fontSize: 30 }}
                  secondary="Amostragem"
                />
              </ListItemButton>

              <ListItemButton
                sx={{ bgcolor: "background.paper" }}
                component={Link}
                selected={location.pathname === "/diagnostico60Meses"}
                to="/diagnostico60Meses"
              >
                <ListItemIcon>
                  <ListAltIcon sx={{ color: "#28a6de", fontSize: 30 }} />
                </ListItemIcon>
                <ListItemText
                  primary="CheckList"
                  sx={{ color: "#28a6de", fontSize: 30 }}
                  secondary="60 meses"
                />
              </ListItemButton>

              <ListItemButton
                sx={{ bgcolor: "background.paper" }}
                component={Link}
                selected={
                  location.pathname === `/cronogramaExecCliente/${cnpj}`
                }
                to={`/cronogramaExecCliente/${cnpj}`}
              >
                <ListItemIcon>
                  <EventNoteIcon sx={{ color: "#28a6de", fontSize: 30 }} />
                </ListItemIcon>
                <ListItemText
                  primary="Acompanhamento"
                  sx={{ color: "#28a6de", fontSize: 30 }}
                  secondary="do Projeto"
                />
              </ListItemButton>
            </Box>
            <Filiais />
            <Documentos />
          </Drawer>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

MenuCliente.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default MenuCliente
