import React, { useState, useContext } from "react"
import { AuthContext } from "../../Context/auth"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { IMaskInput } from "react-imask"
import "../../styles/styles.css"
import MailIcon from "@mui/icons-material/Mail"
import "bootstrap/dist/css/bootstrap.min.css"
import { Box } from "@mui/material"
import background from "../../assets/imgs/background.png"
import logo_bkps from "../../assets/imgs/logo_bkps.png"

export default function EsqueceuSenhaAssociado() {
  const [email, setEmail] = useState("")

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const { esqueceuSenhaAssociado } = useContext(AuthContext)

  const invite = async (e) => {
    e.preventDefault()
    esqueceuSenhaAssociado(email)
  }

  return (
    <div>
      <ToastContainer />
      <div className="col-md-6 background">
        <img src={background} alt="Logo" />
      </div>

      <img src={logo_bkps} alt="logo" className="brand" />

      <div className="container col-md-6 ">
        <div className="bloco">
          <form onSubmit={handleSubmit} className="col-md-12">
            <div className="p-3 py-1 offset-2 col-md-12">
              <br />
              <span className="span">Recuperação de Senha</span>
            </div>

            <br />
            <br />
            <div className="col-md-12">
              <span className="info">
                Digite seus dados e receba o link de recuperação no e-mail
                cadastrado
              </span>
            </div>
            <br />
            <div className="py-2 p-1">
              <div className="icon">
                <MailIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>

              <label className="label">E-mail</label>
            </div>

            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "55ch" }
              }}
              autoComplete="off"
            >
              <div className="p-3 pt-1">
                <IMaskInput
                  className="input-login"
                  placeholder="Digite o E-mail"
                  autoComplete="false"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Box>

            <button
              className="button"
              alt="Login"
              type="submit"
              onClick={invite}
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
