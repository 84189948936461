import React, { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import { toast, ToastContainer } from "react-toastify"

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography
} from "@mui/material"
import { Table, TableRow } from "semantic-ui-react"
import { saveAs } from "file-saver"
import ResponsiveDrawer from "../../components/menu"

import api from "../../Config/api"
import "./style.css"

const mdTheme = createTheme()

export default function DiagnósticoPorAssociado() {
  const [tipoDiagnostico, setTipoDiagnostico] = useState("cliente")
  const [listas, setListas] = useState({
    clientes: [],
    grupos: [],
    subgrupos: []
  })
  const [cliente, setCliente] = useState(null)
  const [grupo, setGrupo] = useState("")
  const [subgrupo, setSubgrupo] = useState("")

  const idUsuarioLogado = localStorage.getItem("id")

  const handleState = (setState) => (event) => {
    setState(event.target.value)
  }

  const handleSelecaoCliente = (clienteClicado) => {
    setCliente(clienteClicado)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const toastConfigBase = {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    }

    const load = toast.loading("...Solicitação em andamento", toastConfigBase)

    try {
      let response
      if (tipoDiagnostico === "cliente") {
        response = await api.post(
          "/diagnostico-analitico/gerar-diagnostico-cliente",
          {
            associado: idUsuarioLogado,
            cliente: cliente.idCliente
          },
          {
            responseType: "blob"
          }
        )
      } else {
        response = await api.post(
          "/diagnostico-analitico/gerar-diagnostico-grupo",
          {
            associado: idUsuarioLogado,
            grupo,
            subgrupo
          },
          {
            responseType: "blob"
          }
        )
      }

      const arquivo = new Blob([response.data], {
        type: response.headers["content-type"]
      })
      saveAs(arquivo, "relatorio-diagnostico.xlsx")

      toast.update(load, {
        ...toastConfigBase,
        render: "Efetuando o download!",
        type: "success",
        isLoading: false
      })
    } catch (error) {
      setTimeout(() => {
        toast.update(load, {
          ...toastConfigBase,
          render: `Erro no download: tente novamente mais tarde!`,
          type: "error",
          isLoading: false
        })
        console.error(error)
      }, 2000)
    }
  }

  useEffect(() => {
    async function realizarFetch() {
      try {
        const listaClientes = await api.get(
          `/lista-cliente-por-associado/${idUsuarioLogado}`
        )
        const listasGruposSubgrupos = await api.get(
          `/grupo-subgrupo/${idUsuarioLogado}`
        )
        setListas({
          clientes: listaClientes.data.associadoXCliente,
          grupos: listasGruposSubgrupos.data.grupos,
          subgrupos: listasGruposSubgrupos.data.subgrupos
        })
      } catch (error) {
        console.error(error)
      }
    }

    realizarFetch()
  }, [])

  const clienteNaoSelecionado = tipoDiagnostico === "cliente" && !cliente

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <ToastContainer closeOnClick pauseOnFocusLoss draggable pauseOnHover />
        <CssBaseline />
        <ResponsiveDrawer title="Diagnóstico" />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <div className="diag--filtro">
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 700
              }}
            >
              Filtrar por:
            </Typography>
            <RadioGroup
              row
              aria-label="Diagnóstico por"
              name="diagnosticoPor"
              value={tipoDiagnostico}
              onChange={handleState(setTipoDiagnostico)}
              defaultValue="cliente"
              className="diag-escolha"
              sx={{
                display: "flex",
                padding: "1rem",
                alignItems: "center"
              }}
            >
              <FormControlLabel
                value="cliente"
                control={<Radio />}
                label="Cliente"
              />
              <FormControlLabel
                value="grupo-subgrupo"
                control={<Radio />}
                label="Grupo/Subgrupo"
              />
            </RadioGroup>
          </div>
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <center>
              <span className="submenu">Diagnóstico</span>
            </center>
            <hr />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <form className="diag__form" onSubmit={handleSubmit}>
                    {tipoDiagnostico === "cliente" ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ width: "8%", fontWeight: 700 }}
                                align="center"
                              />
                              <TableCell
                                sx={{ width: "90%", fontWeight: 800 }}
                                align="left"
                              >
                                Cliente
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listas.clientes.map((clienteAtual, index) => (
                              <TableRow key={`cli${index}`}>
                                <TableCell>
                                  <Radio
                                    checked={
                                      clienteAtual.idCliente ===
                                      cliente?.idCliente
                                    }
                                    onClick={() =>
                                      handleSelecaoCliente(clienteAtual)
                                    }
                                  />
                                </TableCell>
                                <TableCell style={{ verticalAlign: "middle" }}>
                                  {clienteAtual.razaoSocialCliente}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="form__inputs">
                        <div className="input__select">
                          <select
                            id="grupo"
                            value={grupo}
                            onChange={handleState(setGrupo)}
                          >
                            <option value="">Todos os grupos</option>
                            {listas.grupos.map((grupo, index) => (
                              <option
                                className="select__option"
                                value={grupo}
                                key={`grupo-${index}`}
                              >
                                {grupo}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="input__select">
                          <select
                            id="subgrupo"
                            value={subgrupo}
                            onChange={handleState(setSubgrupo)}
                          >
                            <option value="">Todos os subgrupos</option>
                            {listas.subgrupos.map((subgrupo, index) => (
                              <option
                                className="select__option"
                                value={subgrupo}
                                key={`subgrupo-${index}`}
                              >
                                {subgrupo}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <Button
                      disabled={clienteNaoSelecionado}
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        width: "fit-content",
                        padding: "0.5rem 3rem",
                        marginTop: "2rem"
                      }}
                    >
                      Gerar Relatório
                    </Button>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
