import * as React from "react"
import { Link } from "react-router-dom"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ViewListIcon from "@mui/icons-material/ViewList"

export const mainListItems = (
  <>
    <ListItemButton component={Link} to="/dashboard">
      <ListItemIcon sx={{ minWidth: 40 }}>
        <DashboardIcon sx={{ color: "#28a6de", fontSize: 30 }} />
      </ListItemIcon>
      <ListItemText
        primary="Uploads"
        sx={{ color: "#28a6de", fontSize: 30 }}
        secondary="Matriz"
      />
    </ListItemButton>
    <ListItemButton component={Link} to="/diagnostico">
      <ListItemIcon sx={{ minWidth: 40 }}>
        <ViewListIcon sx={{ color: "#28a6de", fontSize: 30 }} />
      </ListItemIcon>
      <ListItemText
        primary="CheckList"
        sx={{ color: "#28a6de", fontSize: 30 }}
        secondary="Matriz"
      />
    </ListItemButton>
  </>
)

export const secondaryListItems = <></>
