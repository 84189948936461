import React, { useState, useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Up4 from "./rowsUploads/up4"
import api from "../../../Config/api"

import ResponsiveDrawer from "../../../components/menu"

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

const mdTheme = createTheme()

const NovoCliente = () => {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const params = useParams()

  const cnpj = params._id

  const [datas, setDatas] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const buscaCNPJ = { cnpj }
      const cliente = await api.post("/checklist", buscaCNPJ)
      let datas = []
      datas = cliente.data.dados.cincoAnos
      let x = 0
      const meses = []
      while (x < datas.length) {
        meses.push({ id: x, data: datas[x] })
        x++
      }
      setDatas(meses)

      const uploads = cliente.data.tipos
    }

    getCliente()
  }, [cnpj])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title=" Cliente  " />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            width: "100vh",
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Paper sx={{ overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span className="title">Arquivo</span>
                        </TableCell>

                        {datas.map((column) => (
                          <TableCell key={column.id} align="center">
                            <span className="title">
                              {formataData(column.data)}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <Up4 />
              </Paper>
            </Container>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <NovoCliente />
}
