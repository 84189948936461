import React, { useState, useContext } from "react"
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import { IMaskInput } from "react-imask"
import "../../styles/styles.css"
import "../../styles/styles.scss"
import LockIcon from "@mui/icons-material/Lock"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import background from "../../assets/imgs/background.png"
import logo_bkps from "../../assets/imgs/logo_bkps.png"
import "bootstrap/dist/css/bootstrap.min.css"
import { Box, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import { Form } from "semantic-ui-react"
import { AuthContext } from "../../Context/auth"

export default function Login() {
  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
  }

  const [state, setstate] = useState(false)

  const toggleBtn = (event) => {
    setstate((prevState) => !prevState)
  }

  const { login } = useContext(AuthContext)
  const [cnpjLogin, setCnpjLogin] = useState("")
  const [senha, setSenha] = useState("")

  const logar = async (e) => {
    e.preventDefault()
    login(cnpjLogin, senha)
  }

  return (
    <div>
      <ToastContainer />

      <div className="col-md-6 background">
        <img src={background} alt="logo de fundo" />
      </div>

      <img src={logo_bkps} alt="logo" className="brand" />

      <div className="row" />

      <div className="container col-md-6">
        <div className="bloco">
          <div onSubmit={handleSubmit}>
            <div className="container-logins-adm-associado">
              <div>
                <a className="a" href="/loginadmin">
                  <AdminPanelSettingsIcon
                    sx={{ color: "#28A6DE", fontSize: 30 }}
                  />
                  Intranet Bookeepers
                </a>
              </div>
            </div>
            <div className="offset-4 ">
              <span className="span">Faça seu login</span>
            </div>
            <div className="py-1 p-1">
              <div className="icon">
                <AccountCircleIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Login</label>
            </div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <Tooltip
                title="Informe os 8 primeiros dígitos do CNPJ"
                arrow
                placement="top-end"
              >
                <div className=" p-3 pt-1">
                  <IMaskInput
                    className="input-login"
                    placeholder="Digite o CNPJ"
                    autoComplete="false"
                    id="cnpjLogin"
                    mask="00000000"
                    name="cnpj"
                    value={cnpjLogin}
                    onChange={(e) => setCnpjLogin(e.target.value)}
                  />
                </div>
              </Tooltip>
            </Box>
            <div className="py-1 p-1">
              <div className="icon">
                <LockIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Senha</label>
            </div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <div className="p-3 pt-1">
                <input
                  className="input-login padding-right"
                  placeholder="Digite a senha"
                  autoComplete="false"
                  id="senha"
                  name="senha"
                  type={state ? "text" : "password"}
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
              </div>
            </Box>
            <div className="col-md-12 offset-9 p-3">
              <button className="btn-eye-login" onClick={toggleBtn}>
                {state ? (
                  <AiOutlineEye size={25} />
                ) : (
                  <AiOutlineEyeInvisible size={25} />
                )}
              </button>
            </div>
            <Link id="link" to="/esqueceuSenhaCliente">
              Esqueceu a senha?
            </Link>

            <button
              className="button"
              alt="Login"
              type="submit"
              onClick={logar}
            >
              Entrar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
