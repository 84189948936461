import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { toast, ToastContainer } from "react-toastify"

import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

import IconButton from "@mui/material/IconButton"
import SaveIcon from "@mui/icons-material/Save"

import { Alert } from "reactstrap"

import Modal from "@mui/material/Modal"
import api from "../../../../Config/api"

const styleModalErro = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 1,
  align: "center",
  fontSize: 10
}

const Painel = () => {
  const [openModalVazio, setOpenModalVazio] = useState(false)
  const handleOpenVazio = () => setOpenModalVazio(true)
  const handleCloseVazio = () => setOpenModalVazio(false)

  const params = useParams()

  const cnpj = params._id

  const [statusEdit, setStatusEdit] = useState("")

  // CIAP
  const [status29_id, setStatus29_id] = useState("")
  const [status29_0, setStatus29_0] = useState("")
  const [status29_1, setStatus29_1] = useState("")
  const [status29_2, setStatus29_2] = useState("")
  const [status29_3, setStatus29_3] = useState("")
  const [status29_4, setStatus29_4] = useState("")
  const [status29_5, setStatus29_5] = useState("")
  const [status29_6, setStatus29_6] = useState("")
  const [status29_7, setStatus29_7] = useState("")
  const [status29_8, setStatus29_8] = useState("")
  const [status29_9, setStatus29_9] = useState("")
  const [status29_10, setStatus29_10] = useState("")
  const [status29_11, setStatus29_11] = useState("")
  const [status29_12, setStatus29_12] = useState("")
  const [status29_13, setStatus29_13] = useState("")
  const [status29_14, setStatus29_14] = useState("")
  const [status29_15, setStatus29_15] = useState("")
  const [status29_16, setStatus29_16] = useState("")
  const [status29_17, setStatus29_17] = useState("")
  const [status29_18, setStatus29_18] = useState("")
  const [status29_19, setStatus29_19] = useState("")
  const [status29_20, setStatus29_20] = useState("")
  const [status29_21, setStatus29_21] = useState("")
  const [status29_22, setStatus29_22] = useState("")
  const [status29_23, setStatus29_23] = useState("")
  const [status29_24, setStatus29_24] = useState("")
  const [status29_25, setStatus29_25] = useState("")
  const [status29_26, setStatus29_26] = useState("")
  const [status29_27, setStatus29_27] = useState("")
  const [status29_28, setStatus29_28] = useState("")
  const [status29_29, setStatus29_29] = useState("")
  const [status29_30, setStatus29_30] = useState("")
  const [status29_31, setStatus29_31] = useState("")
  const [status29_32, setStatus29_32] = useState("")
  const [status29_33, setStatus29_33] = useState("")
  const [status29_34, setStatus29_34] = useState("")
  const [status29_35, setStatus29_35] = useState("")
  const [status29_36, setStatus29_36] = useState("")
  const [status29_37, setStatus29_37] = useState("")
  const [status29_38, setStatus29_38] = useState("")
  const [status29_39, setStatus29_39] = useState("")
  const [status29_40, setStatus29_40] = useState("")
  const [status29_41, setStatus29_41] = useState("")
  const [status29_42, setStatus29_42] = useState("")
  const [status29_43, setStatus29_43] = useState("")
  const [status29_44, setStatus29_44] = useState("")
  const [status29_45, setStatus29_45] = useState("")
  const [status29_46, setStatus29_46] = useState("")
  const [status29_47, setStatus29_47] = useState("")
  const [status29_48, setStatus29_48] = useState("")
  const [status29_49, setStatus29_49] = useState("")
  const [status29_50, setStatus29_50] = useState("")
  const [status29_51, setStatus29_51] = useState("")
  const [status29_52, setStatus29_52] = useState("")
  const [status29_53, setStatus29_53] = useState("")
  const [status29_54, setStatus29_54] = useState("")
  const [status29_55, setStatus29_55] = useState("")
  const [status29_56, setStatus29_56] = useState("")
  const [status29_57, setStatus29_57] = useState("")
  const [status29_58, setStatus29_58] = useState("")
  const [status29_59, setStatus29_59] = useState("")

  const [datas, setDatas] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/up29", { cnpj })
      const datasUp = await api.post("/checklist", { cnpj })
      let datas = []
      datas = datasUp.data.dados.cincoAnos
      let x = 0
      const meses = []
      while (x < datas.length) {
        meses.push({ id: x, data: datas[x] })
        x++
      }
      setDatas(meses)

      if (!cliente.data.up4) {
        setStatus29_id(cliente.data.tipo._id)
        setStatus29_0(cliente.data.tipo.statusCincoAnos[0])
        setStatus29_1(cliente.data.tipo.statusCincoAnos[1])
        setStatus29_2(cliente.data.tipo.statusCincoAnos[2])
        setStatus29_3(cliente.data.tipo.statusCincoAnos[3])
        setStatus29_4(cliente.data.tipo.statusCincoAnos[4])
        setStatus29_5(cliente.data.tipo.statusCincoAnos[5])
        setStatus29_6(cliente.data.tipo.statusCincoAnos[6])
        setStatus29_7(cliente.data.tipo.statusCincoAnos[7])
        setStatus29_8(cliente.data.tipo.statusCincoAnos[8])
        setStatus29_9(cliente.data.tipo.statusCincoAnos[9])
        setStatus29_10(cliente.data.tipo.statusCincoAnos[10])
        setStatus29_11(cliente.data.tipo.statusCincoAnos[11])
        setStatus29_12(cliente.data.tipo.statusCincoAnos[12])
        setStatus29_13(cliente.data.tipo.statusCincoAnos[13])
        setStatus29_14(cliente.data.tipo.statusCincoAnos[14])
        setStatus29_15(cliente.data.tipo.statusCincoAnos[15])
        setStatus29_16(cliente.data.tipo.statusCincoAnos[16])
        setStatus29_17(cliente.data.tipo.statusCincoAnos[17])
        setStatus29_18(cliente.data.tipo.statusCincoAnos[18])
        setStatus29_19(cliente.data.tipo.statusCincoAnos[19])
        setStatus29_20(cliente.data.tipo.statusCincoAnos[20])
        setStatus29_21(cliente.data.tipo.statusCincoAnos[21])
        setStatus29_22(cliente.data.tipo.statusCincoAnos[22])
        setStatus29_23(cliente.data.tipo.statusCincoAnos[23])
        setStatus29_24(cliente.data.tipo.statusCincoAnos[24])
        setStatus29_25(cliente.data.tipo.statusCincoAnos[25])
        setStatus29_26(cliente.data.tipo.statusCincoAnos[26])
        setStatus29_27(cliente.data.tipo.statusCincoAnos[27])
        setStatus29_28(cliente.data.tipo.statusCincoAnos[28])
        setStatus29_29(cliente.data.tipo.statusCincoAnos[29])
        setStatus29_30(cliente.data.tipo.statusCincoAnos[30])
        setStatus29_31(cliente.data.tipo.statusCincoAnos[31])
        setStatus29_32(cliente.data.tipo.statusCincoAnos[32])
        setStatus29_33(cliente.data.tipo.statusCincoAnos[33])
        setStatus29_34(cliente.data.tipo.statusCincoAnos[34])
        setStatus29_35(cliente.data.tipo.statusCincoAnos[35])
        setStatus29_36(cliente.data.tipo.statusCincoAnos[36])
        setStatus29_37(cliente.data.tipo.statusCincoAnos[37])
        setStatus29_38(cliente.data.tipo.statusCincoAnos[38])
        setStatus29_39(cliente.data.tipo.statusCincoAnos[39])
        setStatus29_40(cliente.data.tipo.statusCincoAnos[40])
        setStatus29_41(cliente.data.tipo.statusCincoAnos[41])
        setStatus29_42(cliente.data.tipo.statusCincoAnos[42])
        setStatus29_43(cliente.data.tipo.statusCincoAnos[43])
        setStatus29_44(cliente.data.tipo.statusCincoAnos[44])
        setStatus29_45(cliente.data.tipo.statusCincoAnos[45])
        setStatus29_46(cliente.data.tipo.statusCincoAnos[46])
        setStatus29_47(cliente.data.tipo.statusCincoAnos[47])
        setStatus29_48(cliente.data.tipo.statusCincoAnos[48])
        setStatus29_49(cliente.data.tipo.statusCincoAnos[49])
        setStatus29_50(cliente.data.tipo.statusCincoAnos[50])
        setStatus29_51(cliente.data.tipo.statusCincoAnos[51])
        setStatus29_52(cliente.data.tipo.statusCincoAnos[52])
        setStatus29_53(cliente.data.tipo.statusCincoAnos[53])
        setStatus29_54(cliente.data.tipo.statusCincoAnos[54])
        setStatus29_55(cliente.data.tipo.statusCincoAnos[55])
        setStatus29_56(cliente.data.tipo.statusCincoAnos[56])
        setStatus29_57(cliente.data.tipo.statusCincoAnos[57])
        setStatus29_58(cliente.data.tipo.statusCincoAnos[58])
        setStatus29_59(cliente.data.tipo.statusCincoAnos[59])
      }
    }

    getCliente()
  }, [cnpj])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  async function salvar(id) {
    const statusCincoAnos = [
      status29_0,
      status29_1,
      status29_2,
      status29_3,
      status29_4,
      status29_5,
      status29_6,
      status29_7,
      status29_8,
      status29_9,
      status29_10,
      status29_11,
      status29_12,
      status29_13,
      status29_14,
      status29_15,
      status29_16,
      status29_17,
      status29_18,
      status29_19,
      status29_20,
      status29_21,
      status29_22,
      status29_23,
      status29_24,
      status29_25,
      status29_26,
      status29_27,
      status29_28,
      status29_29,
      status29_30,
      status29_31,
      status29_32,
      status29_33,
      status29_34,
      status29_35,
      status29_36,
      status29_37,
      status29_38,
      status29_39,
      status29_40,
      status29_41,
      status29_42,
      status29_43,
      status29_44,
      status29_45,
      status29_46,
      status29_47,
      status29_48,
      status29_49,
      status29_50,
      status29_51,
      status29_52,
      status29_53,
      status29_54,
      status29_55,
      status29_56,
      status29_57,
      status29_58,
      status29_59
    ]

    await api.put("/editar-tipo-upload-60meses", { id, statusCincoAnos })
    toast.success("Registro salvo com sucesso!", {
      position: toast.POSITION.TOP_CENTER
    })
  }

  return (
    <div>
      {status29_0.length === 0 ? (
        <></>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <ToastContainer />
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Arquivo</TableCell>
                {datas.map((column) => (
                  <TableCell key={column.id} align="center">
                    {formataData(column.data)}
                  </TableCell>
                ))}
                <TableCell>Salvar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>XMLs</TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_0 ?? ""}
                    onChange={(e) => setStatus29_0(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_1 ?? ""}
                    onChange={(e) => setStatus29_1(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_2 ?? ""}
                    onChange={(e) => setStatus29_2(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_3 ?? ""}
                    onChange={(e) => setStatus29_3(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_4 ?? ""}
                    onChange={(e) => setStatus29_4(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_5 ?? ""}
                    onChange={(e) => setStatus29_5(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_6 ?? ""}
                    onChange={(e) => setStatus29_6(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_7 ?? ""}
                    onChange={(e) => setStatus29_7(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_8 ?? ""}
                    onChange={(e) => setStatus29_8(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_9 ?? ""}
                    onChange={(e) => setStatus29_9(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_10 ?? ""}
                    onChange={(e) => setStatus29_10(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_11 ?? ""}
                    onChange={(e) => setStatus29_11(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_12 ?? ""}
                    onChange={(e) => setStatus29_12(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_13 ?? ""}
                    onChange={(e) => setStatus29_13(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_14 ?? ""}
                    onChange={(e) => setStatus29_14(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_15 ?? ""}
                    onChange={(e) => setStatus29_15(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_16 ?? ""}
                    onChange={(e) => setStatus29_16(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_17 ?? ""}
                    onChange={(e) => setStatus29_17(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_18 ?? ""}
                    onChange={(e) => setStatus29_18(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_19 ?? ""}
                    onChange={(e) => setStatus29_19(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_20 ?? ""}
                    onChange={(e) => setStatus29_20(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_21 ?? ""}
                    onChange={(e) => setStatus29_21(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_22 ?? ""}
                    onChange={(e) => setStatus29_22(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_23 ?? ""}
                    onChange={(e) => setStatus29_23(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_24 ?? ""}
                    onChange={(e) => setStatus29_24(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_25 ?? ""}
                    onChange={(e) => setStatus29_25(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_26 ?? ""}
                    onChange={(e) => setStatus29_26(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_27 ?? ""}
                    onChange={(e) => setStatus29_27(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_28 ?? ""}
                    onChange={(e) => setStatus29_28(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_29 ?? ""}
                    onChange={(e) => setStatus29_29(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_30 ?? ""}
                    onChange={(e) => setStatus29_30(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_31 ?? ""}
                    onChange={(e) => setStatus29_31(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_32 ?? ""}
                    onChange={(e) => setStatus29_32(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_33 ?? ""}
                    onChange={(e) => setStatus29_33(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_34 ?? ""}
                    onChange={(e) => setStatus29_34(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_35 ?? ""}
                    onChange={(e) => setStatus29_35(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_36 ?? ""}
                    onChange={(e) => setStatus29_36(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_37 ?? ""}
                    onChange={(e) => setStatus29_37(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_38 ?? ""}
                    onChange={(e) => setStatus29_38(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_39 ?? ""}
                    onChange={(e) => setStatus29_39(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_40 ?? ""}
                    onChange={(e) => setStatus29_40(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_41 ?? ""}
                    onChange={(e) => setStatus29_41(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_42 ?? ""}
                    onChange={(e) => setStatus29_42(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_43 ?? ""}
                    onChange={(e) => setStatus29_43(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_44 ?? ""}
                    onChange={(e) => setStatus29_44(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_45 ?? ""}
                    onChange={(e) => setStatus29_45(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_46 ?? ""}
                    onChange={(e) => setStatus29_46(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_47 ?? ""}
                    onChange={(e) => setStatus29_47(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_48 ?? ""}
                    onChange={(e) => setStatus29_48(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_49 ?? ""}
                    onChange={(e) => setStatus29_49(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_50 ?? ""}
                    onChange={(e) => setStatus29_50(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_51 ?? ""}
                    onChange={(e) => setStatus29_51(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_52 ?? ""}
                    onChange={(e) => setStatus29_52(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_53 ?? ""}
                    onChange={(e) => setStatus29_53(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_54 ?? ""}
                    onChange={(e) => setStatus29_54(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_55 ?? ""}
                    onChange={(e) => setStatus29_55(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_56 ?? ""}
                    onChange={(e) => setStatus29_56(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_57 ?? ""}
                    onChange={(e) => setStatus29_57(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_58 ?? ""}
                    onChange={(e) => setStatus29_58(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status29_59 ?? ""}
                    onChange={(e) => setStatus29_59(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    onClick={() => salvar(status29_id)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Modal
        open={openModalVazio}
        onClose={handleCloseVazio}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalErro}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Registro salvo com sucesso.</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  )
}

export default Painel
