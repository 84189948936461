import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import { Link } from "react-router-dom"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import AssignmentIcon from "@mui/icons-material/Assignment"
import { createTheme, ThemeProvider, Tooltip } from "@mui/material"
import Paper from "@mui/material/Paper"
import Alert from "@mui/material/Alert"
import api from "../../../Config/api"

export default function Filiais() {
  const [open, setOpen] = React.useState(false)
  const [rows, setRows] = useState([])
  const cnpj = localStorage.getItem("cnpj")
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 800,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  })
  const [selectedIndex, setSelectedIndex] = React.useState(rows === 1)

  const handleListItemClick = (event, rows) => {
    setSelectedIndex(rows)
  }

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/find-cliente-cnpj", { cnpj })
      let cont = 0
      const rows = []
      while (cont < cliente.data.cliente.filiais.length) {
        rows.push({ id: cliente.data.cliente.filiais[cont] })
        cont++
      }
      setRows(rows)
    }
    getCliente()
  }, [cnpj])

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title="Para visualizar as filiais, é preciso primeiramente efetuar o upload do EFD-Contribuições!"
        arrow
      >
        <Box
          sx={{
            bgcolor: open ? "rgba(220,220,220, 0.1)" : null,
            pb: open ? 2 : 0
          }}
        >
          <ListItemButton
            onClick={() => setOpen(!open)}
            sx={{ bgcolor: "background.paper" }}
          >
            <ListItemIcon sx={{ minWidth: 40, paddingRight: 3 }}>
              <AssignmentIcon sx={{ color: "#28a6de", fontSize: 30 }} />
            </ListItemIcon>

            <ListItemText
              primary="Checklist"
              secondary="Filiais"
              sx={{ color: "#28a6de", fontSize: 30 }}
            />
            <KeyboardArrowDown
              sx={{
                mr: -1,
                opacity: 2,
                transform: open ? "rotate(-180deg)" : "rotate(0)",
                transition: "0.4s"
              }}
            />
          </ListItemButton>

          {rows.length !== 0 &&
            open &&
            rows.map((row) => (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "info.main",
                  background: "rgb(25 118 210, 10%)"
                }}
                key={row.id}
              >
                <Paper elevation={6}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.id}
                    component={Link}
                    to={`/filial/${row.id}`}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary={row.id}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              </Box>
            ))}

          {rows.length === 0 && open && (
            <Box>
              <Paper elevation={6}>
                <Alert
                  style={{ display: "flex", alignItems: "center" }}
                  severity="warning"
                >
                  Só haverá informações neste campo quando empresa possuir uma
                  ou mais filiais!
                </Alert>
              </Paper>
            </Box>
          )}
        </Box>
      </Tooltip>
    </ThemeProvider>
  )
}
