import ArticleIcon from "@mui/icons-material/Article"
import SearchIcon from "@mui/icons-material/Search"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { visuallyHidden } from "@mui/utils"
import { ptBR } from "@mui/x-data-grid"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"
import { Link, useParams } from "react-router-dom"
import api from "../../../Config/api"
import "../../../styles/styles.css"

import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import FactCheckIcon from "@mui/icons-material/FactCheck"
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded"
import { Paper } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import { minTime } from "date-fns"
import BKPSPaginationActions from "../../../components/PaginationActions"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: "grupo",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Grupo</span>
  },
  {
    id: "subgrupo",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Subgrupo</span>
  },
  {
    id: "cnpj",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">CNPJ</span>
  },
  {
    id: "razaoSocial",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Razão Social</span>
  },
  {
    id: "recebimentoContrato",
    numeric: true,
    disablePadding: true,
    label: (
      <span className="tableFont" align="center">
        Data de Recebimento
      </span>
    )
  },
  {
    id: "statusDiagnostico",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Status Diagnóstico</span>
  },
  {
    id: "totalOportunidade",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Total Oportunidades</span>
  },
  {
    id: "honorarioPotencial",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Honorário Potencial</span>
  },
  {
    id: "documentos",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Documentos</span>
  },
  {
    id: "checklist",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Checklist</span>
  },
  {
    id: "observacoes",
    numeric: false,
    disablePadding: true,
    label: <span className="tableFont">Observações</span>
  }
]

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default function TabelaClientesPorAssociados() {
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  // const [id, setId] = React.useState("");
  const [cnpjsClientesPorAssociados, setCnpjsClientesPorAssociados] =
    React.useState([])

  const [isCheckLoading, setIsCheckLoading] = useState(true)
  const [statusAtualCronograma, setstatusAtualCronograma] = useState([
    {
      cnpj: "",
      observacao: ""
    }
  ])

  // const _id = localStorage.getItem("_id");
  const params = useParams()
  const { _id } = params

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([_id])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const [rows, setRows] = useState([])
  const [originalData, setOriginalData] = useState([])

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  function handleInput(e) {
    setRows(searchTable(e.currentTarget.value, rows))
  }

  function searchTable(value) {
    const lowerCaseInputValue = value.toLowerCase()
    return originalData.filter((row) => {
      if (value.length === 0) {
        return true
      }

      const groupFilter = row.grupo?.toLowerCase().includes(lowerCaseInputValue)
      const subGroupFilter = row.subgrupo
        ?.toLowerCase()
        .includes(lowerCaseInputValue)
      const cnpjFilter = row.cnpj?.toLowerCase().includes(lowerCaseInputValue)

      const recContratoVal = new Date(
        row.recebimentoContrato
      ).toLocaleDateString()
      const recContrato = recContratoVal
        .toLowerCase()
        .includes(lowerCaseInputValue)
      const razaoSocialFilter = row.razaoSocial
        ?.toLowerCase()
        .includes(lowerCaseInputValue)

      return (
        groupFilter ||
        subGroupFilter ||
        cnpjFilter ||
        razaoSocialFilter ||
        recContrato
      )
    })
  }
  useEffect(() => {
    async function associadoSelecionado() {
      const listaClientesPorAssociados = await api.get(
        `/lista-cliente-por-associado/${_id}`
      )

      const associados = listaClientesPorAssociados.data.associadoXCliente
      var cont = 0
      const rows = []
      while (cont < associados.length) {
        rows.push({
          id: associados[cont].idCliente,
          cnpj: associados[cont].cnpjCliente,
          razaoSocial: associados[cont].razaoSocialCliente,
          grupo: associados[cont].grupo,
          subgrupo: associados[cont].subgrupo,
          diagnostico: associados[cont].diagnosticoAnalitico,
          recebimentoContrato: associados[cont].recebimentoContrato
        })
        cont++
      }
      setRows(rows)
      setOriginalData(rows)

      var cont = 0
      const cnpjsClientesPorAssociadoLocal = []
      while (cont < associados.length) {
        cnpjsClientesPorAssociadoLocal.push(associados[cont].cnpjCliente)
        cont++
      }
      setCnpjsClientesPorAssociados(cnpjsClientesPorAssociadoLocal)
    }

    associadoSelecionado()
  }, [_id])

  useEffect(() => {
    const loadStatus = async () => {
      const rowsStatus = []

      setIsCheckLoading(true)
      const allCronogramas = await Promise.all(
        cnpjsClientesPorAssociados.map((cnpj) => {
          return api.post("/checklist", { cnpj })
        })
      )

      for (const cnpj of cnpjsClientesPorAssociados) {
        const buscaCNPJ = { cnpj }

        // let cliente = await api.post("/checklist", buscaCNPJ);
        const cliente = allCronogramas.find((x) => x.data.dados.cnpj === cnpj)
        // setId(cliente.data.dados._id); // Id do cliente que teve o cnpj buscado
        // console.log("From api/checklist " + id);

        const cronograma = await cliente.data.dados.cronogramaExecucao
        let statusAtualCronograma = {}
        // console.log(cliente.data.dados.razaoSocial);
        // console.log(observacao);
        // console.log(cliente.data.dados._id);
        // console.log(cliente.data.dados);

        const etapaFinalizada = cronograma.filter(
          (item) => item.diagnosticoTributario && item.status === 1
        )
        const etapaEmAndamento = cronograma.find(
          (item) => item.diagnosticoTributario && item.status === 2
        )

        if (etapaFinalizada.length === 0 && !etapaEmAndamento)
          statusAtualCronograma = {
            cronograma: cronograma.find((x) => x.diagnosticoTributario)
          }
        if (etapaEmAndamento)
          statusAtualCronograma = { cronograma: etapaEmAndamento }

        if (etapaFinalizada.length >= 1 && !etapaEmAndamento)
          statusAtualCronograma = {
            cronograma: cronograma.find(
              (item) => item.diagnosticoTributario && item.status === 0
            )
          }

        if (etapaFinalizada.length >= 9)
          statusAtualCronograma = {
            cronograma: cronograma.find(
              (item) => item.diagnosticoTributario === "1.8"
            )
          }

        rowsStatus.push({
          cnpj: buscaCNPJ.cnpj,
          cronograma: statusAtualCronograma.cronograma
        })
      }
      setstatusAtualCronograma(rowsStatus)
      setIsCheckLoading(false)
    }

    loadStatus()
  }, [cnpjsClientesPorAssociados])

  return (
    <Box sx={{ width: "100%" }}>
      <TableCell component="th" padding="normal" align="justify" sx={{ p: 2 }}>
        <SearchIcon sx={{ color: "#28a6de", fontSize: 30 }} />
      </TableCell>
      <TableCell
        component="th"
        padding="normal"
        align="justify"
        sx={{ p: 2, width: "400px" }}
      >
        <input
          className="search"
          onChange={handleInput}
          id="input-table"
          placeholder="Pesquisar"
        />
      </TableCell>

      <TableContainer>
        <Table
          sx={{ minWidth: 450 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow hover key={row.id}>
                    <TableCell
                      align="center"
                      sx={{ padding: "16px, 31px", lineHeight: 4.27 }}
                    >
                      <span>{row.grupo}</span>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "16px, 31px", lineHeight: 4.27 }}
                    >
                      <span>
                        <span>{row.subgrupo}</span>
                      </span>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      padding="none"
                      align="center"
                      sx={{ pr: 4, pl: 1 }}
                    >
                      {row.cnpj}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      padding="none"
                      align="center"
                    >
                      <Link
                        to={`/cronogramaExecAssociado/${row.cnpj}`}
                        style={{
                          textDecoration: "none",
                          textDecorationColor: "black"
                        }}
                        className="table_row__link"
                      >
                        {row.razaoSocial}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {!row.recebimentoContrato ||
                      row.recebimentoContrato === minTime
                        ? "-"
                        : new Date(
                            row.recebimentoContrato
                          ).toLocaleDateString()}
                    </TableCell>

                    <TableCell align="center">
                      <Link
                        to={`/cronogramaExecAssociado/${row.cnpj}`}
                        style={{
                          textDecoration: "none",
                          textDecorationColor: "black"
                        }}
                      >
                        <span>
                          {isCheckLoading ? (
                            <CircularProgress />
                          ) : (
                            statusAtualCronograma
                              .filter(
                                (item) =>
                                  item.cnpj === row.cnpj &&
                                  item.cronograma?.diagnosticoTributario
                              )
                              .map(
                                (item) => item.cronograma.diagnosticoTributario
                              )
                              .map((item) => {
                                // console.log(item);
                                return item === "1.1"
                                  ? "1/8 Etapas - Obtenção dos dados"
                                  : item === "1.2"
                                  ? "2/8 Etapas - Validação"
                                  : item === "1.3"
                                  ? "3/8 Etapas - Diagnóstico"
                                  : item === "1.4"
                                  ? "4/8 Etapas - Triagens"
                                  : item === "1.5"
                                  ? "5/8 Etapas - Apresentação"
                                  : item === "1.6"
                                  ? "6/8 Etapas - Aprovação"
                                  : item === "1.7"
                                  ? "7/8 Etapas - Valor"
                                  : item === "1.8"
                                  ? "8/8 Etapas - Honorários"
                                  : item === "1.0"
                                  ? "0/8 Etapas - Data Contrato"
                                  : ""
                              })
                          )}
                          <br />
                          {!isCheckLoading &&
                            statusAtualCronograma
                              .filter(
                                (item) =>
                                  item.cnpj === row.cnpj && item.cronograma
                              )
                              .map((item) => item.cronograma.status)
                              .map((item) => {
                                return item === 0 ? (
                                  <CancelIcon sx={{ color: "red" }} />
                                ) : item === 1 ? (
                                  <CheckCircleIcon sx={{ color: "green" }} />
                                ) : item === 2 ? (
                                  <UpdateRoundedIcon sx={{ color: "gold" }} />
                                ) : (
                                  ""
                                )
                              })}
                        </span>
                      </Link>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "16px, 31px", lineHeight: 4.27 }}
                    >
                      {/* <span>
                        {rows
                          .filter((item) => item.cnpj === row.cnpj)
                          .map(
                            (item) =>
                              item.diagnostico?.totalDasOportunidades || 0
                          )}
                      </span> */}
                      <span>
                        {rows
                          .filter((item) => item.cnpj === row.cnpj)
                          .map((item) => (
                            <NumericFormat
                              value={
                                item.diagnostico?.totalDasOportunidades || 0
                              }
                              allowLeadingZeros
                              thousandSeparator="."
                              decimalSeparator=","
                              allowedDecimalSeparators={[","]}
                              prefix="R$ "
                              displayType="text"
                            />
                          ))}
                      </span>
                      {/* <TextField */}
                      {/*  id="totalOportunidades" */}
                      {/*  label="" */}
                      {/*  sx={{ height: "54px", top: "3px" }} */}
                      {/*  value={rows */}
                      {/*    .filter((item) => item.cnpj === row.cnpj) */}
                      {/*    .map( */}
                      {/*      (item) => */}
                      {/*        item.diagnostico?.totalDasOportunidades || 0 */}
                      {/*    )} */}
                      {/*  InputProps={{ */}
                      {/*    readOnly: true, */}
                      {/*  }} */}
                      {/* /> */}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: "16px, 31px", lineHeight: 4.27 }}
                    >
                      {/* <span>
                        {rows
                          .filter((item) => item.cnpj === row.cnpj)
                          .map((item) =>
                            item.diagnostico?.oportunidades?.reduce(
                              (acc, x) =>
                                acc + parseInt(x.previsaoDeHonorarios || 0),
                              0
                            )
                          )}
                      </span> */}

                      <span>
                        {rows
                          .filter((item) => item.cnpj === row.cnpj)
                          .map(
                            (item) => {
                              const total = parseFloat(
                                item.diagnostico?.oportunidades
                                  ?.filter(
                                    (x) =>
                                      typeof x.previsaoDeHonorarios === "number"
                                  )
                                  .reduce(
                                    (acc, x) =>
                                      acc + x.previsaoDeHonorarios || 0,
                                    0
                                  ) || 0
                              )

                              return (
                                <NumericFormat
                                  value={total}
                                  allowLeadingZeros
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  allowedDecimalSeparators={[","]}
                                  prefix="R$ "
                                  displayType="text"
                                />
                              )
                            }

                            // item.diagnostico?.oportunidades?.reduce(
                            //   (acc, x) => (
                            //     <NumericFormat
                            //       value={
                            //         (acc +
                            //           parseInt(x.previsaoDeHonorarios || 0),
                            //         0)
                            //       }
                            //       allowLeadingZeros
                            //       thousandSeparator="."
                            //       decimalSeparator=","
                            //       allowedDecimalSeparators={[","]}
                            //       prefix={"R$ "}
                            //       displayType="text"
                            //     />
                            //   )
                            // )
                          )}
                      </span>

                      {/* <TextField */}
                      {/*  id="totalHonorarios" */}
                      {/*  label="" */}
                      {/*  sx={{ height: "54px", top: "3px" }} */}
                      {/*  value={rows */}
                      {/*    .filter((item) => item.cnpj === row.cnpj) */}
                      {/*    .map((item) => */}
                      {/*      item.diagnostico?.oportunidades?.reduce( */}
                      {/*        (acc, x) => */}
                      {/*          acc + parseInt(x.previsaoDeHonorarios || 0), */}
                      {/*        0 */}
                      {/*      ) */}
                      {/*    )} */}
                      {/*  InputProps={{ */}
                      {/*    readOnly: true, */}
                      {/*  }} */}
                      {/* /> */}
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/dashboard-associado/${row.cnpj}`}>
                        <ArticleIcon />
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/diagnostico/${row.cnpj}`}>
                        <FactCheckIcon />
                      </Link>
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ padding: "16px, 31px", lineHeight: 4.27 }}
                    >
                      <TextField
                        id="observacao1"
                        label=""
                        sx={{ height: "54px", top: "3px" }}
                        value={statusAtualCronograma
                          .filter(
                            (item) =>
                              item.cnpj === row.cnpj &&
                              item.cronograma?.observacao
                          )
                          .map((item) => item.cronograma.observacao)}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        localetext={ptBR.components.MuiDataGrid.defaultProps.localetext}
        component="div"
        count={rows.length}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}-${to} de ${count}`
        }}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        pagesize={10}
        rowsPerPageOptions={[5]}
        checkboxselection="true"
        disableselectiononclick="true"
        experimentalfeatures={{ newEditingApi: true }}
        ActionsComponent={BKPSPaginationActions}
      />

      <br />
      <br />
      <br />

      <TableContainer>
        <Paper elevation={6}>
          <Table sx={{ minWidth: 500 }} aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell size="small" align="center" variant="footer">
                  Legenda:
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell size="small" align="center" variant="footer">
                  <CancelIcon fontSize="small" sx={{ color: "red" }} />
                </TableCell>
                <TableCell size="small" align="left" variant="footer">
                  <p>
                    {" "}
                    Paralisada - Necessita de intervenção ou ação adicional
                  </p>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell size="small" align="center" variant="footer">
                  <UpdateRoundedIcon fontSize="small" sx={{ color: "gold" }} />
                </TableCell>
                <TableCell size="small" align="left" variant="footer">
                  <p> Fase em andamento</p>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell size="small" align="center" variant="footer">
                  <CheckCircleIcon fontSize="small" sx={{ color: "green" }} />
                </TableCell>
                <TableCell size="small" align="left" variant="footer">
                  <p> Fase concluída</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>
    </Box>
  )
}
