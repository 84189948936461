import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import DashboardIcon from "@mui/icons-material/Dashboard"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"

import { Link } from "react-router-dom"

import "./style.css"

export const mainListItems = (
  <>
    <Link to="/dashboardContadoresSA" className="linkList">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>

    <Link to="/novoClienteContadoresSA" className="linkList">
      <ListItemButton>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Novo Cliente" />
      </ListItemButton>
    </Link>

    <Link to="/clientesContadoresSA" className="linkList">
      <ListItemButton>
        <ListItemIcon>
          <PeopleAltIcon />
        </ListItemIcon>
        <ListItemText primary="Clientes" />
      </ListItemButton>
    </Link>
  </>
)

export const secondaryListItems = <></>
