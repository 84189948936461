import React, { useState, useContext } from "react"
import { AuthContext } from "../../Context/auth"
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import { IMaskInput } from "react-imask"
import "../../styles/styles.css"
import LockIcon from "@mui/icons-material/Lock"
import MailIcon from "@mui/icons-material/Mail"
import background from "../../assets/imgs/background.png"
import logo_bkps from "../../assets/imgs/logo_bkps.png"
import "bootstrap/dist/css/bootstrap.min.css"
import { Box, Tooltip } from "@mui/material"

export default function Login() {
  const { loginAdmin } = useContext(AuthContext)

  const [email, setEmail] = useState("")
  const [senha, setSenha] = useState("")
  const [state, setstate] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const toggleBtn = (event) => {
    setstate((prevState) => !prevState)
  }

  const logar = async (e) => {
    loginAdmin(email, senha)
  }

  return (
    <div>
      <ToastContainer />
      <div className="col-md-6 background">
        <img src={background} alt="logo de fundo" />
      </div>

      <img src={logo_bkps} alt="logo" className="brand" />

      <div className="row" />

      <div className="container col-md-6">
        <div className="bloco">
          <form onSubmit={handleSubmit}>
            <div id="seccurity">
              <AdminPanelSettingsIcon sx={{ color: "#000000", fontSize: 80 }} />
            </div>
            <div className="offset-4">
              <span className="span">Faça seu login</span>
            </div>
            <div className="py-1 p-1">
              <div className="icon">
                <MailIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">E-mail</label>
            </div>

            {/* <br></br> */}
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "57ch" }
              }}
              autoComplete="off"
            >
              <div className=" p-3 pt-1">
                <IMaskInput
                  className="input-login"
                  placeholder="Digite o Email"
                  autoComplete="false"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Box>
            <div className="mt-1 py-1 p-1">
              <div className="icon">
                <LockIcon sx={{ color: "#000000", fontSize: 30 }} />
              </div>
              <label className="label">Senha</label>
            </div>
            <Box
              sx={{
                "& > :not(style)": { m: 1 }
              }}
              autoComplete="off"
            >
              <div className="p-3 pt-1">
                <input
                  className="input-login padding-right"
                  placeholder="Digite a senha"
                  autoComplete="false"
                  id="senha"
                  name="senha"
                  type={state ? "text" : "password"}
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
              </div>
            </Box>
            <div className="col-md-12 offset-9 p-3">
              <button className="btn-eye" onClick={toggleBtn}>
                {state ? (
                  <AiOutlineEye size={25} />
                ) : (
                  <AiOutlineEyeInvisible size={25} />
                )}
              </button>
            </div>

            <a id="link2" href="/esqueceuSenhaAdmin">
              Esqueceu sua senha?
            </a>

            <button
              className="button"
              alt="Login"
              type="submit"
              onClick={logar}
            >
              Entrar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
