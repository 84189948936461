import { TableHead } from "@mui/material"
import React, { useMemo } from "react"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TextField from "@mui/material/TextField"
import { produce } from "immer"
import TableBody from "@mui/material/TableBody"
import { NumericFormat } from "react-number-format"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import MenuRoundedIcon from "@mui/icons-material/MenuRounded"
import Button from "@mui/material/Button"
import { formatDate, getStatusCampoColor, getStatusColor } from "./utils"
import { ITabelaAnalitica, ITabelaAnaliticaOportunidade } from "./types"
import TabelaAnaliticaField from "./TabelaAnaliticaField"

type IPageData = {
  screenData: ITabelaAnalitica
  setScreenData: (newScreenData: ITabelaAnalitica) => void
  setShowModalFuturo: (
    show: boolean,
    row: ITabelaAnaliticaOportunidade,
    index: number
  ) => void
  creditoDisponivelFinal: number
  handleRemoveRow: (index: number) => void
  isReadOnly: boolean
  isCliente?: boolean
}

const TabelaAnaliticaBody = (pageData: IPageData) => {
  const {
    screenData,
    setScreenData,
    setShowModalFuturo,
    creditoDisponivelFinal,
    handleRemoveRow,
    isReadOnly,
    isCliente
  } = pageData

  const columnIsReadOnly = useMemo(() => {
    return {
      tributo: isReadOnly,
      nivel: isReadOnly,
      status: isReadOnly,
      periodo: isReadOnly,
      descricao: isReadOnly,
      oportunidade: isReadOnly,
      statusAprovacao: isReadOnly,
      statusCampo: isReadOnly,
      perdaMediaMensalEstimada: isReadOnly,
      previsaoDeHonorarios: isReadOnly,
      valorParcial1: isReadOnly,
      valorParcial2: isReadOnly,
      valorParcial3: isReadOnly,
      valorParcial4: isReadOnly,
      valorParcial5: isReadOnly,
      futuros: isReadOnly,
      observacoes: isReadOnly,
      remover: isReadOnly,
      valorJurosSelic: isReadOnly,
      valoresAtingidosPelaPrescricao: isReadOnly
    }
  }, [isReadOnly])

  return (
    <TableBody>
      {screenData.oportunidades.map((row, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={index}>
            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.tributo}
              field="tributo"
              fieldType="text"
              placeholder="Tributo"
            />

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.nivel}
              field="nivel"
              fieldType="number"
              placeholder="Nivel"
              isMoney={false}
            />
            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.status}
              field="status"
              fieldType="text"
              placeholder="Status"
            />
            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.periodo}
              field="periodo"
              fieldType="text"
              placeholder="Periodo"
            />
            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.descricao}
              field="descricao"
              fieldType="multiline"
              placeholder="Descricao"
            />

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.oportunidade}
              field="oportunidade"
              fieldType="number"
              placeholder="oportunidade"
              isMoney
            />

            <TableCell align="center" style={{ backgroundColor: "white" }}>
              {columnIsReadOnly.statusCampo ? (
                <span
                  style={{
                    color: getStatusColor(
                      screenData.oportunidades[index].statusAprovacao
                    ),
                    width: "150px"
                  }}
                >
                  {screenData.oportunidades[index].statusAprovacao}
                </span>
              ) : (
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <Select
                    labelId={`status${index}`}
                    id={`status${index}`}
                    style={{
                      color: getStatusColor(
                        screenData.oportunidades[index].statusAprovacao
                      ),
                      width: "150px"
                    }}
                    value={screenData.oportunidades[index].statusAprovacao}
                    label="Status"
                    onChange={(e) => {
                      const newData = produce(screenData, (draft) => {
                        draft.oportunidades[index].statusAprovacao =
                          e.target.value
                      })
                      setScreenData(newData)
                    }}
                  >
                    <MenuItem
                      value="Aprovado"
                      style={{
                        backgroundColor: "white",
                        color: "green"
                      }}
                    >
                      Aprovado
                    </MenuItem>
                    <MenuItem
                      value="Não Aprovado"
                      style={{
                        backgroundColor: "white",
                        color: "red"
                      }}
                    >
                      Não Aprovado
                    </MenuItem>
                    <MenuItem
                      value="Pendente"
                      style={{
                        backgroundColor: "white",
                        color: "gold"
                      }}
                    >
                      Pendente
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </TableCell>

            <TableCell align="center" style={{ backgroundColor: "white" }}>
              {columnIsReadOnly.statusCampo ? (
                <span
                  style={{
                    color: getStatusCampoColor(
                      screenData.oportunidades[index].statusCampo
                    ),
                    width: "220px"
                  }}
                >
                  {screenData.oportunidades[index].statusCampo}
                </span>
              ) : (
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <Select
                    labelId={`statusCampo${index}`}
                    id={`status${index}`}
                    style={{
                      color: getStatusCampoColor(
                        screenData.oportunidades[index].statusCampo
                      ),
                      width: "220px"
                    }}
                    value={screenData.oportunidades[index].statusCampo}
                    label="Status"
                    onChange={(e) => {
                      const newData = produce(screenData, (draft) => {
                        draft.oportunidades[index].statusCampo = e.target.value
                      })
                      setScreenData(newData)
                    }}
                  >
                    <MenuItem
                      value="Em Andamento"
                      style={{
                        backgroundColor: "white",
                        color: getStatusCampoColor("Em Andamento")
                      }}
                    >
                      Em Andamento
                    </MenuItem>
                    <MenuItem
                      value="Concluído"
                      style={{
                        backgroundColor: "white",
                        color: getStatusCampoColor("Concluído")
                      }}
                    >
                      Concluído
                    </MenuItem>
                    <MenuItem
                      value="Não Confirmado"
                      style={{
                        backgroundColor: "white",
                        color: getStatusCampoColor("Não Confirmado")
                      }}
                    >
                      Não Confirmado
                    </MenuItem>
                    <MenuItem
                      value="Pendente de Arquivos"
                      style={{
                        backgroundColor: "white",
                        color: getStatusCampoColor("Pendente de Arquivos")
                      }}
                    >
                      Pendente de Arquivos
                    </MenuItem>
                    <MenuItem
                      value="Suspenso"
                      style={{
                        backgroundColor: "white",
                        color: getStatusCampoColor("Suspenso")
                      }}
                    >
                      Suspenso
                    </MenuItem>
                    <MenuItem
                      value="Crédito em Utilização"
                      style={{
                        backgroundColor: "white",
                        color: getStatusCampoColor("Crédito em Utilização")
                      }}
                    >
                      Crédito em Utilização
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </TableCell>
            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.perdaMediaMensalEstimada}
              field="perdaMediaMensalEstimada"
              fieldType="number"
              placeholder="Perda Mensal"
              isMoney
              decimalPlaces={2}
            />

            {!isCliente && (
              <TabelaAnaliticaField
                screenData={screenData}
                setScreenData={setScreenData}
                row={row}
                index={index}
                isReadOnly={columnIsReadOnly.previsaoDeHonorarios}
                field="previsaoDeHonorarios"
                fieldType="number"
                placeholder="Previsão de Honorários"
                isMoney
                decimalPlaces={0}
              />
            )}

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.valorParcial1}
              field="valorParcial1"
              fieldType="number"
              placeholder="Parcial 1"
              isMoney
              decimalPlaces={2}
            />

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.valorParcial2}
              field="valorParcial2"
              fieldType="number"
              placeholder="Parcial 2"
              isMoney
              decimalPlaces={2}
            />

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.valorParcial3}
              field="valorParcial3"
              fieldType="number"
              placeholder="Parcial 3"
              isMoney
              decimalPlaces={2}
            />

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.valorParcial4}
              field="valorParcial4"
              fieldType="number"
              placeholder="Parcial 4"
              isMoney
              decimalPlaces={2}
            />

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.valorParcial5}
              field="valorParcial5"
              fieldType="number"
              placeholder="Parcial 5"
              isMoney
              decimalPlaces={2}
            />

            <TableCell
              align="center"
              style={{
                // position: "sticky",
                right: 0,
                backgroundColor: "white"
              }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <MenuRoundedIcon
                  sx={{
                    color: "#28a6de",
                    fontSize: 30,
                    cursor: "pointer"
                  }}
                  onClick={() => setShowModalFuturo(true, row, index)}
                />

                <NumericFormat
                  value={screenData.oportunidades[index].futuros.reduce(
                    (acc, x) => acc + parseFloat(x.valor || 0),
                    0
                  )}
                  allowLeadingZeros
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale
                  decimalScale={2}
                  allowedDecimalSeparators={[","]}
                  prefix="R$ "
                  displayType="text"
                />
              </span>
            </TableCell>

            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <NumericFormat
                value={
                  parseFloat(
                    (screenData.oportunidades[index].valorParcial1 as any) || 0
                  ) +
                  parseFloat(
                    (screenData.oportunidades[index].valorParcial2 as any) || 0
                  ) +
                  parseFloat(
                    (screenData.oportunidades[index].valorParcial3 as any) || 0
                  ) +
                  parseFloat(
                    (screenData.oportunidades[index].valorParcial4 as any) || 0
                  ) +
                  parseFloat(
                    (screenData.oportunidades[index].valorParcial5 as any) || 0
                  ) +
                  screenData.oportunidades[index].futuros.reduce(
                    (acc, x) => acc + parseFloat(x.valor || 0),
                    0
                  )
                }
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
              />
            </TableCell>

            <TabelaAnaliticaField
              screenData={screenData}
              setScreenData={setScreenData}
              row={row}
              index={index}
              isReadOnly={columnIsReadOnly.observacoes}
              field="observacoes"
              fieldType="multiline"
              placeholder="Observacoes"
            />

            {!columnIsReadOnly.remover && (
              <TableCell align="center">
                <Button
                  sx={{
                    "&:hover": {
                      background: "red",
                      color: "white"
                    },
                    color: "red",
                    cursor: "pointer"
                  }}
                  onClick={() => handleRemoveRow(index)}
                >
                  REMOVER
                </Button>
              </TableCell>
            )}
          </TableRow>
        )
      })}
      {screenData ? (
        <>
          <TableRow>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell
              style={{
                left: 0,
                backgroundColor: "white",
                width: "50%"
              }}
            >
              <span className="table-title">Total das Oportunidades</span>
            </TableCell>
            <TableCell
              align="center"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={parseFloat(
                  screenData.oportunidades
                    .filter((x) => typeof x.oportunidade === "number")
                    .reduce(
                      (acc, x) => acc + (x.oportunidade as number),
                      0
                    ) as any
                )}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell
              align="center"
              style={{ left: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={parseFloat(
                  screenData.oportunidades
                    .filter(
                      (x) => typeof x.perdaMediaMensalEstimada === "number"
                    )
                    .reduce(
                      (acc, x) => acc + x.perdaMediaMensalEstimada || 0,
                      0
                    ) as any
                )}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "red"
                }}
              />
            </TableCell>

            {!isCliente && (
              <TableCell
                align="center"
                style={{ right: 0, backgroundColor: "white" }}
              >
                <NumericFormat
                  value={parseFloat(
                    screenData.oportunidades
                      .filter((x) => typeof x.previsaoDeHonorarios === "number")
                      .reduce(
                        (acc, x) =>
                          acc + (x.previsaoDeHonorarios as number) || 0,
                        0
                      ) as any
                  )}
                  allowLeadingZeros
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale
                  decimalScale={2}
                  allowedDecimalSeparators={[","]}
                  prefix="R$ "
                  displayType="text"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                />
              </TableCell>
            )}

            <TableCell
              style={{ left: 0, backgroundColor: "white" }}
              align="center"
            >
              <NumericFormat
                value={screenData.oportunidades.reduce(
                  (acc, x) => acc + x.valorParcial1,
                  0
                )}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={screenData.oportunidades.reduce(
                  (acc, x) => acc + x.valorParcial2,
                  0
                )}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={screenData.oportunidades.reduce(
                  (acc, x) => acc + x.valorParcial3,
                  0
                )}
                allowLeadingZeros
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={screenData.oportunidades.reduce(
                  (acc, x) => acc + x.valorParcial4,
                  0
                )}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={screenData.oportunidades.reduce(
                  (acc, x) => acc + x.valorParcial5,
                  0
                )}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="center"
              style={{
                right: 0,
                backgroundColor: "white"
              }}
            >
              <NumericFormat
                value={screenData.oportunidades.reduce(
                  (acc, x) =>
                    acc +
                    x.futuros.reduce((acc2, y) => {
                      return acc2 + parseFloat(y.valor)
                    }, 0),
                  0
                )}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={screenData.oportunidades.reduce((acc, x) => {
                  const linhaTotal =
                    parseFloat((x.valorParcial1 as any) || 0) +
                    parseFloat((x.valorParcial2 as any) || 0) +
                    parseFloat((x.valorParcial3 as any) || 0) +
                    parseFloat((x.valorParcial4 as any) || 0) +
                    parseFloat((x.valorParcial5 as any) || 0) +
                    x.futuros.reduce(
                      (acc2, y) => acc2 + parseFloat(y.valor || 0),
                      0
                    )

                  return acc + linhaTotal
                }, 0)}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="right"
              style={{ right: 0, backgroundColor: "white" }}
            />
          </TableRow>
          <TableRow>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span className="table-title">Total Entregue</span>
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell
              align="center"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <NumericFormat
                value={screenData.oportunidades.reduce((acc, x) => {
                  const linhaTotal =
                    parseFloat((x.valorParcial1 as any) || 0) +
                    parseFloat((x.valorParcial2 as any) || 0) +
                    parseFloat((x.valorParcial3 as any) || 0) +
                    parseFloat((x.valorParcial4 as any) || 0) +
                    parseFloat((x.valorParcial5 as any) || 0) +
                    x.futuros.reduce(
                      (acc2, y) => acc2 + parseFloat(y.valor || 0),
                      0
                    )
                  return acc + linhaTotal
                }, 0)}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px", height: "30px" }}
              />
            </TableCell>
            <TableCell
              align="right"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <span className="table-title" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span className="table-title" style={{ color: "red" }}>
                Valores atingidos pela prescricao
              </span>
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                right: 0,
                backgroundColor: "white",
                color: "red"
              }}
            >
              <NumericFormat
                value={screenData.valoresAtingidosPelaPrescricao}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                allowedDecimalSeparators={[","]}
                fixedDecimalScale
                decimalScale={2}
                prefix="R$ "
                displayType={
                  columnIsReadOnly.valoresAtingidosPelaPrescricao
                    ? "text"
                    : "input"
                }
                style={{
                  top: "3",
                  height: "25px",
                  width: "120px",
                  border: "none",
                  borderBottom: "1px solid #cccccc",
                  color: "red"
                }}
                onValueChange={(values, sourceInfo) => {
                  if (columnIsReadOnly.valoresAtingidosPelaPrescricao) return
                  const newData = produce(screenData, (draft) => {
                    draft.valoresAtingidosPelaPrescricao = parseFloat(
                      values.floatValue as any
                    )
                  })
                  setScreenData(newData)
                }}
              />
              {/* <TextField
                            sx={{ height: "14px", top: "3px", text: "red" }}
                            value={screenData.valoresAtingidosPelaPrescricao}
                            size="small"
                            onChange={(e) => {
                              const newData = produce(screenData, (draft) => {
                                draft.valoresAtingidosPelaPrescricao =
                                  parseFloat(e.target.value || 0);
                              });
                              setScreenData(newData);
                            }}
                            variant="standard"
                            placeholder="Valor Prescrito"
                          /> */}
            </TableCell>
            <TableCell
              align="right"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <span className="table-title" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span className="table-title">
                Total dos créditos disponíveis
              </span>
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                right: 0,
                backgroundColor: "white"
              }}
            >
              <NumericFormat
                value={creditoDisponivelFinal}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px", height: "30px" }}
              />
            </TableCell>
            <TableCell
              align="right"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <span className="table-title" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span className="table-title">Valor dos juros - SELIC</span>
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                right: 0,
                backgroundColor: "white"
              }}
            >
              <NumericFormat
                value={screenData.valorJurosSelic}
                allowLeadingZeros
                thousandSeparator="."
                decimalSeparator=","
                allowedDecimalSeparators={[","]}
                fixedDecimalScale
                decimalScale={2}
                displayType={
                  columnIsReadOnly.valorJurosSelic ? "text" : "input"
                }
                prefix="R$ "
                style={{
                  top: "3px",
                  height: "28px",
                  width: "120px",
                  border: "none",
                  borderBottom: "1px solid #cccccc"
                }}
                onValueChange={(values, sourceInfo) => {
                  if (columnIsReadOnly.valorJurosSelic) return
                  const newData = produce(screenData, (draft) => {
                    draft.valorJurosSelic = parseFloat(values.floatValue as any)
                  })
                  setScreenData(newData)
                }}
              />

              {/* <TextField
                            id="valorSelic"
                            sx={{ height: "14px", top: "3px" }}
                            value={screenData.valorJurosSelic}
                            size="small"
                            onChange={(e) => {
                              const newData = produce(screenData, (draft) => {
                                draft.valorJurosSelic = parseFloat(
                                  e.target.value || 0
                                );
                              });
                              setScreenData(newData);
                            }}
                            variant="standard"
                            placeholder="Valor Selic"
                          /> */}
            </TableCell>
            <TableCell
              align="right"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <span className="table-title" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span className="table-title">
                Total dos créditos disponíveis - Atualizado (se houver)
              </span>
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell style={{ left: 0, backgroundColor: "white" }}>
              <span />
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                right: 0,
                backgroundColor: "white"
              }}
            >
              <NumericFormat
                value={
                  parseFloat(
                    screenData.oportunidades.reduce((acc, x) => {
                      const linhaTotal =
                        parseFloat((x.valorParcial1 as any) || 0) +
                        parseFloat((x.valorParcial2 as any) || 0) +
                        parseFloat((x.valorParcial3 as any) || 0) +
                        parseFloat((x.valorParcial4 as any) || 0) +
                        parseFloat((x.valorParcial5 as any) || 0) +
                        x.futuros.reduce(
                          (accF, y) => accF + parseFloat(y.valor || 0),
                          0
                        )
                      return acc + linhaTotal
                    }, 0) as any
                  ) +
                  parseFloat(
                    (screenData.valoresAtingidosPelaPrescricao as any) || 0
                  ) +
                  parseFloat((screenData.valorJurosSelic as any) || 0)
                }
                allowLeadingZeros
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                allowedDecimalSeparators={[","]}
                prefix="R$ "
                displayType="text"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TableCell>
            <TableCell
              align="right"
              style={{ right: 0, backgroundColor: "white" }}
            >
              <span className="table-title" />
            </TableCell>
          </TableRow>
        </>
      ) : null}
    </TableBody>
  )
}
export default TabelaAnaliticaBody
