import React, { useContext } from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom"
import { AuthProvider, AuthContext } from "./Context/auth"
import Login from "./Pages/Login/index"
import LoginAdmin from "./Pages/LoginAdmin/index"
import NovaSenha from "./Pages/TrocaSenha/novaSenha"
import NovaSenhaAdmin from "./Pages/TrocaSenha/novaSenhaAdmin"
import EsqueceuSenhaCliente from "./Pages/EsqueceuSenha/esqueceuSenhaCliente"
import EsqueceuSenhaAdmin from "./Pages/EsqueceuSenha/esqueceuSenhaAdmin"

import LoginContadoresSA from "./Pages/LoginContadoresSA/index"
import DashboardCliente from "./Pages/DashboardCliente/Dashboard"
import CheckListCampo from "./Pages/DashboardCliente/CheckListCampo"
import CheckListCampoDocumentos from "./Pages/DashboardCliente/ChecklistCampoDocumentos"
import CronogramaExecCliente from "./Pages/DashboardCliente/CronogramaExecCliente"

import CheckListSped from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListSped"
import CheckListCIAP from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/ChecklistCIAP"
import CheckListFontesPag from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/ChecklistFontesPagadoras"
import CheckListResumoFolha from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/ChecklistResumoFolha"
import CheckListPrintFAP from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/ChecklistPrintFAP"
import CheckListGPS from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/ChecklistGPS"
import CheckListINSSRubrica from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/ChecklistINSSRubrica"
import CheckListArquivosCompactados from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/ChecklistArquivosCompactados"
import CheckListPinsCofinsSintetica from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListPinsCofinsSintetica"
import CheckListPinsCofinsAnalitica from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListPinsCofinsAnalitica"

import CheckListICMSAntecipacao from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListICMSAntecipacao"
import CheckListDCTFs from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListDCTFs"
import CheckListRazoesContabeis from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListRazoesContabeis"
import CheckListEFDContribuicoes from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListEFDContribuicoes"
import CheckListTabelaIncidenciaINSS from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListTabelaIncidenciaINSS"
import CheckListXMLsEntradas from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListXMLsEntradas"
import CheckListXMLsSaidas from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListXMLsSaidas"
import CheckListGuiaICMS from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListGuiaICMS"
import CheckListComprovanteArrecadacaoTributos from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListComprovanteArrecadacaoTributos"
import CheckListSpedContabil from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListSpedContabil"
import CheckListECF from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListECF"
import CheckListMemoriaIRPJeCSLL from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListMemoriaIRPJeCSLL"
import CheckListMemoriaCPRBDesoneracao from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListMemoriaCPRBDesoneracao"
import CheckListDCTFWEB from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListDCTFWEB"
import CheckListDARF from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListDARF"
import CheckListDAS from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListDAS"
import CheckListExtratoSimplesNacional from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListExtratoSimplesNacional"
import CheckListAtivoImobilizado from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListAtivoImobilizado"
import CheckListCreditosExtemporaneos from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListCreditosExtemporaneos"
import CheckListBalancetesContabeis from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListBalancetesContabeis"
import CheckListBalanceteContabilUltimoNaoEncerrado from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListBalanceteContabilUltimoNaoEncerrado"
import CheckListQuestionarioPrevio from "./Pages/DashboardCliente/Diagnostico/ChecklistPorDocumentos/CheckListQuestionarioPrevio"

import TabelaAnaliticaCliente from "./Pages/DashboardAdmin/TabelaAnaliticaCliente/TabelaAnaliticaCliente"
import TabelaAnaliticaVisaoCliente from "./Pages/DashboardCliente/TabelaAnaliticaCliente/TabelaAnaliticaVisaoCliente"

import Relatorio from "./Pages/DashboardCliente/Relatorio"
import Filial from "./Pages/DashboardCliente/Diagnostico/DiagnosticoFilial"

import DiagnosticoCliente from "./Pages/DashboardCliente/Diagnostico/Diagnostico"
import Diagnostico60Meses from "./Pages/DashboardCliente/Diagnostico/Diagnostico60Meses"
import DashboardAdmin from "./Pages/DashboardAdmin/Dashboard"
import NovoCliente from "./Pages/DashboardAdmin/NovoCliente"
import NovoClienteProximo from "./Pages/DashboardAdmin/NovoClienteProximo"
import Clientes from "./Pages/DashboardAdmin/Clientes"
import Cliente from "./Pages/DashboardAdmin/Cliente"
import EditarCliente from "./Pages/DashboardAdmin/EditarCliente"
import Diagnostico from "./Pages/DashboardAdmin/Diagnostico/Diagnostico"

import CronogramaExec from "./Pages/DashboardAdmin/CronogramaExec"

import DiagnosticoFilial from "./Pages/DashboardAdmin/Diagnostico/DiagnosticoFilial"
// import Up15 from './Pages/DashboardAdmin/Diagnostico/TabelasFiliais/up15';

import TrabalhoCampo from "./Pages/DashboardAdmin/Tabelas/TrabalhoCampo"
import DashboardContadoresSA from "./Pages/DashboardContadoresSA/Dashboard"
import NovoClienteContadoresSA from "./Pages/DashboardContadoresSA/NovoCliente"
import NovoClienteProximoContadoresSA from "./Pages/DashboardContadoresSA/NovoClienteProximo"
import ClientesContadoresSA from "./Pages/DashboardContadoresSA/Clientes"
import EditarListaDocCliente from "./Pages/DashboardAdmin/EditarListaDocCliente"
import NovoAssociado from "./Pages/DashboardAdmin/NovoAssociado"
import Associados from "./Pages/DashboardAssociado/Associados"
import EditarAssociado from "./Pages/DashboardAdmin/EditarAssociado"
import ClientesPorAssociado from "./Pages/DashboardAssociado/ClientesPorAssociado"
import DashboardAssociado from "./Pages/DashboardAssociado/DashboardAssociado"
import CronogramaExecAssociado from "./Pages/DashboardAssociado/CronogramaExecAssociado"

import AddAssociadoAoCliente from "./Pages/DashboardAdmin/AddAssociadoAoCliente"
import EditarAssociadoAoCliente from "./Pages/DashboardAdmin/EditarAssociadoAoCliente"
import LoginAssociado from "./Pages/LoginAssociado"
import EsqueceuSenhaAssociado from "./Pages/EsqueceuSenha/esqueceuSenhaAssociado"
import NovaSenhaAssociado from "./Pages/TrocaSenha/novaSenhaAssociado"
import DiagnósticoPorAssociado from "./Pages/DashboardAssociado/DiagnosticoPorAssociado"
import TabelaAnaliticaClienteAssociado from "./Pages/DashboardAssociado/TabelaAnaliticaClienteVistaAssociado"

const Rotas = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext)
    if (loading) {
      return <div className="loading">Carregando</div>
    }
    if (!authenticated) {
      return <Navigate to="/" />
    }
    return children
  }
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/** ******** Sessão de Login Admin e Cliente ********** */}
          <Route exact path="/" element={<Login />} />
          <Route exact path="/loginadmin" element={<LoginAdmin />} />
          <Route
            exact
            path="/logincontadoressa"
            element={<LoginContadoresSA />}
          />
          <Route exact path="/loginAssociado" element={<LoginAssociado />} />
          <Route exact path="/novasenha" element={<NovaSenha />} />
          <Route exact path="/novasenhaadmin" element={<NovaSenhaAdmin />} />
          <Route
            exact
            path="/novaSenhaAssociado"
            element={<NovaSenhaAssociado />}
          />
          <Route
            exact
            path="/esqueceuSenhaCliente"
            element={<EsqueceuSenhaCliente />}
          />
          <Route
            exact
            path="/esqueceuSenhaAdmin"
            element={<EsqueceuSenhaAdmin />}
          />
          <Route
            exact
            path="/esqueceuSenhaAssociado"
            element={<EsqueceuSenhaAssociado />}
          />
          {/** ******** Fim da Sessão de Login Admin e Cliente ********** */}

          {/** ******** Sessão de Upload Matriz/Filial ********** */}
          <Route
            exact
            path="/dashboard/"
            element={
              <Private>
                <DashboardCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/dashboard/:_id"
            element={
              <Private>
                <DashboardCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/filial/:_id"
            element={
              <Private>
                <Filial />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico"
            element={
              <Private>
                <DiagnosticoCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico60Meses"
            element={
              <Private>
                <Diagnostico60Meses />
              </Private>
            }
          />
          {/** ******** Fim da Sessão de Upload Matriz/Filial ********** */}

          {/** ******** Sessão de Checklist por documentos Matriz/Filial ********** */}
          {/** * Tipos Amostragem ** */}
          <Route
            exact
            path="/DiagnosticoDocPinsCofinsSintetica/:_id"
            element={
              <Private>
                <CheckListPinsCofinsSintetica />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoDocPinsCofinsAnalitica/:_id"
            element={
              <Private>
                <CheckListPinsCofinsAnalitica />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoICMSAntecipacao/:_id"
            element={
              <Private>
                <CheckListICMSAntecipacao />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoDCTFs/:_id"
            element={
              <Private>
                <CheckListDCTFs />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoRazoesContabeis/:_id"
            element={
              <Private>
                <CheckListRazoesContabeis />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoEFDContribuicoes/:_id"
            element={
              <Private>
                <CheckListEFDContribuicoes />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoTabelaIncidenciaINSS/:_id"
            element={
              <Private>
                <CheckListTabelaIncidenciaINSS />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoXMLsEntradas/:_id"
            element={
              <Private>
                <CheckListXMLsEntradas />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoXMLsSaidas/:_id"
            element={
              <Private>
                <CheckListXMLsSaidas />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoGuiaICMS/:_id"
            element={
              <Private>
                <CheckListGuiaICMS />
              </Private>
            }
          />
          {/** * Tipos Amostragem ** */}

          {/** * Tipos Anuais ** */}
          <Route
            exact
            path="/DiagnosticoComprovanteArrecadacaoTributos/:_id"
            element={
              <Private>
                <CheckListComprovanteArrecadacaoTributos />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoDocSpedContabil/:_id"
            element={
              <Private>
                <CheckListSpedContabil />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoECF/:_id"
            element={
              <Private>
                <CheckListECF />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoMemoriaIRPJeCSLL/:_id"
            element={
              <Private>
                <CheckListMemoriaIRPJeCSLL />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoMemoriaCPRBDesoneracao/:_id"
            element={
              <Private>
                <CheckListMemoriaCPRBDesoneracao />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoDCTFWEB/:_id"
            element={
              <Private>
                <CheckListDCTFWEB />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoDARF/:_id"
            element={
              <Private>
                <CheckListDARF />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoDAS/:_id"
            element={
              <Private>
                <CheckListDAS />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoExtratoSimplesNacional/:_id"
            element={
              <Private>
                <CheckListExtratoSimplesNacional />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoArquivosCompactados/:_id"
            element={
              <Private>
                <CheckListArquivosCompactados />
              </Private>
            }
          />
          {/** * Tipos Anuais ** */}

          {/** * Tipos Sem Periodo ** */}
          <Route
            exact
            path="/DiagnosticoAtivoImobilizado/:_id"
            element={
              <Private>
                <CheckListAtivoImobilizado />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoCreditosExtemporaneos/:_id"
            element={
              <Private>
                <CheckListCreditosExtemporaneos />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoBalancetesContabeis/:_id"
            element={
              <Private>
                <CheckListBalancetesContabeis />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoBalanceteContabilUltimoNaoEncerrado/:_id"
            element={
              <Private>
                <CheckListBalanceteContabilUltimoNaoEncerrado />
              </Private>
            }
          />

          <Route
            exact
            path="/DiagnosticoQuestionarioPrevio/:_id"
            element={
              <Private>
                <CheckListQuestionarioPrevio />
              </Private>
            }
          />
          {/** * Tipos Sem Periodo ** */}

          {/** **** Tipos de Filiais ***** */}
          {/** * Tipos Amostragem ** */}
          <Route
            exact
            path="/DiagnosticoDocSped/:_id"
            element={
              <Private>
                <CheckListSped />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoDocCiap/:_id"
            element={
              <Private>
                <CheckListCIAP />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoFontesPag/:_id"
            element={
              <Private>
                <CheckListFontesPag />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoPrintFAP/:_id"
            element={
              <Private>
                <CheckListPrintFAP />
              </Private>
            }
          />
          {/** * Tipos Amostragem ** */}

          {/** * Tipos 60 Meses ** */}
          <Route
            exact
            path="/DiagnosticoResumoFolha/:_id"
            element={
              <Private>
                <CheckListResumoFolha />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoGPS/:_id"
            element={
              <Private>
                <CheckListGPS />
              </Private>
            }
          />
          <Route
            exact
            path="/DiagnosticoINSSRubrica/:_id"
            element={
              <Private>
                <CheckListINSSRubrica />
              </Private>
            }
          />
          {/** * Tipos 60 Meses ** */}
          {/** **** Tipos de Filiais ***** */}

          {/** ******** Fim da Sessão de Checklist por documentos Matriz/Filial ********** */}

          {/** ******** Sessão do Administrador ********** */}
          <Route
            exact
            path="/checklistCampo/:_id"
            element={
              <Private>
                <CheckListCampo />
              </Private>
            }
          />
          <Route
            exact
            path="/checklistCampoDocumentos/:_id"
            element={
              <Private>
                <CheckListCampoDocumentos />
              </Private>
            }
          />
          <Route
            exact
            path="/cronogramaExec/:_id"
            element={
              <Private>
                <CronogramaExec />
              </Private>
            }
          />
          <Route
            exact
            path="/tabela-analitica-cliente/:_id"
            element={
              <Private>
                <TabelaAnaliticaCliente />
              </Private>
            }
          />

          <Route
            exact
            path="/cronogramaExecCliente/:_id"
            element={
              <Private>
                <CronogramaExecCliente />
              </Private>
            }
          />

          <Route
            exact
            path="/tabela-analitica-vcliente/:_id"
            element={
              <Private>
                <TabelaAnaliticaVisaoCliente />
              </Private>
            }
          />

          <Route
            exact
            path="/relatorio"
            element={
              <Private>
                <Relatorio />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico"
            element={
              <Private>
                <DiagnosticoCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico60Meses"
            element={
              <Private>
                <Diagnostico60Meses />
              </Private>
            }
          />
          <Route
            exact
            path="/dashboardAdmin/:_id"
            element={
              <Private>
                <DashboardAdmin />
              </Private>
            }
          />
          <Route
            exact
            path="/novoCliente"
            element={
              <Private>
                <NovoCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/associado-cliente/:_id"
            element={
              <Private>
                <AddAssociadoAoCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/novoClienteProximo"
            element={
              <Private>
                <NovoClienteProximo />
              </Private>
            }
          />
          <Route
            exact
            path="/novoClienteProximo/:_id"
            element={
              <Private>
                <NovoClienteProximo />
              </Private>
            }
          />

          <Route
            exact
            path="/clientes"
            element={
              <Private>
                <Clientes />
              </Private>
            }
          />
          <Route
            exact
            path="/cliente/:_id"
            element={
              <Private>
                <Cliente />
              </Private>
            }
          />
          <Route
            exact
            path="/editar-cliente/:_id"
            element={
              <Private>
                <EditarCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/editar-doc-solictados-cliente/:_id"
            element={
              <Private>
                <EditarListaDocCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/editar-associado-ao-cliente/:_id"
            element={
              <Private>
                <EditarAssociadoAoCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico-cliente/:_id"
            element={
              <Private>
                <Diagnostico />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico-filial/:_id"
            element={
              <Private>
                <DiagnosticoFilial />
              </Private>
            }
          />
          <Route
            exact
            path="/trabalho-campo-cliente/:_id"
            element={
              <Private>
                <TrabalhoCampo />
              </Private>
            }
          />
          <Route
            exact
            path="/novoAssociado"
            element={
              <Private>
                <NovoAssociado />
              </Private>
            }
          />
          <Route
            exact
            path="/editar-associado/:_id"
            element={
              <Private>
                <EditarAssociado />
              </Private>
            }
          />
          <Route
            exact
            path="/associados"
            element={
              <Private>
                <Associados />
              </Private>
            }
          />
          {/** ******** Fim da Sessão do Administrador ********** */}

          {/** ******** Sessão de Associados ********** */}
          <Route
            exact
            path="/clientes-associado/:_id"
            element={
              <Private>
                <ClientesPorAssociado />
              </Private>
            }
          />
          <Route
            exact
            path="/dashboard-associado/:_id"
            element={
              <Private>
                <DashboardAssociado />
              </Private>
            }
          />
          <Route
            exact
            path="/cronogramaExecAssociado/:_id"
            element={
              <Private>
                <CronogramaExecAssociado />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico-associado/:_id"
            element={
              <Private>
                <DiagnósticoPorAssociado />
              </Private>
            }
          />
          <Route
            exact
            path="/diagnostico/:_id"
            element={
              <Private>
                <DiagnosticoCliente />
              </Private>
            }
          />
          <Route
            exact
            path="/tabela-analitica-cliente-associado/:_id"
            element={
              <Private>
                <TabelaAnaliticaClienteAssociado />
              </Private>
            }
          />
          {/** ******** Fim da Sessão de Associados ********** */}

          {/** ******** Sessão de Contadores sem utilidade no momento ********** */}
          <Route
            exact
            path="/dashboardContadoresSA"
            element={
              <Private>
                <DashboardContadoresSA />
              </Private>
            }
          />
          <Route
            exact
            path="/novoClienteContadoresSA"
            element={
              <Private>
                <NovoClienteContadoresSA />
              </Private>
            }
          />
          <Route
            exact
            path="/novoClienteProximoContadoresSA"
            element={
              <Private>
                <NovoClienteProximoContadoresSA />
              </Private>
            }
          />
          <Route
            exact
            path="/clientesContadoresSA"
            element={
              <Private>
                <ClientesContadoresSA />
              </Private>
            }
          />
          {/** ******** Fim da Sessão de Contadores sem utilidade no momento ********** */}
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default Rotas
