import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import Divider from "@mui/material/Divider"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import FileOpenIcon from "@mui/icons-material/FileOpen"
import { Link, useParams } from "react-router-dom"
import Paper from "@mui/material/Paper"
import api from "../../../Config/api"

export default function Documentos() {
  const [open, setOpen] = React.useState(false)
  const params = useParams()
  const cnpjCliente = params._id
  const cnpj = localStorage.getItem("cnpj")

  const [_id, set_Id] = useState("")
  const [dadosCliente, setDadosCliente] = useState({})
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 800,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  })

  const [selectedIndex, setSelectedIndex] = React.useState(rows === 1)

  const handleListItemClick = (event, rows) => {
    setSelectedIndex(rows)
  }

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/dashboard", {
        cnpj: cnpjCliente !== null ? cnpj : cnpj
      })
      setDadosCliente(cliente)
      set_Id(cliente.data.cliente._id)
      setRows(cliente.data.tipos)
      setLoading(false)
    }

    getCliente()
    setLoading(true)
  }, [cnpj])

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: open ? "rgba(220,220,220, 0.1)" : null,
          pb: open ? 2 : 0
        }}
      >
        <ListItemButton
          onClick={() => setOpen(!open)}
          sx={{ bgcolor: "background.paper" }}
        >
          <ListItemIcon sx={{ minWidth: 40, paddingRight: 3 }}>
            <FileOpenIcon sx={{ color: "#28a6de", fontSize: 30 }} />
          </ListItemIcon>
          <ListItemText
            primary="Checklist"
            sx={{ color: "#28a6de", fontSize: 30 }}
            secondary="Documentos"
          />
          <KeyboardArrowDown
            sx={{
              mr: -1,
              opacity: 2,
              transform: open ? "rotate(-180deg)" : "rotate(0)",
              transition: "0.4s"
            }}
          />
        </ListItemButton>
        {open &&
          rows.map((row) => (
            <Box
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "info.light",
                background: "rgb(25 118 210, 1%)"
              }}
            >
              {row.tipo === 34 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoBalanceteContabilUltimoNaoEncerrado/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Balancete Contábil (último não encerrado)"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 2 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDocPinsCofinsSintetica/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Planilhas memórias de cálculo Pis/Cofins"
                      secondary="Sintética"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 3 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDocPinsCofinsAnalitica/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Planilhas memórias de cálculo Pis/Cofins"
                      secondary="Analítica"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 4 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      minWidth: 20,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoICMSAntecipacao/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Memória de cálculo/Rascunho do ICMS"
                      secondary="ICMS ST e ICMS Antecipação"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 5 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDCTFs/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="DCTFs"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 6 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoBalancetesContabeis/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Balancetes Contábeis"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 16 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoAtivoImobilizado/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Bens do ativo imobilizado"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 12 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDocCiap/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="CIAP - Controle de Crédito do ICMS do Ativo Permanente"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 20 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoINSSRubrica/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Comprovante de declaração a recolher"
                      secondary="do INSS (Rubrica)"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 18 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoCreditosExtemporaneos/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Créditos Extemporâneos"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 26 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDARF/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="DARF"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 31 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDAS/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="DAS"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 27 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDCTFWEB/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="DCTFWEB"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 13 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoComprovanteArrecadacaoTributos/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="E-CAC - Comprovante de Arrecadação dos"
                      secondary="Tributos IRPJ e CSLL"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 15 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoFontesPag/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="E-CAC - Fontes Pagadoras"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 14 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoECF/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="ECF"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 10 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoEFDContribuicoes/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="EFD Contribuições"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 30 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoExtratoSimplesNacional/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Extrato Simples Nacional"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 22 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoGPS/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="GPS"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 33 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoGuiaICMS/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Guia ou Declaração de Apuração do ICMS"
                      secondary="(GIA; DMA; DAM; DAPI; GIME; GIAM)"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 7 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoRazoesContabeis/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Livros Razões Contábeis"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 24 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoMemoriaCPRBDesoneracao/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Memórias de Cálculo de CPRB/Desoneração"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 17 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoMemoriaIRPJeCSLL/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Memórias de Cálculo do IRPJ e CSLL"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 21 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoPrintFAP/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Print da FAP"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 25 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoQuestionarioPrevio/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Questionário Prévio"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 19 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoResumoFolha/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Resumo da folha de pagamento"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}

              {row.tipo === 1 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoArquivosCompactados/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Solicitações Complementares"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 8 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDocSpedContabil/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="SPED Contábil"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 9 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoDocSped/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="SPED Fiscal"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 23 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoTabelaIncidenciaINSS/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="Tabela de Incidência do INSS sobre os eventos da folha de pagamentos"
                      secondary="Contribuições Previdenciárias"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 29 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoXMLsEntradas/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="XMLs"
                      secondary="NF-es e CT-es de entradas"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
              {row.tipo === 32 ? (
                <Paper elevation={16}>
                  <ListItemButton
                    selected={selectedIndex === row}
                    onClick={(event) => handleListItemClick(event, row)}
                    key={row.cnpj}
                    component={Link}
                    sx={{
                      py: 1,
                      minHeight: 35,
                      color: "#28a6de",
                      bgcolor: "background.paper"
                    }}
                    to={`/DiagnosticoXMLsSaidas/${cnpj}`}
                  >
                    <ListItemText
                      sx={{ py: 0, minHeight: 20, color: "rgba(0,0,0.8)" }}
                      primary="XMLs"
                      secondary="NF-es e CT-es de saídas"
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "Normal",
                        color: "#28a6de"
                      }}
                    />
                  </ListItemButton>
                </Paper>
              ) : (
                ""
              )}
            </Box>
          ))}
      </Box>
    </ThemeProvider>
  )
}
