import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"

import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import api from "../../../../Config/api"

const Painel = () => {
  const params = useParams()

  const cnpj = params._id

  // CIAP
  const [status4_0, setStatus4_0] = useState("")
  const [status4_1, setStatus4_1] = useState("")
  const [status4_2, setStatus4_2] = useState("")
  const [status4_3, setStatus4_3] = useState("")
  const [status4_4, setStatus4_4] = useState("")
  const [status4_5, setStatus4_5] = useState("")
  const [status4_6, setStatus4_6] = useState("")
  const [status4_7, setStatus4_7] = useState("")
  const [status4_8, setStatus4_8] = useState("")
  const [status4_9, setStatus4_9] = useState("")
  const [status4_10, setStatus4_10] = useState("")
  const [status4_11, setStatus4_11] = useState("")
  const [status4_12, setStatus4_12] = useState("")
  const [status4_13, setStatus4_13] = useState("")
  const [status4_14, setStatus4_14] = useState("")
  const [status4_15, setStatus4_15] = useState("")
  const [status4_16, setStatus4_16] = useState("")
  const [status4_17, setStatus4_17] = useState("")
  const [status4_18, setStatus4_18] = useState("")
  const [status4_19, setStatus4_19] = useState("")
  const [status4_20, setStatus4_20] = useState("")
  const [status4_21, setStatus4_21] = useState("")
  const [status4_22, setStatus4_22] = useState("")
  const [status4_23, setStatus4_23] = useState("")
  const [status4_24, setStatus4_24] = useState("")
  const [status4_25, setStatus4_25] = useState("")
  const [status4_26, setStatus4_26] = useState("")
  const [status4_27, setStatus4_27] = useState("")
  const [status4_28, setStatus4_28] = useState("")
  const [status4_29, setStatus4_29] = useState("")
  const [status4_30, setStatus4_30] = useState("")
  const [status4_31, setStatus4_31] = useState("")
  const [status4_32, setStatus4_32] = useState("")
  const [status4_33, setStatus4_33] = useState("")
  const [status4_34, setStatus4_34] = useState("")
  const [status4_35, setStatus4_35] = useState("")
  const [status4_36, setStatus4_36] = useState("")
  const [status4_37, setStatus4_37] = useState("")
  const [status4_38, setStatus4_38] = useState("")
  const [status4_39, setStatus4_39] = useState("")
  const [status4_40, setStatus4_40] = useState("")
  const [status4_41, setStatus4_41] = useState("")
  const [status4_42, setStatus4_42] = useState("")
  const [status4_43, setStatus4_43] = useState("")
  const [status4_44, setStatus4_44] = useState("")
  const [status4_45, setStatus4_45] = useState("")
  const [status4_46, setStatus4_46] = useState("")
  const [status4_47, setStatus4_47] = useState("")
  const [status4_48, setStatus4_48] = useState("")
  const [status4_49, setStatus4_49] = useState("")
  const [status4_50, setStatus4_50] = useState("")
  const [status4_51, setStatus4_51] = useState("")
  const [status4_52, setStatus4_52] = useState("")
  const [status4_53, setStatus4_53] = useState("")
  const [status4_54, setStatus4_54] = useState("")
  const [status4_55, setStatus4_55] = useState("")
  const [status4_56, setStatus4_56] = useState("")
  const [status4_57, setStatus4_57] = useState("")
  const [status4_58, setStatus4_58] = useState("")
  const [status4_59, setStatus4_59] = useState("")
  const [status4_60, setStatus4_60] = useState("")
  // const [status4_61, setStatus4_61] = useState("");
  // const [status4_62, setStatus4_62] = useState("");
  // const [status4_63, setStatus4_63] = useState("");
  // const [status4_64, setStatus4_64] = useState("");
  // const [status4_65, setStatus4_65] = useState("");

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/up4", { cnpj })
      if (!cliente.data.up4) {
        setStatus4_0(cliente.data.tipo.statusCincoAnos[0])
        setStatus4_1(cliente.data.tipo.statusCincoAnos[1])
        setStatus4_2(cliente.data.tipo.statusCincoAnos[2])
        setStatus4_3(cliente.data.tipo.statusCincoAnos[3])
        setStatus4_4(cliente.data.tipo.statusCincoAnos[4])
        setStatus4_5(cliente.data.tipo.statusCincoAnos[5])
        setStatus4_6(cliente.data.tipo.statusCincoAnos[6])
        setStatus4_7(cliente.data.tipo.statusCincoAnos[7])
        setStatus4_8(cliente.data.tipo.statusCincoAnos[8])
        setStatus4_9(cliente.data.tipo.statusCincoAnos[9])
        setStatus4_10(cliente.data.tipo.statusCincoAnos[10])
        setStatus4_11(cliente.data.tipo.statusCincoAnos[11])
        setStatus4_12(cliente.data.tipo.statusCincoAnos[12])
        setStatus4_13(cliente.data.tipo.statusCincoAnos[13])
        setStatus4_14(cliente.data.tipo.statusCincoAnos[14])
        setStatus4_15(cliente.data.tipo.statusCincoAnos[15])
        setStatus4_16(cliente.data.tipo.statusCincoAnos[16])
        setStatus4_17(cliente.data.tipo.statusCincoAnos[17])
        setStatus4_18(cliente.data.tipo.statusCincoAnos[18])
        setStatus4_19(cliente.data.tipo.statusCincoAnos[19])
        setStatus4_20(cliente.data.tipo.statusCincoAnos[20])
        setStatus4_21(cliente.data.tipo.statusCincoAnos[21])
        setStatus4_22(cliente.data.tipo.statusCincoAnos[22])
        setStatus4_23(cliente.data.tipo.statusCincoAnos[23])
        setStatus4_24(cliente.data.tipo.statusCincoAnos[24])
        setStatus4_25(cliente.data.tipo.statusCincoAnos[25])
        setStatus4_26(cliente.data.tipo.statusCincoAnos[26])
        setStatus4_27(cliente.data.tipo.statusCincoAnos[27])
        setStatus4_28(cliente.data.tipo.statusCincoAnos[28])
        setStatus4_29(cliente.data.tipo.statusCincoAnos[29])
        setStatus4_30(cliente.data.tipo.statusCincoAnos[30])
        setStatus4_31(cliente.data.tipo.statusCincoAnos[31])
        setStatus4_32(cliente.data.tipo.statusCincoAnos[32])
        setStatus4_33(cliente.data.tipo.statusCincoAnos[33])
        setStatus4_34(cliente.data.tipo.statusCincoAnos[34])
        setStatus4_35(cliente.data.tipo.statusCincoAnos[35])
        setStatus4_36(cliente.data.tipo.statusCincoAnos[36])
        setStatus4_37(cliente.data.tipo.statusCincoAnos[37])
        setStatus4_38(cliente.data.tipo.statusCincoAnos[38])
        setStatus4_39(cliente.data.tipo.statusCincoAnos[39])
        setStatus4_40(cliente.data.tipo.statusCincoAnos[40])
        setStatus4_41(cliente.data.tipo.statusCincoAnos[41])
        setStatus4_42(cliente.data.tipo.statusCincoAnos[42])
        setStatus4_43(cliente.data.tipo.statusCincoAnos[43])
        setStatus4_44(cliente.data.tipo.statusCincoAnos[44])
        setStatus4_45(cliente.data.tipo.statusCincoAnos[45])
        setStatus4_46(cliente.data.tipo.statusCincoAnos[46])
        setStatus4_47(cliente.data.tipo.statusCincoAnos[47])
        setStatus4_48(cliente.data.tipo.statusCincoAnos[48])
        setStatus4_49(cliente.data.tipo.statusCincoAnos[49])
        setStatus4_50(cliente.data.tipo.statusCincoAnos[50])
        setStatus4_51(cliente.data.tipo.statusCincoAnos[51])
        setStatus4_52(cliente.data.tipo.statusCincoAnos[52])
        setStatus4_53(cliente.data.tipo.statusCincoAnos[53])
        setStatus4_54(cliente.data.tipo.statusCincoAnos[54])
        setStatus4_55(cliente.data.tipo.statusCincoAnos[55])
        setStatus4_56(cliente.data.tipo.statusCincoAnos[56])
        setStatus4_57(cliente.data.tipo.statusCincoAnos[57])
        setStatus4_58(cliente.data.tipo.statusCincoAnos[58])
        setStatus4_59(cliente.data.tipo.statusCincoAnos[59])
        setStatus4_60(cliente.data.tipo.statusCincoAnos[60])
        // setStatus4_61(cliente.data.tipo.statusCincoAnos[61]);
        // setStatus4_62(cliente.data.tipo.statusCincoAnos[62]);
        // setStatus4_63(cliente.data.tipo.statusCincoAnos[63]);
        // setStatus4_64(cliente.data.tipo.statusCincoAnos[64]);
        // setStatus4_65(cliente.data.tipo.statusCincoAnos[65]);
      }
    }

    getCliente()
  }, [cnpj])

  return (
    <div>
      {status4_0.length === 0 ? (
        <></>
      ) : (
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow>
                <TableCell>Memória ICMS/ST e Antecipação</TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_0 ?? ""}
                    onChange={(e) => setStatus4_0(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_1 ?? ""}
                    onChange={(e) => setStatus4_1(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_2 ?? ""}
                    onChange={(e) => setStatus4_2(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_3 ?? ""}
                    onChange={(e) => setStatus4_3(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_4 ?? ""}
                    onChange={(e) => setStatus4_4(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_5 ?? ""}
                    onChange={(e) => setStatus4_5(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_6 ?? ""}
                    onChange={(e) => setStatus4_6(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_7 ?? ""}
                    onChange={(e) => setStatus4_7(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_8 ?? ""}
                    onChange={(e) => setStatus4_8(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_9 ?? ""}
                    onChange={(e) => setStatus4_9(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_10 ?? ""}
                    onChange={(e) => setStatus4_10(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_11 ?? ""}
                    onChange={(e) => setStatus4_11(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_12 ?? ""}
                    onChange={(e) => setStatus4_12(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_13 ?? ""}
                    onChange={(e) => setStatus4_13(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_14 ?? ""}
                    onChange={(e) => setStatus4_14(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_15 ?? ""}
                    onChange={(e) => setStatus4_15(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_16 ?? ""}
                    onChange={(e) => setStatus4_16(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_17 ?? ""}
                    onChange={(e) => setStatus4_17(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_18 ?? ""}
                    onChange={(e) => setStatus4_18(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_19 ?? ""}
                    onChange={(e) => setStatus4_19(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_20 ?? ""}
                    onChange={(e) => setStatus4_20(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_21 ?? ""}
                    onChange={(e) => setStatus4_21(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_22 ?? ""}
                    onChange={(e) => setStatus4_22(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_23 ?? ""}
                    onChange={(e) => setStatus4_23(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_24 ?? ""}
                    onChange={(e) => setStatus4_24(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_25 ?? ""}
                    onChange={(e) => setStatus4_25(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_26 ?? ""}
                    onChange={(e) => setStatus4_26(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_27 ?? ""}
                    onChange={(e) => setStatus4_27(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_28 ?? ""}
                    onChange={(e) => setStatus4_28(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_29 ?? ""}
                    onChange={(e) => setStatus4_29(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_30 ?? ""}
                    onChange={(e) => setStatus4_30(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_31 ?? ""}
                    onChange={(e) => setStatus4_31(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_32 ?? ""}
                    onChange={(e) => setStatus4_32(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_33 ?? ""}
                    onChange={(e) => setStatus4_33(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_34 ?? ""}
                    onChange={(e) => setStatus4_34(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_35 ?? ""}
                    onChange={(e) => setStatus4_35(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_36 ?? ""}
                    onChange={(e) => setStatus4_36(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_37 ?? ""}
                    onChange={(e) => setStatus4_37(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_38 ?? ""}
                    onChange={(e) => setStatus4_38(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_39 ?? ""}
                    onChange={(e) => setStatus4_39(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_40 ?? ""}
                    onChange={(e) => setStatus4_40(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_41 ?? ""}
                    onChange={(e) => setStatus4_41(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_42 ?? ""}
                    onChange={(e) => setStatus4_42(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_43 ?? ""}
                    onChange={(e) => setStatus4_43(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_44 ?? ""}
                    onChange={(e) => setStatus4_44(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_45 ?? ""}
                    onChange={(e) => setStatus4_45(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_46 ?? ""}
                    onChange={(e) => setStatus4_46(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_47 ?? ""}
                    onChange={(e) => setStatus4_47(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_48 ?? ""}
                    onChange={(e) => setStatus4_48(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_49 ?? ""}
                    onChange={(e) => setStatus4_49(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_50 ?? ""}
                    onChange={(e) => setStatus4_50(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_51 ?? ""}
                    onChange={(e) => setStatus4_51(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_52 ?? ""}
                    onChange={(e) => setStatus4_52(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_53 ?? ""}
                    onChange={(e) => setStatus4_53(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_54 ?? ""}
                    onChange={(e) => setStatus4_54(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_55 ?? ""}
                    onChange={(e) => setStatus4_55(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_56 ?? ""}
                    onChange={(e) => setStatus4_56(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_57 ?? ""}
                    onChange={(e) => setStatus4_57(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_58 ?? ""}
                    onChange={(e) => setStatus4_58(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_59 ?? ""}
                    onChange={(e) => setStatus4_59(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_60 ?? ""}
                    onChange={(e) => setStatus4_60(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                {/* <TableCell>
                  <Select
                    variant="standard"
                    value={status4_61 ?? ""}
                    onChange={(e) => setStatus4_61(e.target.value)}
                  >
                    <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                    <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                    <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_62 ?? ""}
                    onChange={(e) => setStatus4_62(e.target.value)}
                  >
                    <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                    <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                    <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_63 ?? ""}
                    onChange={(e) => setStatus4_63(e.target.value)}
                  >
                    <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                    <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                    <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_64 ?? ""}
                    onChange={(e) => setStatus4_64(e.target.value)}
                  >
                    <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                    <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                    <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status4_65 ?? ""}
                    onChange={(e) => setStatus4_65(e.target.value)}
                  >
                    <MenuItem value={0}><CancelIcon sx={{ color: 'red' }} /></MenuItem>
                    <MenuItem value={1}><ErrorIcon sx={{ color: 'gold' }} /></MenuItem>
                    <MenuItem value={2}><CheckCircleIcon sx={{ color: 'green' }} /></MenuItem>
                  </Select>
                </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default Painel
