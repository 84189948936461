import React from "react"

import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"

import ResponsiveDrawer from "../../components/menu"
import TabelaAssociados from "./Tabelas/TabelaAssociado"

const mdTheme = createTheme()

export default function Associados() {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Associados" />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4
            }}
          >
            <Container sx={{ mt: 4, mb: 4 }}>
              <center>
                <span className="submenu">Associados Cadastrados</span>
              </center>
              <hr />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <TabelaAssociados />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
