import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { CircularProgress, Grid } from "@mui/material"
import ResponsiveDrawer from "../../../components/menuCliente"
import api from "../../../Config/api"

const mdTheme = createTheme()

const Filial = () => {
  const params = useParams()
  const filial = params._id
  const cnpj = localStorage.getItem("cnpj")
  const [datas, setDatas] = useState([])
  const [colunas, setColunas] = useState([])
  const [tipo9, setTipo9] = useState([])
  const [tipo12, setTipo12] = useState([])
  const [tipo15, setTipo15] = useState([])
  const [tipo19, setTipo19] = useState([])
  const [tipo20, setTipo20] = useState([])
  const [tipo21, setTipo21] = useState([])
  const [tipo22, setTipo22] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getUploadsFilial() {
      const cliente = await api.post("/checklist-filial", { cnpj: filial })

      setColunas(cliente.data.dados.cincoAnos)

      const datas = []
      if (cliente.data.dados.data1 !== null) {
        datas.push(
          `${cliente.data.dados.data1.substr(
            0,
            4
          )}/${cliente.data.dados.data1.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data2 !== null) {
        datas.push(
          `${cliente.data.dados.data2.substr(
            0,
            4
          )}/${cliente.data.dados.data2.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data3 !== null) {
        datas.push(
          `${cliente.data.dados.data3.substr(
            0,
            4
          )}/${cliente.data.dados.data3.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data4 !== null) {
        datas.push(
          `${cliente.data.dados.data4.substr(
            0,
            4
          )}/${cliente.data.dados.data4.substr(5, 2)}`
        )
      }
      setDatas(datas)

      const res = {
        data: { resposta: cliente.data.tipos }
      }
      let cont = 0
      while (cont < res.data.resposta.length) {
        if (res.data.resposta[cont].tipo === 9) {
          setTipo9(res.data.resposta[cont])
        }
        if (res.data.resposta[cont].tipo === 12) {
          setTipo12(res.data.resposta[cont])
        }
        if (res.data.resposta[cont].tipo === 15) {
          setTipo15(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 19) {
          setTipo19(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 20) {
          setTipo20(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 21) {
          setTipo21(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 22) {
          setTipo22(res.data.resposta[cont].statusCincoAnos)
        }
        cont++
      }
      setLoading(false)
    }

    getUploadsFilial()
    setLoading(true)
  }, [filial, cnpj])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer Title="Matriz -" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Container sx={{ mt: 4, mb: 4 }}>
                  <center>
                    <span className="submenu">Checklist de Documentos </span>
                  </center>
                  <hr />
                  <center>
                    <span className="title"> Filial - {filial} </span>
                  </center>
                  <hr />
                  <TableContainer component={Paper}>
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="title">
                            <div className="title">Arquivos</div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="title">
                              {datas === 0 ? "-" : datas[0]}{" "}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="title">
                              {datas === 0 ? "-" : datas[1]}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="title">
                              {datas === 0 ? "-" : datas[2]}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="title">
                              {datas === 0 ? "-" : datas[3]}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Sped Fiscal
                          </TableCell>
                          <TableCell align="center">
                            {tipo9.status1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo9.status1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo9.status1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo9.status1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo9.status1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          {datas.length > 1 ? (
                            <TableCell align="center">
                              {tipo9.status2 === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status2 === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status2 === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status2 === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status2 === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {datas.length > 2 ? (
                            <TableCell align="center">
                              {tipo9.status3 === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status3 === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status3 === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status3 === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status3 === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {datas.length === 4 ? (
                            <TableCell align="center">
                              {tipo9.status4 === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status4 === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status4 === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status4 === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {tipo9.status4 === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            CIAP - Controle de Crédito do ICMS do Ativo
                            Permanente
                          </TableCell>
                          <TableCell align="center">
                            {tipo12.status1 === 0 ? (
                              <CancelIcon sx={{ color: "red" }} />
                            ) : (
                              ""
                            )}
                            {tipo12.status1 === 1 ? (
                              <ErrorIcon sx={{ color: "gold" }} />
                            ) : (
                              ""
                            )}
                            {tipo12.status1 === 2 ? (
                              <CheckCircleIcon sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {tipo12.status1 === 3 ? (
                              <RectangleIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                            {tipo12.status1 === 4 ? (
                              <FormatItalicIcon sx={{ color: "black" }} />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          {datas.length > 1 ? (
                            <TableCell align="center">
                              {tipo12.status2 === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status2 === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status2 === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status2 === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status2 === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {datas.length > 2 ? (
                            <TableCell align="center">
                              {tipo12.status3 === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status3 === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status3 === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status3 === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status3 === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {datas.length === 4 ? (
                            <TableCell align="center">
                              {tipo12.status4 === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status4 === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status4 === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status4 === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {tipo12.status4 === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container>
              )}
            </Grid>
          </Container>

          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="title">Arquivo</span>
                          </TableCell>

                          {colunas.map((col, index) => (
                            <TableCell key={index} align="center">
                              {formataData(col)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            E-CAC - Fontes Pagadoras
                          </TableCell>
                          {tipo15.map((row, index) => (
                            <TableCell key={index}>
                              {row === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {row === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {row === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {row === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {row === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ))}
                        </TableRow>

                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Resumo da folha de pagamento
                          </TableCell>
                          {tipo19.map((row, index) => (
                            <TableCell key={index}>
                              {row === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {row === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {row === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {row === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {row === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ))}
                        </TableRow>

                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Comprovante de declaração a recolher do INSS
                            (Rubrica)
                          </TableCell>
                          {tipo20.map((row, index) => (
                            <TableCell key={index}>
                              {row === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {row === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {row === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {row === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {row === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ))}
                        </TableRow>

                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Print da FAP
                          </TableCell>
                          {tipo21.map((row, index) => (
                            <TableCell key={index}>
                              {row === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {row === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {row === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {row === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {row === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ))}
                        </TableRow>

                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            GPS
                          </TableCell>
                          {tipo22.map((row, index) => (
                            <TableCell key={index}>
                              {row === 0 ? (
                                <CancelIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                              {row === 1 ? (
                                <ErrorIcon sx={{ color: "gold" }} />
                              ) : (
                                ""
                              )}
                              {row === 2 ? (
                                <CheckCircleIcon sx={{ color: "green" }} />
                              ) : (
                                ""
                              )}
                              {row === 3 ? (
                                <RectangleIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                              {row === 4 ? (
                                <FormatItalicIcon sx={{ color: "black" }} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container>
              )}
            </Grid>
          </Container>
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <TableCell scope="row" align="center">
              {" "}
              <br />
              <span className="title">Status:</span>
            </TableCell>
            <TableCell scope="row" align="center">
              {" "}
              <br />
              <CheckCircleIcon sx={{ color: "green" }} /> <p />{" "}
              <p> Arquivos recebidos </p>
            </TableCell>

            <TableCell scope="row" align="center">
              {" "}
              <br />
              <ErrorIcon sx={{ color: "gold" }} /> <p />{" "}
              <p>Arquivos em análise</p>
            </TableCell>

            <TableCell scope="row" align="center">
              {" "}
              <br />
              <CancelIcon sx={{ color: "red" }} /> <p />{" "}
              <p>Arquivos pendentes</p>
            </TableCell>

            <TableCell scope="row" align="center">
              {" "}
              <br />
              <RectangleIcon sx={{ color: "black" }} /> <p />{" "}
              <p>Arquivos não existentes</p>
            </TableCell>

            <TableCell scope="row" align="center">
              {" "}
              <br />
              <FormatItalicIcon sx={{ color: "black" }} /> <p />{" "}
              <p>Arquivos parciais</p>
            </TableCell>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <Filial />
}
