import React from "react"
import { Button } from "react-bootstrap"
import FileSaver from "file-saver"
import XLSX from "xlsx"

const ExportCSV = ({ csvData, fileName, wscols }) => {
  // ******** XLSX with object key as header *************
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const exportToCSV = (csvData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(csvData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  // ******** XLSX with new header *************
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  const Heading = [
    {
      arquivos: "Arquivos",
      status1: "1º Período",
      status2: "2º Período",
      status3: "3º Período"
    }
  ]

  const exportToCSV = (csvData, fileName, wscols) => {
    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: ["arquivos", "status1", "status2", "status3"],
      skipHeader: true,
      origin: 0 // ok
    })
    ws["!cols"] = wscols
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: ["arquivos", "status1", "status2", "status3"],
      skipHeader: true,
      origin: -1 // ok
    })
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <Button
      variant="success"
      onClick={(e) => exportToCSV(csvData, fileName, wscols)}
    >
      Exportar Checklist
    </Button>
  )
}

export default ExportCSV
