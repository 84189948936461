import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { toast, ToastContainer } from "react-toastify"

import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

import IconButton from "@mui/material/IconButton"
import SaveIcon from "@mui/icons-material/Save"

import { Alert } from "reactstrap"

import Modal from "@mui/material/Modal"
import api from "../../../../Config/api"

const styleModalErro = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  opacity: 1,
  align: "center",
  fontSize: 10
}

const Painel = () => {
  const [openModalVazio, setOpenModalVazio] = useState(false)
  const handleOpenVazio = () => setOpenModalVazio(true)
  const handleCloseVazio = () => setOpenModalVazio(false)

  const params = useParams()

  const cnpj = params._id

  const [statusEdit, setStatusEdit] = useState("")

  // CIAP
  const [status16_id, setStatus16_id] = useState("")
  const [status16_0, setStatus16_0] = useState("")
  const [status16_1, setStatus16_1] = useState("")
  const [status16_2, setStatus16_2] = useState("")
  const [status16_3, setStatus16_3] = useState("")
  const [status16_4, setStatus16_4] = useState("")
  const [status16_5, setStatus16_5] = useState("")
  const [status16_6, setStatus16_6] = useState("")
  const [status16_7, setStatus16_7] = useState("")
  const [status16_8, setStatus16_8] = useState("")
  const [status16_9, setStatus16_9] = useState("")
  const [status16_10, setStatus16_10] = useState("")
  const [status16_11, setStatus16_11] = useState("")
  const [status16_12, setStatus16_12] = useState("")
  const [status16_13, setStatus16_13] = useState("")
  const [status16_14, setStatus16_14] = useState("")
  const [status16_15, setStatus16_15] = useState("")
  const [status16_16, setStatus16_16] = useState("")
  const [status16_17, setStatus16_17] = useState("")
  const [status16_18, setStatus16_18] = useState("")
  const [status16_19, setStatus16_19] = useState("")
  const [status16_20, setStatus16_20] = useState("")
  const [status16_21, setStatus16_21] = useState("")
  const [status16_22, setStatus16_22] = useState("")
  const [status16_23, setStatus16_23] = useState("")
  const [status16_24, setStatus16_24] = useState("")
  const [status16_25, setStatus16_25] = useState("")
  const [status16_26, setStatus16_26] = useState("")
  const [status16_27, setStatus16_27] = useState("")
  const [status16_28, setStatus16_28] = useState("")
  const [status16_29, setStatus16_29] = useState("")
  const [status16_30, setStatus16_30] = useState("")
  const [status16_31, setStatus16_31] = useState("")
  const [status16_32, setStatus16_32] = useState("")
  const [status16_33, setStatus16_33] = useState("")
  const [status16_34, setStatus16_34] = useState("")
  const [status16_35, setStatus16_35] = useState("")
  const [status16_36, setStatus16_36] = useState("")
  const [status16_37, setStatus16_37] = useState("")
  const [status16_38, setStatus16_38] = useState("")
  const [status16_39, setStatus16_39] = useState("")
  const [status16_40, setStatus16_40] = useState("")
  const [status16_41, setStatus16_41] = useState("")
  const [status16_42, setStatus16_42] = useState("")
  const [status16_43, setStatus16_43] = useState("")
  const [status16_44, setStatus16_44] = useState("")
  const [status16_45, setStatus16_45] = useState("")
  const [status16_46, setStatus16_46] = useState("")
  const [status16_47, setStatus16_47] = useState("")
  const [status16_48, setStatus16_48] = useState("")
  const [status16_49, setStatus16_49] = useState("")
  const [status16_50, setStatus16_50] = useState("")
  const [status16_51, setStatus16_51] = useState("")
  const [status16_52, setStatus16_52] = useState("")
  const [status16_53, setStatus16_53] = useState("")
  const [status16_54, setStatus16_54] = useState("")
  const [status16_55, setStatus16_55] = useState("")
  const [status16_56, setStatus16_56] = useState("")
  const [status16_57, setStatus16_57] = useState("")
  const [status16_58, setStatus16_58] = useState("")
  const [status16_59, setStatus16_59] = useState("")

  const [datas, setDatas] = useState([])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/up16", { cnpj })
      const datasUp = await api.post("/checklist", { cnpj })
      let datas = []
      datas = datasUp.data.dados.cincoAnos
      let x = 0
      const meses = []
      while (x < datas.length) {
        meses.push({ id: x, data: datas[x] })
        x++
      }
      setDatas(meses)

      if (!cliente.data.up4) {
        setStatus16_id(cliente.data.tipo._id)
        setStatus16_0(cliente.data.tipo.statusCincoAnos[0])
        setStatus16_1(cliente.data.tipo.statusCincoAnos[1])
        setStatus16_2(cliente.data.tipo.statusCincoAnos[2])
        setStatus16_3(cliente.data.tipo.statusCincoAnos[3])
        setStatus16_4(cliente.data.tipo.statusCincoAnos[4])
        setStatus16_5(cliente.data.tipo.statusCincoAnos[5])
        setStatus16_6(cliente.data.tipo.statusCincoAnos[6])
        setStatus16_7(cliente.data.tipo.statusCincoAnos[7])
        setStatus16_8(cliente.data.tipo.statusCincoAnos[8])
        setStatus16_9(cliente.data.tipo.statusCincoAnos[9])
        setStatus16_10(cliente.data.tipo.statusCincoAnos[10])
        setStatus16_11(cliente.data.tipo.statusCincoAnos[11])
        setStatus16_12(cliente.data.tipo.statusCincoAnos[12])
        setStatus16_13(cliente.data.tipo.statusCincoAnos[13])
        setStatus16_14(cliente.data.tipo.statusCincoAnos[14])
        setStatus16_15(cliente.data.tipo.statusCincoAnos[15])
        setStatus16_16(cliente.data.tipo.statusCincoAnos[16])
        setStatus16_17(cliente.data.tipo.statusCincoAnos[17])
        setStatus16_18(cliente.data.tipo.statusCincoAnos[18])
        setStatus16_19(cliente.data.tipo.statusCincoAnos[19])
        setStatus16_20(cliente.data.tipo.statusCincoAnos[20])
        setStatus16_21(cliente.data.tipo.statusCincoAnos[21])
        setStatus16_22(cliente.data.tipo.statusCincoAnos[22])
        setStatus16_23(cliente.data.tipo.statusCincoAnos[23])
        setStatus16_24(cliente.data.tipo.statusCincoAnos[24])
        setStatus16_25(cliente.data.tipo.statusCincoAnos[25])
        setStatus16_26(cliente.data.tipo.statusCincoAnos[26])
        setStatus16_27(cliente.data.tipo.statusCincoAnos[27])
        setStatus16_28(cliente.data.tipo.statusCincoAnos[28])
        setStatus16_29(cliente.data.tipo.statusCincoAnos[29])
        setStatus16_30(cliente.data.tipo.statusCincoAnos[30])
        setStatus16_31(cliente.data.tipo.statusCincoAnos[31])
        setStatus16_32(cliente.data.tipo.statusCincoAnos[32])
        setStatus16_33(cliente.data.tipo.statusCincoAnos[33])
        setStatus16_34(cliente.data.tipo.statusCincoAnos[34])
        setStatus16_35(cliente.data.tipo.statusCincoAnos[35])
        setStatus16_36(cliente.data.tipo.statusCincoAnos[36])
        setStatus16_37(cliente.data.tipo.statusCincoAnos[37])
        setStatus16_38(cliente.data.tipo.statusCincoAnos[38])
        setStatus16_39(cliente.data.tipo.statusCincoAnos[39])
        setStatus16_40(cliente.data.tipo.statusCincoAnos[40])
        setStatus16_41(cliente.data.tipo.statusCincoAnos[41])
        setStatus16_42(cliente.data.tipo.statusCincoAnos[42])
        setStatus16_43(cliente.data.tipo.statusCincoAnos[43])
        setStatus16_44(cliente.data.tipo.statusCincoAnos[44])
        setStatus16_45(cliente.data.tipo.statusCincoAnos[45])
        setStatus16_46(cliente.data.tipo.statusCincoAnos[46])
        setStatus16_47(cliente.data.tipo.statusCincoAnos[47])
        setStatus16_48(cliente.data.tipo.statusCincoAnos[48])
        setStatus16_49(cliente.data.tipo.statusCincoAnos[49])
        setStatus16_50(cliente.data.tipo.statusCincoAnos[50])
        setStatus16_51(cliente.data.tipo.statusCincoAnos[51])
        setStatus16_52(cliente.data.tipo.statusCincoAnos[52])
        setStatus16_53(cliente.data.tipo.statusCincoAnos[53])
        setStatus16_54(cliente.data.tipo.statusCincoAnos[54])
        setStatus16_55(cliente.data.tipo.statusCincoAnos[55])
        setStatus16_56(cliente.data.tipo.statusCincoAnos[56])
        setStatus16_57(cliente.data.tipo.statusCincoAnos[57])
        setStatus16_58(cliente.data.tipo.statusCincoAnos[58])
        setStatus16_59(cliente.data.tipo.statusCincoAnos[59])
      }
    }

    getCliente()
  }, [cnpj])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  async function salvar(id) {
    const statusCincoAnos = [
      status16_0,
      status16_1,
      status16_2,
      status16_3,
      status16_4,
      status16_5,
      status16_6,
      status16_7,
      status16_8,
      status16_9,
      status16_10,
      status16_11,
      status16_12,
      status16_13,
      status16_14,
      status16_15,
      status16_16,
      status16_17,
      status16_18,
      status16_19,
      status16_20,
      status16_21,
      status16_22,
      status16_23,
      status16_24,
      status16_25,
      status16_26,
      status16_27,
      status16_28,
      status16_29,
      status16_30,
      status16_31,
      status16_32,
      status16_33,
      status16_34,
      status16_35,
      status16_36,
      status16_37,
      status16_38,
      status16_39,
      status16_40,
      status16_41,
      status16_42,
      status16_43,
      status16_44,
      status16_45,
      status16_46,
      status16_47,
      status16_48,
      status16_49,
      status16_50,
      status16_51,
      status16_52,
      status16_53,
      status16_54,
      status16_55,
      status16_56,
      status16_57,
      status16_58,
      status16_59
    ]

    await api.put("/editar-tipo-upload-60meses", { id, statusCincoAnos })
    toast.success("Registro salvo com sucesso!", {
      position: toast.POSITION.TOP_CENTER
    })
  }

  return (
    <div>
      {status16_0.length === 0 ? (
        <></>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <ToastContainer />

          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Arquivo</TableCell>
                {datas.map((column) => (
                  <TableCell key={column.id} align="center">
                    {formataData(column.data)}
                  </TableCell>
                ))}
                <TableCell>Salvar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Ativo Imobilizado</TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_0 ?? ""}
                    onChange={(e) => setStatus16_0(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_1 ?? ""}
                    onChange={(e) => setStatus16_1(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_2 ?? ""}
                    onChange={(e) => setStatus16_2(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_3 ?? ""}
                    onChange={(e) => setStatus16_3(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_4 ?? ""}
                    onChange={(e) => setStatus16_4(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_5 ?? ""}
                    onChange={(e) => setStatus16_5(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_6 ?? ""}
                    onChange={(e) => setStatus16_6(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_7 ?? ""}
                    onChange={(e) => setStatus16_7(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_8 ?? ""}
                    onChange={(e) => setStatus16_8(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_9 ?? ""}
                    onChange={(e) => setStatus16_9(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_10 ?? ""}
                    onChange={(e) => setStatus16_10(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_11 ?? ""}
                    onChange={(e) => setStatus16_11(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_12 ?? ""}
                    onChange={(e) => setStatus16_12(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_13 ?? ""}
                    onChange={(e) => setStatus16_13(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_14 ?? ""}
                    onChange={(e) => setStatus16_14(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_15 ?? ""}
                    onChange={(e) => setStatus16_15(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_16 ?? ""}
                    onChange={(e) => setStatus16_16(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_17 ?? ""}
                    onChange={(e) => setStatus16_17(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_18 ?? ""}
                    onChange={(e) => setStatus16_18(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_19 ?? ""}
                    onChange={(e) => setStatus16_19(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_20 ?? ""}
                    onChange={(e) => setStatus16_20(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_21 ?? ""}
                    onChange={(e) => setStatus16_21(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_22 ?? ""}
                    onChange={(e) => setStatus16_22(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_23 ?? ""}
                    onChange={(e) => setStatus16_23(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_24 ?? ""}
                    onChange={(e) => setStatus16_24(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_25 ?? ""}
                    onChange={(e) => setStatus16_25(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_26 ?? ""}
                    onChange={(e) => setStatus16_26(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_27 ?? ""}
                    onChange={(e) => setStatus16_27(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_28 ?? ""}
                    onChange={(e) => setStatus16_28(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_29 ?? ""}
                    onChange={(e) => setStatus16_29(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_30 ?? ""}
                    onChange={(e) => setStatus16_30(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_31 ?? ""}
                    onChange={(e) => setStatus16_31(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_32 ?? ""}
                    onChange={(e) => setStatus16_32(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_33 ?? ""}
                    onChange={(e) => setStatus16_33(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_34 ?? ""}
                    onChange={(e) => setStatus16_34(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_35 ?? ""}
                    onChange={(e) => setStatus16_35(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_36 ?? ""}
                    onChange={(e) => setStatus16_36(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_37 ?? ""}
                    onChange={(e) => setStatus16_37(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_38 ?? ""}
                    onChange={(e) => setStatus16_38(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_39 ?? ""}
                    onChange={(e) => setStatus16_39(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_40 ?? ""}
                    onChange={(e) => setStatus16_40(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_41 ?? ""}
                    onChange={(e) => setStatus16_41(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_42 ?? ""}
                    onChange={(e) => setStatus16_42(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_43 ?? ""}
                    onChange={(e) => setStatus16_43(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_44 ?? ""}
                    onChange={(e) => setStatus16_44(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_45 ?? ""}
                    onChange={(e) => setStatus16_45(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_46 ?? ""}
                    onChange={(e) => setStatus16_46(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_47 ?? ""}
                    onChange={(e) => setStatus16_47(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_48 ?? ""}
                    onChange={(e) => setStatus16_48(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_49 ?? ""}
                    onChange={(e) => setStatus16_49(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_50 ?? ""}
                    onChange={(e) => setStatus16_50(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_51 ?? ""}
                    onChange={(e) => setStatus16_51(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_52 ?? ""}
                    onChange={(e) => setStatus16_52(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_53 ?? ""}
                    onChange={(e) => setStatus16_53(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_54 ?? ""}
                    onChange={(e) => setStatus16_54(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_55 ?? ""}
                    onChange={(e) => setStatus16_55(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_56 ?? ""}
                    onChange={(e) => setStatus16_56(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_57 ?? ""}
                    onChange={(e) => setStatus16_57(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_58 ?? ""}
                    onChange={(e) => setStatus16_58(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <Select
                    variant="standard"
                    value={status16_59 ?? ""}
                    onChange={(e) => setStatus16_59(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <CancelIcon sx={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem value={1}>
                      <ErrorIcon sx={{ color: "gold" }} />
                    </MenuItem>
                    <MenuItem value={2}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                    </MenuItem>
                    <MenuItem value={3}>
                      <RectangleIcon sx={{ color: "black" }} />
                    </MenuItem>
                    <MenuItem value={4}>
                      <FormatItalicIcon sx={{ color: "black" }} />
                    </MenuItem>
                  </Select>
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    onClick={() => salvar(status16_id)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Modal
        open={openModalVazio}
        onClose={handleCloseVazio}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalErro}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Registro salvo com sucesso.</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  )
}

export default Painel
