import React, { useState, useEffect, useContext } from "react"
import Box from "@mui/material/Box"
import { DataGrid, GridActionsCellItem, ptBR } from "@mui/x-data-grid"
import SaveIcon from "@mui/icons-material/Save"
import {
  alpha,
  InputBase,
  Toolbar,
  CssBaseline,
  Container,
  createTheme,
  styled,
  ThemeProvider,
  Button
} from "@mui/material"
import { useParams } from "react-router-dom"

import { toast, ToastContainer } from "react-toastify"
import ResponsiveDrawer from "../../components/menu"
import api from "../../Config/api"

const mdTheme = createTheme()

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 420,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))

export default function DataGridDemo() {
  const [_id, setIdCliente] = React.useState("")
  const [simples, setSimples] = React.useState(false)

  const [statusCincoAnos, setStatusCincoAnos] = React.useState([])
  const [documentosNecessarios, setDocumentosNecessarios] = React.useState({})
  const [documentosNecessariosSN, setDocumentosNecessariosSN] = React.useState(
    {}
  )
  const [dataLimite, setDataLimite] = React.useState("")

  const [saveAllUploads, setSaveAllUploads] = React.useState([])

  const [loading, setLoading] = React.useState(false)

  const params = useParams()
  const cnpjCliente = params._id

  const cnpj = localStorage.getItem("cnpj")
  // const simples = localStorage.getItem("simples");

  React.useEffect(() => {
    const dataSimplesNacional = [
      {
        id: 29,
        description: "XMLs - NF-es e CT-es de entradas",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 30,
        description: "Extrato Simples Nacional",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 31,
        description:
          "DAS (Enviar apenas para os períodos sem informação de pagamento no extrato)",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 32,
        description: "XMLs - NF-es e CT-es de saídas",
        dataLimite,
        obs: "",
        editing: false
      }
    ]
    setDocumentosNecessariosSN(
      dataSimplesNacional.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.id]: currentValue
        }),
        {}
      )
    )
  }, [dataLimite])

  React.useEffect(() => {
    const data = [
      {
        id: 1,
        description: "Solicitações Complementares",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 2,
        description: "Planilhas memórias de cálculo Pis/Cofins - Sintética",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 3,
        description: "Planilhas memórias de cálculo Pis/Cofins - Analítica",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 4,
        description:
          "Memória de cálculo/Rascunho do ICMS, ICMS ST e ICMS Antecipação ",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 5,
        description: "DCTFs",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 6,
        description: "Balancetes Contábeis",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 7,
        description: "Livros Razões Contábeis",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 8,
        description: "SPED Contábil",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 9,
        description: "SPED Fiscal",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 10,
        description: "EFD Contribuições",
        dataLimite,
        obs: "",
        editing: false
      },

      {
        id: 12,
        description: "CIAP - Controle de Crédito do ICMS do Ativo Permanente",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 13,
        description:
          "E-CAC - Comprovante de Arrecadação dos Tributos IRPJ e CSLL",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 14,
        description: "ECF",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 15,
        description: "E-CAC - Fontes Pagadoras",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 16,
        description: "Bens do ativo imobilizado",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 17,
        description: "Memórias de Cálculo do IRPJ e CSLL",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 18,
        description: "Créditos Extemporâneos",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 19,
        description: "Resumo da folha de pagamento",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 20,
        description: "Comprovante de declaração a recolher do INSS (Rubrica)",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 21,
        description: "Print da FAP",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 22,
        description: "GPS",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 23,
        description:
          "Tabela de Incidência do INSS sobre os eventos da folha de pagamentos (Contribuições Previdenciárias)",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 24,
        description: "Memórias de Cálculo de CPRB/Desoneração",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 25,
        description: "Questionário Prévio",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 26,
        description: "DARF",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 27,
        description: "DCTFWEB",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 29,
        description: "XMLs - NF-es e CT-es de entradas",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 30,
        description: "Extrato Simples Nacional",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 31,
        description:
          "DAS (Enviar apenas para os períodos sem informação de pagamento no extrato)",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 32,
        description: "XMLs - NF-es e CT-es de saídas",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 33,
        description:
          "Guia ou Declaração de Apuração do ICMS - (GIA; DMA; DAM; DAPI; GIME; GIAM)",
        dataLimite,
        obs: "",
        editing: false
      },
      {
        id: 34,
        description: "Balancete Contábil (último não encerrado)",
        dataLimite,
        obs: "",
        editing: false
      }
    ]
    setDocumentosNecessarios(
      data.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.id]: currentValue
        }),
        {}
      )
    )
  }, [dataLimite])

  useEffect(() => {
    async function carregaCliente() {
      const dados = await api.post("/find-cliente-cnpj", {
        cnpj: cnpj !== null ? cnpj : cnpjCliente
      })
      setIdCliente(dados.data.cliente._id)
      setSimples(dados.data.cliente.simples)
    }

    function gerarStatus5anos() {
      let cont = 0
      const status = []
      while (cont < 60) {
        status.push(0)
        cont++
      }
      setStatusCincoAnos(status)
      console.log(simples)
    }

    gerarStatus5anos()
    carregaCliente()
  }, [cnpj])

  const columns = [
    { field: "id", headerName: "", width: 20, type: "number" },
    {
      field: "description",
      headerName: "Documentos",
      width: 350,
      editable: false
    },
    {
      field: "dataLimite",
      headerName: "Prazo de Envio",
      width: 150,
      editable: true,
      type: "date",
      valueFormatter: ({ value }) => {
        if (!value) return ""
        const dateArrayYYYY_MM_DD = new Date(value)
          .toISOString()
          .split("T")[0]
          .split("-")

        return `${dateArrayYYYY_MM_DD[2]}/${dateArrayYYYY_MM_DD[1]}/${dateArrayYYYY_MM_DD[0]}`
      }
    },
    { field: "obs", headerName: "Observações", width: 110, editable: true },
    {
      field: "actions",
      headerName: "Salvar",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <SaveIcon
              sx={{
                color: params.row.editing ? "#ccc" : "#28A6DE",
                fontSize: 30
              }}
            />
          }
          label="Adicionar"
          disabled={params.row.editing}
          onClick={async (e) => {
            e.preventDefault()
            setLoading(true)
            const { id, dataLimite, description, obs } = params.row
            const tiposUpload = {
              _id,
              tipo: id,
              dataLimite,
              obs,
              statusCincoAnos
            }
            if (dataLimite === "") {
              ;[].map()
              toast.error(`Por favor selecionar prazo de envio!`, {
                position: toast.POSITION.TOP_CENTER
              })
            }
            const add = await api
              .post("/tipos-uploads", tiposUpload)
              .then((resp) => {
                if (resp.status === 201) {
                  toast.success(
                    `Documento ${description} solicitado com sucesso.`,
                    {
                      position: toast.POSITION.TOP_CENTER
                    }
                  )
                  setLoading(false)
                }
              })
              .catch((error) => {
                if (error.response.status === 400) {
                  toast.error(`Documento ${description} já solicitado.`, {
                    position: toast.POSITION.TOP_CENTER
                  })
                  setLoading(false)
                }
              })
          }}
        />
      ]
    }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault(e)
    const _saveAllUploads = saveAllUploads.map((d) => documentosNecessarios[d])
    const dadosMultiplosUploadas = {
      idClient: _id,
      tiposUpload: _saveAllUploads.map((d) => ({
        tipo: d.id,
        dataLimite: d.dataLimite,
        obs: d.obs,
        statusCincoAnos
      }))
    }
    if (_saveAllUploads.filter((d) => d.dataLimite === "").length > 0) {
      toast.error("Por favor selecionar prazo de envio antes!", {
        position: toast.POSITION.TOP_CENTER
      })
    } else {
      const save = await api
        .post("/multiples-tipos-uploads", dadosMultiplosUploadas)
        .then((res) => {
          toast.success("Documentos solicitados com sucesso!", {
            position: toast.POSITION.TOP_CENTER
          })
        })
        .catch((e) => {
          toast.error(
            "Todos os documentos constantes na lista já foram solicitados anteriormente!",
            {
              position: toast.POSITION.TOP_CENTER
            }
          )
        })
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Uploads" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <center>
              <span className="submenu"> Solicitação de Documentos</span>
            </center>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                alignContent: "center",
                marginRight: "15px"
              }}
            >
              <hr />
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { mt: 4, mb: 4, color: "#000" }
                }}
                noValidate
                autoComplete="off"
              >
                <span>Aplicar data aos documentos da lista.</span>
              </Box>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "20ch" }
                }}
                noValidate
                autoComplete="off"
              >
                <BootstrapInput
                  type="date"
                  id="dataLimite"
                  name="dataLimite"
                  onChange={(e) => {
                    setDataLimite(new Date(e.target.value).toISOString())
                  }}
                />
              </Box>
            </div>
            <Container maxWidth="xxl" sx={{ mt: 4, mb: 4 }}>
              {simples === true ? (
                <Box sx={{ height: 541, width: "100%" }}>
                  <DataGrid
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={Object.keys(documentosNecessariosSN).map(
                      (key) => documentosNecessariosSN[key]
                    )}
                    onCellEditStart={(params) => {
                      setDocumentosNecessariosSN({
                        ...documentosNecessariosSN,
                        [params.row.id]: { ...params.row, editing: true }
                      })
                    }}
                    onCellEditStop={(params) => {
                      setDocumentosNecessariosSN({
                        ...documentosNecessariosSN,
                        [params.row.id]: { ...params.row, editing: false }
                      })
                    }}
                    processRowUpdate={(newRow) =>
                      setDocumentosNecessariosSN({
                        ...documentosNecessariosSN,
                        [newRow.id]: {
                          ...newRow,
                          dataLimite: newRow.dataLimite
                        }
                      }) ?? newRow
                    }
                    onProcessRowUpdateError={() => {}}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowHeight={() => "auto"}
                  />
                </Box>
              ) : (
                <Box sx={{ height: 541, width: "100%" }}>
                  <DataGrid
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={Object.keys(documentosNecessarios).map(
                      (key) => documentosNecessarios[key]
                    )}
                    onCellEditStart={(params) => {
                      setDocumentosNecessarios({
                        ...documentosNecessarios,
                        [params.row.id]: { ...params.row, editing: true }
                      })
                    }}
                    onCellEditStop={(params) => {
                      setDocumentosNecessarios({
                        ...documentosNecessarios,
                        [params.row.id]: { ...params.row, editing: false }
                      })
                    }}
                    processRowUpdate={(newRow) =>
                      setDocumentosNecessarios({
                        ...documentosNecessarios,
                        [newRow.id]: {
                          ...newRow,
                          dataLimite: newRow.dataLimite
                        }
                      }) ?? newRow
                    }
                    onProcessRowUpdateError={() => {}}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={(newSelection) =>
                      setSaveAllUploads(newSelection)
                    }
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowHeight={() => "auto"}
                  />
                </Box>
              )}
              {simples === true ? (
                ""
              ) : (
                <Button
                  sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Salvar Todos Documentos Selecionados
                </Button>
              )}
            </Container>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
