import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  InputBase,
  Paper,
  styled,
  ThemeProvider,
  Toolbar,
  Tooltip
} from "@mui/material"
import { useState } from "react"
import { toast, ToastContainer } from "react-toastify"

import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"

import ResponsiveDrawer from "../../components/menu"

import api from "../../Config/api"

const mdTheme = createTheme()

const CustomizedInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 420,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))

export default function NovoAssociado() {
  const [name, setName] = useState("")
  const [emailPrincipal, setEmailPrincipal] = useState("")
  const [listaEmailSecundario, setListaEmailSecundario] = useState([""])
  const [telefone, setTelefone] = useState()
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e, index) => {
    const list = [...listaEmailSecundario]
    list[index] = e.target.value
    setListaEmailSecundario(list)
  }

  const handleRemoveClick = (index) => {
    const list = [...listaEmailSecundario]
    list.splice(index, 1)
    setListaEmailSecundario(list)
  }

  const handleAddClick = () => {
    setListaEmailSecundario([...listaEmailSecundario, ""])
  }

  const handleChange = async (e) => {
    e.preventDefault(e)
    setLoading(true)
    if (emailPrincipal.split("@")[1] == null) {
      toast.warn("Email com formato inválido!", {
        position: toast.POSITION.TOP_CENTER
      })
      setLoading(false)
    }
    if (!name || !emailPrincipal || !telefone) {
      toast.warn("Preencha todos os campos!", {
        position: toast.POSITION.TOP_CENTER
      })
      setLoading(false)
    } else {
      const buscaAssociado = await api.post("/find-associado-pelo-email", {
        emailPrincipal
      })
      if (!buscaAssociado.data.err) {
        const dados = {
          name,
          emailPrincipal,
          emailSecundario: listaEmailSecundario,
          telefone
        }
        api.post("/novo-associado", dados).then((response) => {
          toast.success("Associado cadastrado com sucesso!", {
            position: toast.POSITION.TOP_CENTER
          })
          setLoading(false)
        })
      }
      if (buscaAssociado.data.err) {
        toast.warn("Email já cadastrado!", {
          position: toast.POSITION.TOP_CENTER
        })
        setLoading(false)
      }
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Novo Associado" />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <form autoComplete="off" onSubmit={handleChange}>
                  <Grid>
                    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                      <center>
                        <span className="submenu">Novo Associado</span>
                      </center>
                      <hr />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <Box
                            sx={{
                              "& > :not(style)": {
                                m: 1,
                                width: "40ch"
                              }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <CustomizedInput
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Nome Associado"
                              label="Nome Associado"
                              onChange={(e) => setName(e.target.value)}
                            />
                            <CustomizedInput
                              type="text"
                              label="Telefone"
                              id="telefone"
                              name="telefone"
                              placeholder="Telefone"
                              onChange={(e) => setTelefone(e.target.value)}
                            />
                          </Box>
                          <Box
                            sx={{
                              "& > :not(style)": {
                                m: 1,
                                width: "40ch"
                              }
                            }}
                          >
                            <Tooltip
                              title="O email cadastrado neste campo irá receber usuário e senha para entrar no sistema"
                              arrow
                            >
                              <CustomizedInput
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email Principal"
                                label="Email Principal"
                                onChange={(e) =>
                                  setEmailPrincipal(e.target.value)
                                }
                              />
                            </Tooltip>
                          </Box>
                          {listaEmailSecundario.map((email, idx) => {
                            return (
                              <Box
                                sx={{
                                  "& > :not(style)": { m: 1, width: "40ch" }
                                }}
                                noValidate
                                autoComplete="off"
                                key={idx}
                              >
                                <CustomizedInput
                                  type="email"
                                  id="emailSecundario"
                                  name="emailSecundario"
                                  placeholder="Email secundário"
                                  value={email}
                                  onChange={(e) => handleInputChange(e, idx)}
                                />
                                <Box
                                  sx={{
                                    "& > :not(style)": {
                                      mr: 1,
                                      width: "25ch"
                                    }
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  {listaEmailSecundario.length !== 1 && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        mt: 1,
                                        mb: 1,
                                        backgroundColor: "#f07167"
                                      }}
                                      onClick={() => handleRemoveClick(idx)}
                                      startIcon={
                                        <DeleteIcon fontSize="small" />
                                      }
                                    >
                                      Remover
                                    </Button>
                                  )}
                                  {listaEmailSecundario.length - 1 === idx && (
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      sx={{
                                        mt: 1,
                                        mb: 1,
                                        backgroundColor: "#28A6DE"
                                      }}
                                      onClick={handleAddClick}
                                      startIcon={
                                        <AddCircleOutlineOutlinedIcon />
                                      }
                                    >
                                      Adicionar
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            )
                          })}
                          <Box
                            sx={{
                              "& > :not(style)": { ml: 1, width: "25ch" }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                mt: 1,
                                mb: 1,
                                backgroundColor: "#28A6DE"
                              }}
                            >
                              Salvar
                            </Button>
                          </Box>
                        </Paper>
                      </div>
                    </Container>
                  </Grid>
                </form>
              )}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
