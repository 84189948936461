import React, { useState, useEffect } from "react"

import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"

import Toolbar from "@mui/material/Toolbar"

import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { CircularProgress, Grid, Box, Container } from "@mui/material"
import { useParams } from "react-router-dom"
import ResponsiveDrawer from "../../../components/menuCliente"
import api from "../../../Config/api"

import "./style.css"

const mdTheme = createTheme()

export default function Painel(props) {
  const cnpj = localStorage.getItem("cnpj")
  const params = useParams()
  const cnpjCliente = params._id
  const [datas, setDatas] = useState([])
  const [datasIndex, setDatasIndex] = useState([]) // Indice que será escondido da tabela

  const [up10, setUp10] = useState([])
  const [up19, setUp19] = useState([])
  const [up20, setUp20] = useState([])
  const [up21, setUp21] = useState([])
  const [up22, setUp22] = useState([])
  const [up23, setUp23] = useState([])
  const [up24, setUp24] = useState([])
  const [up26, setUp26] = useState([])
  const [up27, setUp27] = useState([])
  const [up30, setUp30] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/checklist", {
        cnpj: cnpj === null ? cnpjCliente : cnpj
      })
      let datas = []
      datas = cliente.data.dados.cincoAnos
      let x = 0
      const meses = []
      while (x < datas.length) {
        meses.push({ id: x, data: datas[x] })
        x++
      }
      x = 0
      while (x < cliente.data.tipos.length) {
        if (cliente.data.tipos[x].tipo === 10) {
          setUp10(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 19) {
          setUp19(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 20) {
          setUp20(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 21) {
          setUp21(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 22) {
          setUp22(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 23) {
          setUp23(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 24) {
          setUp24(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 26) {
          setUp26(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 27) {
          setUp27(cliente.data.tipos[x].statusCincoAnos)
        }
        if (cliente.data.tipos[x].tipo === 30) {
          setUp30(cliente.data.tipos[x].statusCincoAnos)
        }
        x++
      }
      setDatas(meses)
      setLoading(false)
    }

    getCliente()
    setLoading(true)
  }, [cnpj])

  function formataData(data) {
    const ano = data.substr(2, 2)
    const mes = data.substr(5, 2)
    return `${mes}/${ano}`
  }

  const filtrarColunas = (ano) => {
    const selecionados = []
    for (const idx in datas) {
      const item = datas[idx].data.substring(2, 4)
      if (item !== ano) {
        selecionados.push(parseInt(idx))
      }
    }
    setDatasIndex(selecionados)
  }

  const BotoesFiltro = (props) => {
    return (
      <button onClick={() => filtrarColunas(props.ano)} className="year">
        {props.ano}
      </button>
    )
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />

          <Container sx={{ mt: 4, mb: 4 }}>
            <center>
              <span className="submenu">Checklist de Documentos 60 meses</span>
            </center>
            <hr />
            <TableContainer component={Paper} sx={{ overflowX: "unset" }}>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <BotoesFiltro ano="22" align="center">
                        2022
                      </BotoesFiltro>
                    </TableCell>
                    <TableCell>
                      <BotoesFiltro ano="21" align="center">
                        2021
                      </BotoesFiltro>
                    </TableCell>
                    <TableCell>
                      <BotoesFiltro ano="20" align="center">
                        2020
                      </BotoesFiltro>
                    </TableCell>
                    <TableCell>
                      <BotoesFiltro ano="19" align="center">
                        2019
                      </BotoesFiltro>
                    </TableCell>
                    <TableCell>
                      <BotoesFiltro ano="18" align="center">
                        2018
                      </BotoesFiltro>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Container>
          <Container
            sx={{
              mt: 4,
              mb: 4
            }}
          >
            <Grid>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 490 }}>
                    <Table aria-label="sticky table">
                      <TableHead
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "white",
                          zIndex: 1
                        }}
                      >
                        <TableRow>
                          <TableCell
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white"
                            }}
                          >
                            <span className="table-title">Arquivo</span>
                          </TableCell>
                          {datas
                            .filter(
                              (col, colIdx) =>
                                !datasIndex.find((idx) => idx === colIdx)
                            )
                            .map((col) => (
                              <TableCell key={col.id}>
                                <strong className="table-title">
                                  {formataData(col.data)}
                                </strong>
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {up10.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              EFD Contribuições
                            </TableCell>
                            {up10
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}
                        {up19.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              Resumo da folha de pagamento
                            </TableCell>
                            {up19
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up20.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              Comprovante de declaração a recolher do INSS
                              (Rubrica)
                            </TableCell>
                            {up20
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up21.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              Print da FAP
                            </TableCell>
                            {up21
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up22.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              GPS
                            </TableCell>
                            {up22
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up23.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              Tabela de Incidência do INSS sobre os eventos da
                              folha de pagamentos (Contribuições
                              Previdenciárias)
                            </TableCell>
                            {up23
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up24.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              Memórias de Cálculo de CPRB/Desoneração
                            </TableCell>
                            {up24
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up26.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              DARF
                            </TableCell>
                            {up26
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up27.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              DCTFWEB
                            </TableCell>
                            {up27
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}

                        {up30.length === 0 ? (
                          <></>
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white"
                              }}
                            >
                              Extrato Simples Nacional
                            </TableCell>
                            {up30
                              .filter(
                                (col, colIdx) =>
                                  !datasIndex.find((idx) => idx === colIdx)
                              )
                              .map((row, index) => (
                                <TableCell key={index}>
                                  {row === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
            </Grid>
            <>
              <TableContainer>
                <Table
                  sx={{ minWidth: 500 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow scope="row" align="center">
                      <TableCell className="title">Status:</TableCell>

                      <TableCell scope="row" align="center">
                        <CheckCircleIcon sx={{ color: "green" }} /> <p />{" "}
                        <p> Arquivos recebidos </p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <ErrorIcon sx={{ color: "gold" }} /> <p />{" "}
                        <p>Arquivos em análise</p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <CancelIcon sx={{ color: "red" }} /> <p />{" "}
                        <p>Arquivos pendentes</p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <RectangleIcon sx={{ color: "black" }} /> <p />{" "}
                        <p>Arquivos não existentes</p>
                      </TableCell>

                      <TableCell scope="row" align="center">
                        <FormatItalicIcon sx={{ color: "black" }} /> <p />{" "}
                        <p>Arquivos parciais</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
