import * as React from "react"

const Painel = () => {
  return (
    <div>
      <h1>Painel</h1>
    </div>
  )
}

export default Painel
