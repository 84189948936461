import React, { useEffect, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useParams } from "react-router-dom"
import ResponsiveDrawerAdmin from "../../../../components/menu"
import ResponsiveDrawer from "../../../../components/menuCliente"
import api from "../../../../Config/api"

const mdTheme = createTheme()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "White",
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const CheckListXMLsEntradas = () => {
  const [loading, setLoading] = useState(false)
  const [perfil, setPerfil] = useState("")
  const params = useParams()
  const cnpjCliente = params._id
  const cnpj = localStorage.getItem("cnpj")
  const [rows, setRows] = useState([])
  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState("")
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")
  const [data5, setData5] = useState("")
  const [data6, setData6] = useState("")
  const [data7, setData7] = useState("")
  const [data8, setData8] = useState("")
  const [data9, setData9] = useState("")
  const [data10, setData10] = useState("")
  const [data11, setData11] = useState("")
  const [data12, setData12] = useState("")

  const [dataCincoAnos1, setDataCincoAnos1] = useState("")
  const [dataCincoAnos2, setDataCincoAnos2] = useState("")
  const [dataCincoAnos3, setDataCincoAnos3] = useState("")
  const [dataCincoAnos4, setDataCincoAnos4] = useState("")
  const [dataCincoAnos5, setDataCincoAnos5] = useState("")
  const [dataCincoAnos6, setDataCincoAnos6] = useState("")
  const [dataCincoAnos7, setDataCincoAnos7] = useState("")
  const [dataCincoAnos8, setDataCincoAnos8] = useState("")
  const [dataCincoAnos9, setDataCincoAnos9] = useState("")
  const [dataCincoAnos10, setDataCincoAnos10] = useState("")
  const [dataCincoAnos11, setDataCincoAnos11] = useState("")
  const [dataCincoAnos12, setDataCincoAnos12] = useState("")
  const [dataCincoAnos13, setDataCincoAnos13] = useState("")
  const [dataCincoAnos14, setDataCincoAnos14] = useState("")
  const [dataCincoAnos15, setDataCincoAnos15] = useState("")
  const [dataCincoAnos16, setDataCincoAnos16] = useState("")
  const [dataCincoAnos17, setDataCincoAnos17] = useState("")
  const [dataCincoAnos18, setDataCincoAnos18] = useState("")
  const [dataCincoAnos19, setDataCincoAnos19] = useState("")
  const [dataCincoAnos20, setDataCincoAnos20] = useState("")
  const [dataCincoAnos21, setDataCincoAnos21] = useState("")
  const [dataCincoAnos22, setDataCincoAnos22] = useState("")
  const [dataCincoAnos23, setDataCincoAnos23] = useState("")
  const [dataCincoAnos24, setDataCincoAnos24] = useState("")
  const [dataCincoAnos25, setDataCincoAnos25] = useState("")
  const [dataCincoAnos26, setDataCincoAnos26] = useState("")
  const [dataCincoAnos27, setDataCincoAnos27] = useState("")
  const [dataCincoAnos28, setDataCincoAnos28] = useState("")
  const [dataCincoAnos29, setDataCincoAnos29] = useState("")
  const [dataCincoAnos30, setDataCincoAnos30] = useState("")
  const [dataCincoAnos31, setDataCincoAnos31] = useState("")
  const [dataCincoAnos32, setDataCincoAnos32] = useState("")
  const [dataCincoAnos33, setDataCincoAnos33] = useState("")
  const [dataCincoAnos34, setDataCincoAnos34] = useState("")
  const [dataCincoAnos35, setDataCincoAnos35] = useState("")
  const [dataCincoAnos36, setDataCincoAnos36] = useState("")
  const [dataCincoAnos37, setDataCincoAnos37] = useState("")
  const [dataCincoAnos38, setDataCincoAnos38] = useState("")
  const [dataCincoAnos39, setDataCincoAnos39] = useState("")
  const [dataCincoAnos40, setDataCincoAnos40] = useState("")
  const [dataCincoAnos41, setDataCincoAnos41] = useState("")
  const [dataCincoAnos42, setDataCincoAnos42] = useState("")
  const [dataCincoAnos43, setDataCincoAnos43] = useState("")
  const [dataCincoAnos44, setDataCincoAnos44] = useState("")
  const [dataCincoAnos45, setDataCincoAnos45] = useState("")
  const [dataCincoAnos46, setDataCincoAnos46] = useState("")
  const [dataCincoAnos47, setDataCincoAnos47] = useState("")
  const [dataCincoAnos48, setDataCincoAnos48] = useState("")
  const [dataCincoAnos49, setDataCincoAnos49] = useState("")
  const [dataCincoAnos50, setDataCincoAnos50] = useState("")
  const [dataCincoAnos51, setDataCincoAnos51] = useState("")
  const [dataCincoAnos52, setDataCincoAnos52] = useState("")
  const [dataCincoAnos53, setDataCincoAnos53] = useState("")
  const [dataCincoAnos54, setDataCincoAnos54] = useState("")
  const [dataCincoAnos55, setDataCincoAnos55] = useState("")
  const [dataCincoAnos56, setDataCincoAnos56] = useState("")
  const [dataCincoAnos57, setDataCincoAnos57] = useState("")
  const [dataCincoAnos58, setDataCincoAnos58] = useState("")
  const [dataCincoAnos59, setDataCincoAnos59] = useState("")
  const [dataCincoAnos60, setDataCincoAnos60] = useState("")

  useEffect(() => {
    const jsonRecuperado = localStorage.getItem("user")
    const perfilUser = JSON.parse(jsonRecuperado).perfil
    setPerfil(perfilUser)
  }, [])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/checklist", { cnpj: cnpjCliente })
      setRazaoSocial(cliente.data.dados.razaoSocial)
      setSimples(cliente.data.dados.simples)
      setData1(cliente.data.dados.data1)
      setData2(cliente.data.dados.data2)
      setData3(cliente.data.dados.data3)
      setDataCincoAnos1(cliente.data.dados.cincoAnos[0])
      setDataCincoAnos2(cliente.data.dados.cincoAnos[1])
      setDataCincoAnos3(cliente.data.dados.cincoAnos[2])
      setDataCincoAnos4(cliente.data.dados.cincoAnos[3])
      setDataCincoAnos5(cliente.data.dados.cincoAnos[4])
      setDataCincoAnos6(cliente.data.dados.cincoAnos[5])
      setDataCincoAnos7(cliente.data.dados.cincoAnos[6])
      setDataCincoAnos8(cliente.data.dados.cincoAnos[7])
      setDataCincoAnos9(cliente.data.dados.cincoAnos[8])
      setDataCincoAnos10(cliente.data.dados.cincoAnos[9])
      setDataCincoAnos11(cliente.data.dados.cincoAnos[10])
      setDataCincoAnos12(cliente.data.dados.cincoAnos[11])
      setDataCincoAnos13(cliente.data.dados.cincoAnos[12])
      setDataCincoAnos14(cliente.data.dados.cincoAnos[13])
      setDataCincoAnos15(cliente.data.dados.cincoAnos[14])
      setDataCincoAnos16(cliente.data.dados.cincoAnos[15])
      setDataCincoAnos17(cliente.data.dados.cincoAnos[16])
      setDataCincoAnos18(cliente.data.dados.cincoAnos[17])
      setDataCincoAnos19(cliente.data.dados.cincoAnos[18])
      setDataCincoAnos20(cliente.data.dados.cincoAnos[19])
      setDataCincoAnos21(cliente.data.dados.cincoAnos[20])
      setDataCincoAnos22(cliente.data.dados.cincoAnos[21])
      setDataCincoAnos23(cliente.data.dados.cincoAnos[22])
      setDataCincoAnos24(cliente.data.dados.cincoAnos[23])
      setDataCincoAnos25(cliente.data.dados.cincoAnos[24])
      setDataCincoAnos26(cliente.data.dados.cincoAnos[25])
      setDataCincoAnos27(cliente.data.dados.cincoAnos[26])
      setDataCincoAnos28(cliente.data.dados.cincoAnos[27])
      setDataCincoAnos29(cliente.data.dados.cincoAnos[28])
      setDataCincoAnos30(cliente.data.dados.cincoAnos[29])
      setDataCincoAnos31(cliente.data.dados.cincoAnos[30])
      setDataCincoAnos32(cliente.data.dados.cincoAnos[31])
      setDataCincoAnos33(cliente.data.dados.cincoAnos[32])
      setDataCincoAnos34(cliente.data.dados.cincoAnos[33])
      setDataCincoAnos35(cliente.data.dados.cincoAnos[34])
      setDataCincoAnos36(cliente.data.dados.cincoAnos[35])
      setDataCincoAnos37(cliente.data.dados.cincoAnos[36])
      setDataCincoAnos38(cliente.data.dados.cincoAnos[37])
      setDataCincoAnos39(cliente.data.dados.cincoAnos[38])
      setDataCincoAnos40(cliente.data.dados.cincoAnos[39])
      setDataCincoAnos41(cliente.data.dados.cincoAnos[40])
      setDataCincoAnos42(cliente.data.dados.cincoAnos[41])
      setDataCincoAnos43(cliente.data.dados.cincoAnos[42])
      setDataCincoAnos44(cliente.data.dados.cincoAnos[43])
      setDataCincoAnos45(cliente.data.dados.cincoAnos[44])
      setDataCincoAnos46(cliente.data.dados.cincoAnos[45])
      setDataCincoAnos47(cliente.data.dados.cincoAnos[46])
      setDataCincoAnos48(cliente.data.dados.cincoAnos[47])
      setDataCincoAnos49(cliente.data.dados.cincoAnos[48])
      setDataCincoAnos50(cliente.data.dados.cincoAnos[49])
      setDataCincoAnos51(cliente.data.dados.cincoAnos[50])
      setDataCincoAnos52(cliente.data.dados.cincoAnos[51])
      setDataCincoAnos53(cliente.data.dados.cincoAnos[52])
      setDataCincoAnos54(cliente.data.dados.cincoAnos[53])
      setDataCincoAnos55(cliente.data.dados.cincoAnos[54])
      setDataCincoAnos56(cliente.data.dados.cincoAnos[55])
      setDataCincoAnos57(cliente.data.dados.cincoAnos[56])
      setDataCincoAnos58(cliente.data.dados.cincoAnos[57])
      setDataCincoAnos59(cliente.data.dados.cincoAnos[58])
      setDataCincoAnos60(cliente.data.dados.cincoAnos[59])
      if (cliente.data.dados.simples === true) {
        setData4(cliente.data.dados.data4)
        setData5(cliente.data.dados.data5)
        setData6(cliente.data.dados.data6)
        setData7(cliente.data.dados.data7)
        setData8(cliente.data.dados.data8)
        setData9(cliente.data.dados.data9)
        setData10(cliente.data.dados.data10)
        setData11(cliente.data.dados.data11)
        setData12(cliente.data.dados.data12)
      }
    }
    getCliente()
  }, [cnpj])

  useEffect(() => {
    async function getTipoXMLsEntradas() {
      const xmlsEntrada = await api.post("/checklist-tipo-xmls-entradas", {
        cnpj: cnpjCliente
      })
      const XMLsEntradasMatriz = xmlsEntrada.data.statusXMLsEntradas.map(
        (d) => ({
          cnpj: d.cnpj,
          tipoUpload: d.tipoUploadMatriz
        })
      )

      setRows([XMLsEntradasMatriz])
    }
    getTipoXMLsEntradas()
    setLoading(false)
  }, [cnpj])

  const xmlsEntrada = rows
    .flatMap((d) => d)
    .map((d) => ({
      cnpj: d.cnpj,
      ...d.tipoUpload.map((d) => d.statusCincoAnos).reduce((a, b) => b, {})
    }))

  function formatarData(data) {
    if (data === null) {
      return "-"
    }
    const mes = data.substr(5, 2)
    const ano = data.substr(0, 4)
    const dataAtual = `${mes}/${ano}`
    return dataAtual
  }

  function formatarDataSimples(data) {
    if (data === null) {
      return "-"
    }
    const mes = data.substr(5, 2)
    const ano = data.substr(2, 2)
    const dataAtual = `${mes}/${ano}`
    return dataAtual
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {perfil === 2 ? (
          <ResponsiveDrawer title={`Cliente - ${razaoSocial}`} />
        ) : (
          <ResponsiveDrawerAdmin title={`Cliente - ${razaoSocial}`} />
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4 }}>
            <div>
              <Grid>
                {loading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Container sx={{ mt: 4, mb: 4 }}>
                    <center>
                      <span className="submenu">Checklist de Documentos</span>
                    </center>
                    <hr />
                    <center>
                      <span className="sublabel">
                        XMLs NF-es e CT-es de Entradas
                      </span>
                    </center>
                    <hr />
                    <>
                      <TableContainer component={Paper}>
                        <Table aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <div className="title-arquivo">CNPJ</div>
                              </TableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos60)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos59)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos58)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos57)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos56)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos55)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos54)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos53)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos52)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos51)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos50)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos49)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos48)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos47)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos46)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos45)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos44)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos43)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos42)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos41)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos40)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos39)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos38)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos37)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos36)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos35)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos34)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos33)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos32)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos31)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos30)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos29)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos28)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos27)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos26)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos25)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos24)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos23)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos22)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos21)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos20)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos19)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos18)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos17)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos16)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos15)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos14)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos13)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos12)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos11)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos10)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos9)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos8)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos7)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos6)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos5)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos4)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos3)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos2)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatarData(dataCincoAnos1)}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {xmlsEntrada.map((row, idx) => (
                              <StyledTableRow key={row.idx}>
                                <StyledTableCell component="th" scope="row">
                                  {row.cnpj ? row.cnpj : "-"}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {row[0] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[0] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[0] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[0] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[0] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[1] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[1] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[1] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[1] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[1] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[2] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[2] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[2] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[2] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[2] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[3] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[3] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[3] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[3] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[3] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[4] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[4] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[4] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[4] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[4] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[5] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[5] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[5] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[5] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[5] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[6] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[6] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[6] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[6] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[6] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[7] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[7] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[7] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[7] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[7] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[8] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[8] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[8] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[8] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[8] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[9] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[9] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[9] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[9] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[9] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[10] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[10] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[10] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[10] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[10] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[11] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[11] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[11] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[11] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[11] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[12] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[12] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[12] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[12] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[12] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[13] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[13] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[13] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[13] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[13] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[14] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[14] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[14] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[14] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[14] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[15] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[15] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[15] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[15] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[15] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[16] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[16] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[16] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[16] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[16] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[17] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[17] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[17] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[17] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[17] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[18] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[18] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[18] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[18] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[18] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[19] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[19] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[19] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[19] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[19] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[20] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[20] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[20] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[20] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[20] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[21] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[21] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[21] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[21] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[21] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[22] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[22] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[22] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[22] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[22] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[23] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[23] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[23] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[23] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[23] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[24] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[24] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[24] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[24] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[24] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[25] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[25] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[25] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[25] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[25] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[26] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[26] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[26] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[26] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[26] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[27] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[27] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[27] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[27] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[27] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[28] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[28] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[28] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[28] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[28] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[29] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[29] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[29] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[29] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[29] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[30] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[30] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[30] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[30] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[30] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[31] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[31] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[31] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[31] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[31] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[32] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[32] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[32] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[32] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[32] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[33] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[33] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[33] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[33] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[33] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[34] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[34] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[34] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[34] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[34] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[35] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[35] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[35] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[35] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[35] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[36] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[36] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[36] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[36] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[36] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[37] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[37] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[37] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[37] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[37] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[38] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[38] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[38] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[38] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[38] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[39] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[39] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[39] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[39] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[39] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[40] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[40] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[40] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[40] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[40] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[41] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[41] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[41] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[41] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[41] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[42] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[42] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[42] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[42] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[42] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[43] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[43] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[43] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[43] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[43] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[44] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[44] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[44] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[44] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[44] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[45] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[45] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[45] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[45] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[45] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[46] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[46] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[46] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[46] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[46] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[47] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[47] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[47] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[47] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[47] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[48] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[48] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[48] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[48] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[48] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[49] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[49] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[49] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[49] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[49] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[50] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[50] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[50] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[50] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[50] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[51] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[51] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[51] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[51] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[51] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[52] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[52] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[52] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[52] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[52] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[53] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[53] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[53] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[53] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[53] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[54] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[54] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[54] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[54] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[54] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[55] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[55] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[55] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[55] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[55] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[56] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[56] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[56] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[56] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[56] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[57] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[57] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[57] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[57] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[57] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[58] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[58] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[58] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[58] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[58] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row[59] === 0 ? (
                                    <CancelIcon sx={{ color: "red" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[59] === 1 ? (
                                    <ErrorIcon sx={{ color: "gold" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[59] === 2 ? (
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[59] === 3 ? (
                                    <RectangleIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                  {row[59] === 4 ? (
                                    <FormatItalicIcon sx={{ color: "black" }} />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  </Container>
                )}
              </Grid>
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <CheckListXMLsEntradas />
}
