import React, { useState, useContext, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { NumericFormat } from "react-number-format"

import { styled, createTheme, ThemeProvider } from "@mui/material/styles"

import { toast, ToastContainer } from "react-toastify"
import { produce } from "immer"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"

import { Button } from "@mui/material"
import * as XLSX from "xlsx"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import MenuRoundedIcon from "@mui/icons-material/MenuRounded"

import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"

import Paper from "@mui/material/Paper"
import api from "../../../Config/api"
import { AuthContext } from "../../../Context/auth"
import ResponsiveDrawer from "../../../components/menu"
import MyModal from "../TabelaFuturo"
import ValoresUtilizados from "./tabelaValoresUtilizados"
import TabelaAnaliticaHeader from "./TabelaAnaliticaHeader"
import TabelaAnaliticaBody from "./TabelaAnaliticaBody"

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

const mdTheme = createTheme()

export default function () {
  const isReadOnly = false

  const [screenData, setScreenData] = useState(null)
  const [idCliente, setIdCliente] = useState("")

  const cnpj = useParams()._id

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/find-cliente-cnpj", { cnpj })
      setIdCliente(cliente.data.cliente._id)
    }

    getCliente()
  }, [cnpj])

  useEffect(() => {
    if (!idCliente) {
      return
    }
    async function getData() {
      await api
        .get(`/diagnostico-analitico/${idCliente}`)
        .then((res) => setScreenData(res.data))
    }
    getData()
  }, [idCliente])

  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const [razaoSocial, setRazaoSocial] = useState("")

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/find-cliente-cnpj", { cnpj })
      setRazaoSocial(cliente.data.cliente.razaoSocial)
    }

    getCliente()
  }, [cnpj])

  const setDemonstrativoDiagnostico = (json) => {
    const newScreenData = {
      percentualAssoc: "0",
      dataParcial1: "",
      dataParcial2: "",
      dataParcial3: "",
      dataParcial4: "",
      dataParcial5: "",
      oportunidades: [],
      valoresUtilizados: [],
      totalValoresUtilizados: 0,
      perdaMediaMensalEstimadaTotal: 0,
      totalParcial1: 0,
      totalParcial2: 0,
      totalParcial3: 0,
      totalParcial4: 0,
      totalParcial5: 0,
      totalDasOportunidades: 0,
      totalsValoresEntregues: 0,
      valoresAtingidosPelaPrescricao: 0,
      valorJurosSelic: 0,
      totalDosCreditosDisponiveis: 0,
      saldoCreditosDisponiveis: 0
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const line of json) {
      newScreenData.oportunidades.push({
        tributo: line.Tributo,
        nivel: line["Nível"],
        status: line.Status,
        periodo: line["Período"],
        descricao: line["Descrição Oportunidade"],
        oportunidade: line["Total Oportunidade"],
        statusAprovacao: "",
        previsaoDeHonorarios: line["Previsão de honorários"],
        valorParcial1: 0,
        valorParcial2: 0,
        valorParcial3: 0,
        valorParcial4: 0,
        valorParcial5: 0,
        perdaMediaMensalEstimada:
          line["Parcela Mensal da Perda por Decadência"],
        futuros: [],
        totalFuturo: 0,
        totalValoresEntreguesOportunidade: 0,
        observacoes: ""
      })
    }
    setScreenData(newScreenData)
    console.log(json)
  }
  const handleUpload = (e) => {
    e.preventDefault()

    const { files } = e.target
    const f = files[0]

    const reader = new FileReader()

    reader.onload = (evt) => {
      // Parse data
      const data = evt.target.result
      const readedData = XLSX.read(data, { type: "binary" })

      // Get the first worksheet
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json */
      setDemonstrativoDiagnostico(XLSX.utils.sheet_to_json(ws))
    }
    reader.readAsBinaryString(f)
  }

  const [modalFuturo, setModalFuturo] = useState(false)
  const [modalFuturoData, setModalFuturoData] = useState([])
  const [modalFuturoIndex, setModalFuturoIndex] = useState(null)

  const setValoresFaturas = (valoresFaturas) => {
    const nextState = produce(screenData, (draft) => {
      draft.valoresUtilizados = valoresFaturas
    })
    setScreenData(nextState)
  }

  const setRowData = (rowData, index) => {
    const nextState = produce(screenData, (draft) => {
      const row = draft.oportunidades[index]
      row.futuros = rowData
    })
    setScreenData(nextState)
  }

  const setShowModalFuturo = (show, row, index) => {
    const futurosCopy = [...row.futuros]
    setModalFuturoData(futurosCopy)
    setModalFuturo(show)
    setModalFuturoIndex(index)
  }

  const handleRemoveRow = (index) => {
    const nextState = produce(screenData, (draft) => {
      draft.oportunidades.splice(index, 1)
    })
    setScreenData(nextState)
  }

  const Input = styled("input")({
    display: "none"
  })

  const Label = styled("label")({
    padding: "6px 16px",
    background: "#28A6DE",
    border: "0 none",
    cursor: "pointer",
    webkitBorderRadius: "4px",
    borderRadius: "4px",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2)"
  })

  if (!screenData && !idCliente) {
    return <div>Loading</div>
  }
  if (!screenData) {
    return (
      <ThemeProvider theme={mdTheme}>
        <ToastContainer />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <ResponsiveDrawer title={razaoSocial} />

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto"
            }}
          >
            <Toolbar />
            <Label htmlFor="file">
              <Input
                id="file"
                type="file"
                name="demonstrativoDiagnostico"
                onInput={(e) => {
                  handleUpload(e)
                }}
              />
              Importar Demonstrativo Diagnóstico
            </Label>
          </Box>
        </Box>
      </ThemeProvider>
    )
  }

  const creditoDisponivelFinal =
    parseFloat(
      screenData.oportunidades.reduce((acc, x) => {
        const linhaTotal =
          parseFloat(x.valorParcial1 || 0) +
          parseFloat(x.valorParcial2 || 0) +
          parseFloat(x.valorParcial3 || 0) +
          parseFloat(x.valorParcial4 || 0) +
          parseFloat(x.valorParcial5 || 0) +
          x.futuros.reduce((acc2, y) => acc2 + parseFloat(y.valor || 0), 0)

        return acc + linhaTotal
      }, 0)
    ) + parseFloat(screenData.valoresAtingidosPelaPrescricao || 0)
  // parseFloat(screenData.valorJurosSelic || 0);;

  const addOportunity = async () => {
    const nextState = produce(screenData, (draft) => {
      draft.oportunidades.push({
        tributo: "",
        nivel: "",
        status: "",
        periodo: "",
        descricao: "",
        oportunidade: 0,
        statusAprovacao: "",
        previsaoDeHonorarios: 0,
        valorParcial1: 0,
        valorParcial2: 0,
        valorParcial3: 0,
        valorParcial4: 0,
        valorParcial5: 0,
        perdaMediaMensalEstimada: 0,
        futuros: [],
        totalFuturo: 0,
        totalValoresEntreguesOportunidade: 0,
        observacoes: ""
      })
    })
    setScreenData(nextState)
  }

  const salveInputsInfo = async () => {
    const finalData = produce(screenData, (draft) => {
      for (let i = 0; i < draft.oportunidades.length; i += 1) {
        const oportunidade = draft.oportunidades[i]
        oportunidade.totalFuturo = oportunidade.futuros.reduce(
          (acc, oportunidadeItem) => {
            return acc + parseFloat(oportunidadeItem.valor || 0)
          },
          0
        )
        oportunidade.totalValoresEntreguesOportunidade =
          oportunidade.totalFuturo +
          oportunidade.valorParcial1 +
          oportunidade.valorParcial2 +
          oportunidade.valorParcial3 +
          oportunidade.valorParcial4 +
          oportunidade.valorParcial5
        oportunidade.oportunidade = parseFloat(oportunidade.oportunidade || 0)
      }

      draft.perdaMediaMensalEstimadaTotal = parseFloat(
        draft.oportunidades
          .filter((x) => typeof x.perdaMediaMensalEstimada === "number")
          .reduce((acc, x) => acc + x.perdaMediaMensalEstimada || 0, 0)
      )

      draft.previsaoDeHonorariosTotal = parseFloat(
        draft.oportunidades
          .filter((x) => typeof x.previsaoDeHonorarios === "number")
          .reduce((acc, x) => acc + x.previsaoDeHonorarios || 0, 0)
      )

      draft.totalDasOportunidades = parseFloat(
        draft.oportunidades
          .filter((x) => typeof x.oportunidade === "number")
          .reduce((acc, x) => acc + x.oportunidade, 0) || 0
      )
      draft.totalParcial1 = parseFloat(
        draft.oportunidades.reduce((acc, x) => acc + x.valorParcial1, 0) || 0
      )
      draft.totalParcial2 = parseFloat(
        draft.oportunidades.reduce((acc, x) => acc + x.valorParcial2, 0) || 0
      )
      draft.totalParcial3 = parseFloat(
        draft.oportunidades.reduce((acc, x) => acc + x.valorParcial3, 0) || 0
      )
      draft.totalParcial4 = parseFloat(
        draft.oportunidades.reduce((acc, x) => acc + x.valorParcial4, 0) || 0
      )
      draft.totalParcial5 = parseFloat(
        draft.oportunidades.reduce((acc, x) => acc + x.valorParcial5, 0) || 0
      )
      draft.totalsValoresEntregues = parseFloat(
        draft.oportunidades.reduce(
          (acc, x) => acc + x.totalValoresEntreguesOportunidade,
          0
        ) || 0
      )
      draft.totalDosCreditosDisponiveis = creditoDisponivelFinal
      draft.totalValoresUtilizados = parseFloat(
        draft.valoresUtilizados.reduce(
          (acc, x) => acc + parseFloat(x.valor || 0),
          0
        ) || 0
      )
      draft.saldoCreditosDisponiveis =
        creditoDisponivelFinal -
        draft.totalValoresUtilizados +
        draft.valorJurosSelic
    })
    console.log("finaldata", finalData)
    try {
      await api.post(`/diagnostico-analitico/${idCliente}`, finalData)
      toast.success("Dados Salvos", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 8000
      })
    } catch {
      toast.error("Erro ao Salvar - Verifique os Dados", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 8000
      })
    }
  }

  const totalCreditosUtilizados = screenData.valoresUtilizados.reduce(
    (acc, x) => acc + parseFloat(x.valor || 0),
    0
  )

  console.log(screenData)

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title={razaoSocial} />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />

          {/* <Container sx={{ mt: 1}}> */}
          <Box sx={{ display: "flex" }}>
            <Label
              htmlFor="file"
              sx={{ mt: 3, mb: 3, ml: 2, backgroundColor: "#28A6DE" }}
            >
              <Input
                id="file"
                type="file"
                name="demonstrativoDiagnostico"
                onInput={(e) => {
                  handleUpload(e)
                }}
              />
              Importar Demonstrativo Diagnóstico
            </Label>

            <Button
              sx={{ mt: 3, mb: 3, ml: 2, backgroundColor: "#28A6DE" }}
              variant="contained"
              onClick={salveInputsInfo}
            >
              Salvar todas as alterações
            </Button>
            <Box sx={{ flexGrow: 1 }} />

            <Button
              sx={{ mt: 3, mb: 3, ml: 2, mr: 2, backgroundColor: "#198e2c" }}
              variant="contained"
              onClick={addOportunity}
            >
              Adicionar Oportunidade
            </Button>
          </Box>

          <hr />

          <Paper elevation={10} sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 690 }}>
              <Table aria-label="sticky table">
                <TabelaAnaliticaHeader
                  screenData={screenData}
                  setScreenData={setScreenData}
                  isReadOnly={isReadOnly}
                />

                <TabelaAnaliticaBody
                  screenData={screenData}
                  setScreenData={setScreenData}
                  setShowModalFuturo={setShowModalFuturo}
                  creditoDisponivelFinal={creditoDisponivelFinal}
                  handleRemoveRow={handleRemoveRow}
                  isReadOnly={isReadOnly}
                />
              </Table>
            </TableContainer>
            <ValoresUtilizados
              data={screenData.valoresUtilizados}
              setRowFaturas={setValoresFaturas}
              isReadOnly={isReadOnly}
            />
            <Table>
              <TableRow>
                <TableCell>
                  <span />
                </TableCell>
                <TableCell>
                  <span />
                </TableCell>
                <TableCell>
                  <span />
                </TableCell>
                <TableCell>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span className="table-title">
                    Saldo de créditos disponíveis
                  </span>
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>

                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell style={{ left: 0, backgroundColor: "white" }}>
                  <span />
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    position: "sticky",
                    right: 0,
                    backgroundColor: "white"
                  }}
                >
                  <NumericFormat
                    value={
                      creditoDisponivelFinal -
                      totalCreditosUtilizados +
                      screenData.valorJurosSelic
                    }
                    allowLeadingZeros
                    thousandSeparator="."
                    readOnly
                    decimalSeparator=","
                    fixedDecimalScale
                    decimalScale={2}
                    allowedDecimalSeparators={[","]}
                    prefix="R$ "
                    style={{
                      top: "3",
                      height: "25px",
                      width: "150px",
                      border: "none"
                    }}
                  />
                  <span />
                </TableCell>
              </TableRow>
            </Table>
          </Paper>
          {/* </Container> */}

          <hr />

          {screenData.oportunidades !== 0 ? (
            <Paper elevation={10} sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 490 }}>
                <Table aria-label="sticky table">
                  <TableHead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                      zIndex: 1
                    }}
                  >
                    <TableRow>
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "white"
                        }}
                      >
                        <span className="table-title">
                          Oportunidades - Nível 1 - Administrativas via RFB ou
                          Secretaria da Fazenda Estadual
                        </span>
                        <br />
                        <span className="table-title">
                          Oportunidades - Nível 2 - Oportunidades
                          administrativas com possibilidade de êxito via CARF -
                          Conselho Administrativo de Recursos Fiscais
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Paper>
          ) : null}
        </Box>
      </Box>
      <MyModal
        modalFuturo={modalFuturo}
        setModalFuturo={setModalFuturo}
        valoresData={modalFuturoData}
        setRowFuturo={setRowData}
        index={modalFuturoIndex}
      />
    </ThemeProvider>
  )
}
