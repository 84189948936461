import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import { Button } from "@mui/material"
import { Col, Row } from "reactstrap"
import ResponsiveDrawer from "../../components/menu"
import Tabela from "./Tabelas/Documentos"
import api from "../../Config/api"

const mdTheme = createTheme()

const CheckListCampoDocumentos = () => {
  const params = useParams()
  const cnpjCliente = params._id

  const cnpj = localStorage.getItem("cnpj")

  const [razaoSocial, setRazaoSocial] = useState("")
  const [simples, setSimples] = useState(false)
  const [filiais, setFiliais] = useState([])
  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")
  const [data5, setData5] = useState("")
  const [data6, setData6] = useState("")
  const [data7, setData7] = useState("")
  const [data8, setData8] = useState("")
  const [data9, setData9] = useState("")
  const [data10, setData10] = useState("")
  const [data11, setData11] = useState("")
  const [data12, setData12] = useState("")

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/checklist", {
        cnpj: cnpj !== null ? cnpj : cnpjCliente
      })
      setRazaoSocial(cliente.data.dados.razaoSocial)
      setSimples(cliente.data.dados.simples)
      setData1(cliente.data.dados.data1)
      setData2(cliente.data.dados.data2)
      setData3(cliente.data.dados.data3)
      if (cliente.data.dados.simples === true) {
        setData4(cliente.data.dados.data4)
        setData5(cliente.data.dados.data5)
        setData6(cliente.data.dados.data6)
        setData7(cliente.data.dados.data7)
        setData8(cliente.data.dados.data8)
        setData9(cliente.data.dados.data9)
        setData10(cliente.data.dados.data10)
        setData11(cliente.data.dados.data11)
        setData12(cliente.data.dados.data12)
      }
    }

    getCliente()
  }, [cnpj])

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Checklist de Documentos" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            {simples === true ? (
              <div>
                <Tabela />
              </div>
            ) : (
              // Não pertence ao Simples Nacional

              <div>
                {filiais.length > 0 ? (
                  <div>
                    {/* tabela com os documentos */}
                    <Tabela />
                  </div>
                ) : (
                  <div>
                    <Tabela />

                    <Row className="linhas">
                      <Col xl={4}>
                        <Button
                          type="link"
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "#28A6DE",
                            color: "#f2f2f2"
                          }}
                        >
                          <Link id="rollback" to="/clientes/">
                            Voltar
                          </Link>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <CheckListCampoDocumentos />
}
