import TableCell from "@mui/material/TableCell"
import React, { useEffect, useState } from "react"
import { produce } from "immer"
import TextField from "@mui/material/TextField"
import TextareaAutosize from "@mui/base/TextareaAutosize"
import { NumericFormat } from "react-number-format"
import { ITabelaAnalitica, ITabelaAnaliticaOportunidade } from "./types"

type IPageData = {
  screenData: ITabelaAnalitica
  setScreenData: (newScreenData: ITabelaAnalitica) => void
  row: ITabelaAnaliticaOportunidade
  index: number
  isReadOnly: boolean
  field: keyof ITabelaAnaliticaOportunidade
  fieldType: "text" | "number" | "date" | "multiline"
  placeholder: string
  isMoney?: boolean
  decimalPlaces?: number
}

const TabelaAnaliticaField = (props: IPageData) => {
  const {
    screenData,
    setScreenData,
    row,
    field,
    isReadOnly,
    index,
    fieldType,
    placeholder,
    isMoney,
    decimalPlaces
  } = props

  let fieldValue = row
    ? row[field] || ""
    : screenData.oportunidades[index][field] || ""

  if (fieldType === "number" && !isReadOnly) {
    fieldValue = Number(fieldValue)
  }

  const [memoVal, setMemoVal] = useState("")
  useEffect(() => {
    setMemoVal(fieldValue as string)
  }, [fieldValue])

  let totalDecimalWidth = 80
  if (decimalPlaces) {
    totalDecimalWidth = 70 + decimalPlaces * 15
  }

  if (isReadOnly) {
    return (
      <TableCell style={{ left: 0, backgroundColor: "white" }}>
        {fieldType === "number" && typeof fieldValue === "number" ? (
          <NumericFormat
            value={fieldValue}
            allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            allowedDecimalSeparators={[","]}
            prefix={isMoney ? "R$ " : ""}
            displayType="text"
          />
        ) : (
          <span>{fieldValue}</span>
        )}
      </TableCell>
    )
  }

  return (
    <TableCell
      align="center"
      style={{
        left: 0,
        backgroundColor: "white"
      }}
    >
      {fieldType === "text" && (
        <TextField
          sx={{ height: "14px" }}
          value={fieldValue}
          size="small"
          onChange={(e) => {
            const newData = produce(screenData, (draft) => {
              const oportunidade = draft.oportunidades[index]

              const newOportunidade = Object.assign(oportunidade, {
                [field]: e.target.value
              })

              draft.oportunidades[index] = newOportunidade
              // oportunidade[field] = e.target.value
            })
            setScreenData(newData)
          }}
          variant="standard"
          placeholder={placeholder}
        />
      )}

      {fieldType === "multiline" && (
        <TextareaAutosize
          value={memoVal}
          onChange={(e) => {
            setMemoVal(e.target.value)
          }}
          onBlur={(e) => {
            const newData = produce(screenData, (draft) => {
              const oportunidade = draft.oportunidades[index]

              const newOportunidade = Object.assign(oportunidade, {
                [field]: e.target.value
              })

              draft.oportunidades[index] = newOportunidade
              // oportunidade[field] = e.target.value
            })
            setScreenData(newData)
          }}
          placeholder={placeholder}
        />
      )}

      {fieldType === "number" && typeof fieldValue === "number" && (
        <NumericFormat
          value={fieldValue}
          allowLeadingZeros
          thousandSeparator="."
          decimalSeparator=","
          allowedDecimalSeparators={[","]}
          prefix={isMoney ? "R$ " : ""}
          fixedDecimalScale={isMoney}
          decimalScale={decimalPlaces || 0}
          style={{
            marginTop: "10px",
            height: "25px",
            width: `${totalDecimalWidth}px`,
            border: "none",
            borderBottom: "1px solid #cccccc"
          }}
          onValueChange={(values, sourceInfo) => {
            const newData = produce(screenData, (draft) => {
              const oportunidade = draft.oportunidades[index]

              const newOportunidade = Object.assign(oportunidade, {
                [field]: values.floatValue
              })

              draft.oportunidades[index] = newOportunidade
            })
            setScreenData(newData)
          }}
        />
      )}
    </TableCell>
  )
}

export default TabelaAnaliticaField
