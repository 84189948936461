export const formatDate = (date: any) => {
  return date
}

export function getStatusColor(status: string) {
  switch (status) {
    case "Aprovado":
      return "green"
    case "Não Aprovado":
      return "red"
    case "Pendente":
      return "gold"
    default:
      return "inherit"
  }
}

export function getStatusCampoColor(status: string) {
  switch (status) {
    case "Concluído":
      return "green"
    case "Crédito em Utilização":
      return "green"
    case "Não Confirmado":
      return "red"
    case "Pendente de Arquivos":
      return "red"
    case "Suspenso":
      return "red"
    case "Em Andamento":
      return "gold"
    default:
      return "inherit"
  }
}
