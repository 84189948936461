import { TableHead } from "@mui/material"
import React from "react"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TextField from "@mui/material/TextField"
import { produce } from "immer"
import { formatDate } from "./utils"
import { ITabelaAnalitica } from "./types"

type IPageData = {
  screenData: ITabelaAnalitica
  setScreenData: (newScreenData: ITabelaAnalitica) => void
  isReadOnly?: boolean
  isCliente?: boolean
}

const TabelaAnaliticaHeader = (pageData: IPageData) => {
  const { screenData, setScreenData, isReadOnly, isCliente } = pageData

  return (
    <TableHead
      style={{
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 1
      }}
    >
      <TableRow>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span
            style={{
              display: "flex",
              width: "100px",
              justifyContent: "center",
              alignItems: "center"
            }}
            className="table-title"
          >
            Tributo
          </span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span className="table-title">Nível</span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span
            style={{
              display: "flex",
              width: "70px",
              justifyContent: "center",
              alignItems: "center"
            }}
            className="table-title"
          >
            Status
          </span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span
            style={{
              display: "flex",
              width: "100px",
              justifyContent: "center",
              alignItems: "center"
            }}
            className="table-title"
          >
            Período
          </span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white",
            width: "50%"
          }}
        >
          <span
            className="table-title"
            style={{
              width: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Descrição da Oportunidade
          </span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span className="table-title">Total por Oportunidade</span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span className="table-title">
            Status de Aprovação do diagnóstico
          </span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span
            className="table-title"
            style={{
              display: "flex",
              width: "220px",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Status do Andamento do Trabalho de Campo
          </span>
        </TableCell>
        <TableCell
          align="center"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "white"
          }}
        >
          <span className="table-title" style={{ color: "red" }}>
            Parcela Mensal da Perda por Decadência
          </span>
        </TableCell>

        {!isCliente && (
          <TableCell
            align="center"
            style={{
              position: "sticky",
              left: 0,
              backgroundColor: "white"
            }}
          >
            <span className="table-title">Previsão de Honorários</span>
          </TableCell>
        )}

        {screenData.oportunidades ? (
          <>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span
                className="table-title"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                1ª Parcial Entregue
              </span>

              {isReadOnly ? (
                <span>{formatDate(screenData.dataParcial1)}</span>
              ) : (
                <TextField
                  id="data1"
                  sx={{ height: "14px", top: "3px" }}
                  value={formatDate(screenData.dataParcial1)}
                  size="small"
                  onChange={(e) => {
                    const newData = produce(screenData, (draft) => {
                      draft.dataParcial1 = e.target.value
                    })
                    setScreenData(newData)
                  }}
                  variant="standard"
                  placeholder="Data"
                />
              )}
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span
                className="table-title"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                2ª Parcial Entregue
              </span>
              {isReadOnly ? (
                <span>{formatDate(screenData.dataParcial2)}</span>
              ) : (
                <TextField
                  id="data2"
                  sx={{ height: "14px", top: "3px" }}
                  value={formatDate(screenData.dataParcial2)}
                  size="small"
                  onChange={(e) => {
                    const newData = produce(screenData, (draft) => {
                      draft.dataParcial2 = e.target.value
                    })
                    setScreenData(newData)
                  }}
                  variant="standard"
                  placeholder="Data"
                />
              )}
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span
                className="table-title"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                3ª Parcial Entregue
              </span>
              {isReadOnly ? (
                <span>{formatDate(screenData.dataParcial3)}</span>
              ) : (
                <TextField
                  id="data3"
                  sx={{ height: "14px", top: "3px" }}
                  value={formatDate(screenData.dataParcial3)}
                  size="small"
                  onChange={(e) => {
                    const newData = produce(screenData, (draft) => {
                      draft.dataParcial3 = e.target.value
                    })
                    setScreenData(newData)
                  }}
                  variant="standard"
                  placeholder="Data"
                />
              )}
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span
                className="table-title"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                4ª Parcial Entregue
              </span>
              {isReadOnly ? (
                <span>{formatDate(screenData.dataParcial4)}</span>
              ) : (
                <TextField
                  id="data4"
                  sx={{ height: "14px", top: "3px" }}
                  value={formatDate(screenData.dataParcial4)}
                  size="small"
                  onChange={(e) => {
                    const newData = produce(screenData, (draft) => {
                      draft.dataParcial4 = e.target.value
                    })
                    setScreenData(newData)
                  }}
                  variant="standard"
                  placeholder="Data"
                />
              )}
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span
                className="table-title"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                5ª Parcial Entregue
              </span>
              {isReadOnly ? (
                <span>{formatDate(screenData.dataParcial5)}</span>
              ) : (
                <TextField
                  id="data5"
                  sx={{ height: "14px", top: "3px" }}
                  value={formatDate(screenData.dataParcial5)}
                  size="small"
                  onChange={(e) => {
                    const newData = produce(screenData, (draft) => {
                      draft.dataParcial5 = e.target.value
                    })
                    setScreenData(newData)
                  }}
                  variant="standard"
                  placeholder="Data"
                />
              )}
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span
                style={{
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                className="table-title"
              >
                Futuro
              </span>
            </TableCell>
            <TableCell
              align="center"
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span
                className="table-title"
                style={{
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Total dos Valores Entregues
              </span>
            </TableCell>
            <TableCell
              align="center"
              style={{
                // position: "sticky",
                left: 0,
                backgroundColor: "white"
              }}
            >
              <span className="table-title">Observações</span>
            </TableCell>

            {isReadOnly && (
              <TableCell
                align="center"
                style={{
                  // position: "sticky",
                  left: 0,
                  backgroundColor: "white"
                }}
              >
                <span className="table-title" />
              </TableCell>
            )}
          </>
        ) : null}
      </TableRow>
    </TableHead>
  )
}
export default TabelaAnaliticaHeader
