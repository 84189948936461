import React, { useState, useContext, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import api from "../../Config/api"
import "./style.css"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { alpha, InputBase, Button, Tooltip } from "@mui/material"
import { Col, Input, Row, Label } from "reactstrap"
import DeleteIcon from "@mui/icons-material/Delete"

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import ResponsiveDrawer from "../../components/menu"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import UploadIcon from "@mui/icons-material/Upload"
import SaveIcon from "@mui/icons-material/Save"
import PeopleIcon from "@mui/icons-material/People"
import CurrencyInput from "react-currency-input-field"
import { IMaskInput } from "react-imask"

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 420,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))

const mdTheme = createTheme()

const NovoCliente = () => {
  const navigate = useNavigate()
  const params = useParams()
  const cnpj2 = params._id

  const [cnpj, setCnpj] = useState(cnpj2)

  const [razaoSocial, setRazaoSocial] = useState("")
  const [grupo, setGrupo] = useState("")
  const [subgrupo, setSubgrupo] = useState("")
  const [email, setEmail] = useState(null)
  const [_idCliente, setIdCliente] = useState("")
  const [listaEmailSecundario, setListaEmailSecundario] = useState([""])
  const [simples, setSimples] = useState(false)
  const [dataSimples, setDataSimples] = useState("")
  const [dataContrato, setDataContrato] = useState("")
  const [faturamento, setFaturamento] = useState("")
  const [observacao, setObservacao] = useState("")
  const [plataforma, setPlataforma] = useState("")
  const [recebimentoContrato, setRecebimentoContrato] = useState("")

  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data4, setData4] = useState("")

  const [status, setStatus] = useState("")

  const [rows, setRows] = useState([])

  const handleInputChange = (e, index) => {
    const list = [...listaEmailSecundario]
    list[index] = e.target.value
    setListaEmailSecundario(list)
  }

  const handleRemoveClick = (index) => {
    const list = [...listaEmailSecundario]
    list.splice(index, 1)
    setListaEmailSecundario(list)
  }

  const handleAddClick = () => {
    setListaEmailSecundario([...listaEmailSecundario, ""])
  }

  useEffect(() => {
    async function getCliente() {
      const cliente = await api.post("/find-cliente-cnpj", { cnpj })
      // console.log(cliente);
      setIdCliente(cliente.data.cliente._id)
      setCnpj(cliente.data.cliente.cnpj)
      setRazaoSocial(cliente.data.cliente.razaoSocial)
      setDataContrato(
        cliente.data.cliente.dataContrato !== null
          ? cliente.data.cliente.dataContrato.substring(0, 10)
          : null
      )
      setFaturamento(cliente.data.cliente.faturamento)
      setRecebimentoContrato(
        cliente.data.cliente.recebimentoContrato !== null
          ? cliente.data.cliente.recebimentoContrato.substring(0, 10)
          : null
      )
      setPlataforma(cliente.data.cliente.plataforma)
      setObservacao(cliente.data.cliente.observacao)
      setGrupo(cliente.data.cliente.grupo)
      setSubgrupo(cliente.data.cliente.subgrupo)
      setEmail(cliente.data.cliente.email)
      setEmail(cliente.data.cliente.email)
      setListaEmailSecundario(cliente.data.cliente.emailSecundario)
      setSimples(cliente.data.cliente.simples)
      setDataSimples(cliente.data.cliente.data1.substring(0, 10))
      setData1(cliente.data.cliente.data1.substring(0, 10))
      setData2(cliente.data.cliente.data2.substring(0, 10))
      setData3(cliente.data.cliente.data3.substring(0, 10))
      setData4(cliente.data.cliente.data4.substring(0, 10))
    }

    getCliente()
  }, [])

  useEffect(() => {
    const getCliente = async () => {
      const cliente = await api.post("/find-cliente-cnpj", { cnpj })
      let cont = 0
      const rows = []
      while (cont < cliente.data.cliente.filiais.length) {
        rows.push({ id: cliente.data.cliente.filiais[cont] })
        cont++
      }
      setRows(rows)
    }
    getCliente()
  }, [cnpj])

  function mesAnterior(data) {
    let ano = data.substring(0, 4)
    let mes = data.substring(5, 7)
    const dia = data.substring(8, 10)
    setData1(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData2(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData3(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    setData4(`${ano}-${mes}-${dia}`)
    if (mes === 1) {
      mes = 12
      ano -= 1
    } else {
      mes -= 1
    }
    return data1, data2, data3, data4
  }

  function refreshPage() {
    window.location.reload(false)
  }

  const updateCliente = async (e) => {
    e.preventDefault()
    if (!data1 || !email) {
      setStatus(0)
      toast.warn("Preencha pelo menos uma data e e-mail principal")
    } else {
      setStatus(1)
      if (simples === true) {
        mesAnterior(dataSimples)
        const dados = {
          _id: _idCliente,
          simples: true,
          email,
          emailSecundario: listaEmailSecundario,
          dataContrato,
          faturamento,
          observacao,
          grupo,
          subgrupo,
          plataforma,
          recebimentoContrato,
          data1,
          data2,
          data3,
          data4,
          dataSimples
        }
        await api
          .patch("/editar-dados-cliente", dados)
          .then((resp) => {
            toast.success("Cliente editado com sucesso!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 15000
            })
            refreshPage(() => window.location.reload(), 15000)
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(
                `Erro ao editar o cliente, tente novamente mais tarde!`,
                {
                  position: toast.POSITION.TOP_CENTER
                }
              )
            }
          })
      } else {
        const dados = {
          _id: _idCliente,
          email,
          emailSecundario: listaEmailSecundario,
          dataContrato,
          faturamento,
          observacao,
          plataforma,
          grupo,
          subgrupo,
          recebimentoContrato,
          simples,
          data1,
          data2,
          data3,
          data4
        }
        await api.patch("/editar-dados-cliente", dados)
        toast.success("Cliente editado com sucesso!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000
        })
        refreshPage(() => window.location.reload(), 15000)
      }
    }
  }
  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title={`Cliente - ${razaoSocial}`} />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <form className="form" autoComplete="off" onSubmit={updateCliente}>
              <Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <center>
                      <span className="submenu">Editar Cliente</span>
                    </center>
                    <hr />

                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <BootstrapInput
                        type="text"
                        required
                        inputProps={{
                          maxLength: "8"
                        }}
                        label="CNPJ"
                        name="cnpj"
                        placeholder="CNPJ"
                        value={cnpj}
                        readOnly
                      />
                      <BootstrapInput
                        type="text"
                        required
                        id="razaoSocial"
                        name="razaoSocial"
                        placeholder="Razão social"
                        value={razaoSocial}
                        onChange={(e) => setRazaoSocial(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <BootstrapInput
                        type="text"
                        id="plataforma"
                        name="plataforma"
                        value={plataforma}
                        placeholder="Plataforma"
                        label="Plataforma"
                        onChange={(e) => setPlataforma(e.target.value)}
                      />
                      <BootstrapInput
                        type="text"
                        id="observacao"
                        name="observacao"
                        value={observacao}
                        placeholder="Observações"
                        label="Observações"
                        onChange={(e) => setObservacao(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <BootstrapInput
                        type="text"
                        id="grupo"
                        name="grupo"
                        value={grupo}
                        placeholder="Grupo"
                        label="Grupo"
                        onChange={(e) => setGrupo(e.target.value)}
                      />
                      <BootstrapInput
                        type="text"
                        id="subgrupo"
                        name="subgrupo"
                        value={subgrupo}
                        placeholder="Subgrupo"
                        label="Subgrupo"
                        onChange={(e) => setSubgrupo(e.target.value)}
                      />
                    </Box>
                    <hr />

                    <Box
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "50ch",
                          display: "flex",
                          flexDirection: "column"
                        }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Label>Data do Contrato:</Label>
                      <BootstrapInput
                        type="date"
                        id="dataContrato"
                        value={dataContrato}
                        name="dataContrato"
                        onChange={(e) => setDataContrato(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "50ch",
                          display: "flex",
                          flexDirection: "column"
                        }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Label>Data do Receb. Contrato:</Label>
                      <BootstrapInput
                        type="date"
                        value={recebimentoContrato}
                        id="recebimentoContrato"
                        name="recebimentoContrato"
                        onChange={(e) => setRecebimentoContrato(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "50ch",
                          display: "flex",
                          flexDirection: "column"
                        }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Label>Faturamento:</Label>
                      <CurrencyInput
                        type="text"
                        label="faturamento"
                        id="number"
                        value={faturamento}
                        decimalSeparator=","
                        groupSeparator="."
                        min={0}
                        name="faturamento"
                        style={{ width: 445 }}
                        onChange={(e) => setFaturamento(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1, width: "50ch" }
                      }}
                      autoComplete="off"
                    >
                      <BootstrapInput
                        type="email"
                        required
                        id="email"
                        name="email"
                        placeholder="Email principal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                    {listaEmailSecundario.map((email, idx) => {
                      return (
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "50ch" }
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <BootstrapInput
                            type="email"
                            id="emailSecundario"
                            name="emailSecundario"
                            placeholder="Email secundário"
                            value={email}
                            onChange={(e) => handleInputChange(e, idx)}
                          />
                          <Box
                            sx={{
                              "& > :not(style)": { mr: 1, width: "25ch" }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            {listaEmailSecundario.length !== 1 && (
                              <Button
                                variant="contained"
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  backgroundColor: "#f07167"
                                }}
                                onClick={() => handleRemoveClick(idx)}
                                startIcon={<DeleteIcon fontSize="small" />}
                              >
                                Remover
                              </Button>
                            )}
                            {listaEmailSecundario.length - 1 === idx && (
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  backgroundColor: "#28A6DE"
                                }}
                                onClick={handleAddClick}
                                startIcon={<AddCircleOutlineOutlinedIcon />}
                              >
                                Adicionar
                              </Button>
                            )}
                          </Box>
                        </Box>
                      )
                    })}
                    {simples === false ? (
                      <div>
                        <hr />
                        <Row className="linhas">
                          <h6>
                            Selecione as datas para os periodos de diagnósticos
                          </h6>
                          <Box
                            sx={{
                              "& > :not(style)": { m: 1, width: "25ch" }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Tooltip
                              title="Selecione pelo menos uma data para diagnóstico. Digite as datas começando por este campo"
                              arrow
                            >
                              <BootstrapInput
                                type="date"
                                id="data1"
                                name="data1"
                                required
                                value={data1}
                                onChange={(e) => setData1(e.target.value)}
                              />
                            </Tooltip>
                            <BootstrapInput
                              type="date"
                              id="data2"
                              name="data2"
                              value={data2}
                              onChange={(e) => setData2(e.target.value)}
                            />

                            <BootstrapInput
                              type="date"
                              id="data3"
                              name="data3"
                              value={data3}
                              onChange={(e) => setData3(e.target.value)}
                            />
                          </Box>
                          <Box
                            sx={{
                              "& > :not(style)": { m: 1, width: "25ch" }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <BootstrapInput
                              type="date"
                              id="data4"
                              name="data4"
                              value={data4}
                              onChange={(e) => setData4(e.target.value)}
                            />
                          </Box>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <hr />
                        <Row className="linhas">
                          <h6>Período de diagnóstico</h6>
                          <Col xl={4}>
                            <Input
                              required
                              type="date"
                              name="dataSimples"
                              value={dataSimples}
                              onChange={(e) => setDataSimples(e.target.value)}
                            />
                          </Col>
                          <Col>Orientações sobre a data do simples</Col>
                        </Row>
                      </div>
                    )}
                    <hr />
                    {simples === false ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          "& > :not(style)": { mr: 1, width: "50ch" }
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          startIcon={<SaveIcon />}
                          sx={{
                            mt: 3,
                            mb: 3,
                            backgroundColor: "#28A6DE",
                            color: "#f2f2f2"
                          }}
                        >
                          Salvar Alterações
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                          startIcon={<UploadIcon />}
                          onClick={() =>
                            navigate(`/editar-doc-solictados-cliente/${cnpj2}`)
                          }
                        >
                          Editar Documentos Solicitados
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                          startIcon={<PeopleIcon />}
                          onClick={() =>
                            navigate(
                              `/editar-associado-ao-cliente/${_idCliente}`
                            )
                          }
                        >
                          Editar Associados Vinculados
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          "& > :not(style)": { mr: 1, width: "50ch" }
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 3,
                            backgroundColor: "#28A6DE",
                            color: "#f2f2f2"
                          }}
                          startIcon={<SaveIcon />}
                          onClick={() => mesAnterior(dataSimples)}
                        >
                          Salvar Alterações
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                          startIcon={<UploadIcon />}
                          onClick={() =>
                            navigate(`/editar-doc-solictados-cliente/${cnpj2}`)
                          }
                        >
                          Editar Documentos Solicitados
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
                          startIcon={<PeopleIcon />}
                          onClick={() =>
                            navigate(
                              `/editar-associado-ao-cliente/${_idCliente}`
                            )
                          }
                        >
                          Editar Associados Vinculados
                        </Button>
                      </Box>
                    )}
                  </Paper>
                </div>
              </Grid>
            </form>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <NovoCliente />
}
