import * as React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { createTheme, ThemeProvider } from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import Badge from "@mui/material/Badge"
import { useParams, Link, useLocation } from "react-router-dom"
import ListItemText from "@mui/material/ListItemText"
import AssignmentIcon from "@mui/icons-material/Assignment"
import AssessmentIcon from "@mui/icons-material/Assessment"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemButton from "@mui/material/ListItemButton"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount"
import logo_bkps from "../assets/imgs/logo_bkps.png"
import {
  mainListItems,
  secondaryListItems
} from "../Pages/DashboardAdmin/listItems"

import { AuthContext } from "../Context/auth"

const drawerWidth = 240

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2"
    }
  }
})

const ResponsiveDrawer = (props) => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const [perfil, setPerfil] = React.useState("")
  const [_id, set_id] = React.useState("")

  React.useEffect(() => {
    const jsonRecuperado = localStorage.getItem("user")
    const perfilUser = JSON.parse(jsonRecuperado).perfil
    const _idUser = JSON.parse(jsonRecuperado)._id
    setPerfil(perfilUser)
    set_id(_idUser)
  }, [])

  const location = useLocation()
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined

  const params = useParams()
  const { razaoSocial } = params
  const { logout } = React.useContext(AuthContext)

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` }
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {props.title} {[razaoSocial]}
            </Typography>

            <img src={logo_bkps} alt="logo" className="top" />
            <IconButton color="inherit" onClick={logout}>
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          color="inherit"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            <List component="nav">
              {mainListItems}
              <Divider sx={{ my: 3 }} />
              {secondaryListItems}
            </List>
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
            open
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                background: "rgb(137 143 149 / 32%)"
              }}
            >
              {perfil === 1 ? (
                <>
                  <ListItemButton
                    sx={{ bgcolor: "background.paper" }}
                    component={Link}
                    selected={location.pathname === "/novoCliente"}
                    to="/novoCliente"
                  >
                    <ListItemIcon>
                      <PersonAddIcon sx={{ color: "#fff", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Novo Cliente"
                      sx={{ color: "#fff", fontSize: 30 }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ bgcolor: "background.paper" }}
                    component={Link}
                    selected={location.pathname === "/clientes"}
                    to="/clientes"
                  >
                    <ListItemIcon>
                      <AssignmentIcon sx={{ color: "#fff", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Clientes"
                      sx={{ color: "#fff", fontSize: 30 }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ bgcolor: "background.paper" }}
                    component={Link}
                    selected={location.pathname === "/novoAssociado"}
                    to="/novoAssociado"
                  >
                    <ListItemIcon>
                      <SwitchAccountIcon sx={{ color: "#fff", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Novo Associado"
                      sx={{ color: "#fff", fontSize: 30 }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ bgcolor: "background.paper" }}
                    component={Link}
                    selected={location.pathname === "/associados"}
                    to="/associados"
                  >
                    <ListItemIcon>
                      <AssignmentIcon sx={{ color: "#fff", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Associados"
                      sx={{ color: "#fff", fontSize: 30 }}
                    />
                  </ListItemButton>
                </>
              ) : (
                <>
                  <ListItemButton
                    sx={{ bgcolor: "background.paper" }}
                    component={Link}
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 4)}
                    to={`/clientes-associado/${_id}`}
                  >
                    <ListItemIcon>
                      <AssignmentIcon sx={{ color: "#fff", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Clientes"
                      sx={{ color: "#fff", fontSize: 30 }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ bgcolor: "background.paper" }}
                    component={Link}
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 4)}
                    to={`/diagnostico-associado/${_id}`}
                  >
                    <ListItemIcon>
                      <AssessmentIcon sx={{ color: "#fff", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Diagnóstico"
                      sx={{ color: "#fff", fontSize: 30 }}
                    />
                  </ListItemButton>
                </>
              )}
            </Box>
          </Drawer>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default ResponsiveDrawer
