import React, { useState, useEffect } from "react"

import { useParams } from "react-router-dom"

import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"

// import Up4 from './TabelasFiliais/up4';

import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import SaveIcon from "@mui/icons-material/Save"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RectangleIcon from "@mui/icons-material/Rectangle"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"

import { toast, ToastContainer } from "react-toastify"
import ResponsiveDrawer from "../../../components/menu"

import Up15 from "./TabelasFiliais/up15"

import Up19 from "./TabelasFiliais/up19"
import Up20 from "./TabelasFiliais/up20"
import Up21 from "./TabelasFiliais/up21"
import Up22 from "./TabelasFiliais/up22"
import api from "../../../Config/api"

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

const mdTheme = createTheme()

const Diagnostico = () => {
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const [statusEdit, setStatusEdit] = useState("")

  const params = useParams()

  const filial = params._id
  const matriz = params._id

  const cnpj = localStorage.getItem("cnpj")
  const [datas, setDatas] = useState([])

  const [colunas, setColunas] = useState([])

  const [tipo, setTipo] = useState("")
  const setarTipo = (e) => setTipo({ ...tipo, [e.target.name]: e.target.value })

  const [loading, setLoading] = useState(false)

  const [tipo9, setTipo9] = useState("")
  const setarTipo9 = (e) =>
    setTipo9({ ...tipo9, [e.target.name]: e.target.value })
  const [tipo12, setTipo12] = useState("")
  const setarTipo12 = (e) =>
    setTipo12({ ...tipo12, [e.target.name]: e.target.value })
  const [tipo15, setTipo15] = useState("")
  const [tipo19, setTipo19] = useState("")
  const [tipo20, setTipo20] = useState("")
  const [tipo21, setTipo21] = useState("")
  const [tipo22, setTipo22] = useState("")

  useEffect(() => {
    const getUploadsFilial = async () => {
      const cliente = await api.post("/checklist-filial", { cnpj: filial })
      const col = []
      if (cliente.data.dados.data1 !== null) {
        col.push(
          `${cliente.data.dados.data1.substr(
            0,
            4
          )}/${cliente.data.dados.data1.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data2 !== null) {
        col.push(
          `${cliente.data.dados.data2.substr(
            0,
            4
          )}/${cliente.data.dados.data2.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data3 !== null) {
        col.push(
          `${cliente.data.dados.data3.substr(
            0,
            4
          )}/${cliente.data.dados.data3.substr(5, 2)}`
        )
      }
      if (cliente.data.dados.data4 !== null) {
        col.push(
          `${cliente.data.dados.data4.substr(
            0,
            4
          )}/${cliente.data.dados.data4.substr(5, 2)}`
        )
      }
      setColunas(col)
      setDatas(cliente)
      const res = {
        data: { resposta: cliente.data.tipos }
      }
      let cont = 0
      while (cont < cliente.data.tipos.length) {
        if (res.data.resposta[cont].tipo === 9) {
          setTipo9(cliente.data.tipos[cont])
        }
        if (res.data.resposta[cont].tipo === 12) {
          setTipo12(cliente.data.tipos[cont])
        }
        if (res.data.resposta[cont].tipo === 15) {
          setTipo15(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 19) {
          setTipo19(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 20) {
          setTipo20(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 21) {
          setTipo21(res.data.resposta[cont].statusCincoAnos)
        }
        if (res.data.resposta[cont].tipo === 22) {
          setTipo22(res.data.resposta[cont].statusCincoAnos)
        }
        cont++
      }
      setLoading(false)
    }

    getUploadsFilial()
    setLoading(true)
  }, [filial, cnpj])

  async function salvar(id, s1, s2, s3, s4) {
    setStatusEdit(0)
    await api.put("/editar-tipo-upload", { id, s1, s2, s3, s4 })
    setStatusEdit(1)
    if (statusEdit === 1) {
      toast.success("Registro salvo com sucesso!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Cliente - " />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container
            sx={{
              mt: 4,
              mb: 4,
              maxWidth: "100vw"
            }}
          >
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
              <center>
                <span className="submenu">Diagnóstico Filial</span>
              </center>
              <hr />
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span className="title">Arquivos</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">
                          {colunas.length === 0 ? "-" : colunas[0]}{" "}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">
                          {colunas.length === 0 ? "-" : colunas[1]}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">
                          {colunas.length === 0 ? "-" : colunas[2]}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">
                          {colunas.length === 0 ? "-" : colunas[3]}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="title">
                          {colunas.length === 0 ? "-" : colunas[4]}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* TIPO9 */}
                    {tipo9.length === 0 ? (
                      <></>
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          SPED Fiscal
                        </TableCell>
                        <TableCell align="center">
                          <Select
                            variant="standard"
                            name="status1"
                            value={tipo9.status1 ?? ""}
                            onChange={setarTipo9}
                          >
                            <MenuItem value={0}>
                              <CancelIcon sx={{ color: "red" }} />
                            </MenuItem>
                            <MenuItem value={1}>
                              <ErrorIcon sx={{ color: "gold" }} />
                            </MenuItem>
                            <MenuItem value={2}>
                              <CheckCircleIcon sx={{ color: "green" }} />
                            </MenuItem>
                            <MenuItem value={3}>
                              <RectangleIcon sx={{ color: "black" }} />
                            </MenuItem>
                            <MenuItem value={4}>
                              <FormatItalicIcon sx={{ color: "black" }} />
                            </MenuItem>
                          </Select>
                        </TableCell>
                        {colunas.length > 1 ? (
                          <TableCell align="center">
                            <Select
                              variant="standard"
                              name="status2"
                              value={tipo9.status2 ?? ""}
                              onChange={setarTipo9}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <ErrorIcon sx={{ color: "gold" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={3}>
                                <RectangleIcon sx={{ color: "black" }} />
                              </MenuItem>
                              <MenuItem value={4}>
                                <FormatItalicIcon sx={{ color: "black" }} />
                              </MenuItem>
                            </Select>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {colunas.length > 2 ? (
                          <TableCell align="center">
                            <Select
                              variant="standard"
                              name="status3"
                              value={tipo9.status3 ?? ""}
                              onChange={setarTipo9}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <ErrorIcon sx={{ color: "gold" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={3}>
                                <RectangleIcon sx={{ color: "black" }} />
                              </MenuItem>
                              <MenuItem value={4}>
                                <FormatItalicIcon sx={{ color: "black" }} />
                              </MenuItem>
                            </Select>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {colunas.length === 4 ? (
                          <TableCell align="center">
                            <Select
                              variant="standard"
                              name="status4"
                              value={tipo9.status4 ?? ""}
                              onChange={setarTipo9}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <ErrorIcon sx={{ color: "gold" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={3}>
                                <RectangleIcon sx={{ color: "black" }} />
                              </MenuItem>
                              <MenuItem value={4}>
                                <FormatItalicIcon sx={{ color: "black" }} />
                              </MenuItem>
                            </Select>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              salvar(
                                tipo9.id,
                                tipo9.status1,
                                tipo9.status2,
                                tipo9.status3,
                                tipo12.status4
                              )
                            }
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <SaveIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                    {/* TIPO12 */}
                    {tipo12.length === 0 ? (
                      <></>
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          CIAP - Controle de Crédito do ICMS do Ativo Permanente
                        </TableCell>
                        <TableCell align="center">
                          <Select
                            variant="standard"
                            name="status1"
                            value={tipo12.status1 ?? ""}
                            onChange={setarTipo12}
                          >
                            <MenuItem value={0}>
                              <CancelIcon sx={{ color: "red" }} />
                            </MenuItem>
                            <MenuItem value={1}>
                              <ErrorIcon sx={{ color: "gold" }} />
                            </MenuItem>
                            <MenuItem value={2}>
                              <CheckCircleIcon sx={{ color: "green" }} />
                            </MenuItem>
                            <MenuItem value={3}>
                              <RectangleIcon sx={{ color: "black" }} />
                            </MenuItem>
                            <MenuItem value={4}>
                              <FormatItalicIcon sx={{ color: "black" }} />
                            </MenuItem>
                          </Select>
                        </TableCell>
                        {colunas.length > 1 ? (
                          <TableCell align="center">
                            <Select
                              variant="standard"
                              name="status2"
                              value={tipo12.status2 ?? ""}
                              onChange={setarTipo12}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <ErrorIcon sx={{ color: "gold" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={3}>
                                <RectangleIcon sx={{ color: "black" }} />
                              </MenuItem>
                              <MenuItem value={4}>
                                <FormatItalicIcon sx={{ color: "black" }} />
                              </MenuItem>
                            </Select>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {colunas.length > 2 ? (
                          <TableCell align="center">
                            <Select
                              variant="standard"
                              name="status3"
                              value={tipo12.status3 ?? ""}
                              onChange={setarTipo12}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <ErrorIcon sx={{ color: "gold" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={3}>
                                <RectangleIcon sx={{ color: "black" }} />
                              </MenuItem>
                              <MenuItem value={4}>
                                <FormatItalicIcon sx={{ color: "black" }} />
                              </MenuItem>
                            </Select>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {colunas.length === 4 ? (
                          <TableCell align="center">
                            <Select
                              variant="standard"
                              name="status4"
                              value={tipo12.status4 ?? ""}
                              onChange={setarTipo12}
                            >
                              <MenuItem value={0}>
                                <CancelIcon sx={{ color: "red" }} />
                              </MenuItem>
                              <MenuItem value={1}>
                                <ErrorIcon sx={{ color: "gold" }} />
                              </MenuItem>
                              <MenuItem value={2}>
                                <CheckCircleIcon sx={{ color: "green" }} />
                              </MenuItem>
                              <MenuItem value={3}>
                                <RectangleIcon sx={{ color: "black" }} />
                              </MenuItem>
                              <MenuItem value={4}>
                                <FormatItalicIcon sx={{ color: "black" }} />
                              </MenuItem>
                            </Select>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              salvar(
                                tipo12.id,
                                tipo12.status1,
                                tipo12.status2,
                                tipo12.status3,
                                tipo12.status4
                              )
                            }
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <SaveIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Container>

          <Grid>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <center>
                  <span className="submenu">Diagnóstico 60 Meses</span>
                </center>
                <hr />
                <TableContainer component={Paper}>
                  <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    {tipo15 ? <Up15 /> : <></>}

                    {tipo19 ? <Up19 /> : <></>}

                    {tipo20 ? <Up20 /> : <></>}

                    {tipo21 ? <Up21 /> : <></>}

                    {tipo22 ? <Up22 /> : <></>}
                  </Table>
                </TableContainer>
              </Container>
            )}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <Diagnostico />
}
