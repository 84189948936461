import * as React from "react"
import Box from "@mui/material/Box"
import { DataGrid, GridActionsCellItem, ptBR } from "@mui/x-data-grid"
import SaveIcon from "@mui/icons-material/Save"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import {
  Toolbar,
  CssBaseline,
  Container,
  createTheme,
  ThemeProvider,
  CircularProgress,
  Button
} from "@mui/material"

import { toast, ToastContainer } from "react-toastify"
import { useParams, useNavigate } from "react-router-dom"
import UploadIcon from "@mui/icons-material/Upload"
import api from "../../Config/api"
import ResponsiveDrawer from "../../components/menu"

const mdTheme = createTheme()

export default function EditarListaDocCliente() {
  const navigate = useNavigate()
  const params = useParams()
  const [rows, setRows] = React.useState([])
  const [documentosNecessarios, setDocumentosNecessarios] = React.useState({})

  const [loading, setLoading] = React.useState(false)
  const cnpj2 = localStorage.getItem("cnpj")

  const cnpj = params._id

  React.useEffect(() => {
    setLoading(true)
    async function getDocumentosCliente() {
      const dadosCliente = await api.post("/dashboard", { cnpj })
      const descricaoDocumentos = await api.get("description-tipos-uploads")
      const response = {
        tipos: dadosCliente.data.tipos,
        description: descricaoDocumentos.data
      }
      setRows(
        response.tipos.map((d, idx, arr) => ({
          tipo: d.tipo,
          identificador: d.id,
          dataLimite: d.dataLimite,
          obs: d.obs,
          description: response.description.find((i) => i.id === d.tipo)
            .description
        }))
      )
    }
    getDocumentosCliente()
    setLoading(false)
  }, [cnpj])

  React.useEffect(() => {
    const data = rows.map((d) => ({
      tipo: d.tipo,
      description: d.description,
      dataLimite: d.dataLimite,
      obs: d.obs,
      editing: false,
      id: d.identificador
    }))
    setDocumentosNecessarios(
      data.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.id]: currentValue
        }),
        {}
      )
    )
  }, [rows])

  const columns = [
    { field: "tipo", headerName: "", width: 20, type: "number" },
    {
      field: "description",
      headerName: "Documentos",
      width: 350,
      editable: false
    },
    {
      field: "dataLimite",
      headerName: "Prazo de Envio",
      width: 150,
      editable: true,
      type: "date",
      valueFormatter: ({ value }) => {
        if (!value) return ""
        const dateArrayYYYY_MM_DD = new Date(value)
          .toISOString()
          .split("T")[0]
          .split("-")

        return `${dateArrayYYYY_MM_DD[2]}/${dateArrayYYYY_MM_DD[1]}/${dateArrayYYYY_MM_DD[0]}`
      }
    },
    { field: "obs", headerName: "Observações", width: 110, editable: true },
    {
      field: "actions",
      headerName: "Editar",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <SaveIcon
              sx={{
                color: params.row.editing ? "#ccc" : "#28A6DE",
                fontSize: 30
              }}
            />
          }
          label="Editar"
          disabled={params.row.editing}
          onClick={async (e) => {
            e.preventDefault()
            setLoading(true)
            const { id, dataLimite, obs, description } = params.row
            const dataUpdate = { _id: id, dataLimite, obs }
            await api
              .patch("/editar-tipos-uploads", dataUpdate)
              .then((resp) => {
                if (resp.status === 200) {
                  toast.success(
                    `Documento ${description} alterado com sucesso!`,
                    {
                      position: toast.POSITION.TOP_CENTER
                    }
                  )
                  setLoading(false)
                } else {
                  toast.error(
                    `Não foi possível alterar o documento ${description}! Por favor tente novamente mais tarde.`,
                    {
                      position: toast.POSITION.TOP_CENTER
                    }
                  )
                  setLoading(false)
                }
              })
          }}
        />
      ]
    },
    {
      field: "delete",
      headerName: "Deletar",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <DeleteForeverIcon
              sx={{ color: params.row.editing ? "#ccc" : "red", fontSize: 30 }}
            />
          }
          label="Deletar"
          disabled={params.row.editing}
          onClick={async (e) => {
            e.preventDefault()
            setLoading(true)
            const { id, description } = params.row
            await api.delete(`/delete-tipos-uploads/${id}`).then((resp) => {
              if (resp.status === 200) {
                toast.success(
                  `Documento ${description} deletado com sucesso!`,
                  {
                    position: toast.POSITION.TOP_CENTER
                  }
                )
                setLoading(false)
              } else {
                toast.error(
                  `Não foi possível deletar o documento ${description}! Por favor tente novamente mais tarde.`,
                  {
                    position: toast.POSITION.TOP_CENTER
                  }
                )
                setLoading(false)
              }
            })
          }}
        />
      ]
    }
  ]

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveDrawer title="Uploads" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <center>
              <span className="submenu">Editar documentos solicitados</span>
            </center>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                alignContent: "center",
                marginRight: "15px"
              }}
            >
              <hr />
            </div>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Container maxWidth="xxl" sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ height: 541, width: 900 }}>
                  <DataGrid
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={Object.keys(documentosNecessarios).map(
                      (key) => documentosNecessarios[key]
                    )}
                    onCellEditStart={(params) => {
                      setDocumentosNecessarios({
                        ...documentosNecessarios,
                        [params.row.id]: { ...params.row, editing: true }
                      })
                    }}
                    onCellEditStop={(params) => {
                      setDocumentosNecessarios({
                        ...documentosNecessarios,
                        [params.row.id]: { ...params.row, editing: false }
                      })
                    }}
                    processRowUpdate={(newRow) =>
                      setDocumentosNecessarios({
                        ...documentosNecessarios,
                        [newRow.id]: {
                          ...newRow,
                          dataLimite: newRow.dataLimite
                        }
                      }) ?? newRow
                    }
                    onProcessRowUpdateError={() => {}}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowHeight={() => "auto"}
                  />
                </Box>
              </Container>
            )}
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 3, backgroundColor: "#28A6DE" }}
              startIcon={<UploadIcon />}
              onClick={() => navigate(`/novoClienteProximo/${cnpj}`)}
            >
              Adicionar mais documentos
            </Button>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
