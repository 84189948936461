import { produce } from "immer"
import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import { NumericFormat } from "react-number-format"

const MyModal = ({
  modalFuturo,
  setModalFuturo,
  valoresData,
  setRowFuturo,
  index
}) => {
  const [data, setData] = useState([])
  useEffect(() => {
    setData(valoresData)
  }, [valoresData])

  const handleClose = () => {
    setModalFuturo(false)
  }

  const handleAddRow = () => {
    setData([...data, { descricao: "", valor: "" }])
  }

  const handleRemoveRow = (index) => {
    setData(data.filter((row, i) => i !== index))
  }

  const handleInputChange = (event, index, field) => {
    const newData = produce(data, (draft) => {
      draft[index][field] = event.target.value
    })
    setData(newData)
  }

  const handleInputChangeNumeric = (values, index, field) => {
    const newData = produce(data, (draft) => {
      draft[index][field] = parseFloat(values.floatValue)
    })
    setData(newData)
  }

  const handleSubmit = () => {
    setRowFuturo(data, index)
    handleClose()
  }

  const changeCurrencySeparator = (att) => {
    const correctSeparator = "."
    const edit = att.toLocaleString()
    const correct = edit.replace(",", correctSeparator)
    return correct
  }

  return (
    <div>
      <Dialog open={modalFuturo} onClose={handleClose}>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: "80px" }}>Descrição</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        value={row.descricao}
                        onChange={(event) =>
                          handleInputChange(event, index, "descricao")
                        }
                      />
                    </TableCell>
                    <TableCell
                      sx={{ height: "14px", top: "3px" }}
                      align="right"
                    >
                      <NumericFormat
                        value={row.valor}
                        allowLeadingZeros
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        allowedDecimalSeparators={[","]}
                        prefix="R$ "
                        style={{
                          top: "3",
                          height: "25px",
                          width: "150px",
                          border: "none",
                          borderBottom: "1px solid #cccccc"
                        }}
                        onValueChange={(values) =>
                          handleInputChangeNumeric(values, index, "valor")
                        }
                      />

                      {/* <TextField */}
                      {/* //   value={changeCurrencySeparator( */}
                      {/* //     row.valor */}
                      {/* //   ).toLocaleString()} */}
                      {/* //   InputProps={{ */}
                      {/* //     startAdornment: ( */}
                      {/* //       <InputAdornment position="start">R$</InputAdornment> */}
                      {/* //     ), */}
                      {/* //   }} */}
                      {/* //   onChange={(event) => */}
                      {/* //     handleInputChange(event, index, "valor") */}
                      {/* //   } */}
                      {/* // /> */}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        sx={{
                          "&:hover": {
                            background: "red",
                            color: "white"
                          },
                          color: "red",
                          cursor: "pointer"
                        }}
                        onClick={() => handleRemoveRow(index)}
                      >
                        REMOVER
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="center">
                    <Button
                      align="center"
                      sx={{
                        "&:hover": {
                          background: "green",
                          color: "white"
                        },
                        color: "green",
                        cursor: "pointer"
                      }}
                      onClick={handleAddRow}
                    >
                      ADICIONAR
                    </Button>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: 20
                    }}
                  >
                    Total:
                    <NumericFormat
                      value={data.reduce(
                        (acc, x) => acc + parseFloat(x.valor || 0),
                        0
                      )}
                      allowLeadingZeros
                      thousandSeparator="."
                      readOnly
                      decimalSeparator=","
                      fixedDecimalScale
                      decimalScale={2}
                      allowedDecimalSeparators={[","]}
                      prefix="R$ "
                      style={{
                        top: "3",
                        height: "25px",
                        width: "150px",
                        border: "none"
                      }}
                    />
                    {/* {data */}
                    {/*  .reduce((acc, x) => acc + parseFloat(x.valor || 0), 0) */}
                    {/*  .toLocaleString("pt-BR", { */}
                    {/*    style: "currency", */}
                    {/*    currency: "BRL", */}
                    {/*    era: "long", */}
                    {/*  })} */}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              width: 1 / 2,
              color: "red",
              "&:hover": {
                backgroundColor: "red",
                color: "white"
              }
            }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            sx={{
              width: 1 / 2,
              color: "green",
              "&:hover": {
                backgroundColor: "green",
                color: "white"
              }
            }}
            onClick={handleSubmit}
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MyModal
